import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import * as auth0 from 'auth0-js';
import { profile } from 'console';
import { SigninService } from 'src/app/services/sign-in.service';
import { commonDomains, environment, systemLanguages } from 'src/environments/environment';
import { Location } from '@angular/common';
import { Redirect } from 'auth0-js';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { catchError } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ProfileDto } from 'src/app/dtos/user-profile.dto';

import { ShepherdService } from 'angular-shepherd';
import { firstValueFrom } from 'rxjs';

import StepOptionsButton from 'shepherd.js/src/types/step';
import { COUNTRIES_DB } from '@angular-material-extensions/select-country';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, AfterViewInit{

  private webAuth = new auth0.WebAuth({
    domain: environment.auth0Domain,
    clientID: environment.auth0ID,
    audience: environment.auth0Audience,
  });
bookIconOpen: any;


  constructor(
    public auth: AuthService,
    public router:Router,
    private location: Location,
    private toastrService:ToastrService,
    private userService:UserService,
    private sanitize:DomSanitizer,
    public translate:TranslateService,
    private cdr: ChangeDetectorRef,
    private signinService:SigninService,
    private shepherdService: ShepherdService,
    private renderer: Renderer2,
    private elRef: ElementRef
    ){}

  public showModal!:boolean;
  public modalMessage!:string;
  public modalTittle!:string;
  public loading:boolean = true;
  public loadingLogo:boolean = true;
  public openLanguageSelection!:boolean;
  public systemLanguages = this.sortSystemLanguages();
  public selectedLanguage:string = this.checkDefaultLanguage();
  public openSelection!:boolean;
  public languageName!:string;
  public isAuthenticated!:boolean;
  public termsModal!:boolean;
  public isMobile:boolean = window.screen.availWidth < 1100;
  public toggledMobileMenu!:boolean;
  public searching!:boolean;
  public domainOwnerModal!:boolean;
  public target!:any;
  public signupMessage:boolean = true;


  public targetDomains: any;
  public userId: any;
  public targetNick: any;
  public targetCompany: any;
  public companyWebsite: any;
  public userProfile!: boolean;
  public user!:ProfileDto;
  public myNick!:string;
  public currentPage = this.router.url;


  private tourStepLegend:any = undefined; 
  private nextButton:any = undefined;
  private backButton:any = undefined;
  private exitButton:any = undefined;
  private finishButton:any = undefined;
  private tourDefaults:any = undefined;
  public existingGeneralToursPages:string[] = [
    '/overview',
    '/settings',
  ]
  private defaultTourButtons: {
    haveBackButton:any[],
    haveFinishButton:any[],
    onlyNextButton:any[]
  } = {
    haveBackButton : [],
    haveFinishButton:[],
    onlyNextButton : []
  }

  private currentStepId!:string |undefined;

  private tourMobilePositionCheck:any;
  private tourPositionDefault:any;


  sortSystemLanguages(){
    const sortedLanguages = systemLanguages.sort((a, b) => {
      const browserLanguage = window.navigator.language.toLocaleLowerCase();
      if ( a.language === browserLanguage ) return -1;
      if ( b.language === browserLanguage ) return 1;
      if ( a.language === 'en' ) return -1;
      if ( b.language === 'en' ) return 1;
      return a.name.localeCompare(b.name);
    });
    return sortedLanguages
  }

  public selectLanguage(language: string) {
    this.systemLanguages.filter( idiom => {
      if(language == idiom.language){
        this.languageName = idiom.name;
        this.cdr.detectChanges();
        return true;
      } else {
        return false;
      }
    })
    this.selectedLanguage = language;
    this.updateLanguage();
    this.userSelectedLanguage.emit(this.selectedLanguage);
    this.openSelection = false;
    this.cdr.detectChanges();
  }

  checkDefaultLanguage(){
    const browserLanguage = navigator.language.toLowerCase()
    const defaultLanguage = localStorage.getItem('defaultLang')
    const hasTransaltions = systemLanguages.filter(idiom => idiom.language === browserLanguage)
    if(defaultLanguage){
      return defaultLanguage;
    } else if(hasTransaltions.length > 0){
      return browserLanguage;
    } else {
      return 'en'
    }
  }

  @ViewChild('headerContent') headerContent!:any;
  @ViewChild('profileContent') profileContent!:any;
  @ViewChild('userProfileDiv') userProfileDiv!:any;


  @Input('userLogo')userLogo!: any;
  @Input('fromHome')fromHome!: boolean;
  @Input('JWTToken')JWTToken!: string;
  @Input('assets')assets!: any;
  @Input('fromProfile')fromProfile!: any;
  @Input('manualTourName')manualTourName!: string;

  @Output() selectTab: EventEmitter<string> = new EventEmitter<string>();

  @Output() closeProfile: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() finishedTour: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() userSelectedLanguage: EventEmitter<string> = new EventEmitter<string>();

  @Output() tabName: EventEmitter<string> = new EventEmitter<string>();


  async ngOnInit(): Promise<void> {
    let languageObject;
    this.populateTourLegendAsAButton();
    this.populateTourButton();
    this.populateTourDefaults();
    this.renderer.listen('document', 'click', (event: Event) => {
      const profileContent = this.profileContent?.nativeElement;
      const clickedInside = this.elRef.nativeElement.contains(event.target);
      if (!clickedInside && profileContent?.classlist.contains('active') && !this.isMobile) {
        this.toggleProfile();
      }
    });
  }

  ngAfterViewInit() {
    this.getTokenWithCorrectAudience();
    this.redirectIfMailLink();
    this.userSelectedLanguage.emit(this.selectedLanguage);
    this.checkToken();
    if (!this.isAuthenticated) {
      this.selectLanguage(this.selectedLanguage);
    }
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    this.setShepherdDefaults();
  }

  ngOnChanges(changes:SimpleChanges) {
    this.startManualTour(this.manualTourName);
    this.finishedTour.emit(true);
  }
  
  @HostListener('window:resize', ['$event'])
    onResize(event:any) {
    this.isMobile = event.target.innerWidth < 1100
  }

  setShepherdDefaults(){

    this.shepherdService.defaultStepOptions = {
      scrollTo: false,
      cancelIcon: {
        enabled: false
      },
      canClickTarget: true,
      arrow: true,
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4
    }

  }

  startManualTour(manualTourName:string){
    switch(manualTourName){
      case 'dashboardTour':
        this.startDashboardTour();
        break;
      case 'generalSettingsTour':
        this.startGeneralProfileTour();
        break;
    }
  }
  
  openPayment(target: any) {
    this.target = target.target;
    this.targetDomains = target.domains
    this.userId = target.userId;
    this.targetNick = target.nick;
    this.targetCompany = target.publicName;
    this.companyWebsite = target.websiteUrl;
    this.userProfile = true;
  }

  toggleBookIcon(open: boolean) {
    this.bookIconOpen = open;
  }

  populateTourButton() {
    this.nextButton = {
      //classes: 'shepherd-button-primary',
      text: () => {
        return this.translate.instant("TOUR.next")
      },
      action() {
        return this.next();
      }
    };
    this.exitButton = {
      classes: 'exit-button',
      text:() => {
        return this.translate.instant("TOUR.exit")
      }, 
      action: () => {
        this.shepherdService.complete();
        setTimeout(() => {
          this.toggleBookIcon(false);
        }, 100); 
      }
    };
    this.backButton = {
      classes: 'back-button secondary-button',
      text: () => {
        return this.translate.instant("TOUR.back")
      },
      action: () => {
        this.toggleBookIcon(true);
        return this.shepherdService.back();
      }
    } 
    this.finishButton = {
      classes: 'secondary-button',
      text: () => {
        return this.translate.instant("TOUR.finish")
      },
      action: () => {
        this.toggleBookIcon(false);
        return this.shepherdService.complete();
      }
    }

    this.defaultTourButtons.haveBackButton = [
        this.tourStepLegend,
        this.exitButton,
        this.backButton,
        this.nextButton
    ]

    this.defaultTourButtons.onlyNextButton = [
        this.tourStepLegend,
        this.exitButton,
        this.nextButton
    ]

    
    this.defaultTourButtons.haveFinishButton = [
      this.tourStepLegend,
      this.exitButton,
      this.backButton,
      this.finishButton
  ]

  }

  populateTourLegendAsAButton() {
    this.tourStepLegend = {
      text: () => {
        const tourObject = this.shepherdService.tourObject;
        const stepsLength = tourObject.steps.length;
        const currentStep = this.shepherdService.tourObject.getCurrentStep();
        let stepIndex = -1; 
      
        if (currentStep) {
          stepIndex = tourObject.steps.findIndex(step => step.id === currentStep.id); 
        }
      
        return `${stepIndex + 1} ${this.translate.instant('of').toLowerCase()} ${stepsLength}`; 
      },
      classes: "sheperd-steps-legend"
    }
  }

  

  startDashboardTour(){

  this.populateTourButton();
    setTimeout(()=> {
      this.shepherdService.addSteps([
        {
          id: "into",
          attachTo: {
            element: this.isMobile ? '#mobile-settings' : '#test',
            on: 'bottom-start'
          },

          arrow: true,
          canClickTarget: false,

          buttons: [
            this.tourStepLegend,
            this.exitButton,
            {
              text: () => { return this.translate.instant("next") },
              action: () => {
                this.shepherdService.complete();
                this.router.navigateByUrl("/settings");
                this.toggleProfile();           
              }
            }
          ],

          title: this.translate.instant("TOUR.welcomeToNicky"),
          text: this.translate.instant("TOUR.thisIsTheProfileSettingsPageLetsConfigure")
        }
      ]);

      this.shepherdService
      this.shepherdService.start();

    }, 100)
  }

  startGeneralProfileTour(){
    this.populateTourDefaults();
    const steps: StepOptionsButton.StepOptions[] = [
      
      {
        id: "introduction",
        attachTo: {
          element: '#introduction',
          on: 'bottom-start'
        },

        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.onlyNextButton,

        beforeShowPromise: () => {
          this.toggleBookIcon(true);
          return Promise.resolve();
        },

        title: this.translate.instant("TOUR.welcomeToSettingsTabsTour"),
        text: this.translate.instant("TOUR.letUsShowYouAround"),
        
      },
      {
        id: "general",
        attachTo: {
          element: '#General',
          on: 'bottom-start'
        },

        when: {
          show: () => {
            this.emitTabName('General')
          }
        },

        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.welcomeToNickyTour")
      },      
      {
        id: "Connections",
        attachTo: {
          element: '#Connections',
          on: 'bottom'
        },

        arrow: true,
        canClickTarget: true,

        when: {
            show: () => {
            this.emitTabName('Connections');
          }
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.nickyMayRetrieveYourWalletAddressesFromSomeExchanges")
      },
      {
        id: "Routing",
        attachTo: {
          element: '#Routing',
          on: 'bottom'
        },

        when: {
          show: () => {
            this.emitTabName('Routing');
          }
        },

        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.thisIsWhereYouDefineTheWalletAddresses")
      },
      {
        id: "domain",
        attachTo: {
          element: '#Domain',
          on: 'bottom'
        },

        arrow: true,
        canClickTarget: true,
        when: {
          show: () => {
            if( this.itsAPublicDomain() ) {
              this.shepherdService.next();
            } else {
              this.emitTabName('Domain');
            }
          }
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.doYouOwnYourDomain")
      },
      {
        id: "AutomaticConversion",
        attachTo: {
          element: '#AutomaticConversion',
          on: 'bottom'
        },

        arrow: true,
        canClickTarget: true,

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          {
            text: () => { return this.translate.instant("TOUR.back") },
            classes: "back-button",
            action: () => {
              this.shepherdService.back();
            }
          },
          this.nextButton
        ],

        when: {
          show: () => {
            this.emitTabName('AutomaticConversion');
          }
        },

        text: this.translate.instant("TOUR.nickyCanAutomaticallyConvertCryptoIntoCurrency")
      },
      {
        id: "BankWithdrawal",
        attachTo: {
          element: '#Off-Ramp',
          on: 'bottom'
        },

        arrow: true,
        canClickTarget: true,

        when: {
          show: () => {
            this.emitTabName('Off-Ramp');
          }
        },

        buttons: this.defaultTourButtons.haveBackButton,
        
        text: this.translate.instant("TOUR.nickyCanAutomaticallyWithdrawFundsIntoYourBankAccount")
      },
      {
        id: "Security",
        attachTo: {
          element: '#Security',
          on: 'bottom-end'
        },

        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.haveBackButton,

        when: {
          show: () => {
            this.emitTabName('Security');
          }
        },

        text: this.translate.instant("TOUR.inCaseYouHaveALocalAccount")
      },
      {
        id: "subtours",
        attachTo: {
          element: '#subtours',
          on: 'bottom-end'
        },

        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.lookForTheBookIconForMoreInfo")
      },
      {
        id: "End",
        attachTo: {
          element: '#tabs',
          // on: ''
        },

        when: {
          show: () => {
            this.emitTabName('General');
            this.toggleBookIcon(false);
          }          
        },

        arrow: true,
        canClickTarget: true,

        buttons: this.defaultTourButtons.haveFinishButton,

        text: this.translate.instant("TOUR.weHopeYouEnjoyUsingNicky!") +
              "<img src='/assets/white-nicky-logo.png' class='tour-logo'>",
        ...this.tourDefaults
      }
    ];

    if (this.itsAPublicDomain()) { 
      this.shepherdService.tourObject.removeStep('domain');
    }
    
    // const tourObject = this.shepherdService.tourObject;
    
    // tourObject.steps.forEach((step, index) => {
    //   const stepId = step.id;
    //   const stepObject = tourObject.getById(stepId);
    
    //   if (stepObject) {
    //     const currentTourTitle = stepObject.options.title;
    //     const updatedTitle = `${this.translate.instant('TOUR.step')} ${index + 1}/${tourObject.steps.length} ${currentTourTitle}`;
    
    //     stepObject.updateStepOptions({ title: updatedTitle });
    //   }
    // });

    this.startStepTour(steps);
    
  }


  getCurrentLanguage() {
    const defaultLang = localStorage.getItem('defaultLang') || 'en';
    // if(defaultLang?.includes('-')) {
    //   return defaultLang.split('-')[1];
    // } 
    return defaultLang;
  }


  populateTourDefaults() {
    this.defaultTourButtons.haveBackButton = [
        this.tourStepLegend,
        this.exitButton,
        this.backButton,
        this.nextButton
    ]

     this.defaultTourButtons.haveFinishButton = [
        this.tourStepLegend,
        this.exitButton,
        this.backButton,
        this.finishButton
    ]

    this.defaultTourButtons.onlyNextButton = [
        this.tourStepLegend,
        this.exitButton,
        this.nextButton
    ]

    this.tourMobilePositionCheck = this.isMobile ? 'bottom' : 'right';

    this.tourPositionDefault = 'bottom';

    this.tourDefaults = {
      scrollTo: { behavior: 'smooth', block: 'center', inline: 'center' }
    }

    this.shepherdService.defaultStepOptions = {
      scrollTo: this.tourDefaults,
      cancelIcon: {
        enabled: false
      },
      canClickTarget: this.isMobile ? false : true,
      arrow: true,
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4
    }  
  }
  
  private setFocusOnInput() {
    
    const element = this.shepherdService.tourObject.getCurrentStep()?.getTarget();

    if(this.isMobile) {
      element?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      });
    }
 

    if( element?.id === this.currentStepId ) {
      return;
    }

    this.currentStepId = element?.id;
  
    if (!element) {
      return;
    }

    
    if (element.tagName.toLowerCase() === 'input' || element.tagName.toLowerCase() === 'textarea') {
      element.focus({
        preventScroll: this.isMobile ? false : true
      });
      return;
    }

    var inputElement = element.querySelector('input') || element.querySelector('textarea');
    
    if (inputElement) {
      inputElement.focus({
        preventScroll: this.isMobile ? false : true
      });
      return;
    }

  }

  startPageTour(){
    const currentPage = this.router.url;

    if (this.userProfile) {
      //this.startProfileTour();
      return;
    }

    switch (currentPage) {
    
      case "/overview":
        this.overviewTour();
        break;

      case "/settings":
        this.startGeneralProfileTour();
        break;    

    }
  }


  private startTour() {

    const tourStatus = localStorage.getItem('tour') || "";
    const currentUrl = this.router.url;

    if (currentUrl.includes('/transactions') || currentUrl.includes('/overview')) {

      if(!tourStatus?.includes('/transactions') && !tourStatus.includes('/overview')) {
        localStorage.setItem("tour", `${currentUrl} ${tourStatus}`);

        if(this.isMobile) {
          this.toggleMenu();
        } 

        setTimeout(() => {
          this.startDashboardTour();
        }, 200)
      }

    }

    if (currentUrl.includes('/settings')) {

      if(!tourStatus?.includes('/settings')) {
        localStorage.setItem("tour", `${currentUrl} ${tourStatus}`);

        this.startGeneralProfileTour();

      }
    }
  }
  
      //OVERVIEW TOUR
      overviewTour() {

        const tourDefaults = {
          highlightClass: 'highlight'
        }
    
        const mobilePositionCheck = this.isMobile ? 'bottom' : 'right';
        const positionDefault = this.isMobile ? 'bottom' : 'bottom';
    
        this.shepherdService.defaultStepOptions = {
          scrollTo: { behavior: 'smooth', block: 'center' },
          cancelIcon: {
            enabled: false
          },
          canClickTarget: true,
          arrow: true,
          modalOverlayOpeningPadding: 8,
          modalOverlayOpeningRadius: 4
        }
        const steps: StepOptionsButton.StepOptions[] = ([
              {
                id: "start-overviewTour",
                attachTo: {
                  element: '#start-overviewTour',
                  on: mobilePositionCheck
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                arrow: true,
                canClickTarget: true,
    
                buttons: this.defaultTourButtons.onlyNextButton,

                beforeShowPromise: () => {
                  this.toggleBookIcon(true);
                  return Promise.resolve();
                },
                
                title: this.translate.instant("TOUR.hereIsTheOverview"),
                text: this.translate.instant("TOUR.hereYouWillFindAGeneralView"),
              },
              {
                id: "balances",
                attachTo: {
                  element: '#balances',
                  on: mobilePositionCheck
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                arrow: true,
                canClickTarget: true,
    
                buttons: this.defaultTourButtons.haveBackButton,

                text: this.translate.instant("TOUR.hereYouCanSeeYourBalanceInEach")             
              },
              {
                id: "wallets",
                attachTo: {
                  element: '#wallets',
                  on: mobilePositionCheck
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                arrow: true,
                canClickTarget: true,
        
                buttons: this.defaultTourButtons.haveBackButton,

                text: this.translate.instant("TOUR.clickHereToAddWallets")
              },
              {
                id: "contacts-list",
                attachTo: {
                  element: '#contacts-list',
                  on: mobilePositionCheck
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                arrow: true,
                canClickTarget: true,

                buttons: this.defaultTourButtons.haveBackButton,

                text: this.translate.instant("TOUR.yourContactsAreNickyUsers")                
              },
              {
                id: "contacts",
                attachTo: {
                  element: '#contacts',
                  on: mobilePositionCheck
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                arrow: true,
                canClickTarget: true,

                buttons: this.defaultTourButtons.haveBackButton,

                text: this.translate.instant("TOUR.clickHereToSeeABreakdownOfYourContactList")
              },
              {
                id: "check-transactions",
                attachTo: {
                  element: '#check-transactions',
                  on: 'bottom-start'
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                arrow: true,
                canClickTarget: true,

                buttons: this.defaultTourButtons.haveBackButton,

                text: this.translate.instant("TOUR.hereTheTransactionsControlledByNicky")                
              },
              {
                id: "all-transactions",
                attachTo: {
                  element: '#all-transactions',
                  on: mobilePositionCheck
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                arrow: true,
                canClickTarget: true,
    
                buttons: this.defaultTourButtons.haveFinishButton,
                
                highlightClass: tourDefaults.highlightClass,
                
                text: this.translate.instant("TOUR.clickHereToSeeABreakdownOfAllYourTransactions")
              },
            ]);

            this.startStepTour(steps);
          }

          private startStepTour(steps: StepOptionsButton.StepOptions[]) {
            steps.forEach(step => {
              if (!step.when) {
                step.when = {
                  show: () => {
                    const currentStepText = step.text;
                    const shepherdObject = this.shepherdService.tourObject;
                    const stepIndex = shepherdObject.steps.findIndex(currentStep => currentStep.id === step.id);
                    
                    if (this.isMobile) {
                      const element = shepherdObject.steps[stepIndex].getElement();
                      if (element) element.focus = () => { };
                      element?.scrollTo();
                    }
                    
                    if (stepIndex === steps.length - 1) {
                      shepherdObject.steps[stepIndex].updateStepOptions({
                        text: currentStepText + "<img src='/assets/white-nicky-logo.png' class='tour-logo'>"
                      });
                    }
          
                    setTimeout(() => {
                      this.setFocusOnInput();
                    }, 500);
                  }
                };
              }
            });
          
            this.shepherdService.addSteps(steps);
            this.shepherdService.start();
          }
        
  emitTabName(tabName:string){
    this.tabName.emit(tabName);
  }

  closeModal(event: any) {
    this.showModal = false;
    this.userProfile = false;
  }

  public getTokenWithCorrectAudience() {
    this.auth.isAuthenticated$.subscribe({
      next:(response:any) => {
        this.isAuthenticated = response;
        if(response == true) {
        this.auth.getAccessTokenSilently({
          authorizationParams: {
            audience: environment.auth0Audience
          },
        }).subscribe({
          next: (response: any) => {
            this.JWTToken = response;
            this.getUserInfo();
          },
          error: (e: any) =>
          console.log(e)
        })
      }},
      error: (e:any) => {
        console.log(e)
      }
    })

  }

  async startFirstTour(){
    const auth0User:any = await firstValueFrom(this.auth.user$);
    if(this.user.agreedPrivacyPolicy){
      if( auth0User?.enableTour ){
        this.startTour();
      } else {
        localStorage.removeItem("tour");
      }
    }
  }

  getUserInfo(){
    this.userService.getUser(this.JWTToken).subscribe({
      next: (response:any) => {
        this.user = response;
        if(this.user?.language != this.translate.getDefaultLang()) {
          this.selectLanguage(this.user?.language || this.selectedLanguage);
        }
        if(this.user.name && this.user.publicName && !this.user.agreedPrivacyPolicy ){
          this.termsModal = true;
          return;
        }
        this.getUserProfilePicture();
        this.startFirstTour();
      },
      error: (e:any) => {
        console.log(e )
      }
    })
  }

  goToPage(path:string){
    this.closeProfile.emit(true);
    if(path == this.router.url) {
      this.userProfile = false;
    } 
    document.body.style.overflow = 'auto';
    this.router.navigateByUrl(path)
  }

  formatPublicName(publicName:string | undefined){
    if(publicName?.split(' ').length && publicName?.split(' ').length > 3){
      return publicName.split(' ').slice(0, -1).join(' ');
    }
    return publicName;

  }

  private async convertImageUrlToFile(imageUrl:string | undefined) {
    const response = await fetch(imageUrl || '');
    const blob = await response.blob();

    const file = new File([blob], "profilePicture.jpg", { type: "image/jpg" });
    return file;
  }

  async updateLanguage() {
    try {
      const currentLang = this.translate.getDefaultLang().toLowerCase();
      const selectedLang = this.selectedLanguage.toLowerCase();

      this.translate.setDefaultLang(this.selectedLanguage);
      localStorage.setItem('defaultLang', this.selectedLanguage);
      
      if ((currentLang !== selectedLang || !this.user?.language) && await firstValueFrom(this.auth.isAuthenticated$)) {
        
        if (!this.JWTToken) {
          this.JWTToken = await firstValueFrom(this.auth.getAccessTokenSilently());
        }
        
        this.user = await firstValueFrom(
          this.userService.setUserPreferedLanguage(this.JWTToken, selectedLang)
        );
      }

  
    } catch (error) {
      console.error("Error updating language preferences:", error);
    }
  }
  

  goToRegisterAndRegisterReferral() {
    const shortId = document.getElementById('get-short-id')?.textContent;
    this.router.navigateByUrl('/register?referrer=' + shortId);
  }

  itsProfilePage(){
    if (document.querySelector('app-public-profile')) {
      if (!document.querySelector('app-payment'))
        return true;
    }
    return false;
  }

  getSignupLinklength(element:HTMLElement) {
    if(element.clientWidth > (window.innerWidth * 0.90) ) {
      return true;
    }
    return false;
  }



  getUserProfilePicture(){
    this.loadingLogo = true;
    this.userService.getProfilePicture(this.JWTToken).subscribe({
      next: (response:any) => {
        this.convertToBase64(response)
        this.userLogo = this.sanitize.bypassSecurityTrustUrl(URL.createObjectURL(response));
        if ( !this.user.hasProfilePicture ) {
          this.addImgFlagToOldUser(response)
        }
        this.loadingLogo = false;
      },
      error: (e:any) =>{
        this.loadingLogo = false;
      }
    })
  }

  addImgFlagToOldUser(userImg: any) {
    this.userService.updateProfilePicture(this.JWTToken, userImg).subscribe({
      error: (e: any) => {
        console.log(e)
      }
    })
  }

  getAuthLinkContents(){
    return this.translate.instant('signingUpForNickyTipcally').replace("<a routerLink='/register'>","").replace("</a>", "")
  }

  openProfile(target: any) {
    this.target = target;
    // this.targetDomains = target.domains
    // this.userId = target.id || target.shortId;
    // this.targetNick = target.nick || target.email;
    // this.targetCompany = target.publicName;
    // this.companyWebsite = target.websiteUrl;
    this.userProfile = true;
  }

  redirectToRegister(){
    this.goToRegisterAndRegisterReferral();
  }


  convertToBase64(file: File) {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target?.result) {
        this.userLogo = event.target.result;
      }
    };
    reader.readAsDataURL(file);
  }

  public logout(){
    this.webAuth.logout({
      clientID: environment.auth0ID,
      returnTo: environment.websiteUrl,
    })
  }


  checkToken(){
    this.loading = true;
    this.auth.user$.subscribe(
      (profile) => (
        profile?.email_verified == false ? this.notVerified() : '',
        this.myNick = profile?.email || "",
        this.loading = false
        )
    )
  }

  public goHome(){
    this.router.navigateByUrl('/home')
  }

  public hideModal(){
    this.showModal = false;
  }

  public toggleProfile() {
    const profileContent = this.profileContent.nativeElement;
    if (!profileContent?.classlist.contains('active') && this.isMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    profileContent?.classlist.toggle('active');
    
  }
  
  public toggleMenu() {
    const headerContent = this.headerContent.nativeElement;
    headerContent?.classlist.toggle('active');
    if (this.mobileMenuIsToggled()) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    this.toggledMobileMenu = !this.toggledMobileMenu
    this.cdr.detectChanges();
  }

  mobileMenuIsToggled(){
    const headerContent = this.headerContent?.nativeElement;
    if (headerContent?.classlist.contains('active') && this.isMobile) {
      return true;
    } else {
      return false;
    }
  }

  notVerified(){
      this.modalMessage = this.translate.instant('youCannotLoginWithAnUnverifiedEmail')
      this.modalTittle = this.translate.instant('emailNotVerified')
      this.showModal = true;
      localStorage.removeItem('notVerified');
      setTimeout(() => {
        this.logout();
      }, 5000);
  }

  handleImageError(event:any, userName?:string){
    event.target.onerror = null; 
    event.target.src = this.getInitialAvatar(userName?.charAt(0) || 'X');
  }

  getInitialAvatar(initial: string): string {
    const canvas = document.createElement('canvas');
    canvas.width = 100;
    canvas.height = 100;
    const context = canvas.getContext('2d');
    if(context) {
      context.fillStyle = 'rgba(222, 255, 150, 1)'; 
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.font = '50px Inter'; 
      context.fillStyle = 'rgba(60, 87, 0, 1)'; 
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(initial.charAt(0).toUpperCase(), canvas.width / 2, canvas.height / 2);
    }
    return canvas.toDataURL(); 
  }

   
  itsAPublicDomain(){
    return commonDomains.includes(this.getUserDomain());  
  }

  redirectIfMailLink() {
    if (window.location.href.indexOf('#access_token=') !== -1 || window.location.href.indexOf('#code=') !== -1 ) {
      this.auth.idTokenClaims$.subscribe((e:any) => console.log(e))
      this.auth.loginWithRedirect({
        appState: { target: '/overview' }
      });
      return
    }
    if(window.location.href.indexOf('#error=') !== -1){
      this.toastrService.error('Looks like the mail link you clicked on have some problem or has expired. Please, try again.', '', {
        timeOut:5000
      })
    }
  }


  acceptTerms(){
    const terms:ProfileDto = {
      agreedPrivacyPolicy: true,
    }

    localStorage.removeItem('tour');
    this.userService.editGeneralUserInfo(this.JWTToken, terms).subscribe({
      next:(response:any) => {
        this.user = response;
        this.termsModal = false;
        const userDomain = this.getUserDomain();
        if ( userDomain && !commonDomains.includes(userDomain) ) {
          this.domainOwnerModal = true;
        } else {
          this.startFirstTour();
        }
      },
      error: (e:any) => {
        console.log('error')
      }
    })
  }

  checkDomainOwnerStatus ( isDomainOwner:boolean ) {
    const userDomain = this.getUserDomain();
    if(isDomainOwner && userDomain) {
        this.userService.addDomain(this.JWTToken, userDomain)
    }
    this.domainOwnerModal = false;
    this.startFirstTour();
  }

  getUserDomain () {
    return this.user.email?.split('@')[1] || "gmail.com";
  }

  itsPaymentFlow() {
    const paymentReportFlow = document.querySelector('app-payment') ? 'paymentReport' : '';
    const paymentRequestFlow = document.querySelector('app-payment-requests') ? 'paymentRequest' : ''; 

    return paymentReportFlow || paymentRequestFlow
  }
}



