import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';



@Injectable()
export class NotificationService {

    constructor(private http: HttpClient,){

    }

    getNotifications(JWTToken:string) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Accept': 'text/plain',
            'Authorization': `Bearer ${JWTToken}`
          })
        };
        return this.http.get(environment.api + '/Notification/all?onlyUnread=true', httpOptions)
      }

    markAsRead(JWTToken:string, notificationId:string){
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': '*/*',
          'Authorization': `Bearer ${JWTToken}`
        })
      };

    return this.http.post(environment.api + '/Notification/mark-read?notificationId=' + notificationId, '' , httpOptions)
  }
}

