import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastrFactoryService {

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  success(message: string, title?: string, options?: any) {
    this.toastr.success(this.translate.instant(message), title, { timeOut: 3000, ...options });
  }

  error(message: string, title?: string, options?: any) {
    this.toastr.error(this.translate.instant(message), title, { timeOut: 3000, ...options });
  }

  info(message: string, title?: string, options?: any) {
    this.toastr.info(this.translate.instant(message), title, { timeOut: 3000, ...options });
  }

  warning(message: string, title?: string, options?: any) {
    this.toastr.warning(this.translate.instant(message), title, { timeOut: 3000, ...options });
  }

  unknownError() {
    this.error('unknownError');
  }
}