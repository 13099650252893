<div class="nicky-header">
    <div>
        <img class="logo" src="/assets/nicky-logo.png">
        <div class="links">
            <a>About</a>
            <a>Our Technology</a>
            <a>For Registrars</a>
            <a>For Users</a>
            <a>Contact</a>
        </div>
        <div class="social">
            <img src="/assets/icons/facebook.png">
            <img src="/assets/icons/instagram.png">
            <img src="/assets/icons/twitter.png">
            <img src="/assets/icons/linkedin.png">
            <img src="/assets/icons/youtube.png">
        </div>
    </div>
</div>
