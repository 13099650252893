export interface User {
  googleAuth: any;
  id: string,
  shortId:string | undefined,
  createdDate: string,
  email: string,
  emailConfirmed: boolean,
  agreedPrivacyPolicy: boolean,
  name?: string | null,
  publicName: string,
  websiteUrl?: string | null,
  bio: string,
  country: undefined | string,
  defaultFiatCurrencyId: string,
  autoOffRampPaymentReports?: boolean,
}

export class UserClass {
  googleAuth: any;
  id: string;
  shortId: string | undefined;
  createdDate: string;
  email: string;
  emailConfirmed: boolean;
  agreedPrivacyPolicy: boolean;
  name?: string | null;
  publicName: string;
  websiteUrl?: string | null;
  bio: string;
  country: undefined | string;
  defaultFiatCurrencyId: string;
  autoOffRampPaymentReports?: boolean;

  constructor(data: Partial<UserClass> & { userId?: string, nick?: string }) {
    this.googleAuth = data?.googleAuth;
    this.id = data?.id || data?.userId || '';
    this.shortId = data?.shortId;
    this.createdDate = data?.createdDate!;
    this.email = data?.email || data?.nick || '';
    this.emailConfirmed = data?.emailConfirmed!;
    this.agreedPrivacyPolicy = data?.agreedPrivacyPolicy!;
    this.name = data?.name;
    this.publicName = data?.publicName!;
    this.websiteUrl = data?.websiteUrl;
    this.bio = data?.bio!;
    this.country = data?.country;
    this.defaultFiatCurrencyId = data?.defaultFiatCurrencyId!;
    this.autoOffRampPaymentReports = data?.autoOffRampPaymentReports;
  }
}