<!-- <label *ngIf="!(auth.isAuthenticated$ | async)" class="user-description doesnt-accept sign-up-link">
                {{ 'signingUpForNickyTipcally' | translate }}
                <br *ngIf="!isMobile && translate.getDefaultLang() === 'pt-br'"> <a routerLink="/register" target="_blank">{{ 'signUpNow' | translate }}</a>
</label> -->

<div class="profile-wrapper" id="welcome-profile" [ngClass]="{'payment-profile-wrapper': paymentProfile}" #profileWrapper *ngIf="!profileNotFound" [ngStyle]="{'margin-top': overHeightProfile(profileWrapper) ? '28px' : ''}">
    <label class="short-id" style="visibility: hidden;" id='get-short-id'>{{ profileInfo?.shortId }}</label>
    <div *ngIf="!isMobile" class="gradient-bg green-gradient"></div>
    <!-- <div *ngIf="!isMobile" class="gradient-bg purple-gradient"></div> -->
    <div class="public-profile" *ngIf="finishAssetsLoad">
        <!-- <div class="close only-desktop " *ngIf="publicProfile">
            <img class="close-button clickable" src="assets/icons/x.png" alt="Close button" (click)="closeModal()">
        </div> -->
        <div *ngIf="this.selectedDomain" class="open-domain-info">
            <img src="assets/icons/back-public-profile.png" class="back-button clickable" (click)="this.selectedDomain = undefined"
                alt="back">
            <div class="showing-domain">
                <a [href]="url + '/XPTO123'"> {{ url + '/XPTO123' }} </a>
                <div class="copy-button">
                    <img class="clickable" src="assets/icons/copy-icon.png" alt="copy-icon">
                </div>
            </div>
            <div>
                <img src="assets/icons/twitter.png" class="clickable" alt="twitter">
                <img src="assets/icons/facebook.png" class="clickable" alt="facebook">
                <img src="assets/icons/whatsapp.png" class="clickable" alt="whatsapp">
                <img src="assets/icons/email.png" class="clickable" alt="email">
            </div>
        </div>
        <!-- <hr class="spacement"> -->
        <div class="user-info">
            <div class="personal-info">
                @if ( profileInfo.hasProfilePicture ) {
                    <img class="user-img" [src]="environment.api + 'User/get-public-profile-picture?userId=' + profileInfo.id" alt="user-image"
                    (error)="handleImageError($event, profileInfo.publicName)">
                } @else {
                    <img class="user-img" [src]="getInitialAvatar(profileInfo.publicName)" alt="user-image">
                }
                <div class="common-info">
                    <div>
                        <h3 title="{{ profileInfo?.publicName }}" class="public-name">{{ profileInfo.publicName }}</h3>
                        
                        <!-- <img *ngIf="profileInfo.country" class="country-flag"
                            src="/assets/svg-country-flags/svg/{{profileInfo.country}}.svg"> -->
                        <!-- <h2 title=" {{ profileInfo?.email }} " class="domain">{{ profileInfo.email }}</h2> -->
                    </div>
                    <div class="nick-types">
                        <div class="info-toggle">
                            <i class="ph-fill ph-envelope"
                            (mouseenter)="profileInfo.infoToggle = true" (mouseleave)="profileInfo.infoToggle = false" (click)="isMobile ? profileInfo.shortIdInfoToggle = !profileInfo.shortIdInfoToggle : ''"></i>
                            <div *ngIf="profileInfo.infoToggle" class="domain-info">
                                <label>
                                    {{ 'weHaveVerifiedTheOwnershipOfThisUserEmail' | translate }}
                                </label>
                            </div>
                        </div>
                        
                        <!-- <div class="info-toggle">
                            <img src="assets/icons/IdentificationCard.png"
                            (mouseenter)="shortIdInfoToggle = true" (mouseleave)="shortIdInfoToggle = false">
                            <div *ngIf="shortIdInfoToggle" class="domain-info short-id-info">
                                <label>
                                    {{ 'You can identify this user by their Short Id, which is a unique identification number, which in this case is:' | translate }}
                                </label>
                                <h3>
                                    {{ profileInfo?.shortId }}
                                </h3>
                            </div>
                        </div> -->
                       
                    </div>
                </div>
            </div>

            <div class="user-actions" *ngIf="!paymentProfile">
                <img src="/assets/icons/help-book.png" class="help-book clickable" id="bookIcon"  (click)="startTour()">

                <!-- <i [ngClass]="bookIconOpen ? 'ph-book-open' : 'ph-book'" class="ph button clickable ph-book" id="bookIcon" (click)="startTour()"></i> -->
                <i id="share-profile" class="pay button ph ph-share-network clickable" (click)="shareModal = true"  [matTooltipPosition]="'above'" matTooltip="{{ 'share' | translate }}"></i>

                <i id="edit-general-section" *ngIf="isMyProfile()" class="ph button clickable ph-pencil-simple-line" (click)="openSettings()"></i>
                <button class="secondary-button" (click)="addAsFavorite()" *ngIf="!isMyProfile() && !alreadyFavorite && isAuthenticated">
                    <i class="ph ph-plus"></i>
                    {{ 'public_profile.connect' | translate }}
                </button>
                <div class="toggle-selection selected-toggle clickable" 
                (click)="deleteNick(profileInfo.email)" *ngIf="!isMyProfile() && alreadyFavorite && isAuthenticated">
                <div class="toggle-icons">
                  <i class="ph ph-check"></i>
                </div>
                <h2> {{ 'public_profile.connected'  | translate }} </h2>
              </div>
            </div>
            

            <label id="short-id" class="short-id" #tooltip="matTooltip" (click)="!isMobile || tooltip.toggle()"
            [matTooltipPosition]="'above'" matTooltip="{{ 'theUserShortIdIsAShort' | translate }}">{{ profileInfo?.shortId }}</label>
        </div>
        <!-- <hr class="spacement"> -->
        <div class="connectivity-info">
           
            <div class="upper-content">
                <div class="buttons-wrapper" *ngIf="publicProfile && finishAssetsLoad" id="register-payment">
                    <button [ngClass]="{'disabled': noWallets()}" class="clickable pay-me" (click)="noWallets() || openPaymentFlow()">
                        {{ ('public-profile.pay' | translate) }}
                    </button>
                    <div class="bottom-content doesnt-accept-wrapper">
                        <label class="user-description doesnt-accept" *ngIf="noWallets()"> {{
                            'thisUserIsNotCurrentlyAcceptingPayments' | translate }}</label>
                    </div>
                </div>
                <div class="user-nicks" id="check-nicks">
                <!-- old my nicks -->
                     <!-- <div class="nicks">
                        <label class="property-name">{{ 'myNicks' | translate}}</label>
                        <div *ngIf="profileInfo?.domains?.length < 1" class="nick-box"
                            (mouseenter)="profileInfo.infoToggle = true" (mouseleave)="profileInfo.infoToggle = false">
                            <div *ngIf="profileInfo.infoToggle" class="domain-info">
                                <h3> {{profileInfo.email}} </h3>
                                 <h2> [ managed e-mail ] </h2>
                                <label>
                                    {{ 'weHaveVerifiedTheOwnershipOfThisUserEmail' | translate }}
                                </label>
                            </div>
                            <i class="ph-fill ph-envelope"></i>
                        </div>
    
                        <div class="nick-box" *ngFor="let domain of profileInfo.domains"
                            (mouseenter)="domain.infoToggle = true" (mouseleave)="domain.infoToggle = false">
                            <div *ngIf="domain.infoToggle" class="domain-info">
                                <h2> [ managed domain ] </h2>
                                <label>
                                    {{ 'public-profile.weHaveVerified' | translate }} {{ profileInfo.publicName?.split(' ')[0] }}
                                    {{ 'public-profile.ownershipOfThisOrganizationalDomain' | translate }}
                                </label>
                            </div>
                            <i class="ph-fill ph-globe" (click)="showDomainAndSocial(domain.name)"></i>
                        </div>
                    </div>  -->
                    <div *ngIf="!noWallets()" id="check-assets" class="user-active-assets">
                        <label class="property-name pay-me-with">{{ 'public-profile.payMeWith' | translate}}</label>
                        <img *ngFor="let asset of activeAssets"  #tooltip="matTooltip" (click)="!isMobile || tooltip.toggle()" [matTooltip]="asset" [matTooltipPosition]="'above'" [src]="'assets/icons/' + asset + '.png'">
                    </div>
                </div>
            </div>

            <!-- <hr class="spacement" *ngIf="profileInfo.bio"> -->
            <div class="bottom-content">
                <div class="user-description clickable">
                    <pre>{{ profileInfo?.bio?.trim() }}</pre>
                </div>

                <button *ngIf="paymentProfile" class="clickable pay-me back-to-payment" (click)="backToPayment()">
                    {{ ('backToPayment' | translate) }}
                </button>
            </div>

            <!-- <hr class="spacement" *ngIf="!(auth.isAuthenticated$ | async)"> -->
            <!-- <label *ngIf="!(auth.isAuthenticated$ | async)" class="user-description doesnt-accept sign-up-link">
                {{ 'signingUpForNickyTipcally' | translate }}
                <br *ngIf="!isMobile && translate.getDefaultLang() === 'pt-br'"> <a routerLink="/register" target="_blank">{{ 'signUpNow' | translate }}</a>
            </label> -->
        </div>
    </div>



    <div *ngIf="shareModal" class="modal-backdrop">
        <div class="modal share-modal" >
          <div class="modal-header flex-centered">
            <h3>{{ 'share' | translate }}</h3>
            <!-- <i class="ph-bold ph-x clickable" (click)="shareModal = false"></i> -->
          </div>
          <hr class="spacement">
          <div class="modal-body">

            <div class="qr-code-wrapper">     
                <div class="nick-wallet-code">
                  <div class="copy-code">
                    <!-- <label *ngIf="qrError" class="qr-error"> {{ 'PAYMENT_REPORT.sorryQrCodeGenerationIsUnavailable' | translate }} </label> -->
                    <qrcode id="qr-code" class="qr-code" [qrdata]="getProfileUrl()" [allowEmptyString]="true"
                      [cssClass]="'center'" [colorDark]="'#000000ff'" [colorLight]="'#ffffffff'" [elementType]="'canvas'"
                      [errorCorrectionLevel]="'M'" [margin]="4" [scale]="1" [width]="isMobile ? 120 : 173"
                      [title]="'A custom title attribute'"></qrcode>
                  </div>
                </div>
              </div>
         
            <div class="input-wrapper">
                <label> {{'public_profile.copyProfileLink' | translate }} </label>
                <div class="input-container" (click)="copyProfileUrl()" id="profile-link">
                    <i id="copy-link" class="ph ph-copy"></i>
                    <input [disabled]="true" type="text" [value]="getProfileUrl()">
                </div>
            </div>
          </div>
          <hr class="spacement">
          <div class="modal-buttons-wrapper">
              <!-- <button  (click)="shareModal = false" class="cancel-button"> {{ 'cancel' | translate }} </button> -->
              <button (click)="shareModal = false" id="close-button"> {{ 'close' | translate }} </button>
          </div>
        </div>
      </div>

      <!-- <app-nicky-footer></app-nicky-footer> -->
</div>


<div class="profile-wrapper" *ngIf="profileNotFound">
    <!-- <div *ngIf="!isMobile" class="gradient-bg purple-gradient"></div> -->
    <div class="public-profile profile-not-found">
        <!-- <div class="gradient-bg green-gradient"></div> -->
        <!-- <div class="close only-desktop " *ngIf="publicProfile">
            <img class="close-button clickable" src="assets/icons/x.png" alt="Close button" (click)="closeModal()">
        </div> -->
           <img src="/assets/icons/profile-not-found.png">
           <h1>
                {{ 'profileNotFound' | translate }}
           </h1>
           <h3>
                {{ 'theProfileThatYoureSearchingDoesNotExist' | translate }}
                <br *ngIf="!isMobile">{{ 'mayHaveBeenDeletedOrChangedToADifferenteAddress' | translate }}
           </h3>
    </div>
</div>



<div class="terms" *ngIf="finishAssetsLoad && !paymentProfile">
    <a class="clickable" href="https://nicky.me/policies/privacy-policy/" target="_blank">{{ 'PUBLIC_PROFILE.privacy' | translate }}</a>
    <a class="clickable" href="https://nicky.me/policies/terms-of-use/" target="_blank">{{ 'PUBLIC_PROFILE.terms' | translate }} </a> 
</div>

<div class="loading-modal" *ngIf="loading.length > 0">
    <div class="spinner"></div>
</div>