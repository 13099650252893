import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxCurrencyInputMode } from 'ngx-currency';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { ManualOffRampDto } from 'src/app/dtos/manual-off-ramp.dto';
import { PaymentRequestCreateDto } from 'src/app/dtos/payment-request.dto';
import { EventService } from 'src/app/services/event.service';
import { OffRampService } from 'src/app/services/off-ramp.service';
import { PaymentRequestService } from 'src/app/services/payment.request.service';
import { ToastrFactoryService } from 'src/app/services/toastr-factory.service';
import { UserService } from 'src/app/services/user.service';
import FormatCurrency from 'src/app/utils/format-currency-utils';
import { decimalPlacesFor, environment, qrApi } from 'src/environments/environment';
import { Asset } from '../models/asset.model';
import { optionSelectsModel } from '../models/option-select.model';
import { PaymentRequestStepsModel } from '../models/payment-request-steps.model';
import { savedOffRampSettings } from '../models/saved-off-ramp-seetings.model';
import { User } from '../models/user.mode';

@Component({
  selector: 'app-payment-requests',
  templateUrl: './payment-requests.component.html',
  styleUrls: ['./payment-requests.component.scss']
})
export class PaymentRequestsComponent {

  public step:string = 'payment-details';

  public completedSteps:PaymentRequestStepsModel = {
    paymentDetails: false,
    paymentInfo: false,
    reportPayment: false,
    finished: false
  }; 
  
  public previousStep!:string;

  @Input('paymentModal')paymentModal!: boolean;

  @Input('isAuthenticated')isAuthenticated!: boolean;

  @Input('JWTToken')JWTToken!: string;

  @Input('userId')userId!: string;

  @Input('user')user!: any;

  @Input('targetNick')targetNick!: string;

  @Input('targetUser')targetUser!:User | any;

  @Input('assets')assets!: any;

  public fiatAssets:any[] = [];

  public cryptoAssets:any[] = [];

  public qrModal!:boolean;

  public customRule!:boolean;
  public currentUserOffRampSettings:savedOffRampSettings[] = [];
  public manualOffRampSettings:ManualOffRampDto = {
    amount: undefined,
    assetId: undefined,
    cryptoConnectionId: undefined
  };


  @ViewChild('code') code!: ElementRef;
  @ViewChild('sendEmail') sendEmail!: ElementRef;

    
  constructor(
    private changeDetector:ChangeDetectorRef,
    public router: Router,
    private route: ActivatedRoute,
    public formatCurrency:FormatCurrency,
    public paymentRequestService:PaymentRequestService,
    public toastrService:ToastrService,
    private params: ActivatedRoute,
    public translate:TranslateService,
    private offRampService:OffRampService,
    private userService:UserService,
    private eventService: EventService,
    private toastrFactory: ToastrFactoryService){

  }

  public customCurrencyMaskConfig:any;



  public walletAddress:string = ""

  @Output() onCloseModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() successfullyTransaction: EventEmitter<boolean> = new EventEmitter<boolean>();


  public alreadyFinished!:boolean;
  public amount!: number;
  public showConfirmationModal = false;
  public selectedUserType: string | undefined = 'individual';
  public selectedAsset:string = "BTC";
  public browserLanguage = navigator.language
  public invalidAmount!:boolean;
  public ethPrice!:any;
  public btcPrice!:any; 
  public usdcPrice = 1.00;
  public usdtPrice= 1.00;
  public savedUsdValue!:string | number;
  public formattedPrice!:string;
  public savedAssetValue:any;
  public organization!:string;
  public email!:string;
  public hideCopyButton:boolean = false;
  public firstTime = true;
  public payingAsset:string | undefined ;
  public loading:boolean = false;
  public isCommaDecimal = this.formatCurrency.isCommaDecimal(navigator.language);
  public invalidEmail!:boolean;
  public toggleBook:boolean = false;
  public convertToFiatPorcentage:number = 0;
  public setConversionRate!:boolean;
  public receiverAcceptedAssets:Asset[] = [];
  public firefox = navigator.userAgent.indexOf("Firefox") > -1;
  public navigatorShare = this.firefox ? undefined : navigator.share;
  public url:string = window.location.href.replace('dashboard', '').replace('overview', '') + 'home?paymentId=';
  public cliboardItem = typeof ClipboardItem !== "undefined";



  public conversionValue!:number;
  public exchangeModal:boolean = false;
  public fullEmailModal:boolean = false;
  public showModal:boolean = false;
  public modalMessage!:string;
  public paymentIdModal!:boolean;
  public name!:string | undefined | null;
  public invalidPayerInfo!:boolean;
  public currentBackgroundPosition:number = 0;
  public isMobile!:boolean;

  currentOptionsSelects: {
    payingAsset: optionSelectsModel[],
    userType: optionSelectsModel[],
    currentOffRampSettings: optionSelectsModel[],
  } = {
    payingAsset: [],
    userType: [
       { 
        name: this.translate.instant('PAYMENT_REQUEST.payerIsAnIndividual'),
        value: 'individual'
      },
      { 
        name: this.translate.instant('PAYMENT_REQUEST.payerIsAnOrganization'),
        value: 'organization'
      },
    ],
    currentOffRampSettings: []
  };


  public areAUser:string = 'guest';

  
  public payment: FormGroup = new FormGroup({
    company: new FormControl(this.organization, [Validators.required,Validators.pattern('^.+$')]),
    name: new FormControl(this.name, [Validators.required,Validators.pattern('^.+$')]),
    email: new FormControl(this.email, [Validators.required, Validators.email ,Validators.pattern(/\.[a-zA-Z0-9]+$/)]),
    asset: new FormControl(this.selectedAsset, Validators.required),
    amount: new FormControl(null, [Validators.required]),
    receiverAddress: new FormControl(null, Validators.required),
    senderAddress: new FormControl(null, Validators.required),
    reference: new FormControl(null, Validators.maxLength(17)),
    description: new FormControl(null),
    status: new FormControl(),
    assetId:new FormControl(),
  });

  


  public shortId!:string;
  public environment: any = environment;

  ngOnInit(): void {
    this.getReceiverAcceptedAssets();    
    if(this.targetUser) {
      this.populateSenderInfo();
    } else {
      this.checkParams();
    }
  }

  async checkParams() {
    const queryParams = await firstValueFrom(this.params.queryParams);

    if (queryParams['paymentRequest'] && !this.targetUser ) {
      this.targetUser = {
        email: queryParams['email'] || undefined,
        name: queryParams['name'] || undefined
      }
      this.payment.controls['description'].setValue(queryParams['description'] || undefined);
      this.payment.controls['reference'].setValue(queryParams['reference'] || undefined);
      this.populateSenderInfo();
    }

  }

  populateSenderInfo() {
    this.email = this.targetUser.nick || this.targetUser.email;
    this.name = this.targetUser.name;
  }

  updateMaskConfig (){

    this.customCurrencyMaskConfig = {
      align: "center",
      allowNegative: false,
      allowZero: false,
      decimal: this.isCommaDecimal ? ',' : '.',
      precision: this.getPrecision(),
      prefix: "",
      suffix: "",
      thousands: this.isCommaDecimal ? '.' : ',',
      nullable: false,
      min: null,
      max: null,
      inputMode: NgxCurrencyInputMode.Financial
    }
  }

  async shareScreen(shortId:string) {
    try {
      const requestUrl = this.url + shortId;
      let qrCode = this.getQrCode();
      const imageBlob = await this.buildQRCodeImageBlob(qrCode);
      const text =  this.translate.instant('DASHBOARD.clickOnThisLink') + requestUrl + this.translate.instant('DASHBOARD.toBeRedirectedTo');
      if (navigator.share) {
        this.loading = true;
        try {
          await navigator.share({
            title: this.translate.instant('DASHBOARD.shareTitle'),
            text: text,
            url: window.location.hostname + '/home?paymentId=' + shortId,
            files: [new File([imageBlob], 'qr_code.png', { type: 'image/png' })],
          });
        } catch (e) {
          this.toastrService.error(this.translate.instant('unfortunatelyThisBrowserDoesNotSupportTheShareFunction'));
          this.navigatorShare = undefined;
        }
        this.loading = false;
      } else {
        this.toastrService.error(this.translate.instant('DASHBOARD.shareError'))
      }
    } catch (error) {
      console.log('Error sharing:', error);
    }
  }

  async buildQRCodeImageBlob(qrCode:any) {
    const qrCodeImage = qrCode
    const blob = await fetch(qrCodeImage);
    return await blob.blob();
  }


  navigatorSupportsShare() {
    return this.navigatorShare;
  }

  getQrCode() {
    return qrApi + this.url + this.shortId;
  }

  async copyQr() {
    const response = await fetch(this.getQrCode());
    const blob = await response.blob();
    this.copyQrToCliboard(blob);
  }

  
  copyQrToCliboard(imageBlob:any){
    if(!this.cliboardItem){
      const imageURL = URL.createObjectURL(imageBlob)
      const link = document.createElement('a')
      link.href = imageURL
      link.download = this.translate.instant('DASHBOARD.paymentRequest') + this.shortId
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    try {
      const data = [new ClipboardItem({ [imageBlob.type]: imageBlob })]
      navigator.clipboard.write(data)
      this.toastrService.success(this.translate.instant('qrHasBeenCopied'), this.translate.instant('success'), {
        timeOut: 3000
      })
    } catch (e:any) {
      this.toastrFactory.unknownError();
      console.log(e)
    }
  }
  
  public onCopyRequestLink(shortId: string) {
    let url = window.location.href.replace('dashboard', '').replace('overview', '') + 'home?paymentId=';
    navigator.clipboard.writeText(url + shortId);
    this.toastrService.success(this.translate.instant('theLinkToThePaymentRequestHasBeenCopied'), '', {
      timeOut: 3000
    })
  }
  
  arrayBufferToBase64(buffer: ArrayBuffer): string {
    const binary = String.fromCharCode(...new Uint8Array(buffer));
    return btoa(binary);
  }


  copyRequestId(){
    navigator.clipboard.writeText(this.url + this.shortId);
    this.toastrService.success(this.translate.instant('thePaymentIdHasBeenCopied'));
    this.alreadyFinished = true;
    // this.closeModal();
  }

  
  getPrecision(){
    return decimalPlacesFor[this.payingAsset || 'BTC']
  }

  setAmount(event:any){
    this.amount = event.target.value;
    this.payment.controls['amount'].setValue(this.amount);
  }

  handleImageError(event:any){
    event.target.onerror = null; 
    event.target.src = this.getInitialAvatar(event.target.title);
  }

  getInitialAvatar(initial: string): string {
    const canvas = document.createElement('canvas');
    canvas.width = 100;
    canvas.height = 100;
    const context = canvas.getContext('2d');
    if(context) {
      context.fillStyle = 'rgba(222, 255, 150, 1)'; 
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.font = '50px Inter'; 
      context.fillStyle = 'rgba(60, 87, 0, 1)'; 
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(initial.charAt(0).toUpperCase(), canvas.width / 2, canvas.height / 2);
    }
    return canvas.toDataURL(); 
  }


  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize()
  }

  
  checkWindowSize() {
    if (window.innerWidth <= 1100) {
      this.isMobile = true;
      return;
    }
    this.isMobile = false
  }
  
  populateCryptoAssets(){
    console.log(this.receiverAcceptedAssets);
    this.assets.forEach( (asset:Asset) => {
      if( this.receiverAcceptedAssets.length > 0 ){
          const userAcceptCurrentAsset = this.receiverAcceptedAssets.some(acceptedAsset => 
            acceptedAsset.id === asset.id          
          );
            if(asset.isFiat && userAcceptCurrentAsset){
                this.fiatAssets.push(asset)
            } else {
              if((!asset.id.includes("MATIC") || asset.assetTicker === 'POL') && userAcceptCurrentAsset)
                this.cryptoAssets.push(asset)
            } 
      } else {
        if(asset.isFiat){
          this.fiatAssets.push(asset)
        } else {
          if(!asset.id.includes("MATIC"))
          this.cryptoAssets.push(asset)
        }
      }});
    
    if(this.fiatAssets.length > 0){
      this.currentOptionsSelects.payingAsset.push({
        name: this.translate.instant('PAYMENT_REPORT.fiat'),
        value: 'disabled'
      })
    }
    this.fiatAssets.forEach((fiat:any) => {
      this.currentOptionsSelects.payingAsset.push({
        name: fiat.assetTicker,
        value: fiat.assetTicker
      })
    })
    if(this.cryptoAssets.length > 0){
      this.currentOptionsSelects.payingAsset.push({
        name: this.translate.instant('PAYMENT_REPORT.cryptoAssets' ),
        value: 'disabled'
      })
    }
    this.cryptoAssets.forEach((asset:any) => {
      this.currentOptionsSelects.payingAsset.push({
        name: asset.assetTicker,
        value: asset.assetTicker
      })
      
    })

    if( this.receiverAcceptedAssets.length > 0 ){
      this.payingAsset = this.receiverAcceptedAssets[0].assetTicker;
    } else {
      this.payingAsset = "BTC";
    }

    this.updateMaskConfig();
  }

  getOffRampAllSettings() {
    this.loading = true;
    this.offRampService.getOffRampAllSettings(this.JWTToken).subscribe({
      next: (response:any) => {
        this.currentUserOffRampSettings = [];
        response.forEach((setting:savedOffRampSettings) => {
          if(setting.isEnabled)
            this.currentUserOffRampSettings.push(setting)
        });
        this.currentUserOffRampSettings.forEach((offRampSetting:savedOffRampSettings) =>
        {  
            this.currentOptionsSelects.currentOffRampSettings.push({
              name: offRampSetting.cryptoConnection.name || undefined,
              value: offRampSetting.cryptoConnection.id
            })
        }
        )
        this.manualOffRampSettings.cryptoConnectionId = this.currentOptionsSelects.currentOffRampSettings[0]?.value || "";
        this.loading = false;
      },
      error: (e:any) => {
        console.log(e)
      }
    })
  }
  
  public backPreviousStep(nextStep:string){
    this.step = nextStep;
  }

  getReceiverAcceptedAssets(){
    this.userService.getAcceptedAssetsById(this.user.id).subscribe({
      next: (response:any) => {
        console.log(response)
        this.receiverAcceptedAssets = response.filter((asset:any) => !(asset.id.includes("MATIC")) || asset.assetTicker === "POL");
        this.populateCryptoAssets();
        this.updateMaskConfig();
      },
      error: (e:any) => {
        this.toastrFactory.unknownError();
        this.closeModal();
      }
    });
  }


  public nextStep(nextStep: string) {
    if (nextStep == "finish") {
      this.loading = true;
      this.payment.controls['name'].setValue(this.name);
      this.payment.controls['company'].setValue(this.organization);

      const paymentRequest = this.buildPaymentRequestDto();

      this.paymentRequestService.makeRequest(this.JWTToken, paymentRequest).subscribe({
        next: (response: any) => {
          this.toastrService.success(this.translate.instant('PAYMENT_REQUEST.paymentRequestSuccessfullyCreated'), '', {
            timeOut: 3000
          });
          this.eventService.notifyRequestComplete();
          this.shortId = response.bill.shortId
          this.step = "finish";
          this.loading = false;
          this.completedSteps.finished = true;
        },
        error: (e: any) => {
          console.log(e),
          this.toastrFactory.unknownError();
          this.loading = false;
        }
      })
      return;
    }
    if (this.step == 'payment-details') {
      this.email = this.email?.replaceAll(" ", "").trim();
      this.payment.controls['email'].setValue(this.email);
      this.payment.controls['name'].setValue(this.name);
      this.payment.controls['company'].setValue(this.organization)

      if (this.selectedUserType == "individual" && (!this.payment.controls['name'].valid || !this.payment.controls['email'].valid)) {
        this.completedSteps.paymentDetails = false;
        this.invalidPayerInfo = true;
        return;
      }

      if (this.selectedUserType == "organization" && (!this.payment.controls['company'].valid || !this.payment.controls['email'].valid)) {
        this.invalidPayerInfo = true;
        this.completedSteps.paymentDetails = false;
        return;
      }

      this.invalidPayerInfo = false;
      this.completedSteps.paymentDetails = true;

    }

    if (this.step == 'payment-info') {
      this.payment.controls['amount'].setValue(this.amount);
      const currentAmount = this.payment.controls['amount'].value;
      if (this.isValidAmount(currentAmount)) {
        this.savedUsdValue = currentAmount;
        this.formattedPrice = this.formatPrice(parseFloat(this.savedUsdValue.toString()))
        this.invalidAmount = false;
        this.completedSteps.paymentInfo = true;

      } else {

        this.completedSteps.paymentInfo = false;
        this.invalidAmount = true;
        return;

      }
    }
    this.previousStep = this.step;
    this.step = nextStep;
  }

  checkIfStepCanBeClicked(step: string) {
    const invalidAmount = !(this.isValidAmount(this.payment.controls['amount'].value));
    const invalidEmail = (!this.email[0]?.match(/^[a-zA-Z0-9]+$/));
    const invalidInfo = this.invalidPayerInfo || invalidAmount || invalidEmail;

    if(this.step === 'finished') {
      return;
    }
    
    this.invalidAmount = invalidAmount;
    this.invalidEmail = invalidEmail;

    switch (step) {
      case 'payment-details':
        if(!invalidInfo) {
          this.step = step;
        }
        break;

      case 'payment-info':
        if (this.completedSteps?.paymentDetails && !invalidInfo) {
          this.step = step;
        }
        break;

      case 'report-payment':
        if (this.completedSteps?.paymentInfo && !invalidInfo) {
          this.step = step;
        }
        break;
    }
  }

  buildPaymentRequestDto(){
    const paymentRequestDto:PaymentRequestCreateDto = {
        amountExpectedNative: this.payment.controls['amount'].value,
        billDetails: {
          description: this.payment.controls['description'].value?.trim().substring(0, 17),
          invoiceReference: this.payment.controls['reference'].value?.trim(),
          receiverUserId: this.user.id,
        },
        blockchainAssetId: this.formatAssetId(this.payingAsset || ''),
        requester: {
          email: this.payment.controls['email'].value?.trim() || null,
          name: this.payment.controls['name'].value?.trim()|| null,
          publicName: this.payment.controls['company'].value?.trim() || null
        },
        sendNotification: this.sendEmail?.nativeElement?.checked || true,
        convertToFiatPercentage: this.convertToFiatPorcentage / 100,
    }
    return paymentRequestDto;
  }

  formatAssetId(blockchainAssetId:string){
    switch (blockchainAssetId) {
      case "BTC":
        return "BTC.BTC";
      case "ETH":
        return "ETH.ETH";
      case "USDC":
        return "ETH.USDC";
      case "USDT":
        return "ETH.USDT";
      case "POL":
        return "MATIC.POL"
      default:
        return `${blockchainAssetId}.${blockchainAssetId}`;
    }
  }

  getEllipsisText(maxCharacteres:number, text:string){
    if(text?.length > maxCharacteres) {
      return `${text.substring(0, maxCharacteres)} ...`;
    } else {
      return text;
    }

  }

  animatedBackgroundTransition(toNextStep:boolean){
    let starsBg:HTMLElement | null = document.querySelector(".stars-bg");
    if (starsBg) {
      if (toNextStep) {
        this.currentBackgroundPosition += 20;
      } else {
        this.currentBackgroundPosition -= 20;
      }
      starsBg.style.transition = "background-position-x 1s ease";
      starsBg.style.backgroundPositionX = this.currentBackgroundPosition + "%";
    }
  }

  public openPaymentIdModal(exchange:string){
    this.showModal = true;
    this.modalMessage = exchange;
    this.paymentIdModal = true;
  }


  public isValidAmount(amount:any){
    if(this.formatCurrency.isCommaDecimal(navigator.language)){
      amount = amount?.toString().replace(/\./g, '').replace(/\,/g, '.')
     if(!isNaN(parseFloat(amount))){
      return parseFloat(amount) != 0
     }
     return false;
    } else {
      amount = amount?.toString().replace(/\,/g, '')
     if(!isNaN(parseFloat(amount))){
      return parseFloat(amount) != 0
     }
     return false;
    }
  }

  emailInput(){
    if(!this.email[0]?.match(/^[a-zA-Z0-9]+$/)){
      this.invalidPayerInfo = true;
      this.invalidEmail = true;
    } else {
      this.invalidEmail = false;
    }
    this.payment.controls['email'].setValue(this.email);
  }

  public closeModal(): void {
    this.removeQueryParams();
    this.router.navigateByUrl(this.router.url.split('?')[0]);
    this.onCloseModal.emit(false)
    this.step = 'payment-details';
    if(this.shortId){
      this.successfullyTransaction.emit(true)
    }
  }

  removeQueryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      queryParamsHandling: 'merge'
    });
  }

  getFloatValue(number:any){
    return parseFloat(number);
  }

  closeInfoModal() {
    this.showModal = false;
    this.paymentIdModal = false;
  }

  getCryptoName(assetName:string){
    if(assetName.includes('USDC') || assetName.includes('USDT')){
      return assetName
    } else {
      return assetName.split(".", 1)
    }
  }


  formatPrice(value: any, afterConversion?: boolean) {
    let decimal = this.formatDecimalPlaces()

    const formatter = new Intl.NumberFormat(this.isCommaDecimal ? "pt-BR" : "en-US", {
      maximumFractionDigits:  decimal,
      minimumFractionDigits:  decimal,
    });
    
      if (this.isCommaDecimal) {
        return formatter.format(value).trim().toString();
      }
        return formatter.format(value).trim();
  }

  formatDecimalPlaces(): number {
    return decimalPlacesFor[this.payingAsset || 'BTC']
      
}

  isFiat(payingAsset:string){
    if(payingAsset == "BRL" || payingAsset == "EUR" || payingAsset == "USD"){
      return true;
    }
    return false;
  }


  public isFraction(num: number): boolean {
    const fractionalPart = (num % 1).toString().split('.')[1]; 
    if(fractionalPart){
    return true; 
  }
    return false;
  }



}
