<div class="loading-modal" *ngIf="loading">
    <div class="spinner"></div>
  </div>
<div class="login">
    <!-- <div class="header-content">
        <img src="/assets/nicky-logo.png" (click)="goHome()" class="logo clickable only-desktop">
    </div> -->

    <form *ngIf="showLoginPage" [formGroup]="form">
        <div class="form-wrapper ">

            <button *ngIf="!signup" (click)="cancelForgotPassword()" class="secondary-button">
                <i class="ph ph-arrow-left"></i>
                {{ 'back' | translate }} 
            </button>

            <h1 *ngIf="!userForgotPassword"> Login </h1>
            <!-- <label class="authentication-method" *ngIf="!userForgotPassword"> {{ 'LOGIN.authenticationMethod' | translate }} </label> -->

            
            <h1 class="forgot-password" *ngIf="userForgotPassword"> 
                {{ 'LOGIN.forgotYourPassword' | translate }} 
            </h1>

            <h3 *ngIf="userForgotPassword">
                {{ 'LOGIN.enterYourEmailNickAndWeWill' | translate }} 
            </h3>
            


            <div *ngIf="!signup" 
            [ngClass]="{'invalid': !validNick(), 'valid': validNick()}" 
             class="login-input text-input-wrapper nick-input input-container">
                <input (keyup.enter)="authMethod()" formControlName="nick" type="text" name="nick" placeholder="Email*">
            </div>

            
            <a *ngIf="!signup && !userForgotPassword" class="request-link clickable" (click)="changeAuthInputs();">
                {{ getChangeInputsText() }}
           </a>

            <div *ngIf="!signup" class="login-button-wrapper" >
                <button class="login-button" 
                [ngClass]="{'disabled': !validNick()}"
                (click)="authMethod()"> {{ 'continue' | translate }}</button>
            </div>

            
            <div *ngIf="userForgotPassword" class="buttons-wrapper">
                <button *ngIf="getAuthMethod() == ''" [ngClass]="{'disabled': !form.controls['nick'].valid}" 
                [ngClass]="{'disabled': !validNick()}"
                (click)="forgotPassword(form.controls['nick'].value) " >
                 {{ 'continue' | translate }}
                </button>
            </div>

            <label class="or-label"> {{ 'or' | translate }}  </label>


            <button  *ngIf="!userForgotPassword" (click)="SSOAuth('google-oauth2')" class="clickable google-button social-button">
                 <img class="clickable" src="/assets/icons/google-icon.png"> 
                 <label class="clickable"> {{ 'LOGIN.loginWithGoogle' | translate }} </label>
            </button>

            <button  *ngIf="!userForgotPassword" (click)="SSOAuth('windowslive')" class="clickable google-button social-button">
                 <img class="clickable" src="/assets/icons/microsoft.png"> 
                 <label class="clickable"> {{ 'LOGIN.loginWith' | translate }} Microsoft </label>
            </button> 

            <button  *ngIf="!userForgotPassword" (click)="SSOAuth('github')" class="clickable google-button social-button">
                 <img class="clickable" src="/assets/icons/github.png">
                  <label class="clickable"> {{ 'LOGIN.loginWith' | translate }} Github </label>
            </button>

            <button  *ngIf="!userForgotPassword" (click)="SSOAuth('discord')" class="clickable google-button social-button">
                <img class="clickable" src="/assets/icons/discord.png">
                 <label class="clickable"> {{ 'LOGIN.loginWith' | translate }} Discord </label>
           </button>

              
            <button  *ngIf="!userForgotPassword" (click)="SSOAuth('apple')" class="clickable google-button social-button">
                <img class="clickable" src="/assets/icons/apple.png">
                 <label class="clickable"> {{ 'LOGIN.loginWith' | translate }} Apple </label>
            </button>
        </div>
    </form>


    <div class="success-register" *ngIf="redirectFromValidationEmail">
        <div class="step-container step-overview">
          <div class="step-tittle flex-centered">
            <img class="success-register-img" src="/assets/icons/success-payment.png">
            <h1>
              {{ 'success' | translate }}!
            </h1>
          </div>
          <label class="register-success-inform">
                {{ 'congratulationsYourEmailWasSuccessfullyConfirmed' | translate }} <br><br>
                {{'youWillBeRedirectedToTheloginScreenIn' | translate}} {{ remainingSeconds }} {{ 'REGISTER.seconds' | translate }}
          </label>
        </div>
      </div>
</div>