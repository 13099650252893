<app-modal [modalTittle]="modalTittle" [modalMessage]="modalMessage" *ngIf="!form.valid && showModal" (click)="hideModal()"></app-modal>
<app-modal [modalTittle]="modalTittle" [modalMessage]="modalMessage" *ngIf="showModal" (click)="hideModal()"></app-modal>



<div class="register stars-bg">

    <div class="header-content">
        <img class="clickable" src="/assets/nicky-logo.png" (click)="goHome()" class="logo clickable">
    </div>

<form [formGroup]="form">
<div class="form-wrapper ">
    <!-- <h1>Get started</h1> -->
    
    <div class="mail-container">
        <div class="user-input input-wrapper"
        [ngClass]="{'valid': form.controls['nick'].touched && form.controls['nick'].valid,
        'not-valid': form.controls['nick'].touched && !form.controls['nick'].valid }">
        <input formControlName="nick" type="text" name="nick" placeholder=" {{ 'REGISTER.user' | translate }}">
        </div>
    </div>
    

    <div class="personal-data-wrapper " >
    <div class="password-wrapper" >
    <div class="password-input input-wrapper"
    [ngClass]="{'wont-match': !matchAll() && form.controls['password'].touched, 'match': matchAll() && form.controls['password'].touched}">
    <input formControlName="password" class="half-input" [type]="seeingPassword ? 'text' : 'password'" name="password" placeholder=" {{ 'REGISTER.password' | translate }}">
    <img *ngIf="!seeingPassword" src="/assets/icons/seeing-password.png" class="seeing-password-icon" (click)="seeingPassword = true">
    <img *ngIf="seeingPassword" src="/assets/icons/password-hide.png" (click)="seeingPassword = false">
    </div>
     <div class="validators">
        <div class="symbol" 
        [ngClass]="{'wont-match': !match1Number() && this.form.controls['password'].touched, 'match': match1Number() && this.form.controls['password'].touched}"
         [matTooltipPosition]="'above'" 
 matTooltip="Numbers">1</div>

        <div class="symbol"
        [ngClass]="{'wont-match': !match1UpperCase() && this.form.controls['password'].touched, 'match': match1UpperCase() && this.form.controls['password'].touched}"
         [matTooltipPosition]="'above'" 
 matTooltip="Letters">A</div>

        <div class="symbol" 
        [ngClass]="{'wont-match': !match1LowerCase() && this.form.controls['password'].touched, 'match': match1LowerCase() && this.form.controls['password'].touched}"
         [matTooltipPosition]="'above'" 
 matTooltip="Letters">a</div>

        <div class="symbol symbols" 
        [ngClass]="{'wont-match': !matchSpecialCharacter() && this.form.controls['password'].touched, 'match': matchSpecialCharacter() && this.form.controls['password'].touched}"
         [matTooltipPosition]="'above'" 
 matTooltip="Symbols">&#64;#*</div>

        <div class="eight-digits" 
        [ngClass]="{'wont-match': !matchAtLeast8Digits() && this.form.controls['password'].touched, 'match': matchAtLeast8Digits() && this.form.controls['password'].touched}"
          [matTooltipPosition]="'above'" 
 matTooltip="Eight digits"><span>********</span></div>
    </div> 
    </div>

    <div class="public-name-input input-wrapper"
    [ngClass]="{'valid': form.controls['publicName'].touched && form.controls['publicName'].valid,
    'not-valid': form.controls['publicName'].touched && !form.controls['publicName'].valid }">
    <input formControlName="publicName" type="text" name="public-name" placeholder="{{ 'REGISTER.publicName' | translate }}">
    </div>
    </div>
    <div class="terms">
        <label class="terms-container">
            <input formControlName="terms" type="checkbox">
            <span class="terms-checkmark"></span>
          </label>
          <label class="text-label"> {{ 'REGISTER.iAgreeToNicky' | translate }} <a> {{ 'REGISTER.termsOfUse' | translate }} </a> {{ 'REGISTER.and' | translate }} <a> {{ 'REGISTER.privacyPolicy' | translate}}</a></label>
    </div>
    <!-- <div class="not-a-robot">
        <re-captcha class="g-recaptcha" formControlName="recaptcha" ></re-captcha>
    </div> -->
    <div class="buttons-wrapper">
        <a routerLink="/home" class="cancel-button"> {{ 'cancel' | translate }} </a>
        <button [ngClass]="{disabled: this.form.controls['terms'].value == false || !form.valid || nickAlreadyExist()}" (click)="form.valid && !nickAlreadyExist() ? submit() :  ''"> {{ 'REGISTER.register' | translate }} </button>
    </div>
</div>
</form>

<div class="loading-modal" *ngIf="loading">
    <div class="spinner"></div>
</div>

</div>
