  export enum ExchangeConversionRequestStatus {
    None = 'None',
    NotConfigured = 'NotConfigured',
    Started = 'Started',
    Error = 'Error',
    Success = 'Success',
  }
  
  export interface PaymentTransfer {
    id: string; 
    amountNative: number; 
    blockchainAssetId: string;
    convertedBlockchainAssetId?: string | null;
    convertedAmountNative?: number | null; 
    conversionRateUsed?: number | null; 
    exchangeConversionRequestStatus: ExchangeConversionRequestStatus;
  }