import { ChangeDetectorRef, Component, HostListener, enableProdMode } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, async, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfileDto } from './dtos/user-profile.dto';
import { SigninService } from './services/sign-in.service';
import { UserService } from './services/user.service';
import { COUNTRIES_DB, Country, MatSelectCountryComponent, MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { UTMDto } from './dtos/utm.dto';
import FiatByCountry from './utils/fiat-by-country';

declare const BRANCH_NAME: any;

export const toggleMenu = () => {
  let headerContent = document.querySelector(".header-content")
  let profileMenu = document.querySelector(".profile-menu")

  if (headerContent?.classList.contains('active')) {
    headerContent.classList.toggle('active');
  }

  if (profileMenu?.classList.contains('active')) {
    profileMenu.classList.toggle('active');
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'nicky';
  private isMobile: boolean = window.screen.availWidth < 450;
  public JWTToken!: string;
  public isAuthenticated!: boolean;
  public user!: ProfileDto;
  public loading!: boolean;
  private auth0User: any;

  constructor(
    public auth: AuthService,
    public router: Router,
    private userService: UserService,
    public translate: TranslateService,
    public angularCountry: MatSelectCountryComponent,
    public country: MatSelectCountryModule,
    private httpClient: HttpClient,
    private params: ActivatedRoute,
    private fiatByCountry: FiatByCountry
  ) {

  }

  ngOnInit(): void {
    this.getTokenWithCorrectAudience();
    this.getUTMParams()
  }

  getUTMParams() {
    this.params.queryParams.subscribe((params: Params) => {
      if (this.router.url === "/") {
        if (window.location.href.includes('error_description=many-users')) {
          localStorage.setItem('manyUsers', 'true')
        }
      }
      const utmSettings = {
        utmCampaign: params['utm_campaign'],
        utmMedium: params['utm_medium'],
        utmSource: params['utm_source']
      }
      localStorage.setItem("utmSettings", JSON.stringify(utmSettings));
    });
  }

  public getTokenWithCorrectAudience() {
    this.loading = true;
    this.auth.isAuthenticated$.subscribe({
      next: (response: any) => {
        this.isAuthenticated = response;
        if (response == true) {
          this.auth.getAccessTokenSilently({
            authorizationParams: {
              audience: environment.auth0Audience,
            },
          }).subscribe({
            next: (response: any) => {
              this.JWTToken = response;
              this.auth.user$.subscribe({
                next: (user: any) => {
                  this.auth0User = user;
                  this.getUserInfo();
                }
              })
            },
            error: (e: any) => {
              console.log(e);
              this.loading = false;
            }
          })
        } else {
          this.loading = false;
        }
      },
      error: (e: any) => {
        console.log(e);
        this.loading = false;
      }
    })

  }

  getUserInfo() {
    this.userService.getUser(this.JWTToken).subscribe({
      next: async (response: any) => {
        this.user = response;
        this.populateSocialSignupUserInfo();
      },
      error: (e: any) => {
        console.log(e);
        this.loading = false;
      }
    })
  }

  populateSocialSignupUserInfo() {
    if (!this.user.name && !this.user.publicName && !this.user.agreedPrivacyPolicy) {
      if (this.auth0User?.googleAuth) {
        const newUser: User = this.auth0User;
        const utmSettingsItem = localStorage.getItem('utmSettings');
        let utmInfo!: UTMDto;
        if (utmSettingsItem) {
          try {
            utmInfo = JSON.parse(utmSettingsItem);
          } catch (error) {
            console.error('Error parsing JSON from localStorage:', error);
            utmInfo = {};
          }
        } else {
          utmInfo = {};
        }
        const referrer = localStorage.getItem('referrer');
        let userCountry = this.checkSupportForUserCountry(newUser["https://example.com/country"] || '');
        const userInfo: ProfileDto = {
          agreedPrivacyPolicy: false,
          name: newUser.name,
          publicName: newUser.name,
          websiteUrl: undefined,
          country: userCountry?.toLowerCase(),
          umtCampaign: utmInfo.utmCampaign || undefined,
          umtMedium: utmInfo.utmMedium || undefined,
          umtSource: utmInfo.utmSource || undefined,
          referrer: referrer || undefined
        }
        this.setGeneralInfoForSocialSignup(userInfo, newUser);
      }
      this.loading = false;
      return

    } else {
      this.loading = false;
    }
  }

  checkSupportForUserCountry(userLocale: string) {
    const country = COUNTRIES_DB.filter(country => country.name === userLocale);
    if (country.length === 1) {
      return country[0].alpha2Code
    };

    return undefined
  }

  setGeneralInfoForSocialSignup(userInfo: ProfileDto, newUser: User) {

    this.userService.editGeneralUserInfo(this.JWTToken, userInfo).subscribe({
      next: async (response: any) => {
        const userImg: any = this.convertImageUrlToFile(newUser.picture);

        if (userImg) {
          this.setSocialUserImage(userImg, newUser);
        } else {
          this.setFirstSettlementAsset(newUser);
        }
      },
      error: (e: any) => {
        console.log(e);
        this.loading = false;
      }
    });
  }


  async setSocialUserImage(userImg: any, newUser: User) {
    userImg.then((img: File) =>
      this.userService.updateProfilePicture(this.JWTToken, img).subscribe({
        next: (response: any) => {
          this.setFirstSettlementAsset(newUser);
        },
        error: (e: any) => {
          console.log(e)
          this.setFirstSettlementAsset(newUser);
        }
      })
    )
  }

  setFirstSettlementAsset(newUser: User) {
    let userCountry = this.checkSupportForUserCountry(newUser["https://example.com/country"] || '');
    const asset = this.fiatByCountry.getUserFiatByCountry(userCountry?.toUpperCase() || "US")
    this.userService.setAcceptedAssets(this.JWTToken, asset).subscribe({
      next: (assets: any) => {
        location.reload();
        this.loading = false;
      },
      error: (e: any) => {
      }
    })
  }


  private async convertImageUrlToFile(imageUrl: string | undefined) {
    if (!imageUrl) {
      return undefined;
    }
    try {
      const response = await fetch(imageUrl);
      const blobImage = await response.blob();
      return blobImage;
    } catch (error) {
      console.error('Error fetching image:', error);
      return undefined;
    }
  }
}
