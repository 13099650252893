import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import * as auth0 from 'auth0-js'
import { RegisterPageComponent } from '../pages/register-page/register-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { WalletsForConnector } from '../dtos/wallets-for-connector.dto';
import { OffRampSettingModelSet } from '../components/models/off-ramping-settings.model';
import { OffRampSettings } from '../dtos/off-ramp.set.setting.dto';
import { withdrawFee } from '../dtos/withdrawl-fee.dto';
import { ManualOffRampDto } from '../dtos/manual-off-ramp.dto';
import { FiatWithdrawalConfigModel } from '../components/models/fiat-withdrawal-settings.model';

@Injectable()
export class OffRampService {

  constructor(
    private http: HttpClient,
  ) { }

  
  getOffRampAllSettings(JWTToken: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.get(environment.api + 'OffRamp/all-settings', httpOptions)
  }

  getAvailableConnectors(JWTToken: string, walletsForConnector?: WalletsForConnector) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.get(environment.api + `OffRamp/get-available-connectors?
    ${ walletsForConnector ? 'assetId=' + walletsForConnector?.assetId : ''}
    `,
     httpOptions)
  }
  

  getWalletsForConnector(JWTToken: string, walletsForConnector: WalletsForConnector) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.get(environment.api 
        + `OffRamp/get-wallets-for-connector?cryptoConnectionId=${walletsForConnector.cryptoConnectionId}&assetId=${walletsForConnector.assetId}`,
        httpOptions)
  }


  setOffRampingSettings(JWTToken: any, offRampSetting: OffRampSettings) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    const body:OffRampSettings = offRampSetting;
    return this.http.post(environment.api + 'OffRamp/set-setting', body, httpOptions)
  }

  
  offRampStart(JWTToken: any, manualOffRampSettings:ManualOffRampDto) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    const body = {
    };
    return this.http.post(environment.api + 
    `OffRamp/start?cryptoConnectionId=${manualOffRampSettings.cryptoConnectionId}
    &assetId=${manualOffRampSettings.assetId}&amount=${manualOffRampSettings.amount}`
    , body, httpOptions)
  }
    
  maxOffRampStart(JWTToken: any, manualOffRampSettings:ManualOffRampDto) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    const body = {
    };
    return this.http.post(environment.api + 
      `OffRamp/start-max?cryptoConnectionId=${manualOffRampSettings.cryptoConnectionId}
      &assetId=${manualOffRampSettings.assetId}`
    , body, httpOptions)
  }

  getOffRampFees(JWTToken: any, withdrawFee: withdrawFee){
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.get(environment.api 
        + `OffRamp/get-withdraw-fee?cryptoConnectionId=${withdrawFee.cryptoConnectionId}&assetId=${withdrawFee.assetId}&amount=${1000}`,
        httpOptions)
  }

  addFavorite(JWTToken: any, nick: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    const body = {

    };
    return this.http.post(environment.api + 'FavoriteNick?nick=' + nick + "&type=Email", body, httpOptions)
  }


  setFiatWithdrawalConfig(JWTToken: any, FiatWithdrawalConfig: FiatWithdrawalConfigModel){
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
        'Authorization': `Bearer ${JWTToken}`
      })
    };

    const body = FiatWithdrawalConfig;

    return this.http.post(environment.api + '/FiatWithdrawalConfig/set-setting', body, httpOptions)
  }

  getFiatWithdrawalConfig(JWTToken: any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.get(environment.api + `/FiatWithdrawalConfig/all-settings`, httpOptions)
  }



}






