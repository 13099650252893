import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { PaymentService } from "../services/payment.service";

@Injectable()
export default class ConvertAsset {
    public convertedAsset: { [key: string]: { value: any, asset: string } } = {};
    
    constructor(public toastrService:ToastrService,
        public paymentService:PaymentService,){
  }

  getConvertedAssets(assets:any){
    assets.forEach((asset:any) => {
        let assetInfo = asset
          this.getAssetConversionValue(assetInfo);
      })
    return this.convertedAsset;
  }

  getAssetConversionValue(assetInfo:any){
    let assetId = this.formatAsset(assetInfo.blockchainAsset.id);
    if(assetInfo.amountNative === 0){
        this.convertedAsset[assetId] = { value: 0, asset: assetId  };
        return;
    }
    if(assetId)
    this.paymentService.getCurrencyValue(assetId, "USD.USD").subscribe({
      next: (value: any) => {
        if(value.price == null){
          this.convertedAsset[assetId] = { value: 'Error getting prices', asset: assetId  };
          return;
        }
        this.convertedAsset[assetId] = { value: parseFloat(value.price), asset: assetId  };
      },
      error: (e: any) => {
        console.log(e);
        this.toastrService.error('Unknown error. Please, try again later ...', '', {
        timeOut: 3000
      })},
    })
  }

  formatAsset(asset: string) {
    if (asset === "USDC" || asset === "USDT")
      return "ETH." + asset
    if (asset === "MATIC")
      return "MATIC." + asset
    if (asset === "POL")
      return "MATIC." + asset
    if(!asset.includes(".")){
      return asset + "." + asset
    }
    return asset;
  }
}

