import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { decimalPlacesFor, environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class decimalPlacesService {
  constructor(private http: HttpClient) {}

  fetchData(): Promise<any> {
    const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'text/plain',
        })
      };
    return lastValueFrom(this.http.post(environment.api + 'BlockchainAsset/all', httpOptions));
  }
}

export function fetchDecimalPlaces(decimalConfiguration: decimalPlacesService): () => Promise<any> {
  return () => decimalConfiguration.fetchData().then((data:any) => {
    if(data && (data?.length > 0))
    data.forEach((asset:any) => {
          decimalPlacesFor[asset.assetTicker] = asset.decimalPrecisionUI
    });
  },
  (error) => {
    console.log(error);
  });
}