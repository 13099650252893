<div class="container">
<div class="custom-option-select" [ngClass]="{'opened': openSelection}" [ngClass]="{'disabled': disabled}">
    <div #selectionDiv [ngClass]="{'opened': openSelection}" class="selection-div clickable" (click)="openSelection = !openSelection">
        <label  class="selected-option common-padding clickable" >
            {{ (currentOption ? ( currentOption.name || '' ) : '')  | translate }}
        </label>
        <a class="dropdown-button">
            <img class="drop-button" src="/assets/icons/drop-button.png" alt="open-selection">
        </a>
    </div>
    <div class="options clickable" *ngIf="openSelection">
        <div class="options-wrapper" *ngFor="let option of options; let last = last; let first = first"  (click)="selectedOption(option)">
            <span class="styled-separator" *ngIf="!first"></span>
            <a *ngIf="option.value != 'disabled'" class="option common-padding" [ngClass]="{'last-option': last}">
                {{ (option.name || '') | translate }} 
            </a>
            <a *ngIf="option.value == 'disabled'" class="option common-padding disabled-option" [ngClass]="{'last-option': last}">
                {{ option.name }} 
            </a>
        </div>
    </div>
</div>
</div>

