import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  constructor(
    public toastrService:ToastrService,
    public translate:TranslateService,
    public header:HeaderComponent
    ){
  }
  public url:string =  window.location.href.replace('dashboard', '').replace('overview', '') + 'home?paymentId=';

  @Input('modalMessage')modalMessage!: string;

  @Input('isAuthenticated')isAuthenticated:boolean = true;
  
  @Input('modalTittle') modalTittle!: string;

  @Input('modalNick')modalNick!: string;

  @Input('modalPublicName') modalPublicName!: string;

  @Input('dashBoardOptions')dashBoardOptions!: boolean;

  @Input ('modalDeleteUser')deleteAccount!: boolean;

  @Input ('fullEmailModal')fullEmailModal!: boolean;

  @Input ('exchangeModal')exchangeModal!: boolean;

  @Input ('paymentIdModal')paymentIdModal!: boolean;

  @Input ('hideCopyButton')hideCopyButton!: boolean;

  @Input ('transactionWarning')transactionWarning!: any;

  @Input ('transactionWarningsModal')transactionWarningsModal!: boolean;

  @Input ('cancelRequest')cancelRequest!:boolean;

  @Input ('modalDeleteExchange')modalDeleteExchange!:boolean;



  ngOnInit(): void {}
  
 
  @Output() onCloseModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() onConfirmCancel :EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() onConfirmModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() onClickFavorite: EventEmitter<string> = new EventEmitter<string>();

  @Output() onDeleteConfirmation: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() deleteExchange: EventEmitter<boolean> = new EventEmitter<boolean>();


  public closeModal(): void {
    this.onCloseModal.emit(false)
  }

  public clickFavorite(){
    this.onClickFavorite.emit(this.modalNick)
    this.closeModal();
  }

  public userDeleteConfirmation(){
    this.onDeleteConfirmation.emit(true);
    this.closeModal();
  }

  
  public exchangeDeletionConfirmation(){
    this.deleteExchange.emit(true);
    this.closeModal();
  }

  logoutUser(){
    this.header.logout();
  }

  public emailCopy(){
    navigator.clipboard.writeText(this.modalMessage);
    const emailRegex = /\S+@\S+\.\S+/;
    if(emailRegex.test(this.modalMessage))
    this.toastrService.success("The complete email has been copied to the clipboard",'',{
      timeOut:3000
    })
    else{
      this.toastrService.success("The complete domain has been copied to the clipboard",'',{
        timeOut:3000
      })
    }
  }

  public paymentIdCopy(){
    navigator.clipboard.writeText( this.url + this.modalMessage );
    this.toastrService.success("Payment link has been copied to the clipboard",'',{
      timeOut:3000
    })
  }


  confirmRequestCancel(){
    this.onConfirmCancel.emit(true)
    this.closeModal();
  }
}
