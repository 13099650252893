<app-payment [isAuthenticated]="isAuthenticated" [paymentModal]="paymentFlow" [assets]="assets"
  (onCloseModal)="closeModal($event)" [targetUser]="targetUser" *ngIf="paymentFlow" [user]="user" [JWTToken]="JWTToken"
  [paymentRequestInfo]="requestInfo"></app-payment>


<app-public-profile [profileNotFound]="profileNotFound" [targetDomains]="targetDomains" [target]="target" [selectedLanguage]="selectedLanguage"
  [isAuthenticated]="isAuthenticated" [userId]="userId" [targetNick]="targetNick" [targetCompany]="targetCompany"
  [companyWebsite]="companyWebsite" [user]="user" [paymentRequestInfo]="requestInfo" [JWTToken]="JWTToken"
  *ngIf="userProfile || this.profileNotFound" [assets]="assets" (onCloseModal)="closeModal($event)"></app-public-profile>

<div class="loading-modal" *ngIf="loading">
  <div class="spinner"></div>
</div>

<app-modal [modalTittle]="modalTittle" [modalMessage]="modalMessage" [dashBoardOptions]="dashBoardOptions"
  [isAuthenticated]="isAuthenticated" [fullEmailModal]="fullEmailModal" [modalPublicName]="modalPublicName"
  [modalNick]="modalNick"  [exchangeModal]="exchangeModal"
  (onCloseModal)="closeModal($event)" (onClickFavorite)="addFavorite($event)" *ngIf="showModal"></app-modal>

<app-header (closeProfile)="closeModal($event)" *ngIf="paymentFlow || userProfile || profileNotFound" (userSelectedLanguage)="selectedLanguage = $event" [fromHome]="true" [JWTToken]="JWTToken"></app-header>

<div class="modal-backdrop" *ngIf="manyUsersModal">
  <div class="modal message-modal">
    <div class="modal-header">
      <h3> {{modalTittle}} </h3>
      <!-- <h3 class="close"> X </h3> -->
    </div>
    <div class="modal-body">
      <p [innerHTML]="modalMessage"></p>
      <div class="check-email clickable" >
        <input type="checkbox" [(ngModel)]="notify" (onChange)="notify = !notify" checked="true"> <label (click)="notify = !notify"> {{ 'iWantToBeNotified' | translate}}</label>
      </div>
    </div>
    <div class="modal-footer">
      <button (click)="logoutUser()"> {{ "backToOurWebsite" | translate }} </button>
    </div>
  </div>
</div>

<div class="home" (click)="toggleMenu()">



  <!-- <div *ngIf="!isMobile" class="info-wrapper">
    <div class="info-tittle">
      <div class="info-tittle">
        {{ 'HOME.whoWillYouPayToday' | translate }}
      </div>
    </div>
    
  </div>
  <div *ngIf="isMobile" class="info-wrapper mobile-info-wrapper">
    <img src="/assets/nicky-logo.png">
    <div class="info-tittle">
      {{ 'HOME.whoWillYouPayToday' | translate }}
    </div>
  </div>
  <div class="searchbar" [ngClass]="{'has-results': searchResults}">
    <input type="text" name="search" placeholder="{{ 'searchNick' | translate }}" (keydown.enter)="onSearch(search)"
      #search>
    <img class="search-icon only-desktop clickable" src="/assets/icons/search.png" (click)="onSearch(search)">
    <img class="search-icon only-portrait clickable" src="/assets/icons/mobile-search.png" (click)="onSearch(search)">

  </div>
  <div *ngIf="searchResults" class="search-rectangle">
    <ul *ngIf="searchResults.length == 0 ">
      <li *ngIf="searchResults.length == 0 " class="searh-li no-results">
        <div>{{minimumCharMessage != '' ? minimumCharMessage : 'weDidntFindAnyResultForYourSearch' | translate}}</div>
      </li>
    </ul>
    <ul *ngFor="let item of searchResults">
      <hr class="spacement" *ngIf="!(searchResults.length == 1)">
      <li class="search-li" [ngClass]="{'common-search-li': !item?.exactlyMatch, 'single-result': item?.exactlyMatch }">
        <img class="company-logo" [src]="environment.api + 'User/get-public-profile-picture?userId=' + item.userId"
          (error)="handleImageError($event)">
        <div class="search-info-wrapper multi-results-wrapper"
          [ngClass]="{'firefox-label': (!(item?.nick == myNick && !item?.exactlyMatch) )}" *ngIf="!item?.exactlyMatch"
          (click)="isMobile ?  showEmailModal(item?.domains[0].name) : ''">
          <label
             [matTooltipPosition]="'above'" 
 matTooltip="{{item?.publicName}} - {{ item?.domains[0]?.name ? item.domains[0].name : 'notValidatedDomain' | translate}}"
            class="clickable company-name-link"
            [ngClass]="{'not-validated-company-name': (item?.domains.length == 0)}"><a
              [ngClass]="{'not-validated-company-name': (item?.domains.length == 0)}"
              [href]="item?.domains[0]?.name ? item?.websiteUrl : undefined"
              target="_blank">{{item?.publicName}}</a></label> <img class="checked-user" *ngIf="item?.domains[0]?.name"
             [matTooltipPosition]="'above'" 
 matTooltip="{{'verifiedUser' | translate}}" src="assets/icons/checked-user.png">
        </div>
        <label *ngIf="(!(item?.nick == myNick && !item?.exactlyMatch) || !isMobile) && !(item?.nick == myNick)"
          class="hidden-you clickable search-my-nick search-my-nick-resumed"  [matTooltipPosition]="'above'" 
 matTooltip="This nick belongs to you"> ({{"you"
          | translate}}) </label>
        <label *ngIf="item?.nick == myNick && !item?.exactlyMatch"
          class="clickable search-my-nick search-my-nick-resumed"  [matTooltipPosition]="'above'" 
 matTooltip="This nick belongs to you"> ({{"you" |
          translate}}) </label>
        <div class="search-info-wrapper single-result-wrapper" *ngIf="item?.exactlyMatch">
          <label><a [href]="item?.websiteUrl" [ngClass]="{'not-validated-company-name': (item?.domains.length == 0)}"
              class="clickable company-name-link"
              [ngClass]="{'not-validated-company-name': (item?.domains.length == 0)}"
              target="_blank">{{item?.publicName}}</a></label>
        </div>
       
        <div class="search-icons-wrapper">
          <img class="clickable pay" (click)="openPayment(item)" src="/assets/icons/pay.png">
        </div>
      </li>
    </ul>
  </div> -->
</div>
