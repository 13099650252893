import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, User } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { NgxCurrencyInputMode } from 'ngx-currency';
import { ToastrService } from 'ngx-toastr';
import { ManualOffRampDto } from 'src/app/dtos/manual-off-ramp.dto';
import { OffRampRequestModel } from 'src/app/dtos/off-ramp-request.dto';
import { OffRampSettings } from 'src/app/dtos/off-ramp.set.setting.dto';
import { WalletsForConnector } from 'src/app/dtos/wallets-for-connector.dto';
import { withdrawFee } from 'src/app/dtos/withdrawl-fee.dto';
import { OffRampService } from 'src/app/services/off-ramp.service';
import { PaymentService } from 'src/app/services/payment.service';
import { ToastrFactoryService } from 'src/app/services/toastr-factory.service';
import { UserService } from 'src/app/services/user.service';
import FormatCurrency from 'src/app/utils/format-currency-utils';
import { currentExchanges, currentFiats, devEnvironment, environment, testEnvironment } from 'src/environments/environment';
import { Asset } from '../models/asset.model';
import { ConnectorModel } from '../models/connector.model';
import { optionSelectsModel } from '../models/option-select.model';
import { savedOffRampSettings } from '../models/saved-off-ramp-seetings.model';
import { WalletModel } from '../models/wallet.model';

@Component({
  selector: 'app-manual-withdraw',
  templateUrl: './manual-withdraw.component.html',
  styleUrls: ['./manual-withdraw.component.scss']
})
export class ManualWithdrawComponent {



  constructor(
    private userService: UserService,
    private auth: AuthService,
    private toastrService: ToastrService,
    private paymentService:PaymentService,
    public translate:TranslateService,
    private offRampService:OffRampService,
    public formatCurrency:FormatCurrency,
    private cdr: ChangeDetectorRef,
    private toastrFactory: ToastrFactoryService
    ) { }

  //environments variables
  public currentSystemFiats:string[] = currentFiats;
  public availableExchanges = currentExchanges;

  @Output() onCloseModal: EventEmitter<boolean> = new EventEmitter<boolean>();


  // Server Response Variables Section 
  public assets:any[] = [];
  public allSystemAssets!:Asset[];
  public userExchanges!: any;
  public userActiveWallets: string[] = [];
  public networkAssets:any[] = [];
  public metaMask:any = window.ethereum;
  public metamaskAssets:any[] = [];
  public availableConnectors:ConnectorModel[] = [];
  public availableConnectorsForFiat!:ConnectorModel[] | undefined;
  public allUserConnections:any[] = [];
  public currentUserOffRampSettings:savedOffRampSettings[] = [];
  public exchangeSettingsForUser: any[] = [];
  public showingUserConnections:any[] = [];
  public currentUserConnections:any[] = [];
  public allCountrys:any[] = [];  
  public JWTToken!: string;
  public currentFee: number | undefined = 0;
  public maxTransferAmount:number | undefined = 0;
  private auth0User!:User;

  // user input variables
  public manualOffRampSettings:ManualOffRampDto = {
    amount: undefined,
    assetId: undefined,
    cryptoConnectionId: undefined
  };

  public maxAmount:number | undefined = undefined;
  public withdrawAll!:boolean;
  public notSupportManual!:boolean;


  // Current option selects in the view  
  currentOptionsSelects: {
    availableExchanges: optionSelectsModel[],
    connectionsAvailableExchanges: optionSelectsModel[],
    availableConnectorsForFiat: optionSelectsModel[],
    configurationExchange: optionSelectsModel[],
    anotherOptions: optionSelectsModel[],
    modalOptionsSelect:optionSelectsModel[],
    exchangeSettingsForUser: optionSelectsModel[],
    assets:optionSelectsModel[],
    fiats:optionSelectsModel[],
    networkAssets:optionSelectsModel[],
    currentOffRampSettings: optionSelectsModel[],
  } = {
    availableExchanges: [],
    connectionsAvailableExchanges: [],
    anotherOptions: [],
    modalOptionsSelect: [],
    exchangeSettingsForUser: [],
    assets: [],
    networkAssets:[],
    configurationExchange: [],
    fiats:[],
    availableConnectorsForFiat: [],
    currentOffRampSettings: []
  };


  //option select values variables
  public exchange: string | undefined = "Foxbit";
  public walletAsset:string = "ETH";
  public selectedNetwork:string = "BTC";
  public configurationExchange:any = "Foxbit"
  public connection:string = "";
  public selectedFiat:string = "USD.USD" ;
  public walletNetwork:string = "ETH";
  public selectedExchangeRule!:string | undefined;
  public currentBankWallet!:string;
  public automaticConversionFiat!:string | undefined;


  // view logics variables
  public loading: boolean = false;
  public walletModal:boolean = false;
  public metaMaskWalletNames:any[] = [];
  public choosenMetaMaskAssets:string[] = [];
  public metaMaskAddress!:string;
  public metaMaskModal!:boolean;
  public choosenAssetName!:string;
  public defaultBackgroundPosition:number = 60;
  public customCurrencyMaskConfig:any;
  public isCommaDecimal = this.formatCurrency.isCommaDecimal(navigator.language);
  public selectedConnector!:WalletsForConnector;
  public addingRule!:boolean;
  public loadingConnections!:boolean;
  public currentOffRampSettings!:OffRampSettings;
  public selectedExchangeForSettings!:any;
  public connectorError: { errorMessage:string | undefined, errorCode:string | undefined } = {
    errorMessage : undefined,
    errorCode: undefined
  } ;
  public modalTittle!:string;
  public modalMessage!:string;
  public savedWallets:WalletModel[] = [];
  public currentWallet!:string; 
  public offRampModal!:boolean;
  public automaticConversionSetModal:boolean = false;
  public isMobile!:boolean;
  private devEnvironment = devEnvironment;
  public testEnvironment = testEnvironment;
  

  //tabs logics variables
  public previousTab:string = 'General';
  public currentTab:string = 'General';
  public currentTabsXScroll:number = 0;
  public stopAutoScrollingFunction!:boolean;
  public tabs:string[] = [
    'General',
    'Connections',
    'Wallets',
    'Domain',
    'AutomaticConversion',
    'Off-Ramp',
    'Security'
  ]

  //Profile picture variables
  public logo:any;
  public image:any;
  public base64image!:any;
  public imageFile:any;
  public maxWidth:number = 300;
  public maxHeight:number = 300;
  public cropped?: string;


  public form: FormGroup = new FormGroup({
    password: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])[a-zA-Z\d!@#\$%\^&\*]{8,}$/)]),
    repeatPassword: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])[a-zA-Z\d!@#\$%\^&\*]{8,}$/)]),
  });


  ngOnInit(): void {
    this.setCurrentFiats();
    this.getSystemAssets();
    this.checkWindowSize();
    this.updateMaskConfig();
    this.populateOptionSelects();
  }


  ngAfterViewInit(): void {
    this.getTokenWithCorrectAudience();
  }
  

  itsDevEnvironment(){
    return this.devEnvironment || this.testEnvironment;
  }

  populateOptionSelects() {
    const showAllRules = {
      name: 'PROFILE.showAllRules',
      value: undefined
    };

    const generateOptions = (exchange:any) => ({
      name: exchange,
      value: exchange
    });

    const exchangesOptions = this.availableExchanges.map(generateOptions);

    this.currentOptionsSelects.availableExchanges = [...exchangesOptions];
    this.currentOptionsSelects.connectionsAvailableExchanges = [...exchangesOptions];
    this.currentOptionsSelects.modalOptionsSelect = [...exchangesOptions];

    this.currentOptionsSelects.availableExchanges.unshift(showAllRules);
    this.currentOptionsSelects.connectionsAvailableExchanges.unshift({
      name: 'PROFILE.showAllConnections',
      value: ''
    });
  }


  public getSystemAssets(){
    this.paymentService.getAllAssets().subscribe({
      next: (assets:any) => {
        this.metamaskAssets = assets.filter( (asset:Asset) => !asset.isFiat && !(asset.id === "MATIC.MATIC") && !(asset.id === "BTC.BTC"));
        this.assets = assets.filter( (asset:Asset) => !asset.isFiat && !asset.id?.includes("MATIC"));  
        this.allSystemAssets = assets; 
        this.currentOptionsSelects.assets = [];
        this.assets.forEach(asset => {
          this.currentOptionsSelects.assets.push({
            name: asset.assetTicker,
            value: asset.assetTicker
          })
        })
        this.changeNetworkAssets();
      },
      error: (e:any) => {
        console.log(e)
        this.toastrFactory.unknownError();
      }
    })
  }

  changeNetworkAssets(){
    this.networkAssets = [];
    this.currentOptionsSelects.networkAssets = [];
    if(this.walletAsset == "USDC" || this.walletAsset == "USDT"){
      this.networkAssets.push("ETH")
      this.networkAssets.push('MATIC')
    }
    if(this.networkAssets.length < 1 ){
      this.networkAssets.push('None')
    }
    this.networkAssets.forEach(asset => {
      this.currentOptionsSelects.networkAssets.push({
        name: asset == 'MATIC' ? 'Polygon' : asset,
        value: asset
      })
    })
  }

  updateMaskConfig (){
    this.customCurrencyMaskConfig = {
      align: "center",
      allowNegative: false,
      allowZero: false,
      decimal: this.isCommaDecimal ? ',' : '.',
      precision: 2,
      prefix: "",
      suffix: "",
      thousands: this.isCommaDecimal ? '.' : ',',
      nullable: false,
      min: null,
      max: null,
      inputMode: NgxCurrencyInputMode.Financial
    }
  }

  checkWindowSize() {
    if (window.innerWidth > 1100){
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  closeManualWithdraw(){

  }

  getAvailableConnectors() {
    this.loading = true;
    this.offRampService.getAvailableConnectors(this.JWTToken).subscribe({
      next: (response:any) => {
        this.loading = false;
        this.availableConnectors = response;
        this.setUserOffRampOptions();
      },
      error: (e:any) => {
        console.log(e)
      }
    })
  }

  setCurrentFiats(){
    this.currentSystemFiats.forEach((fiat) => {
      this.currentOptionsSelects.fiats.push({
        name: fiat.split(".")[0],
        value: fiat
    })})
    this.manualOffRampSettings.assetId = "USD.USD"
    this.cdr.detectChanges();
  }

  setUserOffRampOptions(){
    this.availableConnectors?.forEach((connector:ConnectorModel) => {
      let connectorSavingOptions = {
        name: connector.name,
        exchange: connector.cryptoDataSource,
        id: connector.id
      }
      this.currentSystemFiats.forEach((fiat) => {
        this.exchangeSettingsForUser.push({
          connectorSavingOptions,
          fiat: fiat
        })
      })
    })

    this.setExchangeSettingsOptions();

    this.selectedFiat = this.getConnectionIdAndFiat(this.exchangeSettingsForUser[0]);
  }

  setWithdrawAll(){
    this.withdrawAll = !this.withdrawAll;
    if(this.withdrawAll){
      this.manualOffRampSettings.amount = undefined;
    }
  }

  getManualOffRampFee(){
    this.getOffRampFees(this.manualOffRampSettings.cryptoConnectionId, this.manualOffRampSettings.assetId);
  }

  getOffRampFees(connectionId:string | undefined, assetId:any){
    console.log('Teste')
    const feesInfo:withdrawFee =  {
      amount: 1000,
      cryptoConnectionId: connectionId,
      assetId: assetId
    }
    this.offRampService.getOffRampFees(this.JWTToken, feesInfo).subscribe({
      next:(response:any) => {
        this.currentFee = response.fee || 0;
        this.maxTransferAmount = response.amount || 0;
        this.notSupportManual = false;
      },
      error: (e:any) => {
        this.currentFee = undefined;
        this.notSupportManual = true;
        console.log(e)
      }
    }
    )
  }

  setExchangeSettingsOptions(){
    this.currentOptionsSelects.exchangeSettingsForUser = [];
    this.exchangeSettingsForUser.forEach(exchangeSetting => {
      this.currentOptionsSelects.exchangeSettingsForUser.push({
        name: `(${exchangeSetting.fiat?.split('.')[0]}) ${(exchangeSetting.connectorSavingOptions.name || exchangeSetting.connectorSavingOptions.exchange)}`,
        value: this.getConnectionIdAndFiat(exchangeSetting),
      })
    });
  }

  getConnectionIdAndFiat(exchangeSettingForUser:any){
    if(!exchangeSettingForUser){
      return "undefined";
    }
    return exchangeSettingForUser.connectorSavingOptions.id + ' ' + exchangeSettingForUser.fiat
  }

  noCurrentUserOffRampSettings(){
    if(this.currentUserOffRampSettings && this.currentUserOffRampSettings.length < 1){
      return true;
    }
    return false;

  }

  getOffRampAllSettings() {
    this.loading = true;
    this.offRampService.getOffRampAllSettings(this.JWTToken).subscribe({
      next: (response:any) => {
        this.currentUserOffRampSettings = [];
        response.forEach((setting:savedOffRampSettings) => {
          if(setting.isEnabled)
            this.currentUserOffRampSettings.push(setting)
        });
        this.currentUserOffRampSettings.forEach((offRampSetting:savedOffRampSettings) =>
        {  
            this.currentOptionsSelects.currentOffRampSettings.push({
              name: offRampSetting.cryptoConnection.name || undefined,
              value: offRampSetting.cryptoConnection.id
            })
        }
        )
        this.manualOffRampSettings.cryptoConnectionId = this.currentOptionsSelects.currentOffRampSettings[0]?.value || "";
        this.getManualOffRampFee();
        this.cdr.detectChanges();
        this.loading = false;
      },
      error: (e:any) => {
        console.log(e)
      }
    })
  }

  getWalletsForConnector(connection:WalletsForConnector) {
    this.loadingConnections = true;
    this.offRampService.getWalletsForConnector(this.JWTToken, connection).subscribe({
      next: (response:any) => {
        this.availableConnectorsForFiat = [];
        this.currentOptionsSelects.availableConnectorsForFiat = [];
        this.availableConnectorsForFiat = response;
        response.forEach((bankWallet:any) => {
          this.currentOptionsSelects.availableConnectorsForFiat.push({
            name: `${bankWallet.walletId} ${bankWallet.method}` ,
            value: bankWallet.walletId
          })
        });
        this.getOffRampFees(connection.cryptoConnectionId, connection.assetId);
        this.currentBankWallet = (response[0]?.walletId || '') ;
        this.loadingConnections = false;
      },
      error: (e:any) => {
        this.availableConnectorsForFiat = undefined
        this.currentOptionsSelects.availableConnectorsForFiat = [];
        if(e?.error?.ErrorCode == "NotEnoughPermissionsException"){
          this.connectorError.errorMessage = this.translate.instant("PROFILE.NotEnoughPermissionsException");
          this.connectorError.errorCode = e?.error?.ErrorCode;
        } else {
          this.connectorError.errorMessage = this.translate.instant("PROFILE.thisConnectionDoesntSupport");
          this.connectorError.errorCode = undefined;
        }
        this.loadingConnections = false;
      }
    })
  }



  formatAsset(){
    if(this.walletAsset != 'USDC' && this.walletAsset != 'USDT'){
      return this.walletAsset + "." + this.walletAsset
    } else {
      return this.walletNetwork + "." + this.walletAsset
    }
  }

  closeModal(){
    this.onCloseModal.emit(true);
  }

  filterAssociatedWallets(wallet:any,i:number){
    const walletName = wallet.name ? wallet.name : wallet.cryptoDataSource
    const formatedWallet = {
      walletName: walletName == 'Manual' ? 'Private' : walletName,
      cryptoDataSource: wallet.cryptoDataSource,
      asset: wallet.usedForAssets[i],
      assetName: this.formatAssetName(wallet.usedForAssets[i]),
      defaultWallet: wallet.cryptoDataSource,
      address: wallet.address,
      id: wallet.id,
     }
     this.savedWallets.push(formatedWallet)
     this.savedWallets.sort((a, b) => {
       const result = a.asset.split('.')[1].localeCompare(b.asset.split('.')[1]);
       if (result === 0) {
         return a.asset.split('.')[0].localeCompare(b.asset.split('.')[0]);
       }
       return result;
  });
  }

  
  formatAssetName(asset:string){
    if(asset.includes('BTC')){
      return 'Bitcoin'
    }
    if(asset == "ETH.ETH"){
      return 'Ethereum'
    }
    if(asset.includes("USDC")){
      return "USD Coin"
    }
    if(asset.includes("USDT")){
       return "Tether"
     }
     return ''
  }


  getAllUserConnections(){
    this.userService.allUserConnections(this.JWTToken).subscribe({
      next: (connections:any) => {
        this.currentUserConnections = connections;
      },
      error: (e:any) => {
        console.log(e)
        this.toastrFactory.unknownError();
      }
    })
  }

  userHasConnection(exchange: string) {
    return this.userActiveWallets.includes(exchange.trim())
  }


  public getTokenWithCorrectAudience() {
    this.auth.getAccessTokenSilently({
      authorizationParams: {
        audience: environment.auth0Audience
      },
    }).subscribe({
      next: (response: any) => {
        this.JWTToken = response;
        this.getAllUserConnections();
        this.getOffRampAllSettings();
        this.getAvailableConnectors();
      },
      error: (e: any) => this.handleError(e),
    })
  }

  enabledOffRampButton(){
    if((!(this.manualOffRampSettings.amount === undefined || this.manualOffRampSettings.amount === 0) || this.withdrawAll) && !this.notSupportManual){
      return true;
    } else {
      return false;
    }

  }

  startOffRamp(){
    if(this.loading){
      return;
    }

    if(!this.enabledOffRampButton()){
      return;
    }
 
    this.loading = true;

    if(!this.withdrawAll){
      this.settedValueOffRamp();
      return;
    } else {
      this.maxValueOffRamp();
    }

  }

  settedValueOffRamp(){
    this.offRampService.offRampStart(this.JWTToken, this.manualOffRampSettings).subscribe({
      next: (response:any) => {
        this.loading = false;
        const offRampRequestResponse:OffRampRequestModel = response;
        if(offRampRequestResponse.success == false){
          if(offRampRequestResponse.error){
            this.toastrService.error(offRampRequestResponse.error, 'Something went wrong');
          } else {
            this.toastrService.error(this.translate.instant('unknownError'), 'Something went wrong')
          }
          return;
        }
        this.toastrService.success(
          `${this.translate.instant('offRampRequestCompletedSuccessfully')} ${this.translate.instant('inTheValueOf')} ${offRampRequestResponse.blockchainAssetId.split('.')[0] + ' ' + this.formatCurrency.convertToLocal(offRampRequestResponse.amount.toString())}`,
           'Success !', 
           {
            timeOut: 20000
           });
          this.closeModal();
        return;
      },
      error: (e:any) => {
        if(e.error?.Message?.includes('Off ramp settings not found')){
          this.toastrService.error(this.translate.instant('offRampsSettingsNotFoundForTheTargetFiat'));
        }
        if(e.error?.ErrorCode === "NotEnoughBalanceException"){
          this.toastrService.error(this.translate.instant('notEnoughBalances'));
        }
        console.log(e)
        this.loading = false;
      }
    })
  }

  maxValueOffRamp(){
    this.offRampService.maxOffRampStart(this.JWTToken, this.manualOffRampSettings).subscribe({
      next: (response:any) => {
        this.loading = false;
        const offRampRequestResponse: OffRampRequestModel = response;
        if(offRampRequestResponse.success == false){
          if(offRampRequestResponse.error){
            this.toastrService.error(offRampRequestResponse.error);
          } else {
            this.toastrFactory.unknownError();
          }
          return;
        }
        this.toastrService.success(
          `${this.translate.instant('offRampRequestCompletedSuccessfully')} ${this.translate.instant('inTheValueOf')} ${offRampRequestResponse.blockchainAssetId.split('.')[0] + ' ' + this.formatCurrency.convertToLocal(offRampRequestResponse.amount.toString())}`,
          '',
          {
            timeOut: 20000
          });   
          this.closeModal()
          return;
      },
      error: (e:any) => {
        if(e.error?.Message?.includes('Off ramp settings not found')){
          this.toastrService.error(this.translate.instant('offRampsSettingsNotFoundForTheTargetFiat'));
        } else 
        if(e.error?.ErrorCode === "NotEnoughBalanceException"){
          this.toastrService.error(this.translate.instant('notEnoughBalances'));
        } else {
          if(e.error?.Message){
            this.toastrService.error(e.error.Message);
          } else {
            this.toastrFactory.unknownError();
          }
        }


        
        console.log(e)
        this.loading = false;
      }
    })
  }

  public handleError(error: any) {
    console.log(error)
    switch (error.status.toString()) {
      case '500': 
        this.toastrFactory.unknownError();
        break;
      case '400':
        if (error.error.errors.q == "The q field is required.") {
          this.toastrService.error("The field must have an value before submit", '', {
            timeOut: 3000
          })
        } else {
          this.toastrService.error(error.error.errors, '', {
            timeOut: 3000
          })
        }
        break;
      case '404':
        this.toastrService.error(error.statusText, '', {
          timeOut: 3000
        })
        break;
      default:
        this.toastrFactory.unknownError();
    }
    this.loading = false;
  }





}
