import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import * as auth0 from 'auth0-js'
import { RegisterPageComponent } from '../pages/register-page/register-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { QuoteModel } from '../components/models/quote.model';
import { Wallet } from '../components/models/wallet.model';

@Injectable()
export class PaymentService {

  constructor(
    public route: Router,
    private http: HttpClient,
    public auth: AuthService
  ) { }


  getUserWallets(targetNick: string, selectedAsset: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
      })
    };
    return this.http.get<Wallet>(environment.api + 'Address/deposit-address?assetId=' + selectedAsset + "&nick=" + targetNick, httpOptions)
  }


  getCurrencyValue(currency: string, targetCurrency: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Content-Type': 'application/json',
      })
    };
    console.log(currency);
    console.log(targetCurrency);
    const body = {
      amount: 1,
      fromBlockchainId: currency,
      toBlockchainId: targetCurrency
    }
    return this.http.post(environment.api + 'ConversionRate/get-price',body ,httpOptions)
  }
  
  getQuotes(amount:number, currency: string, targetCurrency: string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Content-Type': 'application/json',
      })
    };
    const body = {
      amount: amount,
      fromBlockchainId: currency,
      toBlockchainId: targetCurrency
    }
    return this.http.post(environment.api + 'ConversionRate/get-quote',body ,httpOptions)
  }

  getTransactions(assetId:string, adress:string, dateTime:string){
    return this.http.get(environment.api + `Blockchain/transactions/${assetId}/${adress}?startTime=${dateTime}`)
  }

  doTransaction(JWTToken: string, transaction: FormGroup, walletAddress: string, user: any, targetId: any, targetAdress: any, savedAssetValue: any, savedUsdValue: any, authenticated: boolean, targetCompany:string, targetNick:string, existingBillShortId?:string, currentQuote?:QuoteModel) {
    const asset = transaction.controls['asset'].value;
    let assetId = transaction.controls['assetId'].value
    const amount = savedAssetValue;
    const amountUSD = savedUsdValue;
    const senderAddress = user ? user.email : transaction.controls['email'].value?.trim();
    const receiverAddress = walletAddress;
    const transactionId = transaction.controls['transactionID'].value?.trim();
    const reference = transaction.controls['reference'].value?.trim().substring(0, 17);
    const status = transaction.controls['status'].value?.trim();
    const description = transaction.controls['description'].value?.trim()
    const receiverCompany = targetCompany?.trim();
    const receiverEmail = targetNick?.trim();
    let anonUserName;
    let anonUserEmail;
    let anonUsercompany;
    let paymentId = existingBillShortId ? existingBillShortId : null;
    let quoteID = currentQuote?.id ? currentQuote.id : undefined; 
    
    switch (assetId) {
      case "USD":
        assetId = "USD.USD";
        break;
      case "EUR":
        assetId = "EUR.EUR";
        break;
    }

    
    if (!authenticated) {
      anonUserEmail = transaction.controls['email'].value?.trim()
      anonUserName = transaction.controls['guestName'].value?.trim()
      anonUsercompany = transaction.controls['company'].value ? transaction.controls['company'].value?.trim() : null;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`,
        'Content-Type': 'application/json'
      })
    };

    console.log(user);
    
    const body = {
      payerUserAnon: {
        email: authenticated ? user.email : anonUserEmail,
        name: authenticated ? user.name : anonUserName,
        company: authenticated ? user.company : anonUsercompany
      },
      existingBillShortId: existingBillShortId,
      billDetails: {
        receiverUserId: targetId,
        invoiceReference: reference,
        description: description
      },
      paymentTransactions: [
        {
          blockchainTransactionId: transactionId,
          blockchainAssetId: assetId,
          receiverAddress: receiverAddress,
          conversionQuoteId: quoteID
        }
      ]
    };
    return this.http.post(environment.api + 'PaymentReport/create', body, httpOptions);
  }



  getAllAssets() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
      })
    };
    return this.http.post(environment.api + 'BlockchainAsset/all', httpOptions)
  }
}










