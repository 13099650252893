import { LyTheme2 } from '@alyle/ui';
import { LyDialog } from '@alyle/ui/dialog';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import * as auth0 from 'auth0-js';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { toggleMenu } from 'src/app/app.component';
import { User, UserClass } from 'src/app/components/models/user.mode';
import { FavoritesService } from 'src/app/services/favorites.service';
import { PaymentRequestService } from 'src/app/services/payment.request.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SearchService } from 'src/app/services/search.service';
import { ToastrFactoryService } from 'src/app/services/toastr-factory.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';


const STYLES = ({
  slider: {
    display: 'block',
    padding: '16px'
  }
});

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit, OnInit {
  favoritesList: any;

  constructor(
    private favoritesService: FavoritesService,
    public auth: AuthService,
    public router: Router,
    private toastrService: ToastrService,
    private searchService: SearchService,
    private userService: UserService,
    private params: ActivatedRoute,
    private paymentRequestService: PaymentRequestService,
    private paymentService:PaymentService,
    private sanitize:DomSanitizer,
    public translate: TranslateService,
    private theme: LyTheme2,
    private _dialog: LyDialog,
    private _cd: ChangeDetectorRef,
    private toastrFactory: ToastrFactoryService
  ) { }

  private webAuth = new auth0.WebAuth({
    domain: environment.auth0Domain,
    clientID: environment.auth0ID,
    audience: environment.auth0Audience,
  });

  readonly classes = this.theme.addStyleSheet(STYLES);


  public searchResults!: any;
  public isAuthenticated: any;
  public showModal!: boolean;
  public modalMessage!: string;
  public modalTittle!: string;
  public dashBoardOptions!: boolean;
  public modalNick!: string;
  public modalPublicName!: string;
  public myNick: any;
  public JWTToken: any;
  public fullEmailModal!: boolean;
  public isMobile: boolean = false;
  public loading = false;
  public paymentFlow = false;
  public targetNick!: string;
  public userId!: string;
  public user: any;
  public targetCompany!: string;
  public companyWebsite!: string;
  public requestInfo:any;
  public assets:any;
  public userProfile!:boolean;
  public environment = environment;
  public minimumCharMessage!:string;
  public defaultBackgroundPosition:number = 5;
  public targetDomains:any;
  public closeTooltip:boolean = false;
  public exchangeModal!:boolean;
  public manyUsersModal!:boolean;
  public notify:boolean = true;
  public target!:User;
  public targetUser!:UserClass;
  public profileNotFound!:boolean;
  
  public selectedLanguage!:string;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize()
  }


  @ViewChild('search') search!: ElementRef<HTMLInputElement>;
  @ViewChild('searchDesktop') searchDesktop!: ElementRef<HTMLInputElement>;


  ngOnInit(): void {
    this.checkWindowSize()
    this.getSystemAssets();
    this.checkParams(true)
    // this.loginWithSocialError();
  }

  toggleMenu(){
    toggleMenu()
  }


  ngAfterViewInit(): void {
    this.userAuthenticated();
    this.setSearchBehavior();
  }


  public getSystemAssets(){
    this.paymentService.getAllAssets().subscribe({
      next: (response:any) => {
        this.assets = response
      },
      error: (e:any) => {
        console.log(e)
        this.toastrFactory.unknownError();
      }
    })
  }

  
  public async checkParams(initCheck?: boolean) {
    this.loading = true;
  
    try {
      const params = await firstValueFrom(this.params.paramMap);
      const queryParams = await firstValueFrom(this.params.queryParams);

      const email = params.get('email');

      const shortId = params.get('shortId');

      if (initCheck) return;
  
      if (shortId) {
        this.openPublicProfile(shortId);
        this.loading = false;
        return;
      }
  
      if (email) {
        this.getShortIdByEmail(email);
        this.loading = false;
        return;
      }
  
      if (queryParams) {
        const userId = queryParams['shortId'];
        const paymentId = queryParams['paymentId'];
        const canSocial = queryParams['error_description'];
        this.targetNick = queryParams['targetNick'];
  
        this.loading = false;
  
        if (initCheck) return;
  
        if (userId) {
          this.openPublicProfile(userId);
          return;
        }
  
        if (paymentId) {
          this.openPaymentById(paymentId);
          return;
        }
  
        if (queryParams['targetNick'] || queryParams['paymentAddress']) {
          if (queryParams['paymentAddress']) {
            this.targetNick = queryParams['paymentAddress'];
          }
          this.checkIfUserIsValidated(queryParams);
          return;
        }
      }
  
      if (localStorage.getItem('manyUsers')) {
        await this.handleManyUsers();
        return;
      }
  
      if (!queryParams || Object.values(queryParams).length === 0) {
        this.checkIfIsAuthenticated();
      }
    } catch (error) {
      console.error('Error checking params', error);
      this.loading = false;
    }
  }
  
  private async handleManyUsers() {
    setTimeout(async () => {
      if (await firstValueFrom(this.auth.isAuthenticated$)) {
        this.JWTToken = await firstValueFrom(this.auth.getAccessTokenSilently({
          authorizationParams: {
            audience: environment.auth0Audience
          },
        }));
      }
      this.manyUsersModal = true;
      this.modalTittle = this.translate.instant('thankYourForSigningup');
      this.modalMessage = this.translate.instant('weAreExcitedToHaveYouOnBoard');
      this.loading = false;
    }, 100);
  }

  getShortIdByEmail(email:string){
    this.searchService.generalSearch(email).subscribe({
      next:(response:any) => {
        if(response[0]?.shortId) {
          this.openPublicProfile(response[0]?.shortId);
        } else {
          this.notFoundProfile();
        }
      },
      error: (e:any) => {
        this.toastrFactory.unknownError();
      }
    })
  }

  private openPublicProfile(shortId:string){
    // const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if(shortId){
      this.searchService.publicProfile(shortId).subscribe({
        next: (response:any) => {
          if(response) {
            this.openPayment(response);
            return;
          }
          this.notFoundProfile();

        },
        error: (e:any) => {
          this.toastrFactory.unknownError();
        }
      })
    } else {
      setTimeout(() => {
        this.toastrService.error(this.translate.instant('notAValidUUID'));
      }, 1000);
    }
  }

  checkIfUserIsValidated(params:any){
    this.userService.userIsValidated(this.targetNick).subscribe({
      next: (response: any) => {
        if (response == false) {
          this.toastrFactory.unknownError();
          return;
        }
        if (response == true) {
          this.paymentFlow = params['paymentFlow'] === 'true';
          if (params['targetNick'])
            this.userId = params['userId'];
          this.targetCompany = params['publicName'];
          this.companyWebsite = params['companyWebsite'];
        }
      },
      error: (e: any) => {
        this.toastrFactory.unknownError();
        console.log(e)
      },
    })
  }


  notFoundProfile() {
    this.profileNotFound = true;
  }


  openPayment(target: any) {
    this.target = target;
    this.targetUser = new UserClass(target);
    // this.targetDomains = target.domains
    // this.userId = target.userId || target.id;
    // this.targetNick = target.nick || target.email;
    // this.targetCompany = target.publicName;
    // this.companyWebsite = target.websiteUrl;
    this.userProfile = true;
  }

  moreThanOneAt(input:string){
      const regex = /@/g;
      const matches = input.match(regex);
      return matches ? matches.length : 0;
  }

  private async openPaymentById(paymentId:string){
    if(this.isAuthenticated && !this.user){
      this.user = await firstValueFrom(this.userService.getUser(this.JWTToken));
    }
    this.paymentRequestService.getPaymentById(paymentId).subscribe({
      next: (request: any) => {
        if (request) {
          if(request.bill.paymentReports.length == 0 && request.status != 'Canceled'){
          this.requestInfo = request;
          this.targetUser = new UserClass(request.creator)
          // this.userId = request.creator.id;
          // this.targetCompany = request.creator.publicName;
          // this.companyWebsite = request.creator.websiteUrl ? request.creator.websiteUrl : "#";
          // this.targetNick = request.creator.email;
          this.paymentFlow = true;
          return;
          } else {
            if(request.status == 'Canceled'){
              this.toastrService.error(this.translate.instant("thisPaymentRequestHasBeenCanceled"))
            } else{
            this.toastrService.error(this.translate.instant("thisPaymentRequestHasAlreadyBeenReported"))
            }
          }
        }
      },
      error: (e: any) => {
        this.toastrService.error(this.translate.instant("weAreUnableToFindThisPaymentRequest"), '', {
          timeOut: 3000
        })
        console.log(e)
        return;
      },
    })
  }

  async onSearch(searchInput: HTMLInputElement) {
    const minWidth:number = 3;
    const inputLength:number = searchInput.value.replaceAll("@", "").trim().length;
    const atLength:number = this.moreThanOneAt(searchInput.value);

    if(searchInput.value.replaceAll("@", "").trim().length < minWidth){
      const remainingChar = minWidth - inputLength;
      this.minimumCharMessage = this.translate.instant("HOME.youNeedToType") + remainingChar + this.translate.instant("HOME.moreChar") + (remainingChar == 1 ? '' : 's')
      if(atLength > 1){
        this.minimumCharMessage += this.translate.instant('HOME.doesNotCount')
      }
      this.searchResults = [];
      return
    }
    this.minimumCharMessage = "";
    if(this.isAuthenticated && !this.user){
      this.user = await firstValueFrom(this.userService.getUser(this.JWTToken));
    }
    this.searchService.generalSearch(searchInput.value).subscribe({
      next: (response: any) => { 
        this.searchResults = response;
        if(this.searchResults?.length == 1){
          if(searchInput.value.trim() == this.searchResults[0]?.nick){
            this.searchResults[0].exactlyMatch = true;
          } else {
            this.searchResults = [];
          }
        } else {
          this.searchResults = []
        }
      },
      error: (e: any) => this.handleError(e),
    })
  }

  handleImageError(event:any){
    event.target.src = '/assets/icons/missing-icon.png';
  }

  logoutUser(){
    if(this.notify){
      this.webAuth.logout({
        clientID: environment.auth0ID,
        returnTo: environment.websiteUrl,
      })
      return;
    } else {
      this.userService.deleteUser(this.JWTToken).subscribe({
        next: (response: any) => {
          this.auth.logout({
            logoutParams: { returnTo: environment.websiteUrl }
          })
          this.loading = false;
        },
        error: (e: any) => this.handleError(e),
        complete: () => this.loading = false
      })
    }
  }

  closeModal(event?: any) {
    this.showModal = false;
    this.dashBoardOptions = false;
    this.paymentFlow = false;
    this.userProfile = false;
    this.checkIfIsAuthenticated();
  }

  async checkIfIsAuthenticated(){
    this.loading = true;
    if(await firstValueFrom(this.auth.isAuthenticated$) === false && !(localStorage.getItem('manyUsers'))){
      this.loading = false;
      window.location.href = environment.websiteUrl;
    } else {
      this.loading = false;
      this.router.navigateByUrl('/overview');
    }
  }

  private getCurrentUser() {
    this.loading = true;
    this.auth.user$.subscribe((user: any) => {
      this.myNick = user?.email
      this.userId = user.sub;
    });
  }

  userAuthenticated() {
    this.auth.isAuthenticated$.subscribe((response) => {
      this.isAuthenticated = response;
      if (this.isAuthenticated) {
        this.auth.getAccessTokenSilently({
          authorizationParams: {
            audience: environment.auth0Audience
          },
        }).subscribe({
          next: (response: any) => {
            this.JWTToken = response;
            this.getCurrentUser();
            this.checkParams();
            this.loading = false;
          },
          error: (e: any) => {
            this.toastrFactory.unknownError();
            this.loading = false;
          },
          complete: () => this.loading = false
        })
      } else {
        this.checkParams();
      }
    })
  }

  getCurrentUserNick() {
    this.auth.user$.subscribe(
      (user) => this.myNick = user?.email
    )
  }

  getNickyUser(){
    this.user = firstValueFrom(this.userService.getUser(this.JWTToken));
  }

  public showEmailModal(fullMail: string) {
    this.showModal = true;
    this.modalMessage = fullMail;
    this.fullEmailModal = true;
  }


  public showDashboardOptionsModal(favorite: any) {
    this.showModal = true;
    this.modalNick = favorite.nick;
    this.modalPublicName = favorite.publicName;
    this.modalTittle = this.translate.instant("weFoundIt");
    this.dashBoardOptions = true;
  }

  setSearchBehavior() {
    // this.search.nativeElement.addEventListener('input', this.updateSearchResults.bind(this));
    // this.searchDesktop?.nativeElement.addEventListener('input', this.updateSearchResults.bind(this));
  }

  updateSearchResults() {
    this.searchResults = "";
  }


  checkWindowSize() {
    if (window.innerWidth <= 1100) {
      this.isMobile = true;
      return;
    }
    this.isMobile = false
  }

  public async addFavorite(nick: string) {
    this.loading = true;
   
    let alreadyFavorite:any = await firstValueFrom(await this.favoritesService.favoriteByNick(this.JWTToken, nick));

    if (alreadyFavorite?.length > 0) {
      this.toastrService.success(this.translate.instant('userIsAlreadyInYourFavorites'), '', {
        timeOut: 3000
      });
      this.loading = false;
    } else {
      this.favoritesService.addFavorite(this.JWTToken, nick).subscribe({
        next: (response: any) => {
          this.toastrService.success(this.translate.instant('userAddedToYourFavoritesSuccessfully'), '', {
            timeOut: 3000
          });
          this.searchResults = response;
          this.loading = false;
        },
        error: (e: any) => this.handleError(e),
        complete: () => this.loading = false

      });
    }
  }

  public handleError(error: any) {
    this.loading = false;
    switch (error.status.toString()) {
      case '500': 
      this.toastrFactory.unknownError();
        break;
      case '400':
        if (error.error.errors.q == "The q field is required.") {
          this.toastrService.error(this.translate.instant("theFieldMustHaveAnValueBeforeSubmit"), '', {
            timeOut: 3000
          })
        } else {
          this.toastrService.error(error.error.errors, '', {
            timeOut: 3000
          })
        }
        break;
      case '404':
        this.toastrService.error(error.statusText, '', {
          timeOut: 3000
        })
        break;
      default:
        this.toastrFactory.unknownError();
        this.loading = false
    }
  }
}


