import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FavoritesService } from 'src/app/services/favorites.service';
import { SearchService } from 'src/app/services/search.service';
import { environment } from 'src/environments/environment';
import { User, UserClass } from '../models/user.mode';

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.scss']
})
export class PublicProfileComponent {

  public paymentFlow!:boolean;
  constructor(
      private translate:TranslateService,
      private searchService:SearchService,
      private favoritesService:FavoritesService,
      private toastrService:ToastrService,
    ){
  }

  public profileInfo!:any;
  @Input('user')user!: any;
  @Input('paymentModal')paymentModal!: boolean;
  @Input('profileNotFound')profileNotFound!:boolean;
  @Input('isAuthenticated')isAuthenticated!: boolean;
  @Input('JWTToken')JWTToken!: string;
  @Input('userId')userId!: string;
  @Input('target')target!:User;
  @Input('targetNick')targetNick!: string;
  @Input('targetCompany')targetCompany!: string;
  @Input('companyWebsite')companyWebsite!: string;
  @Input('paymentRequestInfo')paymentRequestInfo!: any;
  @Input('targetDomains')targetDomains:any;
  @Input ('assets')assets:any;
  @Input('selectedLanguage')selectedLanguage!: any;
  @Output() onCloseModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  public targetUser!:UserClass;

  targetInfo = {
    userId: this.userId,
    targetNick: this.targetNick,
  }

  ngOnInit(): void {
    if (this.profileNotFound) {
      return;
    }
    this.isAlreadyFavorite();
    this.getPublicProfileInfo();
  }
  
  public environment = environment;
  public alreadyFavorite!:boolean;
  public loading:boolean = true;

  handleImageError(event:any){
    event.target.src = '/assets/icons/missing-icon.png';
  }

  openPayment() {
    this.targetUser = new UserClass(this.target);
    this.paymentFlow = true;
  }

  getBio(){
    if(this.profileInfo?.bio){
       return this.profileInfo.bio
    } else {
      return ''
    }               
  }

  getPublicProfileInfo(){
    if (this.target && !this.target?.shortId) {
      this.getPublicProfileByUUID();
      return;
    } else {
      this.getPublicProfile();
    }
    
  }

  getPublicProfileByUUID() {
    this.searchService.uuidPublicProfile(this.userId).subscribe({
      next: (response:any) => {
        this.profileInfo = response;
        this.profileInfo.domains = this.targetDomains;
      },
      error: (e:any) => {
        console.log(e);
      }
    });
  }

  getPublicProfile() {
    this.searchService.publicProfile(this.target?.shortId || this.user.shortId).subscribe({
      next: (response:any) => {
        this.profileInfo = response;
        this.profileInfo.domains = this.targetDomains;
      },
      error: (e:any) => {
        console.log(e)
      }
    })
  }

  public addFavorite() {
    if(!this.JWTToken){
      this.toastrService.error("You have to login to add a favorite.")
    }
    this.alreadyFavorite = false;
      this.favoritesService.addFavorite(this.JWTToken, this.targetNick).subscribe({
        next: (response: any) => {
          this.toastrService.success(this.translate.instant('userAddedToYourFavoritesSuccessfully'), '', {
            timeOut: 3000
          });
          this.alreadyFavorite = true;
        },
        error: (e: any) => {
          console.log(e)
        },
      });
  }
  

  isAlreadyFavorite(){
    if ( !this.isAuthenticated ) {
      this.loading = false;
      return;
    }

    this.favoritesService.searchFavorites(this.JWTToken, this.targetNick).subscribe({
      next: (response: any) => {
        if(response.length > 0){
          this.alreadyFavorite = true;
        } else {
          this.alreadyFavorite = false;
        }
        this.loading = false
      },
      error: (e: any) => {
        this.alreadyFavorite = false;
        this.loading = false
      },
    });
  }
  
  closeModal() {
    this.onCloseModal.emit(false);
    this.paymentFlow = false;
  }
}
