import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import * as auth0 from 'auth0-js';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CryptoConnectionDTO } from '../dtos/cryto-connection.dto';
import { ProfileDto } from '../dtos/user-profile.dto';
import { ToastrFactoryService } from './toastr-factory.service';


@Injectable()
export class UserService {
  private webAuth = new auth0.WebAuth({
    domain: environment.auth0Domain,
    clientID: environment.auth0ID,
    responseType: 'token id_token',
    audience: environment.auth0Domain + '/api/v2/',
    scope: 'openid profile email update:current_user_metadata',
    redirectUri: window.location.origin,
  });

  constructor(public route: Router,
    private http: HttpClient,
    private toastrFactory: ToastrFactoryService,
    private toastrService: ToastrService) {

  }

  private success!: boolean;
  private JWTToken!: string;

  getUser(JWTToken: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.get(environment.api + 'user/Profile', httpOptions)
  }

  updateProfilePicture(JWTToken: any, image: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    const formData = new FormData();
    formData.append('file', image);
    return this.http.post(environment.api + 'User/set-profile-picture', formData, httpOptions);
  }

  getProfilePicture(JWTToken: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'image/*', 
        'Authorization': `Bearer ${JWTToken}`
      }),
      responseType: 'blob' as 'json'
    };
    return this.http.get(environment.api + 'User/get-profile-picture', httpOptions);
  }


  getDomain(JWTToken: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.get(environment.api + 'Domain/all', httpOptions)
  }

  addDomain(JWTToken: any, domain:string) {
    const httpOptions = {
        headers: new HttpHeaders({
            'accept': '*/*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${JWTToken}`
        })
    };
    const body = JSON.stringify(domain);
    return this.http.post(environment.api + 'Domain/add', body, httpOptions)
}


  public editGeneralUserInfo(JWTToken: any, userProfile: ProfileDto) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    const body = userProfile;
    
    return this.http.post(environment.api + 'User/profile', body, httpOptions)
  }

  getUserImageById(userId:string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept' : '*/*'
      })
    };
    return this.http.get(environment.api + 'User/get-public-profile-picture?userId=' + userId, httpOptions)
  }


  public checkDNS(JWTToken: any, domain: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JWTToken}`,
        'accept': '*/*',
      }),
    };
    const body = JSON.stringify(domain);
    return this.http.post(environment.api + 'Domain/force-check', body, httpOptions)
  }


  public getCryptoConnections(JWTToken: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.get(environment.api + 'CryptoConnection/all', httpOptions)
  }

  getReceiverConnections(userId:string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
      })
    };
    return this.http.get(environment.api + 'UserAssetConnection/all-assets-for-userid?userId=' + userId, httpOptions)
  }

  checkExchangeConnection(JWTToken:string, exchangeId:string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.post(environment.api + 'CryptoConnection/check-if-valid?id=' + exchangeId, {} , httpOptions)
  }

   deleteUserAssetConnection(JWTToken: string, connectionId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.delete(environment.api + 'UserAssetConnection?id=' + connectionId, httpOptions)
  }
  
  public allUserConnections(JWTToken: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.get(environment.api + 'UserAssetConnection/all', httpOptions)
  }
  


  public addCryptoConnection(JWTToken: string, connectionDto:CryptoConnectionDTO) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JWTToken}`
      })
    };

    const body = connectionDto;

    return this.http.post(environment.api + 'CryptoConnection/add', body, httpOptions)
  }

  public getAcceptedAssets(JWTToken: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.get(environment.api + 'AcceptedAsset/get', httpOptions)
  }

  public getAcceptableAssetsByExchange(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
      })
    };
    return this.http.get(environment.api + 'CryptoConnection/available', httpOptions)
  }
  
  public getAcceptedAssetsById(userId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain'
      })
    };
    return this.http.get(environment.api + 'AcceptedAsset/get-for-user?userId=' + userId, httpOptions)
  }


  public setAcceptedAssets(JWTToken: string, assets:string[]) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JWTToken}`
      })
    };

    const body = assets;

    return this.http.post(environment.api + 'AcceptedAsset/set', body, httpOptions)
  }

  public associateConection(JWTToken: any, blockchainAssetId:String, cryptoConnectionId:string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    const body = {
      blockchainAssetId: blockchainAssetId,
      cryptoConnectionId: cryptoConnectionId
    }
    return this.http.post(environment.api + 'UserAssetConnection/add', body, httpOptions)
  }

  public removeCryptoConnection(JWTToken: string, connectionId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.delete(environment.api + 'CryptoConnection?id=' + connectionId, httpOptions)
  }


  public changePassword(password: any, JWTToken: any, auth: AuthService, userId: any) {
    auth.getAccessTokenWithPopup(
      {
        authorizationParams: {
          audience: environment.auth0Domain + '/api/v2/',
          scope: 'update:current_user_metadata'
        },
      }
    ).subscribe(
      (JWTToken: any) => this.changePasswordWithCurrentAudience(password, JWTToken, userId)
    )
  }

  private changePasswordWithCurrentAudience(password: any, JWTToken: any, userId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JWTToken}`,
      })
    };
    const body = {
      password: password,
    };
    return this.http.patch(environment.auth0Domain+ `/api/v2/users/${userId}`, body, httpOptions).subscribe({
      next: (response: any) => {
      },
      error: (e: any) => console.log(e),
      complete: () => {
      }
    });
  }


  fullyLogout(url:string){
    return this.http.get(environment.auth0Domain + `/v2/logout?returnTo=${url}&client_id=${environment.auth0ID}`)
  }

  public passwordlessAuthentication(email: any) {
    this.userIsValidated(email).subscribe({
      next: (response: any) => {
        if (response == false) {
          this.toastrService.error("We did not find any valid user with this e-mail. If you have registered this e-mail as yours, please check your mailbox for validation directions or try to login with your password to receive the directions again", '', {
            timeOut: 10000
          })
          return;
        }
        if (response == true) {
          this.webAuth.passwordlessStart({
            connection: 'email',
            send: 'link',
            email: email,
            authParams: {
              redirectUri: window.location.href.replace('login', 'overview')
            }
          }, (err, authResult) => {
            if (authResult) {
              this.toastrService.success('We have sent a login link to the given e-mail, please check your mailbox.')
              return
            } else {
              console.log(err)
              if (err?.code == "bad.email") {
                this.toastrService.error('Invalid email, check if the email is correct.')
                return;
              }
              this.toastrService.error('Something wrent wrong sending you a magic link')
              return;
            }
          });
        }
      },
      error: (e: any) => {
        this.toastrFactory.unknownError();
        console.log(e)
      },
    })
  }

  public deleteUser(JWTToken: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${JWTToken}`,
      })
    };
    return this.http.delete(environment.api + 'User/delete', httpOptions)
  }

  public setUserPreferedLanguage(JWTToken: string, ISOlanguage: string) {
   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JWTToken}`,
        'accept': 'text/plain',
      }),
    };
    
    const body = `"${ISOlanguage}"`;
    
    return this.http.post(environment.api + 'User/set-language', body, httpOptions);
  }

  public userIsValidated(email: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
      })
    };
    return this.http.post(environment.api + 'User/isValidated?email=' + email, httpOptions)
  }

  public getBalances(JWTToken: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JWTToken}`,
        'accept': 'text/plain',
      }),
    };
    return this.http.post(environment.api + 'Balance/all', '', httpOptions)
  }
}

