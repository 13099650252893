// src/app/services/wallet-validator.service.ts
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cryptoAddressValidator from '@yieldapp/crypto-address-detector';
import { validate } from 'crypto-address-validator-ts';
import { firstValueFrom } from 'rxjs';
import { tronApiValidateAddress } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WalletValidatorService {

  constructor(public http:HttpClient) { }

  async validateAddress(address: string): Promise<string[]> {
    
    let enabledNetworksForAddress: string[] = [];
    
    const acceptedNetwork = await cryptoAddressValidator.detect(address.trim());
    
    acceptedNetwork?.forEach((network:string) => {
      enabledNetworksForAddress.push(this.getNetworkName(network, address));
    });

    if(!acceptedNetwork) {
      try {

        const validTronWallet = await firstValueFrom(this.validateTronAddress(address)) as any;
        console.log(validTronWallet);
        
        if( validTronWallet.result ) {
          enabledNetworksForAddress.push('TRC')
        }

      } catch( e ) {
        console.log(e);
      }
      
    }
   
    return enabledNetworksForAddress;
  }

  getNetworkName(network:string, address:string){
    if(network === 'polygon') {
      return 'MATIC';
    }
    
    if(network === 'btc' || network === 'bitcoin') {
      if(validate(address, 'btc', { chainType:'btc', networkType: 'prod'})) {
        return 'BTC';
      }
    }

    if(network === 'eth' || network === 'ethereum') {
      console.log(validate(address, 'ethereum', { chainType:'ethereum', networkType: 'prod'}), 'ethereum');
      return 'ETH';
    }

    return network;
  }

  validateTronAddress(address:string) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'text/plain',
          'content-type': 'application/json'
        })
      };

      const body = {
        address: address
      }

      return this.http.post(tronApiValidateAddress, body, httpOptions);
  }
}