import { environment, ipGeoLocationApiKey, ipGeoLocationApi } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import * as auth0 from 'auth0-js'
import { RegisterPageComponent } from '../pages/register-page/register-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class GeolocationService {
  constructor(public route: Router,
    private http: HttpClient,
    public auth: AuthService
  ) { }


  getUserLocation(language:string | null) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
      })
    };
    return this.http.get(ipGeoLocationApi + ipGeoLocationApiKey + '&lang=' + language || 'en', httpOptions)
  }

  
}










