import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import * as auth0 from 'auth0-js';
import { environment } from 'src/environments/environment';

@Injectable()
export class FavoritesService {
  private webAuth = new auth0.WebAuth({
    domain: environment.auth0Domain,
    clientID: environment.auth0ID
  });

  constructor(public route: Router,
    private http: HttpClient,
    public auth: AuthService
  ) { }


  getUserFavorites(JWTToken: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.get(environment.api + 'FavoriteNick', httpOptions)
  }

  deleteFavorite(JWTToken: any, nick: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.delete(environment.api + 'FavoriteNick?nick=' + nick, httpOptions)
  }

  
  async favoriteByNick(JWTToken: string, nick:string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.get(environment.api + 'FavoriteNick/search?q=' + nick, httpOptions)
  }

  addFavorite(JWTToken: any, nick: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    const body = {

    };
    return this.http.post(environment.api + 'FavoriteNick?nick=' + nick + "&type=Email", body, httpOptions)
  }

  searchFavorites(JWTToken: any, nick: string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.get(environment.api + 'FavoriteNick/search?q=' + nick , httpOptions)
  }

}






