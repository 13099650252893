import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService  {
    constructor(private http: HttpClient, private router: Router, private auth: AuthService, private params: ActivatedRoute) { }
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        const currentUrl = state.url;
        const isAuthenticated = await firstValueFrom(this.auth.isAuthenticated$);
        const user:any = await this.getUser(isAuthenticated);

        if (currentUrl.includes('/login') || currentUrl.includes('/register') || currentUrl.includes('/login/forgotpassword') || currentUrl.includes('/activate-account') ) {
            if (isAuthenticated) {
                if(user?.blockedUser){
                    this.router.navigateByUrl('/');
                    localStorage.setItem('manyUsers', 'true');
                    return false;
                }
                localStorage.removeItem('manyUsers');
                this.router.navigateByUrl('/overview');
                return false
            } else {
                return true
            }
        }

        if (isAuthenticated) {
            if(user?.blockedUser){
                this.router.navigateByUrl('/');
                localStorage.setItem('manyUsers', 'true');
                return false;
            }
            localStorage.removeItem('manyUsers');
            return true
        } else {
            this.auth.loginWithRedirect({
                appState: { target: currentUrl },
                authorizationParams: {
                    ui_locales: navigator.language || 'en',
                }
            });
            return false
        }
    }

    async getUser(isAuthenticated:boolean){
        if(isAuthenticated) {
            return await firstValueFrom(this.auth.user$)
        } else  {
            return undefined;
        };
    }
}