import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, Output, ViewChild } from '@angular/core';
import { optionSelectsModel } from '../models/option-select.model';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-custom-option-select',
  templateUrl: './custom-option-select.component.html',
  styleUrls: ['./custom-option-select.component.scss']
})
export class CustomOptionSelectComponent {

  constructor(  
    private changeDetector:ChangeDetectorRef,
    @Inject(DOCUMENT) private doc: Document,
    private elementRef: ElementRef)
    {
  }

  @Input('availableExchanges') availableExchanges!:any;
  @Input('offRampExchangeSettingsForUser') offRampExchangeSettingsForUser!:any;
  @Input('configurationExchangeModal') configurationExchangeModal!:any;
  @Input('configurationExchange') configurationExchange!:any;
  @Input('assets') assets!:any;
  @Input('networkAssets') networkAssets!:any;
  @Input('currentValue') currentValue!:any;
  @Input('disabled')disabled!:boolean;
  @ViewChild('selectionDiv') selectionDiv!:any;


  @Output() changedValue: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();
  @Output() optionName: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();


  public openSelection:boolean = false;
  public currentOption: optionSelectsModel = {
    name: '',
    value: 'undefined'
  };


  public options:optionSelectsModel[] = [];

  ngOnInit(): void {
    this.setOption(
      this.availableExchanges || this.offRampExchangeSettingsForUser ||
      this.assets || this.networkAssets ||
      this.configurationExchange || this.configurationExchangeModal
    );

    // this.doc.body.addEventListener('click', event => {
    //   // if(this.openSelection){
    //   //   this.openSelection =  false
    //   //   return;
    //   // }
    //   if(!this.isInsideCustomOptionSelect(event.target)){
    //     this.openSelection =  false
    //   } 
    // });
    if(this.offRampExchangeSettingsForUser){
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.openSelection = false;
    }
  }

  private isInsideCustomOptionSelect(target: any): boolean {
    return this.hasClassOrParentWithClass(target, 'selection-div');
  }

  private hasClassOrParentWithClass(element: any, className: string): boolean {
    while (element) {
      if (element.classList && element.classList.contains(className)) {
        return true;
      }
      element = element.parentNode;
    }
    return false;
  }

  ngAfterViewInit(): void {
  }

  setOption(option: optionSelectsModel[]){
    this.options = option;
    this.currentOption = this.options.filter(value => value.value == this.currentValue)[0];
  }

  selectedOption(option:optionSelectsModel){
    if(option.value == "disabled"){
      return;
    }
    this.currentValue = option;
    this.currentOption = option
    this.openSelection = false;
    this.optionName.emit(option.name?.toString());
    this.changedValue.emit(option.value?.toString());
    this.changeDetector.detectChanges();
  }

  
}
