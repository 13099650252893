import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import * as auth0 from 'auth0-js';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscriber } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfileDto } from '../dtos/user-profile.dto';
import FiatByCountry from '../utils/fiat-by-country';
import { UTMDto } from './../dtos/utm.dto';
import { UserService } from './user.service';

@Injectable()
export class SigninService {
    private webAuth = new auth0.WebAuth({
        domain: environment.auth0Domain,
        clientID: environment.auth0ID,
        audience: environment.auth0Audience
    });

    constructor(public route: Router,
        private http: HttpClient,
        private toastrService: ToastrService,
        private auth: AuthService,
        private userService:UserService,
        private fiatByCountry:FiatByCountry,
        private translateService: TranslateService
    ) {

    }

    private success!: boolean;
    private JWTToken!: string;
    private isDomainOwner!:boolean;
    private imageFile:any;

    public WebAuth(form: FormGroup<any>, isDomainOwner:boolean, imageFile:any): Observable<string> {
        this.isDomainOwner = isDomainOwner;
        this.imageFile = imageFile;
        let website: any;
        return new Observable(observer => {
            if (form.controls['website'].value == null) {
                form.controls['website'].setValue('');
            } else {
              website = this.setWebsite(form);
            }
            if (form.controls['terms'].value == true) {
                form.controls['terms'].setValue('true');
            }
            this.webAuth.signupAndAuthorize({
                connection: 'Username-Password-Authentication',
                email: form.controls['nick'].value,
                password: form.controls['password'].value,
                scope: 'openid profile',
                userMetadata: {
                    lang: navigator.language || 'en'
                },
            }, (err, authResult) => {
                if (err) {
                    if (err.statusCode == 400 && err.description == 'Invalid sign up') {
                        observer.next('user already');
                        return;
                    } else {
                        this.toastrService.error(err.description, '', {
                            timeOut: 3000
                        })
                        observer.next('error');
                    }
                    console.log(err)
                    observer.next('error');
                }
                this.JWTToken = authResult.accessToken
                this.success = true;
                const domain = form.controls['domain'].value
                const user:ProfileDto = {
                    agreedPrivacyPolicy: true,
                    publicName : form.controls['publicName'].value,
                    name: form.controls['yourName'].value,
                    country: form.controls['country'].value,
                    websiteUrl: website,
                }
                this.storeUserInfoInNickyDatabase(observer, domain, user, website)
            });
        });
    }

    setWebsite(form:any){
        if(!(form.controls['website'].value.includes('https://') || form.controls['website'].value.includes('http://'))){
            return form.controls['websiteCertificate'].value + form.controls['website'].value;
        } else {
            return form.controls['website'].value;
        }
    }

    public storeUserInfoInNickyDatabase(observer:Subscriber<string>, domain:string ,userInfo: ProfileDto, website:string){
        this.saveUserOnDatabase(this.JWTToken, userInfo, website).subscribe({
            next: (response: any) => {
              this.setUserPreferedLanguage(observer, domain ,userInfo, website);
            },
            error: (e: any) => {
                this.toastrService.error('Something went wrong on saving your user on our database', '', {
                    timeOut: 3000
                });
                observer.next('error on saving');
                console.log(e)
            },
            });
    }

    setUserPreferedLanguage(observer:Subscriber<string>, domain:string ,userInfo: ProfileDto, website:string) {
        this.userService.setUserPreferedLanguage(this.JWTToken, this.translateService.getDefaultLang()).subscribe({
            next: (response:any) => {
                this.setFirstSettlementAsset(observer, userInfo, domain);
            }, 
            error: (e:any) => {
                this.setFirstSettlementAsset(observer, userInfo, domain);
            }
        })
    }

    setFirstSettlementAsset(observer:Subscriber<string>, userInfo: ProfileDto ,domain:string ){
        const asset = this.fiatByCountry.getUserFiatByCountry(userInfo.country?.toUpperCase() || "US")
        this.userService.setAcceptedAssets(this.JWTToken, asset).subscribe({
            next: (assets:any) => {
                if(this.isDomainOwner) {
                    this.addUserDomain(observer, domain);
                } else {
                    setTimeout(() => {
                        this.toastrService.success("Registration successful. Please check your mailbox for further directions on how to validate your e-mail", "", {
                            timeOut: 15000
                        })
                    }, 3000);
                    if( this.imageFile ) {
                        this.updateProfilePicture(observer);
                        return;
                    }
                    observer.next('user success');
                    localStorage.removeItem('utmSettings');
                }
            },
            error: (e:any) => {
            }
          })
    }

    updateProfilePicture(observer:Subscriber<string>){
        this.userService.updateProfilePicture(this.JWTToken, this.imageFile).subscribe({
        next: (response:any) => {
            // this.toastrService.success('Your logo has been updated successfully','',{
            //   timeOut: 3000
            // })
            observer.next('user success');
            localStorage.removeItem('utmSettings');
        },
        error: (e:any) => {
            // this.toastrService.success('Your logo has been updated successfully','',{
            //   timeOut: 3000
            // })
            observer.next('user success');
            localStorage.removeItem('utmSettings');
        }
        })
    }


    addUserDomain(observer:Subscriber<string>, domain:string ){
        this.addDomain(this.JWTToken, domain).subscribe({
            next: (response: any) => {
                if( this.imageFile ) {
                    this.updateProfilePicture(observer);
                    return;
                }
                observer.next('user success');
                localStorage.removeItem('utmSettings');
            }
        })
    }


    public activateAccount(form: FormGroup<any>): Observable<string> {
        return new Observable(observer => {
            if (form.controls['terms'].value == true) {
                form.controls['terms'].setValue('true');
            }
            this.webAuth.signupAndAuthorize({
                connection: 'Username-Password-Authentication',
                email: form.controls['nick'].value,
                password: form.controls['password'].value,
                scope: 'openid profile',
                userMetadata: {
                    lang: navigator.language
                }
            }, (err, authResult) => {
                if (err) {
                    if (err.statusCode == 400 && err.description == 'Invalid sign up') {
                        observer.next('user already');
                        return;
                    } else {
                        this.toastrService.error(err.description, '', {
                            timeOut: 3000
                        })
                        observer.next('error');
                    }
                    console.log(err)
                    observer.next('error');
                }
                this.JWTToken = authResult.accessToken
                this.success = true;
                const domain = form.controls['domain'].value
                this.addDomain(this.JWTToken, domain).subscribe({
                    next: (response: any) => {
                        setTimeout(() => {
                            this.toastrService.success("Registration successful. Please check your mailbox for further directions on how to validate your e-mail", "", {
                                timeOut: 15000
                            })
                        }, 3000);
                        observer.next('user success');
                    }
                }),
                observer.next('user success');
            });
        });
    }

    public saveUserOnDatabase(JWTToken: any, userInfo: ProfileDto, website: any) {
        const agreedPrivacyPolicy = true;
        const publicName = userInfo?.publicName || '';
        const name = userInfo?.name;
        const country = userInfo.country;

        const utmInfo:UTMDto = JSON.parse(localStorage.getItem('utmSettings') || "");
        const referrer = localStorage.getItem('referrer')

        const httpOptions = {
            headers: new HttpHeaders({
                'accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JWTToken}`
            })
        };
        const body = {
            agreedPrivacyPolicy: agreedPrivacyPolicy,
            name: name,
            publicName: publicName,
            websiteUrl: website,
            country: country || undefined,
            umtCampaign: utmInfo.utmCampaign || undefined,
            umtMedium: utmInfo.utmMedium || undefined,
            umtSource: utmInfo.utmSource || undefined,
            referrer: referrer || undefined
        };
        return this.http.post(environment.api + 'User/profile', body, httpOptions)
    }

    private addDomain(JWTToken: any, domain:string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JWTToken}`
            })
        };
        const body = JSON.stringify(domain);
        return this.http.post(environment.api + 'Domain/add', body, httpOptions)
    }

    public resetPassword(email: string): Observable<any> {
        let client_id = environment.auth0ID;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const body = {
            client_id: client_id,
            email: email,
            connection: 'Username-Password-Authentication'
        };
        return this.http.post(environment.auth0Domain + '/dbconnections/change_password', body, { headers });
    }

}

