import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SigninService } from 'src/app/services/sign-in.service';
import animatedBackground from 'src/app/utils/background-animation-utils';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent {


  constructor(private router: Router,
    private signin: SigninService,
    private auth: AuthService,
    private toastService: ToastrService,
    public translate:TranslateService,
    private params: ActivatedRoute,
    private animatedBackground:animatedBackground
  ) { }


  public showModal: boolean = false;
  public numbersLettersAndSpecial!: RegExp;
  public atLeast8Digits!: RegExp;
  public atLeast1SpecialCharacter!: RegExp;
  public atLeast1LowerCase!: RegExp;
  public atLeast1UpperCase!: RegExp;
  public atLeast1Number!:RegExp;
  public modalTittle!: string;
  public modalMessage!: string;
  private _success!: boolean;
  public _email: string = "";
  token: string | undefined;
  public loading: boolean = false;
  public seeingPassword:boolean = false;
  public defaultBackgroundPosition:number = 40;
  public alreadyExistNick:string = "";

  public form: FormGroup = new FormGroup({
    user: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9._-]*[a-zA-Z0-9+]*$/)]),
    domain: new FormControl(null, [Validators.required, Validators.pattern(/^[A-Za-z0-9][A-Za-z0-9-_#*.+]*\.[A-Za-z0-9]+$/)]),
    nick: new FormControl(null, Validators.email),
    password: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#\$%\^&*]).{8,}$/)]),
    publicName: new FormControl(null, Validators.required),
    terms: new FormControl(null, Validators.required),
    recaptcha: new FormControl(null)
  });

  ngOnInit(): void {
    this.populateRegexp()
    this.getUrlParams();
  }

  ngAfterViewInit(): void {
    this.animatedBackground.animatedBackgroundTransition(this.defaultBackgroundPosition);
  }


  getUrlParams() {
    this.params.queryParams.subscribe(params => {
      const controlers = {
        email: this.form.controls['nick'],
        user: this.form.controls['user'],
        domain: this.form.controls['domain'],
      }
      let paramEmail = params['email'];
      paramEmail = paramEmail.replace(" ", "+")
      if (paramEmail) {
        this.setAndTestEmail(paramEmail, controlers)
      } else {
        this.router.navigateByUrl('/home');
      }
    });
  }


  setAndTestEmail(paramEmail:string, controlers:any){
    const splitedEmail = paramEmail.split('@');
    controlers.email.setValue(paramEmail);
    controlers.user.setValue(splitedEmail[0]);
    controlers.domain.setValue(splitedEmail[1]);
    controlers.email.disable();

    if (!controlers.domain.valid || !controlers.user.valid) {
      this.router.navigateByUrl('/home');
    }
  }

  set success(newValue: any) {
    this._success = newValue;
    if (this._success === true) {

    }
  }
  
  populateRegexp() {
    this.numbersLettersAndSpecial = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])[a-zA-Z\d!@#\$%\^&\*]{3,}$/;
    this.atLeast8Digits = /^.{8,}$/;
    this.atLeast1SpecialCharacter = /[!@#\$%\^&\*]+/;
    this.atLeast1LowerCase = /.*[a-z].*/;
    this.atLeast1UpperCase = /.*[A-Z].*/;
    this.atLeast1Number = /.*[0-9].*/;
  }

  matchSpecialCharacter(){
    return this.atLeast1SpecialCharacter.test(this.form.controls['password'].value);
  }

  match1Number(){
    return this.atLeast1Number.test(this.form.controls['password'].value);
  }

  match1LowerCase(){
    return this.atLeast1LowerCase.test(this.form.controls['password'].value);
  }

  match1UpperCase(){
    return this.atLeast1UpperCase.test(this.form.controls['password'].value);
  }

  matchAtLeast8Digits(){
    return this.atLeast8Digits.test(this.form.controls['password'].value)
  }

  matchAll(){
    return this.matchSpecialCharacter() && this.match1Number() && this.match1LowerCase() && this.match1UpperCase() && this.matchAtLeast8Digits()
  }

  public hideModal() {
    this.showModal = false;
  }

  public goHome() {
    this.router.navigateByUrl('/home')
  }

  nickAlreadyExist(){
    const newNick = this.form.controls['user'].value + "@" + this.form.controls['domain'].value;
    return this.alreadyExistNick == newNick;
  }

  public async submit() {
    this.loading = true
      if (this.form.valid) {
          await this.signin.activateAccount(this.form).subscribe(
            (response: any) => {
              this.loading = false;
              if (response == 'user already') {
                this.toastService.error(this.translate.instant('REGISTER.nickAlreadyExist'));
                this.alreadyExistNick = this.form.controls['nick'].value;
                this.loading = false
              }
            if (response == 'user success'){
            this.auth.loginWithRedirect({
                authorizationParams: {
                  registering: true
                },
                appState: { target: '/overview' }
            });
            }
            })
      } else {
        this.toastService.error('Invalid form', '', {
          timeOut: 3000
        })
        this.loading = false
      }
  }

  public modalPop(modalMessage: string, modalTittle: string) {
    this.showModal = true;
    this.modalTittle = modalTittle;
    this.modalMessage = modalMessage;
  }



}
