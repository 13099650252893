import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { PaymentRequestCreateDto } from '../dtos/payment-request.dto';

@Injectable()
export class PaymentRequestService {

  constructor(public route: Router,
    private http: HttpClient,
    public auth: AuthService
  ) { }


  makeRequest(JWTToken: string, paymentRequest: PaymentRequestCreateDto) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${JWTToken}`,
        'Content-Type': 'application/json'
      })
    };

    const body = paymentRequest;
        
    return this.http.post(environment.api + 'PaymentRequest/create', body, httpOptions);
  }


  
  getPaymentById(paymentId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
      })
    };
    return this.http.get(environment.api + 'PaymentRequest/get-by-shortid?shortId=' + paymentId, httpOptions)
  }

    
  resendPaymentRequestEmail(shortId: string, JWTToken: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.post(environment.api + 'PaymentRequest/resend-email?shortId=' + shortId, {}, httpOptions);
  }



  changeRequestStatus(JWTToken:string, paymentId:string, status:string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    const payload = `"${status}"`
    return this.http.post(environment.api + 'PaymentRequest/set-status?shortId=' + paymentId, payload, httpOptions)
  }


  assignToPaymentRequest(JWTToken:string, paymentRequestId:string, paymentReportId:string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.post(environment.api + 'PaymentReport/move-to-paymentrequest?id=' + paymentReportId + '&shortId=' + paymentRequestId, '', httpOptions)
  }
}










