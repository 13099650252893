<app-modal [modalMessage]="modalMessage" [fullEmailModal]="fullEmailModal" [paymentIdModal]="paymentIdModal"
    (onCloseModal)="closeInfoModal()" [hideCopyButton]="hideCopyButton" *ngIf="paymentIdModal"
    (onConfirmModal)="alreadyFinished = true; closeModal()"></app-modal>

<div class="payment-request-flow">

    <div class="full-width">
        <h1 class="flow-tittle">
           {{ 'payment_flow.PaymentRequest' | translate }}
        </h1>
        <div class="steps-status">
            <label [ngStyle]="{'color': (step === 'payment-details') || '#000'}"
                (click)="checkIfStepCanBeClicked('payment-details')">
                <div class="toggle-box clickable" [ngClass]="{'past-step': completedSteps.paymentDetails }">
                    <div class="current-step" *ngIf="step === 'payment-details' && !completedSteps.paymentDetails ">

                    </div>
                    <i class="checked-step ph-bold ph-check" *ngIf="completedSteps.paymentDetails"></i>
                </div>
                <span>{{ 'payment_flow.contact' | translate }}</span>
            </label>
            <hr>
            <label [ngStyle]="{'color': (step === 'payment-info') || '#000'}">
                <div class="toggle-box clickable" (click)="checkIfStepCanBeClicked('payment-info') "
                    [ngClass]="{'past-step': completedSteps.paymentInfo }">
                    <div class="current-step" *ngIf="step === 'payment-info' && !completedSteps.paymentInfo">

                    </div>
                    <i class="checked-step ph-bold ph-check" *ngIf="completedSteps.paymentInfo"></i>
                </div>
                <span> {{ 'payment_flow.RequestDetails' | translate }} </span>
            </label>
            <hr>
            <label>
                <div class="toggle-box clickable" (click)="checkIfStepCanBeClicked('report-payment')" 
                [ngClass]="{'past-step': step === 'finish' }">
                    <div class="current-step" *ngIf="step === 'report-payment' || step === 'finish'">

                    </div>
                    <i class="checked-step ph-bold ph-check" *ngIf="step === 'finish'"></i>
                </div>
                <span> {{ 'payment_flow.overview' | translate }} </span>
            </label>
        </div>
    </div>


    <div class="modal-body" *ngIf="step == 'payment-details'">
        <div class="step-container">
            <div class="step-tittle flex-centered">
                <h2>
                    {{ 'payment_flow.contact' | translate }}
                </h2>
                <h3>
                    {{ 'payment_flow.PayerInfo' | translate }}
                </h3>
            </div>
            <!--  -->
            <div class="input-wrapper">
                <h3> {{ 'payment_flow.PayersEmail' | translate }}* </h3>
                <div class="input-container"
                    [ngClass]="{'invalid':(invalidPayerInfo && !(this.payment.controls['email'].valid) || invalidEmail)}">
                    <i class="ph ph-at"></i>
                    <input autocomplete="email" name="email" class="common-input bigger-input" type="text"
                        placeholder="{{'PAYMENT_REQUEST.payersEmail' | translate}}" [(ngModel)]="email"
                        (keyup)="emailInput()">
                </div>
            </div>

            <label *ngIf="(invalidPayerInfo && !this.payment.controls['email'].valid) || invalidEmail"
                class="inform-label warning-inform"> {{ 'youNeedToInformAValidEmail' | translate }} </label>

            <div class="input-wrapper" *ngIf="selectedUserType === 'individual'">
                <h3> {{ 'payment_flow.PayersName' | translate }}* </h3>
                <div class="input-container"
                    [ngClass]="{'invalid':invalidPayerInfo && !(this.payment.controls['name'].valid)}">
                    <i class="ph ph-user-circle"></i>
                    <input autocomplete="name" name="name" class="common-input bigger-input" type="text"
                        placeholder="{{'PAYMENT_REQUEST.payersName' | translate}}"
                        (ngModelChange)="payment.controls['name'].setValue(name)" [(ngModel)]="name"
                        *ngIf="selectedUserType == 'individual'">
                </div>
            </div>
            <div class="input-wrapper" *ngIf="selectedUserType === 'organization'">
                <h3> {{ 'payment_flow.PayersOrganizationName' | translate }}* </h3>
                <div class="input-container"
                    [ngClass]="{'invalid':invalidPayerInfo && !(this.payment.controls['company'].valid)}">
                    <i class="ph ph-user-circle"></i>
                    <input autocomplete="organization" name="organization" class="common-input bigger-input" type="text"
                        placeholder="{{'PAYMENT_REQUEST.payersOrganizationName' | translate}}"
                        (ngModelChange)="payment.controls['company'].setValue(organization)" [(ngModel)]="organization"
                        *ngIf="selectedUserType == 'organization'">
                </div>
            </div>

            <label *ngIf="selectedUserType == 'individual' && invalidPayerInfo && !this.payment.controls['name'].valid"
                class="inform-label warning-inform"> {{ 'youNeedToInformPayerName' | translate }} </label>
            <label
                *ngIf="selectedUserType == 'organization' && invalidPayerInfo && !this.payment.controls['company'].valid"
                class="inform-label warning-inform"> {{ 'youNeedToInformYourCompanyName' | translate }} </label>

            <div class="input-wrapper">
                <h3> {{ 'payment_flow.PayerInfo' | translate }}* </h3>
                <div class="payer-info">
                    <div class="payer-type clickable" (click)="selectedUserType = 'individual'"
                        [ngClass]="{'selected-type': selectedUserType === 'individual'}">
                        <div class="payer-icons">
                            <i class="ph ph-user"></i>
                            <i class="ph ph-check" *ngIf="selectedUserType === 'individual'"></i>
                        </div>
                        <h2>{{ 'payment_flow.Individual' | translate }}</h2>
                        <h3>{{ 'payment_flow.PayerIsAnIndividual' | translate }}</h3>
                    </div>
                    <div class="payer-type clickable" (click)="selectedUserType = 'organization'"
                        [ngClass]="{'selected-type': selectedUserType === 'organization'}">
                        <div class="payer-icons">
                            <i class="ph ph-buildings"></i>
                            <i class="ph ph-check" *ngIf="selectedUserType === 'organization'"></i>
                        </div>
                        <h2>{{ 'payment_flow.Organization' | translate }}</h2>
                        <h3>{{ 'payment_flow.PayerIsAnOrganization' | translate }}</h3>
                    </div>
                </div>

            </div>
            <!-- <label *ngIf="invalidPayerInfo && this.payment.controls['amount'].value == ''"
                class="inform-label warning-inform"> {{ 'enterTheAmountToBePaid' | translate }} </label>
            <label *ngIf="invalidAmount" class="inform-label warning-inform"> {{ 'youNeedToInformTheAmountTransfered' |
                translate }} </label> -->
        </div>
        <div class="modal-footer side-buttons-footer only-desktop">
            <button class="cancel-button" (click)="closeModal()">{{ 'payment_flow.Cancel' | translate }} </button>
            <div>
                <button (click)="nextStep('payment-info')">{{ 'payment_flow.Continue' | translate }} </button>
            </div>
        </div>
    </div>

        <div class="modal-body" *ngIf="payingAsset && step == 'payment-info'">
            <div class="step-container">
                <div class="step-tittle flex-centered">
                    <h2>
                        {{ 'payment_flow.RequestDetails' | translate }}
                    </h2>
                    <h3>
                        {{ 'payment_flow.PaymentRequestDetails' | translate }}
                    </h3>
                </div>

                <!-- <h2 class="informative-header">
                    Amount
                </h2> -->

                <div class="input-wrapper">
                    <h3>
                         {{ 'payment_flow.SettlementAsset' | translate }}*
                         <i class="ph-fill ph-info clickable"  matTooltip="{{ 'settlementAssetsAre' | translate }}" [matTooltipPosition]="'above'"></i> 
                    </h3>
                    <app-custom-option-select class="inline-flex-option-select"
                        [availableExchanges]="currentOptionsSelects.payingAsset" [currentValue]="payingAsset"
                        (changedValue)="payingAsset = $event;
                        updateMaskConfig();"></app-custom-option-select>
                </div>

                <div class="input-wrapper">
                    <h3> {{ 'payment_flow.Amount' | translate }}* </h3>
                    <div class="input-container"
                        [ngClass]="{'invalid':invalidPayerInfo || payment.controls['amount'].value == '' }">
                        <input #amountElement 
                            class="input-value "
                            currencyMask 
                            [options]="customCurrencyMaskConfig"
                            type="text"
                            (paste)="setAmount($event)"
                            [(ngModel)]="amount" 
                            autocomplete="off"
                            (ngModelChange)="payment.controls['amount'].setValue(amount)" 
                            name="usd"
                            placeholder="{{'0' + (isCommaDecimal ? ',' : '.') + '0000000'}}">
                    </div>
                </div>
                <label *ngIf="invalidAmount && !amount" class="inform-label warning-inform"> {{
                    'youNeedToInformTheAmountTransfered' | translate }}
                </label>
<!-- 
                <h2 class="informative-header">
                    Reference
                </h2> -->
                <form [formGroup]="payment">

                <div class="input-wrapper">
                    <h3> {{ 'description' | translate }} </h3>
                    <div class="input-container">
                        <i class="ph ph-user-circle"></i>
                        <input type="text" class="fill-size-input bigger-input" formControlName="description"
                            name="description" placeholder="{{ 'description' | translate }}">
                    </div>
                </div>
                <div class="input-wrapper">
                    <h3> {{ 'payment_flow.Reference' | translate }} </h3>
                    <div class="input-container">
                        <i class="ph ph-at"></i>
                        <input type="text" class="fill-size-input bigger-input" [maxlength]="17" formControlName="reference"
                            name="reference" placeholder="{{ 'reference' | translate }}">
                    </div>
                </div>

                </form>
                <label *ngIf="invalidPayerInfo && this.payment.controls['amount'].value == ''"
                    class="inform-label warning-inform"> {{ 'enterTheAmountToBePaid' | translate }} </label>
            </div>
            <div class="modal-footer side-buttons-footer only-desktop">
                <button class="cancel-button" (click)="closeModal()">{{ 'payment_flow.Cancel' | translate }} </button>
                
                <div class="buttons-wrapper">
                    <button class="secondary-button" (click)="backPreviousStep('payment-details')">{{ 'back' | translate }} </button>
                    <button (click)="nextStep('report-payment')">{{ 'payment_flow.Continue' | translate }} </button>
                </div>
            </div>
        </div>


        <div class="modal-body" *ngIf="step == 'report-payment'">
            <div class="step-container">
                <!-- <div class="step-tittle flex-centered">
                    <h2>
                        {{ 'payment_flow.preview' | translate }}
                    </h2>
                    <h3>
                        {{ 'payment_flow.OverviewPaymentRequest' | translate }}
                    </h3>
                </div> -->
                

                   <!-- 
                    from img
                    <img class="profile-pic"
                        [src]="environment.api + 'User/get-public-profile-picture?userId=' + user.id"
                        [matTooltipPosition]="'above'" matTooltip="{{ (user.publicName || user.name)?.charAt(0) }}"
                        (error)="handleImageError($event)"> -->
                <!-- <div class="request-info">
                    <label class="info-name">{{ 'payment_flow.from' | translate }}:</label>
                 
                    <label class="info-value">
                        {{ user.publicName }} <{{ user.email }}>
                    </label>
                </div> -->

                <!-- <img class="profile-pic" [src]="getInitialAvatar(name || email)" [matTooltipPosition]="'above'"
                        matTooltip="{{ (organization || email) }}"> -->
                <!-- <div class="request-info" *ngIf="selectedUserType === 'organization'">
                    <label class="info-name">{{ 'PAYMENT_REPORT.to' | translate }}</label>   
                    <label class="info-value"> {{ name }} <{{ email }}> </label>
                </div> -->
                <!-- <img class="profile-pic" [src]="getInitialAvatar(name || email)" [matTooltipPosition]="'above'"
                    matTooltip="{{ (name || email) }}"> -->
                <!-- <div class="request-info" *ngIf="selectedUserType === 'individual'">
                    <label class="info-name">{{ 'PAYMENT_REPORT.to' | translate }}</label>

                    <label class="info-value"> {{ name }} <{{ email }}> </label>
                </div> -->

                <!-- @if ( payment.controls['description'].value ) {
                    <div class="request-info">
                        <label class="info-name">
                            {{ 'descriptionLabel' | translate }}</label>
                        <label class="info-value">
                            {{ payment.controls['description'].value || '< ' + ('PAYMENT_REQUEST.empty' | translate) + ' >' }} </label>
    
                    </div>
                } -->
                

                <div class="overview-message">
                    <span class="info-header">{{'payment_flow.Dear' | translate}} {{ name }},</span><br>

                    {{'payment_flow.iAmSendingThisRequestInTheAmount' | translate}}
                    <span class="important-info"> 
                        {{ payingAsset?.toUpperCase() }} {{ formattedPrice }}</span>
                    <br>
                    @if (payment.controls['reference'].value) {
                        {{'payment_flow.Regarding' | translate}}
                        <span class="important-info reference">
                            {{ getEllipsisText(15, payment.controls['reference'].value)
                            || '< ' + ('PAYMENT_REQUEST.empty' | translate) + ' >' }}
                        </span>
                    }
                    <span class="info-footer">
                        <!-- {{'payment_flow.Regards' | translate}},  -->
                    <br *ngIf="isMobile"> {{ user.publicName }}</span>
                </div>



                <!-- <div class="check-email">
                        <input type="checkbox" checked="true" #sendEmail> <label> {{ 'PAYMENT_REQUEST.sendAnEmail' | translate
                            }}</label>
                    </div> -->
            </div>
            <div class="modal-footer side-buttons-footer only-desktop">
                <button class="cancel-button" (click)="closeModal()">{{ 'payment_flow.Cancel' | translate }} </button>
                
                <div class="buttons-wrapper">
                    <button class="secondary-button" (click)="backPreviousStep('payment-info')">{{ 'back' | translate }} </button>
                    <button (click)="!loading ? nextStep('finish') : ''">{{ 'PAYMENT_REQUEST.finish' | translate }}</button>
                </div>
            </div>
        </div>

        
    <!-- finish | finish -->
    <div class="modal-body step-success-body" *ngIf="(step == 'finish' && shortId)">
        <div class="step-container step-overview">
            <div class="step-tittle flex-centered">
                <img class="success-payment-img" src="/assets/icons/success-payment.png">
                <h2>
                    {{ 'payment_flow.requestHasBeenSent' | translate }}
                </h2>
                <label class="success-email-label">{{ 'payment_flow.paymentRequestHasBeenSentTo' | translate }} <br> {{ payment.controls['email'].value?.trim() }}</label>
            </div>
            <label class="payment-success-inform">
                {{ 'payment_flow.requestId' | translate }}
               <span> 
                    {{ shortId }}
                    <i class="ph ph-copy copy-button clickable" (click)="copyRequestId()"></i>
                    <i class="ph ph-qr-code clickable" (click)="qrModal = true"></i>
                </span>
            </label>

            <label class="payment-success-inform settlement-tittle">
                {{ 'payment_flow.settlementAmount' | translate }}   
             </label>
            <div class="settlement-container">
                <label>
                    {{ payingAsset }}
                </label>
                <span>
                    {{ formatPrice(payment.controls['amount'].value) }}
                </span>
            </div>

            <div class="modal-footer side-buttons-footer centered-footer">
                <button *ngIf="navigatorSupportsShare()" class="secondary-button" (click)="shareScreen(shortId)"> 
                    <i class="ph ph-share-network"></i>
                    {{ 'payment_flow.share' | translate }}
                </button>

                <button (click)="showConfirmationModal = false; closeModal();"> {{ isAuthenticated ? ('backToDashboard' | translate) : ('createAnAccount' | translate) }} </button>
            </div>
        </div>
        <!-- <div class="modal-footer side-buttons-footer centered-footer">
            <button (click)="showConfirmationModal = false; closeModal();"> {{ isAuthenticated ? ('backToDashboard' | translate) : ('createAnAccount' | translate) }} </button>
        </div> -->

    </div>

    <app-nicky-footer class="only-mobile"></app-nicky-footer>
</div>

<div class="confirmation-modal-backdrop modal-backdrop" *ngIf="showConfirmationModal">

    <div class="modal payment-info-modal confirmation-modal" *ngIf="showConfirmationModal">
        <div class="modal-header">
            <h3 class="payment-tittle"> Hold on! </h3>
            <h3 class="close" (click)="showConfirmationModal = false"> X </h3><br>

        </div>

        <div class="modal-body">
            <label>Are you sure you don’t want to report this payment?</label>
        </div>
        <div class="modal-footer side-buttons-footer">
            <button class="no-report" (click)="showConfirmationModal = false; alreadyFinished = true; closeModal()">I
                DON'T</button>
            <button (click)="showConfirmationModal = false;">CONTINUE</button>
        </div>
    </div>
</div>

<!-- <div class="confirmation-modal-backdrop modal-backdrop" *ngIf="setConversionRate">   
    <div class="percentage-selector-wrapper">
        <h3 class="close" (click)="setConversionRate = false"> X </h3><br>
        <label class="percentage-tittle">
            {{ "PAYMENT_REQUEST.setUpTheoFFRampPercentageForThisTransaction" | translate }}
        </label>
        <mat-slider min="0" max="100" step="1" value="0">
            <input [(ngModel)]="convertToFiatPorcentage" #slider matSliderThumb>
        </mat-slider> 
        {{ convertToFiatPorcentage }}%
        <div class="modal-footer side-buttons-footer">
            <button (click)="saveAutomaticConversionInfo();">{{'PROFILE.save' | translate}}</button>
        </div>
    </div>
</div> -->



<div *ngIf="qrModal" class="modal-backdrop">
    <div class="modal qr-modal">
      <i class="ph-bold ph-x close-modal clickable" (click)="qrModal = false"></i>
      <div class="modal-title">
          <h1>
            {{ 'DASHBOARD.scanOr' | translate }}
            {{ ( navigatorShare ? 'Copy' :'save' ) | translate }}
            {{ 'DASHBOARD.theQrCode' | translate }}
          </h1>
          <h3>
            {{ 'youCanCopyTheLinkOrClick' | translate }}
          </h3>
      </div>
      <hr class="spacement">
      <div class="modal-body">
        <img [src]="getQrCode()" class="qr-code clickable" (click)="copyQr()">
        <div class="input-wrapper">
          <label> {{ 'copy' | translate }} </label>
          <div class="input-container disabled clickable" (click)="onCopyRequestLink( shortId )">
            <i class="ph ph-copy icon clickable copy-icon" ></i>
            <input type="text" class="clickable" (click)="onCopyRequestLink(  shortId )" [value]="url + shortId" disabled>
          </div>
        </div>
      </div>
      <hr class="spacement">
      <div class="buttons-wrapper">
        <button class="secondary-button" (click)="qrModal = false">{{ 'cancel' | translate }}</button>
        <button (click)="qrModal = false"> {{ 'done' | translate }} </button>
      </div>
    </div>
  </div>

<div class="loading-modal" *ngIf="loading">
    <div class="spinner"></div>
</div>


