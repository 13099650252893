import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import * as auth0 from 'auth0-js';
import { environment } from 'src/environments/environment';

@Injectable()
export class SearchService {
  private webAuth = new auth0.WebAuth({
    domain: environment.auth0Domain,
    clientID: environment.auth0ID,
  });

  constructor(public route: Router,
    private http: HttpClient,
    public auth: AuthService
  ) { }


  generalSearch(query: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
      })
    };
    return this.http.get(environment.api + 'Nick/search?q=' + query, httpOptions)
  }

  
  favoritesSearch(JWTToken:string, query: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`
      })
    };
    return this.http.get(environment.api + 'FavoriteNick/search?q=' + query, httpOptions)
  }

  publicProfile(shortId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
      })
    };
    return this.http.get(environment.api + 'User/profile-public-shortid?shortId=' + shortId, httpOptions)
  }

  uuidPublicProfile(UUID: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
      })
    };
    return this.http.get(environment.api + 'User/profile-public?userId=' + UUID, httpOptions)
  }


}










