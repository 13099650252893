import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-error-page',
  standalone: false,
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss'
})
export class ErrorPageComponent {

  constructor(
    private router: Router,
    private params: ActivatedRoute,
  ) { }

  public errorDescription!: string;

  public goHome() {
    this.router.navigateByUrl('/home');
  }

  ngOnInit(): void {
    this.params.queryParams.subscribe(params => {
      let errorDescription = params['error_description'];
      if (errorDescription) {
        this.errorDescription = errorDescription.replace('Try logging in again from the application and if the problem persists please contact the administrator.', '');
      } else {
        this.errorDescription = "Something went wrong.";
      }
    })
  }
}
