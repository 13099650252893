<div class="error-page">
    
    <div class="error">
        <div class="step-container step-overview">
          <div class="step-tittle flex-centered">
            <img class="error-img" src="/assets/icons/error.png">
            <h1>
              Something went wrong
            </h1>
          </div>
          <label class="error-inform">
            {{ errorDescription }} 
            <!-- <hr class="styled-separator"> -->
            <hr class="styled-separator">
            Try logging in again from the application and if the problem persists Please Contact-us at <a href="mailto: support@nicky.me" target=”_blank”>support&#64;nicky.me</a>.
          </label>

          <button (click)="goHome();">Back to Nicky</button>
        </div>
      </div>
</div>
