import { Component, ElementRef, EventEmitter, HostListener, input, Input, Output, ViewChild } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, firstValueFrom, Subject } from 'rxjs';
import { FavoritesService } from 'src/app/services/favorites.service';
import { SearchService } from 'src/app/services/search.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.mode';
import { Asset } from '../models/asset.model';
import { Console } from 'console';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent {


  constructor(
    private favoritesService: FavoritesService,
    public auth: AuthService,
    private toastrService: ToastrService,
    private searchService: SearchService,
    private userService: UserService,
    public translate: TranslateService,
    private elementRef: ElementRef
  ) { }


  @ViewChild('search') search!: ElementRef<HTMLInputElement>;
  @ViewChild('searchDesktop') searchDesktop!: ElementRef<HTMLInputElement>;
  @Input('authenticated') isAuthenticated!:boolean;
  @Input('generalSearch') generalSearch!:boolean;
  @Input('favoritesSearch') favoritesSearch!:boolean;
  @Input('fromHome') fromHome!:boolean;
  @Input('myNick') myNick!:string;
  @Input('JWTToken') JWTToken!:string;
  @Output() openNewPayment: EventEmitter<any> = new EventEmitter<any>();
  @Output() favoriteSearchList: EventEmitter<any> = new EventEmitter<any>();
  
  public searchResults!: any;
  public showModal!: boolean;
  public modalMessage!: string;
  public modalTittle!: string;
  public dashBoardOptions!: boolean;
  public modalNick!: string;
  public modalPublicName!: string;
  public fullEmailModal!: boolean;
  public isMobile: boolean = false;
  public loading = false;
  public paymentFlow = false;
  public targetNick!: string;
  public userId!: string;
  public user: any;
  public targetCompany!: string;
  public companyWebsite!: string;
  public requestInfo: any;
  public assets: any;
  public userProfile!: boolean;
  public environment = environment;
  public minimumCharMessage!:string;
  public target!:User;
  private favoriteSearchInput$ = new Subject<HTMLInputElement>();
  private debounceTimeInMs = 500;


  async onSearch(searchInput: HTMLInputElement) {
    const searchString = searchInput.value;

    if(this.favoritesSearch) {
      if(!searchString || searchString.trim() === "") {
        this.searchResults = await firstValueFrom(this.favoritesService.getUserFavorites(this.JWTToken));
        this.favoriteSearchList.emit( this.searchResults );
        return;
      }
      this.startFavoriteSearch(searchString);
    } else {
      this.validateInput(searchString);
      this.startGeneralSearch(searchString);
    }

  }

  ngOnInit(): void {
    this.favoriteSearchInput$.pipe(
      debounceTime(this.debounceTimeInMs)
    ).subscribe(searchInput => {this.onSearch(searchInput), console.log(searchInput)});  
  }

  onFavoriteSearch(searchElement: HTMLInputElement) {
    if(this.favoritesSearch){
      this.favoriteSearchInput$.next(searchElement);
    }
  }

  validateInput(searchString:string) {

    const minWidth: number = 3;
    const inputLength: number = searchString.replaceAll("@", "").trim().length;
    const atLength: number = this.moreThanOneAt(searchString);
    this.minimumCharMessage = "";

    if (searchString.replaceAll("@", "").trim().length < minWidth) {
      const remainingChar = minWidth - inputLength;
      this.minimumCharMessage = this.translate.instant("HOME.youNeedToType") + remainingChar + this.translate.instant("HOME.moreChar") + (remainingChar == 1 ? '' : 's')
      if (atLength > 1) {
        this.minimumCharMessage += this.translate.instant('HOME.doesNotCount')
      }
      this.searchResults = [];
      return
    }
  }

  startGeneralSearch(searchString:string) {
    if(!searchString.includes('@')) {
      searchString = searchString.toUpperCase();  
    }
    this.searchService.generalSearch(searchString).subscribe({
      next: (response: any) => {
        this.searchResults = response;
        if(this.searchResults?.length == 1){
          if((searchString.trim() == this.searchResults[0]?.nick) || (searchString.trim() == this.searchResults[0]?.shortId)){
            this.searchResults[0].exactlyMatch = true;
          } else {
            this.searchResults = [];
          }
        } else {
          this.searchResults = []
        }
      },
      error: (e: any) => console.log(e),
    })
  }

  startFavoriteSearch(searchString:string) {
    this.searchService.favoritesSearch(this.JWTToken, searchString).subscribe({
      next: (response:any) => {
        this.searchResults = response;
        this.favoriteSearchList.emit( this.searchResults );
      },
      error: (e:any) => {
        console.log(e)
      }
    })
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.searchResults = undefined;
    }
  }
  

  openPayment(target:any){
    const openPayment = {
      domains: target.domains,
      userId: target.userId,
      nick: target.nick,
      publicName: target.publicName,
      websiteUrl: target.websiteUrl,
      target: target
    }
    
    this.openNewPayment.emit(openPayment)
  }

  
  public async addFavorite(nick: string) {
    let alreadyFavorite:any = await firstValueFrom(await this.favoritesService.favoriteByNick(this.JWTToken, nick));
    if (alreadyFavorite?.length > 0) {
      this.toastrService.success(this.translate.instant('userIsAlreadyInYourFavorites'), '', {
        timeOut: 3000
      });
    } else {
      this.favoritesService.addFavorite(this.JWTToken, nick).subscribe({
        next: (response: any) => {
          this.toastrService.success(this.translate.instant('userAddedToYourFavoritesSuccessfully'), '', {
            timeOut: 3000
          });
          this.searchResults = response;
        },
        error: (e: any) => console.log(e),
      });
    }
  }


  handleImageError(event:any, userName?:string){
    event.target.onerror = null; 
    event.target.src = this.getInitialAvatar(userName?.trim()?.charAt(0) || 'X');
  }

  getInitialAvatar(userName: string): string {
    const initial = userName?.trim()?.charAt(0) || 'X';
    const canvas = document.createElement('canvas');
    canvas.width = 100;
    canvas.height = 100;
    const context = canvas.getContext('2d');
    if(context) {
      context.fillStyle = 'rgba(222, 255, 150, 1)'; 
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.font = '50px Inter'; 
      context.fillStyle = 'rgba(60, 87, 0, 1)'; 
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(initial.charAt(0).toUpperCase(), canvas.width / 2, canvas.height / 2);
    }
    return canvas.toDataURL(); 
  }
  
  public showEmailModal(fullMail: string) {
    this.showModal = true;
    this.modalMessage = fullMail;
    this.fullEmailModal = true;
  }

  moreThanOneAt(input: string) {
    const regex = /@/g;
    const matches = input.match(regex);
    return matches ? matches.length : 0;
  }


  setSearchBehavior() {
    this.search.nativeElement.addEventListener('input', this.updateSearchResults.bind(this));
    this.searchDesktop.nativeElement.addEventListener('input', this.updateSearchResults.bind(this));
  }

  updateSearchResults() {
    this.searchResults = "";
  }
}
