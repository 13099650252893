import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nicky-footer',
  templateUrl: './nicky-footer.component.html',
  styleUrls: ['./nicky-footer.component.scss']
})
export class NickyFooterComponent {

}
