<div class="user-guide" *ngIf="finishedLoading() && (this.auth.isAuthenticated$ | async) && !finishedConfigs() ; track finishedLoading()" cdkDrag @fadeInOut>
    <div class="content-wrapper">
        <div class="steps-wrapper" *ngIf="isOpen">
            <h1>{{ 'getStarted' | translate }}</h1>
            <div class="progress-bar" [ngStyle]="{'background': getGradientBackground()}">
    
            </div>
            @for (step of steps; track steps) {
                <div class="step clickable" (click)="redirectTo(step.url)">
                    <div class="step-content">
                        <div *ngIf="!step.blocked" class="checkmark" [ngClass]="{'checked': step.finished}">
                            <i class="ph-bold ph-check"></i>
                        </div>
                        <i *ngIf="step.blocked" class="ph-fill ph-lock"></i>
                        <label>
                            {{ step.stepInfo | translate }}
                        </label>
                    </div>
                    <a>
                        <i class="ph-bold ph-caret-right"></i>
                    </a>
                </div>
            }
        </div>
        <i (click)="isOpen = !isOpen" [ngClass]="{'ph-x': isOpen}" class="ph ph-info clickable">
        </i>
    </div>
</div>