import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private requestCompleteSource = new Subject<void>();

  requestComplete$ = this.requestCompleteSource.asObservable();

  notifyRequestComplete() {
    this.requestCompleteSource.next();
  }
}