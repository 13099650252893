import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { ShepherdService } from 'angular-shepherd';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import StepOptionsButton from 'shepherd.js/src/types/step';
import { FavoritesService } from 'src/app/services/favorites.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SearchService } from 'src/app/services/search.service';
import { UserService } from 'src/app/services/user.service';
import { NICKY_URLS } from 'src/app/utils/urls.constants';
import { devEnvironment, environment } from 'src/environments/environment';
import { Asset } from '../models/asset.model';

@Component({
  selector: 'app-bubbles',
  templateUrl: './bubbles.component.html',
  styleUrls: ['./bubbles.component.scss']
})
export class BubblesComponent {
  
  @Input('profileInfo') profileInfo!:any;
  @Input('JWTToken') JWTToken!:any;
  @Input('profileNotFound') profileNotFound!:boolean;
  @Input('isAuthenticated') isAuthenticated!:any;
  @Input('publicProfile') publicProfile!:boolean;
  @Input('alreadyFavorite') alreadyFavorite!:boolean;
  @Input('paymentProfile')paymentProfile!:boolean;
  @Output() onCloseModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openPayment: EventEmitter<any> = new EventEmitter();
  @Output() addFavorite: EventEmitter<any> = new EventEmitter();
  @Output() removeFavorite: EventEmitter<any> = new EventEmitter();
  @Output() closePaymentProfile: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public translate:TranslateService,
    private userService:UserService,
    private cdr: ChangeDetectorRef,
    private toastrService:ToastrService,
    private favoritesService:FavoritesService,
    public auth: AuthService,
    public router:Router,
    private shepherdService: ShepherdService,
    private paymentService:PaymentService,
    private searchService:SearchService
    ){}
    
  bookIconOpen: any;

  public environment = environment;
  public url = environment.environmentUrl;
  public noProfilePic!:boolean;
  public loading:boolean[] = [];
  private imageUrl!:string;
  public selectedDomain:any;
  public closeTooltip:boolean = false;
  public toggleBook:boolean = false;
  public isMobile:boolean = false;
  private devEnvironment = devEnvironment;
  public activeAssets:string[] = [];
  public showMore:boolean = false;
  public finishAssetsLoad!:boolean;
  public shareModal:boolean = false;
  private currentUser!:any;
  public shortIdInfoToggle!:boolean;

  private tourStepLegend:any = undefined; 
  private nextButton:any = undefined;
  private backButton:any = undefined;
  private exitButton:any = undefined;
  private finishButton:any = undefined;
  private tourDefaults:any = undefined;
  
  private defaultTourButtons: {
    haveBackButton:any[],
    haveFinishButton:any[],
    onlyNextButton:any[]
  } = {
    haveBackButton : [],
    haveFinishButton:[],
    onlyNextButton : []
  }

  private currentStepId!:string |undefined;

  private tourMobilePositionCheck:any;
  private tourPositionDefault:any;

  public privacyPolicyUrl = NICKY_URLS.PRIVACY_POLICY;
  public tearmsOfUseUrl = NICKY_URLS.TERMS_OF_USE;

  async ngOnInit(): Promise<void> {
    try {
      if(this.paymentProfile) {
        this.profileInfo = await firstValueFrom(this.searchService.publicProfile(this.profileInfo.shortId));
      }
      if( this.profileNotFound ) {
        const isAuthenticated = await firstValueFrom(this.auth.isAuthenticated$);
        setTimeout(() => {
          if (isAuthenticated) {
           this.router.navigateByUrl('/overview');
           return;
          }
          window.location.href = environment.websiteUrl;
        }, 5000);
        return;
      }
    } catch (e) {
      console.log(e);
    }
    
    this.getUserAssets();
    this.populateTourLegendAsAButton();
    this.populateTourButton();
    this.populateTourDefaults();
  }

  ngAfterViewInit(): void {
    this.checkWindowSize();
    if( this.profileNotFound ) {
      return;
    }
    this.cdr.detectChanges();
    this.hasImage();
  }

  getInnerWidth() {
    return window.innerWidth;
  }

  overHeightProfile(element:HTMLElement) {
    if (element) {
      const viewportHeight = window.innerHeight;
      const elementHeight = element.offsetHeight;
  
      if (elementHeight / viewportHeight > 0.7 && !this.isAuthenticated) {
       return true;
      }
    }

    if(this.isMobile && !this.isAuthenticated) {
      return true;
    }

    return false
  }

  setShepherdDefaults(){

    this.shepherdService.defaultStepOptions = {
      scrollTo: false,
      cancelIcon: {
        enabled: true
      },
      canClickTarget: true,
      arrow: true,
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4
    }

  }
  
  toggleBookIcon(open: boolean) {
    this.bookIconOpen = open;
  }

  populateTourButton() {
    this.nextButton = {
      //classes: 'shepherd-button-primary',
      text: () => {
        return this.translate.instant("TOUR.next")
      },
      action() {
        return this.next();
      }
    };
    this.exitButton = {
      classes: 'exit-button',
      text:() => {
        return this.translate.instant("TOUR.exit")
      }, 
      action: () => {
        this.shepherdService.complete();
        this.shareModal = false;
        setTimeout(() => {
          this.toggleBookIcon(false);
        }, 100); 
      }
    };
    this.backButton = {
      classes: 'back-button secondary-button',
      text: () => {
        return this.translate.instant("TOUR.back")
      },
      action: () => {
        this.toggleBookIcon(true);
        return this.shepherdService.back();
      }
    } 
    this.finishButton = {
      classes: 'secondary-button',
      text: () => {
        return this.translate.instant("TOUR.finish")
      },
      action: () => {
        this.toggleBookIcon(false);
        return this.shepherdService.complete();
      }
    }

    this.defaultTourButtons.haveBackButton = [
        this.tourStepLegend,
        this.exitButton,
        this.backButton,
        this.nextButton
    ]

    this.defaultTourButtons.onlyNextButton = [
        this.tourStepLegend,
        this.exitButton,
        this.nextButton
    ]

    
    this.defaultTourButtons.haveFinishButton = [
      this.tourStepLegend,
      this.exitButton,
      this.backButton,
      this.finishButton
  ]

  }

  populateTourLegendAsAButton() {
    this.tourStepLegend = {
      text: () => {
        const tourObject = this.shepherdService.tourObject;
        const stepsLength = tourObject.steps.length;
        const currentStep = this.shepherdService.tourObject.getCurrentStep();
        let stepIndex = -1; 
      
        if (currentStep) {
          stepIndex = tourObject.steps.findIndex(step => step.id === currentStep.id); 
        }
      
        return `${stepIndex + 1} ${this.translate.instant('of').toLowerCase()} ${stepsLength}`; 
      },
      classes: "sheperd-steps-legend"
    }
  }

  populateTourDefaults() {
    this.defaultTourButtons.haveBackButton = [
        this.tourStepLegend,
        this.exitButton,
        this.backButton,
        this.nextButton
    ]

     this.defaultTourButtons.haveFinishButton = [
        this.tourStepLegend,
        this.exitButton,
        this.backButton,
        this.finishButton
    ]

    this.defaultTourButtons.onlyNextButton = [
        this.tourStepLegend,
        this.exitButton,
        this.nextButton
    ]

    this.tourMobilePositionCheck = this.isMobile ? 'bottom' : 'right';

    this.tourPositionDefault = 'bottom';

    this.tourDefaults = {
      scrollTo: { behavior: 'smooth', block: 'center', inline: 'center' }
    }

    this.shepherdService.defaultStepOptions = {
      scrollTo: this.tourDefaults,
      cancelIcon: {
        enabled: false
      },
      canClickTarget: this.isMobile ? false : true,
      arrow: true,
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4
    }  
  }
  
  private setFocusOnInput() {
    
    const element = this.shepherdService.tourObject.getCurrentStep()?.getTarget();

    if(this.isMobile) {
      element?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      });
    }
 

    if( element?.id === this.currentStepId ) {
      return;
    }

    this.currentStepId = element?.id;
  
    if (!element) {
      return;
    }

    
    if (element.tagName.toLowerCase() === 'input' || element.tagName.toLowerCase() === 'textarea') {
      element.focus({
        preventScroll: this.isMobile ? false : true
      });
      return;
    }

    var inputElement = element.querySelector('input') || element.querySelector('textarea');
    
    if (inputElement) {
      inputElement.focus({
        preventScroll: this.isMobile ? false : true
      });
      return;
    }

  }

  startTour() {
    if (this.isAuthenticated && this.isMyProfile()) {
      this.startProfileTour();
    } else {
      this.startVisitorTour();
    }
  }
  

  //PROFILE TOUR

  startProfileTour() {
    this.populateTourDefaults();
    const steps: StepOptionsButton.StepOptions[] = [
      {
        id: "welcome-profile",
        attachTo: {
          element: '#welcome-profile',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.onlyNextButton,

        beforeShowPromise: () => {
          this.toggleBookIcon(true);
          return Promise.resolve();
        },

        title: this.translate.instant("TOUR.welcomeToProfileSection"),
        text: this.translate.instant("TOUR.hereYouCanSeeEverythingAboutYourNickyProfile")
      },      
      {
        id: "short-id",
        attachTo: {
          element: '#short-id',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,
        
        text: this.translate.instant("TOUR.thatsYourShortIDThatsYourNickyID"),
        ...this.tourDefaults
      },
      {
        id: "share-profile",
        attachTo: {
          element: '#share-profile',
          on: this.tourMobilePositionCheck
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          this.backButton,
          {
            text: this.translate.instant('TOUR.next'),
            action: () => {
              this.shareModal = true;
              setTimeout(() => {
                this.shepherdService.next();
              }, 100)
            }
          }
        ],

        text: this.translate.instant("TOUR.hereYouAccessYourProfileLinkOrQRCodeToShare")
      },
      {
        id: "qr-code",
        attachTo: {
          element: '#qr-code',
          on: this.tourMobilePositionCheck
        },

        
        buttons: [
          this.tourStepLegend,
          this.exitButton,
          {
            text: this.translate.instant('TOUR.back'),
            action: () => {
              setTimeout(() => {
                this.shareModal = false;
                this.shepherdService.back();
              }, 100)
            }
          },
          this.nextButton
          
        ],

        text: this.translate.instant("TOUR.hereYouCanSeeTheQRCodeOfYourProfileToShareIt")
      },
      
      {
        id: "profile-link",
        attachTo: {
          element: '#profile-link',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.hereYouCanCopyYourProfileLink")
      },
      {
        id: "copy-link",
        attachTo: {
          element: '#copy-link',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.toCopyYourProfileLinkJustClickHere")
      },
      {
        id: "close-button",
        attachTo: {
          element: '#close-button',
          on: this.tourMobilePositionCheck,
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          this.backButton,
          {
            text: this.translate.instant('TOUR.next'),
            action: () => {
              setTimeout(() => {
                this.shareModal = false;
                this.shepherdService.next();
              }, 100)
            }
          }
        ],

        text: this.translate.instant("TOUR.toCloseClickHere")
      },
      {
        id: "edit-general-section",
        attachTo: {
          element: '#edit-general-section',
          on: this.tourMobilePositionCheck
        },

        beforeShowPromise: () => {
          return new Promise((resolve) => {
            const element = document.querySelector('#edit-general-section');
            if (element && element.getBoundingClientRect().width && element.getBoundingClientRect().height) {
              resolve(true);
            } else {
                this.shepherdService.next();  
            }
          });
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          {
            classes: "back-button",
            text: () => { return this.translate.instant("TOUR.back") },
            action: () => {
              this.shareModal = true;
              setTimeout(() => {
                this.shepherdService.back();
              }, 10)
            }
          },
          this.nextButton
          
        ],

        text: this.translate.instant("TOUR.hereYouAreRedirectedToGeneralSectionToEditPersonalData")
      },
      {
        id: "register-payment",
        attachTo: {
          element: '#register-payment',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,

        text: this.translate.instant("TOUR.clickHereToRegisterAPaymentIfYourWalletsAreConfigured"),
        ...this.tourDefaults
      },
      {
        id: "check-assets",
        attachTo: {
          element: '#check-assets',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,

        text: this.translate.instant("TOUR.hereYouCheckActiveAssetsForPayment"),
        ...this.tourDefaults
      },
      {
        id: "end-profile-tour",
        attachTo: {
          element: '#end-profile-tour',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveFinishButton,

        highlightClass: this.tourDefaults.highlightClass,
        
        text: this.translate.instant("TOUR.thisIsTheEndOfProfileTour"),
        ...this.tourDefaults
      }
    ];


    this.startStepTour(steps);
  }

  //PROFILE TOUR - non authenticated user

  startVisitorTour() {
    this.populateTourDefaults();
    const steps: StepOptionsButton.StepOptions[] = [
      {
        id: "welcome-profile",
        attachTo: {
          element: '#welcome-profile',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.onlyNextButton,

        beforeShowPromise: () => {
          this.toggleBookIcon(true);
          return Promise.resolve();
        },

        title: this.translate.instant("TOUR.welcomeToProfileSection"),
        text: this.translate.instant("TOUR.hereYouCanSeeEverythingAboutthisProfile")
      },      
      {
        id: "short-id",
        attachTo: {
          element: '#short-id',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,
        
        text: this.translate.instant("TOUR.thatIsTheShortID"),
        ...this.tourDefaults
      },
      {
        id: "share-profile",
        attachTo: {
          element: '#share-profile',
          on: this.tourMobilePositionCheck
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          this.backButton,
          {
            text: this.translate.instant('TOUR.next'),
            action: () => {
              this.shareModal = true;
              setTimeout(() => {
                this.shepherdService.next();
              }, 100)
            }
          }
        ],

        text: this.translate.instant("TOUR.hereYouAccessTheProfileLinkOrQRCodeToShare")
      },
      {
        id: "qr-code",
        attachTo: {
          element: '#qr-code',
          on: this.tourMobilePositionCheck
        },

        
        buttons: [
          this.tourStepLegend,
          this.exitButton,
          {
            text: this.translate.instant('TOUR.back'),
            action: () => {
              setTimeout(() => {
                this.shareModal = false;
                this.shepherdService.back();
              }, 100)
            }
          },
          this.nextButton
          
        ],

        text: this.translate.instant("TOUR.hereYouCanSeeTheQRCodeOfTheProfileToShareIt")
      },
      
      {
        id: "profile-link",
        attachTo: {
          element: '#profile-link',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.hereYouCanCopyTheProfileLink")
      },
      {
        id: "copy-link",
        attachTo: {
          element: '#copy-link',
          on: this.tourMobilePositionCheck
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          this.backButton,
          {
            text: this.translate.instant('TOUR.next'),
            action: () => {
              setTimeout(() => {
                this.shareModal = false;
                this.shepherdService.next();
              }, 100)
            }
          }
        ],

        text: this.translate.instant("TOUR.toCopyTheProfileLinkJustClickHere")
      },
      {
        id: "register-payment",
        attachTo: {
          element: '#register-payment',
          on: this.tourMobilePositionCheck
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          
          {
            classes: "back-button",
            text: () => { return this.translate.instant("TOUR.back") },
            action: () => {
              this.shareModal = true;
              setTimeout(() => {
                this.shepherdService.back();
              }, 10)
            }
          },

          this.nextButton
        ],
        highlightClass: this.tourDefaults.highlightClass,

        text: this.translate.instant("TOUR.byClickingHereYouCanRegisterAPayment"),
        ...this.tourDefaults
      },
      {
        id: "check-assets",
        attachTo: {
          element: '#check-assets',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,

        text: this.translate.instant("TOUR.andHereYouCheckAcceptedAssetsForPayment"),
        ...this.tourDefaults
      },
      {
        id: "end-profile-tour",
        attachTo: {
          element: '#end-profile-tour',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveFinishButton,

        highlightClass: this.tourDefaults.highlightClass,
        
        text: this.translate.instant("TOUR.weHaveReachedTheEndOfTheTour"),
        ...this.tourDefaults
      }
    ];


    this.startStepTour(steps);
  }

  private startStepTour(steps: StepOptionsButton.StepOptions[]) {
    steps.forEach(step => {
      if (!step.when) {
       step.when = {
        show: () => {
          const currentStepText = step.text;
          const sheperdObject = this.shepherdService.tourObject; 
          const stepIndex = this.shepherdService.tourObject.steps.findIndex(currentStep => currentStep.id === step.id);
          
          if(this.isMobile) {
            const element = this.shepherdService.tourObject.steps[stepIndex].getElement();
            if (element) element.focus = () => { };
            element?.scrollTo();
          }
          
          if (stepIndex === steps.length - 1) {
            this.shepherdService.tourObject.steps[stepIndex].updateStepOptions({
              text: currentStepText + "<img src='/assets/white-nicky-logo.png' class='tour-logo'>"
            });
          }

          setTimeout(() => {
            this.setFocusOnInput();
          }, 500)
        }
       }
      } 
    });
    this.shepherdService.addSteps(steps)

    const element = document.querySelector('#edit-general-section');
   
    if (!element) {
      this.shepherdService.tourObject.removeStep('edit-general-section');
    }
    
    this.shepherdService.start();
  }


  itsDevEnvironment(){
    return this.devEnvironment;
  }

  openSettings(){
    this.closeModal();
    this.router.navigateByUrl('/settings?edit=true');
  }

  checkWindowSize() {
    if (window.innerWidth <= 700) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

  }

  openPaymentFlow(){
    this.openPayment.emit()
  }

  getProfileUrl() {
    return `${window.location.origin}/s/${this.profileInfo.shortId}`;
  }

  copyProfileUrl() {
    const profileUrl = `${window.location.origin}/s/${this.profileInfo.shortId}`;

    navigator.clipboard.writeText( profileUrl );
      this.toastrService.success(this.translate.instant('public-profile.theLinkToYourNickyPublicProfileHasBeenCopied'),'',{
        timeOut:3000
    })
  }

  isMyProfile() {
    if(this.isAuthenticated) {
      return this.profileInfo.email === this.currentUser?.email;
    }
    return false;
  }

  shareProfile(){
    const profileUrl = `${window.location.origin}/s/${this.profileInfo.shortId}`;
    if(this.isMobile){
      try{
        navigator.share({
          title: `${this.profileInfo.publicName}`,
          text: `${this.translate.instant('public-profile.clickOnThisLinkToBeRedirectToMyNickyPublicProfile')} ${this.profileInfo.publicName}`,
          url: profileUrl
        })
      } catch (e) {
        this.toastrService.error(this.translate.instant('public-profile.thisBrowserDoesntSupportTheShareFeature'),'',{
          timeOut:3000
        })
      }
  
    } else {
      navigator.clipboard.writeText( profileUrl );
      this.toastrService.success(this.translate.instant('public-profile.theLinkToYourNickyPublicProfileHasBeenCopied'),'',{
        timeOut:3000
      })
    }
  }

  userIsAlreadyFavorite(){
    this.toastrService.success('This user is already in your favorites')
  }

  addAsFavorite(){
    this.addFavorite.emit()
  }


  public deleteNick(favorite: any) {
    this.favoritesService.deleteFavorite(this.JWTToken, favorite).subscribe({
      next: (response: any) => {
      this.toastrService.success(this.translate.instant('theNickHasBeenSuccessfullyRemoved'), '', {
        timeOut: 3000
      }),
      this.alreadyFavorite = false;
    },
      error: (e: any) => console.log(e)
    })
  }


  deleteFavorite(){
    this.removeFavorite.emit()
  }

  async hasImage(){
    this.imageUrl =  `${environment.api}User/get-public-profile-picture?userId=${this.profileInfo.id}`
    this.userService.getUserImageById(this.profileInfo.id).subscribe({
        next: (response:any) => {
          this.noProfilePic = false;
        },
        error: (e:any) => {
          if(e?.status == 200){
            this.noProfilePic = false;
          } else {
            this.noProfilePic = true;
          }
        }
      })
  }

  noWallets(){
    return this.activeAssets.length === 0;
  }

  
  handleImageError(event:any, userName?:string){
    event.target.onerror = null; 
    event.target.src = this.getInitialAvatar(userName);
  }

  getInitialAvatar(userName: string | undefined): string {
    const initial = userName?.charAt(0)?.trim() || 'X'
    const canvas = document.createElement('canvas');
    canvas.width = 100;
    canvas.height = 100;
    const context = canvas.getContext('2d');
    if(context) {
      context.fillStyle = 'rgba(222, 255, 150, 1)'; 
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.font = '50px Inter'; 
      context.fillStyle = 'rgba(60, 87, 0, 1)'; 
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(initial.charAt(0).toUpperCase(), canvas.width / 2, canvas.height / 2);
    }
    return canvas.toDataURL(); 
  }

  
  getPictureUrl(){
  //  if(this.noProfilePic == undefined){
    return;
  //  }
  //  this.loading.pop();
  //  if(this.noProfilePic){
  //   return 'url(/assets/icons/default-avatar.png)'
  //  }
  //  return  'url(' + this.imageUrl + ')'
  }

  showDomainAndSocial(selectedDomain:any){
    this.selectedDomain = selectedDomain;
  }

  getUserAssets(){
    if(this.isAuthenticated){
     this.auth.user$.subscribe({
      next: (response:any) => {
        this.currentUser = response;
      }
     })
    }

    this.userService.getReceiverConnections(this.profileInfo.id).subscribe({
      next:(response:any) => {
      response.forEach((asset: Asset) => {
          if (!this.activeAssets.includes(asset.assetTicker)) {
            this.activeAssets.push(asset.assetTicker);
          }
      });
      this.finishAssetsLoad = true;
      this.loading.pop();
      },
      error: (e:any) => {
        console.log(e);
        this.finishAssetsLoad = true;
      }
    })
  }

  closeModal() {
    if(this.isMobile) {
      document.body.style.overflow = 'auto';
    }
    this.onCloseModal.emit(false);
  }

  backToPayment() {
    this.closePaymentProfile.emit(true);
  }
}
