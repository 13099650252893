<div class="nicky-footer">
    <div class="opacity"></div>
    <div class="contents">
        <div class="copyright">© 2024 Nicky L.L.C.</div>
        <div class="links">
            <label> {{ 'nickyDoestNotHoldFunds' | translate }} </label>
            <a class="clickable" href="https://nicky.me/policies/terms-of-use/" target="_blank">{{ 'termsOfUse' | translate }} </a> 
            <a class="clickable" href="https://nicky.me/policies/privacy-policy/" target="_blank">{{ 'privacyPolicy' | translate }}</a>
            <a class="clickable" href="https://nicky.me/policies/legal-disclosure" target="_blank"> {{ 'disclousures' | translate }} </a> 
            <a class="clickable" href=" https://nicky.me/policies/cookie-policy/" target="_blank">{{ 'cookiePolicy' | translate }}</a>
        </div>
    </div>
</div>
