<app-modal [modalTittle]="modalTitle" [modalMessage]="modalMessage" *ngIf="!form.valid && showModal"
    (click)="hideModal()"></app-modal>
<app-modal [modalTittle]="modalTitle" [modalMessage]="modalMessage" *ngIf="showModal"
    (click)="hideModal()"></app-modal>

<app-header></app-header>


<div class="register" [ngStyle]="{'background': finishRegister ? 'url(/assets/success-register-bg.png)' : ''}">

    <!-- <div class="header-content">
        <img class="clickable" src="/assets/nicky-logo.png" (click)="goHome()" class="logo clickable">
        <img [src]="toggleBook ? '/assets/icons/open-book.png' : '/assets/icons/book.png'" [ngClass]="{'opened-book': toggleBook}"   (click)="toggleBook = !toggleBook" class="clickable help" tittle="help-book">  
    </div> -->

    <form [formGroup]="form" >
        
        <!-- <div class="logo-container" *ngIf="currentStep === 'password'">
            <img id="logo" src="https://nicky-dev-web.azurewebsites.net/assets/nickylogo.png" (click)="goHome()" class="clickable logo">
        </div> -->
        
        <button *ngIf="currentStep === 'personal-info' && !finishRegister" (click)="currentStep = 'password'" class="secondary-button back-button">
            <i class="ph ph-arrow-left"></i>
            <!-- {{ 'back' | translate }}  -->
        </button>

        <div class="form-wrapper " *ngIf="!finishRegister">
            <div class="step-wrapper" *ngIf="currentStep === 'password'">
                <h1>
                    {{ 'REGISTER.createYourAccount' | translate }}
                </h1>
                <h3>
                    {{ 'signingUpForNickyTypicallyTakes' | translate }}
                </h3>
                <!-- first-step -->
                <div class="input-container" [ngClass]="{'not-valid': !this.isValidEmail() && isControllerTouched('nick') , 'valid': this.isValidEmail()}">
                    <input formControlName="nick" type="text" name="user" placeholder=" {{ 'email' | translate }}">
                </div>

                <label *ngIf="!itsAPublicDomain()" class="domain-label fade-in" > 
                    <input type="checkbox" formControlName="isDomainOwner">
                    <label>
                        {{ 'iOwnThisDomain' | translate }}
                        {{ this.form.controls['domain'].value }}
                        <i class="ph-fill ph-info clickable" #tooltip="matTooltip" (click)="!isMobile || tooltip.toggle()"  matTooltip="{{ 'ifYouOwnOrControlTheDomainName' | translate }}" [matTooltipPosition]="'above'"></i>   
                    </label> 
                </label>
    
                <div class="input-container input-wrapper"
                [ngClass]="{'not-valid': this.getFormController('password').invalid && isControllerTouched('password') , 'valid': isValidController('password')}">
                    <input formControlName="password" class="half-input" [type]="seeingPassword ? 'text' : 'password'"
                    (onchange)="testPassword()" name="password" placeholder=" {{ 'REGISTER.password' | translate }}">
                    <i class="ph ph-eye" id="checkbox-toggle" (click)="seeingPassword = !seeingPassword" *ngIf="!seeingPassword"></i>
                    <i id="checkbox-toggle" class="ph ph-eye-slash" (click)="seeingPassword = !seeingPassword" *ngIf="seeingPassword"></i>
                </div>
    
                <div *ngIf="getFormController('password').value || isControllerTouched('password')" class="password-strenght fade-in" [ngClass]="{'weak': testPassword() <= 2, 'strong' : testPassword() > 2}">
                    <div class="strenght-bar" *ngFor="let build of [].constructor(5); let i = index" [ngClass]="{'fill': testPassword() > i }"></div>
                </div>

                <div *ngIf="getFormController('password').value || isControllerTouched('password')" class="password-requirements fade-in">
                    <h3>
                        {{ 'REGISTER.yourPasswordMustInclude' | translate }}
                    </h3>

                    <div [ngClass]="{'password-fits': this.matchAtLeast8Digits()}">
                        <i class="ph ph-check"></i>
                        <label>
                           {{'REGISTER.atLeast8Digits' | translate}}
                        </label>
                    </div>
                    
                    <div [ngClass]="{'password-fits': this.matchSpecialCharacter()}">
                        <i class="ph ph-check"></i>
                        <label>
                            {{ 'REGISTER.atLeast1SpecialCharacter' | translate }} 
                        </label>
                    </div>
                    
                    <div [ngClass]="{'password-fits': this.match1LowerCase()}">
                        <i class="ph ph-check"></i>
                        <label>
                            {{ 'REGISTER.atLeast1LowerCase' | translate }} 
                        </label>
                    </div>
                    
                    <div [ngClass]="{'password-fits': this.match1UpperCase()}">
                        <i class="ph ph-check"></i>
                        <label>
                            {{ 'REGISTER.atLeast1UpperCase' | translate }} 
                        </label>
                    </div>
                    
                    <div [ngClass]="{'password-fits': this.match1Number()}">
                        <i class="ph ph-check"></i>
                        <label>
                           {{ 'REGISTER.atLeast1Number' | translate }}
                        </label>
                    </div>
                </div>
    
                <div class="terms">
                    <label class="terms-container">
                        <input formControlName="terms" type="checkbox">
                        <span class="terms-checkmark"></span>
                    </label>
                    <label class="text-label"> {{ 'REGISTER.iAgreeToNicky' | translate }} <a target="_blank"
                            href="https://nicky.me/policies/terms-of-use/"> {{ 'REGISTER.termsOfUse' | translate }}</a>, <a
                            target="_blank" href=" https://nicky.me/policies/cookie-policy/">{{ 'cookiePolicy' | translate
                            }}</a> {{ 'REGISTER.and' | translate }} <a target="_blank"
                            href=" https://nicky.me/policies/privacy-policy/"> {{ 'REGISTER.privacyPolicy' |
                            translate}}</a></label>
                </div>
            </div>


            <!-- second-step -->
            <div class="step-wrapper" *ngIf="currentStep === 'personal-info'">
                <h1>
                    {{ 'REGISTER.tellUsAboutYourself' | translate }}
                </h1>

                <div class="general-section">
                    <!-- <div class="section-info">
                        <h1>{{'profilePhoto' | translate}}</h1>
                        <h3>{{'profilePicture' | translate}}</h3>
                    </div> -->

                    <input #_fileInput type="file" (change)="openCropperDialog($event)" accept="image/*" hidden>
                    <div *ngIf="cropped"><img [src]="cropped"></div>
                                
                    <!-- Cropper -->
                    <div class="user-picture-wrapper clickable" (click)="_fileInput.click()">
                        <div class="user-info">
                            <div click>
                                <img id="user-logo" class="user-logo clickable profile-pic" [src]="base64image ? base64image : '/assets/icons/blank-user.png'">
                                <!-- <img class="take-picture-icon" id="photoTour" src="/assets/icons/take-picture.png" alt="{{ 'PROFILE.uploadYourLogo' | translate }}"> -->
                            </div>
                            <!-- <div>
                                 <h2> Click here to upload a profile picture </h2>
                                <h3>{{ user?.publicName }} </h3>
                            </div> -->
                            <i class="ph-bold ph-upload-simple" id="user-photo"></i>
                        </div>
                        
                    </div>
                </div>

                <div class="half-inputs">
                    <div class="input-container" [ngClass]="{'valid': form.controls['firstName'].touched && form.controls['firstName'].valid,
                    'not-valid': form.controls['firstName'].touched && !form.controls['firstName'].valid }">
                    <input formControlName="firstName" type="text" name="your-name"
                        placeholder="{{ 'REGISTER.firstName' | translate }}">
                    </div>
                    
                    <div class="input-container" [ngClass]="{'valid': form.controls['lastName'].touched && form.controls['lastName'].valid,
                    'not-valid': form.controls['lastName'].touched && !form.controls['lastName'].valid }">
                    <input formControlName="lastName" type="text" name="your-name"
                        placeholder="{{ 'REGISTER.lastName' | translate }}">
                    </div>
                </div>

                <div class="input-container" [ngClass]="{'valid': form.controls['publicName'].touched && form.controls['publicName'].valid,
                'not-valid': form.controls['publicName'].touched && !form.controls['publicName'].valid }">
                    <input formControlName="publicName" type="text" name="public-name"
                        placeholder="{{ 'REGISTER.publicName' | translate }}">
                </div>

                <mat-select-country [value]="country" (onCountrySelected)="onCountrySelected($event)"
                    placeHolder="{{ ('selectYourCountry' | translate) }}">
                </mat-select-country>
            
                <div class="input-container website-input-container">
                    <span *ngIf="getFormController('website').touched && getFormController('website').value">https://</span>
                    <input formControlName="website" type="url" name="user" (focus)="getFormController('website').markAsTouched()" #website
                        placeholder="{{ 'REGISTER.website' | translate }}">
                </div>

            </div>
            <!-- [ngClass]="{disabled: this.form.controls['terms'].value == false || !form.valid || nickAlreadyExist()}" -->
            <button *ngIf=" currentStep === 'password' " 
            (click)="nextStep()" 
            [ngClass]="{'disabled': !(this.getFormController('terms').value) || testPassword() < 5 || !isValidController('nick')}">
                {{ 'continue' | translate }}
            </button>

            <button *ngIf=" currentStep === 'personal-info' " 
            (click)="nextStep()" 
            [ngClass]="{'disabled': !isValidController('firstName') || !isValidController('lastName') || !isValidController('publicName')}">
                {{ 'continue' | translate }}
            </button>

            <label>
                {{ 'REGISTER.alreadyHaveAnAccount' | translate }} <a routerLink="/login">Login</a>
            </label>


            @if (currentStep === 'password') {
                <label class="or-label"> {{ 'or' | translate }}  </label>


                <button  (click)="SSOAuth('google-oauth2')" class="clickable google-button social-button">
                     <img class="clickable" src="/assets/icons/google-icon.png"> 
                     <label class="clickable"> {{ 'LOGIN.loginWithGoogle' | translate }} </label>
                </button>
    
                <button (click)="SSOAuth('windowslive')" class="clickable google-button social-button">
                     <img class="clickable" src="/assets/icons/microsoft.png"> 
                     <label class="clickable"> {{ 'LOGIN.loginWith' | translate }} Microsoft </label>
                </button> 
    
                <button (click)="SSOAuth('github')" class="clickable google-button social-button">
                     <img class="clickable" src="/assets/icons/github.png">
                      <label class="clickable"> {{ 'LOGIN.loginWith' | translate }} Github </label>
                </button>
    
                <button (click)="SSOAuth('discord')" class="clickable google-button social-button">
                    <img class="clickable" src="/assets/icons/discord.png">
                     <label class="clickable"> {{ 'LOGIN.loginWith' | translate }} Discord </label>
               </button>
    
                  
                <button  (click)="SSOAuth('apple')" class="clickable google-button social-button">
                    <img class="clickable" src="/assets/icons/apple.png">
                     <label class="clickable"> {{ 'LOGIN.loginWith' | translate }} Apple </label>
                </button>
    
            }
        </div>

        <div class="success-register" *ngIf="finishRegister">
            <div class="step-container step-overview">
              <div class="step-tittle flex-centered">
                <img class="success-register-img" src="/assets/icons/SealCheck.png">
                <h1>
                  {{ 'verifyYourEmail' | translate }}
                </h1>
              </div>
              <label class="register-success-inform">
                {{ 'weVeSentAConfirmationTo' | translate }} <br> <span> {{ getFormController('nick').value || "rhuanpasti@gmail.com" }} </span><br><br>
                {{'checkYourEmailAndClickOnTheConfirmationLink' | translate}}
              </label>
            </div>
          </div>
    

    </form>

    <div class="loading-modal" *ngIf="loading">
        <div class="spinner"></div>
    </div>

    <app-nicky-footer *ngIf="!finishRegister"></app-nicky-footer>

</div>