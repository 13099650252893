import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/services/notifications.service';
import { ToastrFactoryService } from 'src/app/services/toastr-factory.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {


  constructor( private notificationService:NotificationService,
    private toastrService: ToastrService,
    private toastrFactory: ToastrFactoryService
    ){
  }
  
  @Input('JWTToken')JWTToken!: string;

  public showMessages:boolean = false;
  public notifications:any;

  ngOnInit(): void {
    this.getNotifications()
  }


  public getNotifications(){
    this.notificationService.getNotifications(this.JWTToken).subscribe({
      next: (response: any) => {
        this.notifications = response.data;
      },
      error: (e: any) => console.log(e),
    })
    }


    public markAsRead(notificationId:string){
      this.notificationService.markAsRead(this.JWTToken, notificationId).subscribe({
        next: (response: any) => {
          this.getNotifications();
        },
        error: (e: any) => {
          this.toastrFactory.unknownError();
        console.log(e)
      }
      })
      }
    
  
  }
