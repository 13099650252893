

import { Injectable } from "@angular/core";

@Injectable()
export default class animatedBackground {
    constructor() { }
    animatedBackgroundTransition(backgroundPosition: number, backStep?:boolean) {
        // setTimeout(() => {
        // let starsBg: HTMLElement | null = document.querySelector(".stars-bg");
        // if (starsBg) {
        //     starsBg.style.transition = "background-position-x 1s ease";
        //     if(backStep){
        //         starsBg.style.backgroundPositionX = (backgroundPosition + 100) + "%";
                
        //         starsBg.style.backgroundPositionX = backgroundPosition + "%";
        //     } else {
        //         starsBg.style.backgroundPositionX = backgroundPosition + "%";            
        //     }
        // }}, 50)
    }
}

