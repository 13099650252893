<app-payment [selectedLanguage]="selectedLanguage" [isAuthenticated]="isAuthenticated" [paymentModal]="paymentFlow" [assets]="assets" (onCloseModal)="closeModal()" 
[targetUser]="target" *ngIf="paymentFlow" 
[user]="user" [targetUser]="targetUser" [JWTToken]="JWTToken" [paymentRequestInfo]="paymentRequestInfo"></app-payment>

<div class="modal-backdrop stars-bg" *ngIf="!paymentFlow" >
      
      <div class="loading-modal" *ngIf="false">
        <div class="spinner"></div>
      </div>
  
      <!-- <div class="public-profile" *ngIf="!loading">
        <label class="close-button clickable" (click)="closeModal()">X</label>
            <div class="profile-body">
                <div class="logo-wrapper">
                    <img [src]="environment.api + 'User/get-public-profile-picture?userId=' + userId" (error)="handleImageError($event)" class="clickable info-button">
                </div>
                <div class="info-wrapper">
                    <label class="organization"> {{ 'PUBLIC_PROFILE.publicProfile' | translate }} </label>
                    <label class="organization-name"> {{targetCompany}} <img *ngIf="targetDomains?.length > 0"  [matTooltipPosition]="'above'" 
 matTooltip="{{'verifiedUser' | translate}}" class="checked-user" src="assets/icons/checked-user.png"></label>
                    <label class="nick">Nick: {{targetNick}}</label>
                    <label class="description">{{ getBio() }} <a href="{{companyWebsite ? companyWebsite.replace('https://', 'http://') : ''}}" target="_blank" class="website"><br *ngIf="profileInfo?.bio">{{companyWebsite ? companyWebsite.replace('https://', '') : ''}}</a></label>
                    <div class="buttons-wrapper">
                        <button class="make-payment" [ngStyle]="{'height': selectedLanguage == 'nl' || selectedLanguage == 'da' ? '74px' :  '' }" (click)="openPayment()"> {{ 'PUBLIC_PROFILE.makeAPayment' | translate }} </button>
                        <button class="request-payment" [ngClass]="{'not-authenticated': !JWTToken || alreadyFavorite }"  (click)="addFavorite()"> {{ 'PUBLIC_PROFILE.addFavorite' | translate }} </button>
                    </div>
                </div>
                <div class="profile-footer">
                
                </div>
            </div>
      </div>
   -->
    <app-bubbles (openPayment)="openPayment()" [profileNotFound]="profileNotFound" (addFavorite)="addFavorite()" (onCloseModal)="closeModal()" *ngIf="profileInfo || profileNotFound"
     [publicProfile]="true" [isAuthenticated]="isAuthenticated"
     [JWTToken]="JWTToken" [alreadyFavorite]="alreadyFavorite" [profileInfo]="profileInfo"></app-bubbles>

</div>
  
