import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
// import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import localeEN from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localePT from '@angular/common/locales/pt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@auth0/auth0-angular';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { HeaderComponent } from './components/header/header.component';
import { ModalComponent } from './components/modal/modal.component';
import { ConfirmRegistrationComponent } from './pages/confirm-registration/confirm-registration.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FavoriteNicksComponent } from './pages/favorite-nicks/favorite-nicks.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';

registerLocaleData(localePT);
registerLocaleData(localeEN);
registerLocaleData(localeEs);


import { DATE_FORMATS, environment, systemLanguages } from 'src/environments/environment';

import { LyButtonModule } from '@alyle/ui/button';
import { LyDialog, LyDialogModule } from '@alyle/ui/dialog';
import { LyIconModule } from '@alyle/ui/icon';
import { LyMenuModule } from '@alyle/ui/menu';
import { LySliderModule } from '@alyle/ui/slider';
import { LyTypographyModule } from '@alyle/ui/typography';
import { MatSelectCountryComponent, MatSelectCountryModule } from "@angular-material-extensions/select-country";
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QRCodeModule } from 'angularx-qrcode';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { BubblesComponent } from './components/bubbles/bubbles.component';
import { CustomOptionSelectComponent } from './components/custom-option-select/custom-option-select.component';
import { NickyFooterComponent } from './components/nicky-footer/nicky-footer.component';
import { NickyHeaderComponent } from './components/nicky-header/nicky-header.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PaymentRequestsComponent } from './components/payment-requests/payment-requests.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PublicProfileComponent } from './components/public-profile/public-profile.component';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { ActivateAccountComponent } from './pages/activate-account/activate-account.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { decimalPlacesService, fetchDecimalPlaces } from './services/decimal-places.service';
import { FavoritesService } from './services/favorites.service';
import { NotificationService } from './services/notifications.service';
import { OffRampService } from './services/off-ramp.service';
import { PaymentRequestService } from './services/payment.request.service';
import { PaymentService } from './services/payment.service';
import { SearchService } from './services/search.service';
import { SigninService } from './services/sign-in.service';
import { UserService } from './services/user.service';
import ConvertAsset from './utils/conversion-utils';
import FormatCurrency from './utils/format-currency-utils';
import { NumberFormatDirective } from './utils/format-input';

import {
  HAMMER_GESTURE_CONFIG,
  HammerModule
} from '@angular/platform-browser';

import {
  LY_THEME,
  LY_THEME_NAME,
  LyHammerGestureConfig,
  LyTheme2,
  StyleRenderer
} from '@alyle/ui';

import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LyToolbarModule } from '@alyle/ui/toolbar';

import { MinimaDark, MinimaLight } from '@alyle/ui/themes/minima';
import Web3 from 'web3';
import { CropperDialog } from './components/cropper-dialog/cropper-dialog';
import { ManualWithdrawComponent } from './components/manual-withdraw/manual-withdraw.component';
import { UserGuideComponent } from './components/user-guide/user-guide.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { WalletValidatorService } from './services/crypto-detect-service';
import { EventService } from './services/event.service';
import { GeolocationService } from './services/geo-location-service';
import FiatByCountry from './utils/fiat-by-country';
import RoundUpUtil from './utils/round-up-utils';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    RegisterPageComponent,
    ConfirmRegistrationComponent,
    ModalComponent,
    LoginPageComponent,
    DashboardComponent,
    FavoriteNicksComponent,
    ProfileComponent,
    NumberFormatDirective,
    PaymentComponent,
    NotificationComponent,
    PaymentRequestsComponent,
    PublicProfileComponent,
    ActivateAccountComponent,
    NickyHeaderComponent,
    NickyFooterComponent,
    BubblesComponent,
    SearchbarComponent,
    CustomOptionSelectComponent,
    CropperDialog,
    ManualWithdrawComponent,
    ErrorPageComponent,
    UserGuideComponent
  ],
  imports: [
    MatTooltipModule,
    QRCodeModule,
    LySliderModule,
    CommonModule,
    LyMenuModule,
    LyIconModule,
    LyButtonModule,
    LyTypographyModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    CurrencyMaskModule,
    BrowserModule,
    DragDropModule,
    AppRoutingModule,
    FormsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxSliderModule,
    BrowserAnimationsModule,
    LyButtonModule,
    LyToolbarModule,
    LyImageCropperModule,
    HammerModule,
    MatSelectCountryModule.forRoot(getCountryLanguage()),
    TranslateModule.forRoot({
      defaultLanguage: getSystemLanguages(navigator.language.toLowerCase()),
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
  }),
    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ID,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0Audience,
        currentRoute: window.location.href,
        language: localStorage.getItem('defaultLang')
      }
    }),
  ],
  exports: [
    TranslateModule
  ],
  providers: [
    HeaderComponent,
    {
      provide: APP_INITIALIZER,
      useFactory: fetchDecimalPlaces,
      deps: [decimalPlacesService],
      multi: true
    },
    // { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    { provide: LOCALE_ID, useValue: getSystemLanguages(navigator.language.toLowerCase()).toLowerCase() },
    [ LyTheme2 ],
    [ StyleRenderer ],
    // Theme that will be applied to this module
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
    { provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
    // Gestures
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig }, // Required for <ly-carousel>
    MatDatepickerModule,
    SigninService,
    WalletValidatorService,
    FavoritesService,
    SearchService,
    FormatCurrency,
    FiatByCountry,
    PaymentService,
    PaymentRequestService,
    ConvertAsset,
    OffRampService,
    RoundUpUtil,
    DatePipe,
    NotificationService,
    TranslateService,
    MatSelectCountryComponent,
    EventService,
    provideEnvironmentNgxMask(),
    UserService,
    GeolocationService,
    LyDialogModule,
    LyDialog,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha,
      } as RecaptchaSettings,
    },
    Web3,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] 
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

function getCountryLanguage(){
  const countryLanguage = getSystemLanguages(navigator.language.toLowerCase()).toLowerCase();
  switch (countryLanguage) {
    case 'pt-br':
      return 'br'
    case 'es':
      return 'es';
    default:
      return 'en';
  }
}

function getSystemLanguages(browserLanguage:string){
  let language;
  const hasTransaltions = systemLanguages.filter(idiom => idiom.language === browserLanguage)
  const defaultLanguage = localStorage.getItem('defaultLang')
  if(defaultLanguage){
    if(systemLanguages.filter(idiom => idiom.language === defaultLanguage).length > 0) {
    return defaultLanguage;
    }
  }
  if(hasTransaltions.length > 0){
    const defaultLanguage = localStorage.setItem('defaultLang', browserLanguage)
    language = browserLanguage
  } else {
    localStorage.setItem('defaultLang', 'en')
    language = 'en'
  }
  return language;
}
