import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ConfirmRegistrationComponent } from './pages/confirm-registration/confirm-registration.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { FavoriteNicksComponent } from './pages/favorite-nicks/favorite-nicks.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AuthGuardService } from './services/auth.service';
import { ActivateAccountComponent } from './pages/activate-account/activate-account.component';
import { BubblesComponent } from './components/bubbles/bubbles.component';
import { CustomOptionSelectComponent } from './components/custom-option-select/custom-option-select.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { PaymentComponent } from './components/payment/payment.component';
import { UserGuideComponent } from './components/user-guide/user-guide.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' }, 
  { path: 'home', component: HomeComponent },
  { path: 'register', component: RegisterPageComponent, canActivate: [AuthGuardService] },
  { path: 'login', component: LoginPageComponent, canActivate: [AuthGuardService] },
  { path: 'login/forgotpassword', component: LoginPageComponent, canActivate: [AuthGuardService] },
  // { path: 'activate-account', component: ActivateAccountComponent },
  { path: 'contacts', component: FavoriteNicksComponent, canActivate: [AuthGuardService] },
  { path: 'favorites', component: FavoriteNicksComponent, canActivate: [AuthGuardService] },
  { path: 'overview', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'transactions', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'settings', component: ProfileComponent, canActivate: [AuthGuardService] },
  { path: 'select', component: CustomOptionSelectComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: 's/:shortId', component: HomeComponent },
  { path: 'e/:email', component: HomeComponent },
  { path: 'payment-report/:shortId', component: PaymentComponent },
  { path: '**', redirectTo: '/home' } 
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
