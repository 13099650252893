import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ipGeoLocationApi, ipGeoLocationApiKey } from 'src/environments/environment';

@Injectable()
export class GeolocationService {
  constructor(public route: Router,
    private http: HttpClient,
    public auth: AuthService
  ) { }


  getUserLocation(language:string | null) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
      })
    };
    return this.http.get(ipGeoLocationApi + ipGeoLocationApiKey + '&lang=' + language || 'en', httpOptions)
  }

  
}










