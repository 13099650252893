<app-modal [modalMessage]="modalMessage" [fullEmailModal]="fullEmailModal" [exchangeModal]="exchangeModal"
  (onCloseModal)="closeInfoModal()" [hideCopyButton]="hideCopyButton" *ngIf="exchangeModal"></app-modal>


<app-header *ngIf="router.url.includes('/payment-report')" (userSelectedLanguage)="selectedLanguage = $event" class="direct-payment-report-header"></app-header>

<!-- <div class="modal-backdrop">
  <div class="modal">
    <div class="modal-body">
      <button (click)="testNetworkId()"> TEST NETWORK ID</button>
    </div>
  </div>
</div> -->
<div class="payment-report-flow">
  <div class="full-width">
    <!-- <h1 class="flow-tittle">
              Payment Request
          </h1> -->
    <div class="steps-status">
      <!-- <app-bubbles ></app-bubbles> -->

      <label *ngIf="requestPayment" [ngStyle]="{'color': step === 'overview' || '#000'}">
        <div class="toggle-box clickable" [ngClass]="{'past-step': completedSteps.overview}" 
        (click)="checkIfStepCanBeClicked('overview')">
          <div class="current-step" *ngIf="step === 'overview' && !completedSteps.overview">

          </div>
          <i class="checked-step ph-bold ph-check" *ngIf="completedSteps.overview"></i>
        </div>
        <span *ngIf="!isMobile || step === 'overview'">{{ 'payment_flow.overview' | translate }}</span>
      </label>
      
      <hr *ngIf="requestPayment" >
      

      <label [ngStyle]="{'color': (step === 'payment-info' || step == 'guest') || '#000'}" *ngIf="isAuthenticated && !paymentRequestInfo">
        <div class="toggle-box clickable" [ngClass]="{'past-step': completedSteps.identification }" 
        (click)="checkIfStepCanBeClicked( isAuthenticated ? 'payment-info' : 'guest') ">
          <div class="current-step" *ngIf="(step === 'payment-info' || step == 'guest') && !completedSteps.identification">

          </div>
          <i class="checked-step ph-bold ph-check" *ngIf="completedSteps.identification"></i>
        </div>
        <span *ngIf="!isMobile || (step === 'payment-info' || step == 'guest')">{{ 'payment_flow.identification' | translate }}</span>
      </label>
      <hr *ngIf="isAuthenticated && !paymentRequestInfo"> 

      <label [ngStyle]="{'color': (step === 'your-information' ) || '#000'}" *ngIf="!isAuthenticated">
        <div class="toggle-box clickable" [ngClass]="{'past-step': completedSteps.contact }" 
        (click)="checkIfStepCanBeClicked( 'your-information' ) ">
          <div class="current-step" *ngIf="(step === 'your-information') && !completedSteps.contact">

          </div>
          <i class="checked-step ph-bold ph-check" *ngIf="completedSteps.contact"></i>
        </div>
        <span *ngIf="!isMobile || step === 'your-information'">{{ 'payment_flow.contact' | translate }}</span>
      </label>
      <hr *ngIf="!isAuthenticated"> 

      <label [ngStyle]="{'color': step === 'payment' || '#000'}">
        <div class="toggle-box clickable" [ngClass]="{'past-step': completedSteps.payment}"
        (click)="checkIfStepCanBeClicked('payment')">
          <div class="current-step" *ngIf="step === 'payment' && !completedSteps.payment">

          </div>
          <i class="checked-step ph-bold ph-check"  *ngIf="completedSteps.payment"></i>
        </div>
        <span *ngIf="!isMobile || step === 'payment'">{{ 'payment_flow.payment' | translate }}</span>
      </label>
      <hr>

      <label [ngStyle]="{'color': step === 'confirmation' || '#000'}">
        <div class="toggle-box clickable"  [ngClass]="{'past-step': completedSteps.confirmation}"
        (click)="checkIfStepCanBeClicked('confirmation')">
          <div class="current-step" *ngIf="step === 'confirmation' && !completedSteps.confirmation">

          </div>
          <i class="checked-step ph-bold ph-check"  *ngIf="completedSteps.confirmation"></i>
        </div>
        <span *ngIf="!isMobile || step === 'confirmation'">{{ 'payment_flow.confirmation' | translate }}</span>
      </label>
    </div>
  </div>

  <div class="loading-modal" *ngIf="loading.length > 0 || metamaskLoading.length > 0"> 
    <div class="metamask-loading" *ngIf="metamaskLoading.length > 0">
      <h2>{{ 'theTransactionIsNowUnderTheControlOfMetamask' | translate: { walletExtension: selectedWallet.info.name } }}</h2>
      <img src="/assets/icons/{{selectedWallet.info.name.toLocaleLowerCase().replaceAll(' ', '')}}-icon.png" alt="metamask">
      <div class="spinner" [ngClass]="{'common-spinner': selectedWallet.info.name != 'MetaMask'}"></div>
    </div>
    <div class="spinner" *ngIf="loading.length > 0"></div>
  </div>

  
  <div class="modal-backdrop public-profile-backdrop" *ngIf="publicProfile">
    <div class="modal public-profile-modal">
      <app-bubbles [isAuthenticated]="isAuthenticated" [profileInfo]="targetUser" [paymentProfile]="publicProfile" (closePaymentProfile)="publicProfile = false"></app-bubbles>
    </div>
  </div>

  <!-- {{ 'payment_flow.overview' | translate }} | overview -->
  <div class="modal-body overview-body" *ngIf="step == 'overview'">

    <div class="step-container step-overview">
      <div class="step-tittle flex-centered">
        <h2>
          {{ targetUser.publicName }} {{ 'payment_flow.isRequestingAPayment' | translate }}
        </h2>
      </div>

      <div class="request-info user-info">
        <img class="profile-pic" [src]="environment.api + 'User/get-public-profile-picture?userId=' + targetUser.id"
          [matTooltipPosition]="'above'" matTooltip="{{ (targetUser.publicName)?.charAt(0) }}"
          (error)="handleImageError($event, targetUser.publicName)">
        <div>
          <h2> {{ targetUser.publicName }} </h2>
          <!-- <h3> {{ targetNick }} </h3> -->
        </div>
        <!-- <span class="short-id" *ngIf="target.shortId"> {{ target.shortId }}</span> -->
        <div class="open-public-profile" (click)="publicProfile = true"><i class="ph ph-arrow-line-up-right button"></i></div>
      </div>


      <div class="payer-propertys">
        <div class='amount-info'>
          <div class="asset-info flex-centered">
            @if ( !checkIfRequestedAssetIsFiat() ) {
              <img class="icon asset-icon" src="/assets/icons/{{  payingAsset }}.png" alt="{{ payingAsset }}">
            }
            <label>
              {{ payingAsset }}
            </label>
          </div>
          <label class="amount">{{ formatPrice(paymentValue, true, true) }}</label>
        </div>
        <div class="request-info">
          <label class="info-name">{{ 'payment_flow.from' | translate }}</label>
          <label class="info-value">{{ behalfName || behalfCompany}}</label>
        </div>

        <div class="request-info">
          <label class="info-name">{{ 'payment_flow.email' | translate }}</label>
          <label class="info-value"> {{ behalfEmail }} </label>
        </div>
      </div>

      <div class="payment-details">

      @if (this.payment.controls['description'].value) {
          <div class="request-info bordered-request-info">
            <label class="info-name">{{ 'payment_flow.Description' | translate }}</label>
            <label #description class="info-value">
              <span>{{ this.payment.controls['description'].value || '< ' + ( 'PAYMENT_REQUEST.empty' | translate ) + ' >' }}</span>
              @if (checkIfOverflows(this.payment.controls['description'].value, description)) {
                ... 
                <a class="see-more clickable" *ngIf="checkIfOverflows(this.payment.controls['description'].value, description)" (click)="openMoreInfoModal(this.payment.controls['description'].value)">See more</a>
              }
            </label>
          </div>
      }

  
        @if (this.payment.controls['reference'].value) {
          <div class="request-info bordered-request-info">
            <label class="info-name">{{ 'payment_flow.Reference' | translate }}</label>
            <label #reference class="info-value"> 
              <span>{{this.payment.controls['reference'].value || '< ' + ('PAYMENT_REQUEST.empty' | translate) + ' >' }}</span>
              @if (checkIfOverflows(this.payment.controls['reference'].value, reference)) {
                ... 
                <a class="see-more clickable" (click)="openMoreInfoModal(this.payment.controls['reference'].value)">See more</a>
              }
             </label>
          </div>
        }

  
        <div class="request-info bordered-request-info">
          <label class="info-name">{{ 'payment_flow.PaymentID' | translate }}</label>
          <label class="info-value"> {{ requestParams?.paymentId }} </label>
        </div>
        
        <!-- <h3 class="have-account" (click)='redirectToLogin()'  [innerHTML]="'orLoginIfYouHaveAnAccount' | translate">
          
        </h3> -->
      </div>
      

    </div>
    <div class="modal-footer side-buttons-footer step-overview-buttons">
      <a class="cancel-button" (click)="alreadyFinished = true; closeModal()"> {{ 'payment_flow.Cancel' | translate }} </a>
      <button (click)="isAuthenticated ? nextStep('payment-info') : nextStep('your-information')" class="only-portrait">{{
        'PAYMENT_REPORT.yesIWantToGoAhead' | translate}}</button>
    </div>
  </div>

  <!-- Identification step | guest -->
  <div class="modal-body step-overview-body" *ngIf="step == 'guest'">
    <div class="step-container step-overview">
      <div class="step-tittle flex-centered">
        <h2>
          {{ 'PAYMENT_REPORT.areYouANickyUser' | translate }} ?
        </h2>
        <!-- <h3>
          {{ 'PAYMENT_REPORT.IfYouAlreadyHaveAnAccountYouCanSignIn' | translate }}
        </h3> -->
      </div>
      <div class="input-wrapper">
        <div class="payer-info">
          <div class="payer-type clickable" (click)="areAUser = 'login'"
            [ngClass]="{'selected-type': areAUser === 'login'}">
            <div class="payer-icons">
              <i class="ph ph-sign-out"></i>
              <i class="ph ph-check" *ngIf="areAUser === 'login'"></i>
            </div>
            <h2>Login</h2>
            <h3>{{ 'payment_flow.YesIWantToLogin' | translate }}</h3>
          </div>

          <!-- <div class="payer-type clickable" (click)="areAUser = 'register'"
            [ngClass]="{'selected-type': areAUser === 'register'}">
            <div class="payer-icons">
              <i class="ph ph-user-circle-plus"></i>
              <i class="ph ph-check" *ngIf="areAUser === 'register'"></i>
            </div>
            <h2>Sign up</h2>
            <h3>{{ 'payment_flow.NotAUserButIWantToSignUp' | translate }}</h3>
          </div> -->

          <div class="payer-type clickable" (click)="areAUser = 'guest'"
            [ngClass]="{'selected-type': areAUser === 'guest'}">
            <div class="payer-icons">
              <i class="ph ph-user-circle"></i>
              <i class="ph ph-check" *ngIf="areAUser === 'guest'"></i>
            </div>
            <h2>{{ 'payment_flow.ContinueAsGuest' | translate }}</h2>
            <h3>{{ 'payment_flow.JustAQuickCoupleOfQuestions' | translate }}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer side-buttons-footer centered-footer">
      <button (click)="showConfirmationModal = false; guestNextStep('your-information')"> {{ 'payment_flow.Continue' | translate }} </button>
    </div>
  </div>

  <!-- Contact | your-information -->
  <div class="modal-body  contact-step" *ngIf="step == 'your-information'">
    <div class="step-container">
        <div class="step-tittle flex-centered">
            <h2>
              {{ 'payment_flow.contact' | translate }}
            </h2>
            <!-- <h3>
                {{ 'payment_flow.PayersInformation' | translate }}
            </h3> -->
        </div>
        <div class="input-wrapper" *ngIf="selectedUserType === 'individual'">
          <h3> {{ 'Name' | translate }} </h3>
          <div class="input-container"
              [ngClass]="{'invalid':invalidGuestInfo && !(this.payment.controls['guestName']?.valid)}">
              <i class="ph ph-user-circle"></i>
              <input autocomplete="name" name="name"  id="name" class="common-input bigger-input" type="text"
                  [(ngModel)]="guestName"
                  (ngModelChange)="payment.controls['guestName'].setValue(guestName)" 
                  *ngIf="selectedUserType == 'individual'">
          </div>
      </div>
      
      <div class="input-wrapper" *ngIf="selectedUserType === 'organization'">
        <h3> {{ 'payment_flow.PayersOrganizationName' | translate }} </h3>
        <div class="input-container"
            [ngClass]="{'invalid':invalidGuestInfo && !(this.payment.controls['company'].valid)}">
            <i class="ph ph-user-circle"></i>
            <input autocomplete="organization" name="organization" class="common-input bigger-input" type="text"
                [(ngModel)]="organization"
                (ngModelChange)="payment.controls['company'].setValue(organization)" 
                *ngIf="selectedUserType == 'organization'">
        </div>
    </div>

    <label *ngIf="selectedUserType == 'individual' && invalidGuestInfo && !this.payment.controls['guestName']?.valid"
        class="inform-label warning-inform"> {{ 'youNeedToInformYourName' | translate }} </label>
    <label
        *ngIf="selectedUserType == 'organization' && invalidGuestInfo && !this.payment.controls['company']?.valid"
        class="inform-label warning-inform"> {{ 'youNeedToInformYourCompanyName' | translate }} </label>


        <div class="input-wrapper">
            <h3> {{ 'email' | translate }}</h3>
            <div class="input-container"
                [ngClass]="{'invalid':(invalidGuestInfo && !(this.payment.controls['email']?.valid) || invalidEmail)}">
                <i class="ph ph-at"></i>
                <input autocomplete="email" name="email" class="common-input bigger-input" type="text"
                    [(ngModel)]="email"
                    (keyup)="emailInput()">
            </div>
        </div>

        <label *ngIf="(invalidGuestInfo && !this.payment.controls['email']?.valid) || invalidEmail"
            class="inform-label warning-inform"> {{ 'youNeedToInformAValidEmail' | translate }} </label>

        <div class="input-wrapper">
            <h3> {{ 'iAmAn' | translate }} </h3>
            <div class="payer-info">
                <div class="payer-type clickable" (click)="selectedUserType = 'individual'"
                    [ngClass]="{'selected-type': selectedUserType === 'individual'}">
                    <div class="payer-icons">
                        <i class="ph ph-user"></i>
                        <i class="ph ph-check" *ngIf="selectedUserType === 'individual'"></i>
                    </div>
                    <h2>{{ 'payment_flow.Individual' | translate }} </h2>
                    <!-- <h3>{{ 'payment_flow.PayerIsAnIndividual' | translate }} </h3> -->
                </div>
                <div class="payer-type clickable" (click)="selectedUserType = 'organization'"
                    [ngClass]="{'selected-type': selectedUserType === 'organization'}">
                    <div class="payer-icons">
                        <i class="ph ph-buildings"></i>
                        <i class="ph ph-check" *ngIf="selectedUserType === 'organization'"></i>
                    </div>
                    <h2>{{ 'payment_flow.Organization' | translate }}</h2>
                    <!-- <h3>{{ 'payment_flow.PayerIsAnOrganization' | translate }}</h3> -->
                </div>
            </div>
        </div>
        <h3 class="have-account" (click)='redirectToLogin()'  [innerHTML]="'orLoginIfYouHaveAnAccount' | translate">
          
        </h3>
    </div>
    <div class="modal-footer side-buttons-footer only-desktop">
        <button class="cancel-button" (click)="closeModal()"> {{ 'payment_flow.Cancel' | translate }} </button>
        <button id="payment-continueButton" (click)="nextStep('payment-info')"> {{ 'payment_flow.Continue' | translate }} </button>
    </div>
</div>

  <!-- Payment | payment -->
  <div class="modal-body paying-body" *ngIf="step == 'payment'">
    <div class="step-container step-payment">
      <img src="/assets/icons/help-book.png" class="help-book clickable" id="bookIcon" (click)="startPaymentTour()" style="float: right;">
      <div class="step-tittle flex-centered">
        <h2>
          {{ 'payment' | translate }} 
        </h2>
      </div>

      <div class="amount-inputs-wrapper">
        <div class="amount-info-wrapper" id="received">
          <label class="info-wrapper-title italic">
              {{ ('simpleIOwe' | translate) }} 
              <i class="ph ph-info no-arrow clickable" #tooltip="matTooltip" (click)="!isMobile || tooltip.toggle()" *ngIf="loading.length < 1"  matTooltip="{{ 'iOweExplanation' | translate }}" [matTooltipPosition]="'below'"></i>
          </label>
          <div class="amount-inputs">
            <div class="asset-select-wrapper flex-centered">
              <img class="icon asset-icon" src="/assets/icons/filter-{{  payingAsset }}.png" alt="{{ payingAsset }}">
              @if ( requestParams != undefined && requestPayment || onlyOneAcceptedAsset ) {
                <span style="width:70px"> {{ payingAsset }} </span>
              } @else {
                <select [ngClass]="{'single-asset-select': onlyOneAcceptedAsset}"
                [(ngModel)]="payingAsset" class="selection selected-asset"
                (ngModelChange)="changeTargetCurrency(); updateMaskConfig(); checkAssetNameSize()">
                <option *ngIf="cryptoAssets.length > 0" disabled>{{ 'PAYMENT_REPORT.cryptoAssets' | translate }}
                </option>
                <option [value]="asset.assetTicker" *ngFor="let asset of cryptoAssets">{{ (asset.assetTicker ===
                  payingAsset) ? asset.assetTicker : buildSelectionText(asset.assetName, asset.assetTicker) }}</option>
                <option *ngIf="fiatAssets.length > 0" disabled>{{ 'PAYMENT_REPORT.fiat' | translate }}</option>
                <option [value]="asset.assetTicker" *ngFor="let asset of fiatAssets">{{ (asset.assetTicker ==
                  payingAsset) ? asset.assetTicker : buildSelectionText(asset.assetName, asset.assetTicker) }}</option>
                <option *ngIf="requestParams != undefined && requestPayment" [value]="payingAsset">{{payingAsset}}
                </option>
              </select>
              }
              <!-- <app-custom-option-select class="inline-flex-option-select"
              [assets]="currentOptionsSelects.payingAsset" [currentValue]="payingAsset"
              (changedValue)="payingAsset = $event;
              updateMaskConfig();"></app-custom-option-select>   -->
            </div>
            <div class="input-container">
              <input #input currencyMask [options]="customCurrencyMaskConfig" type="text" id="amount" name="amount" inputmode="numeric"
                autocomplete="off"
                [(ngModel)]="paymentValue" (ngModelChange)="updateAsset()"
                [disabled]="itsFixedSettlementAsset()" name="USD"
                (keydown.backspace)="updateAsset(true)"
                placeholder="{{'0' + (isCommaDecimal ? ',' : '.') + '0000000'}}">
            </div>
          </div>
        </div>
        <hr *ngIf="!isMobile" class="spacement">
            
        <div class="amount-info-wrapper" id="paid">
          <label class="info-wrapper-title italic"> 
            {{ ('soIAmSending' | translate) }} 
            <i class="ph ph-info no-arrow clickable" #tooltip="matTooltip" (click)="!isMobile || tooltip.toggle()" *ngIf="loading.length < 1"  matTooltip="{{ 'iAmSendingExplanations' | translate }}" [matTooltipPosition]="'below'"></i>
          </label>
          <div class="amount-inputs">
            <div [ngClass]="{'single-asset': assetOptions.length === 1}" class="asset-select-wrapper flex-centered">
              <img class="icon asset-icon" src="/assets/icons/filter-{{  selectedAsset }}.png"
                alt="{{ selectedAsset }}">
              <label *ngIf="assetOptions.length === 1">{{ assetOptions[0].assetTicker }}</label>
              <select *ngIf="assetOptions.length > 1" [(ngModel)]="selectedAsset" name="settlementAsset" class="selection asset-selection selected-asset"
                (ngModelChange)="chooseUserWallet(true); checkAssetNameSize()">
                <option [value]="connection.assetTicker" *ngFor="let connection of assetOptions">
                  {{ (connection.assetTicker === selectedAsset) ? connection.assetTicker :
                  buildSelectionText(connection.assetName, connection.assetTicker) }}
                </option>
              </select>
              <!-- <app-custom-option-select class="inline-flex-option-select"
              [assets]="currentOptionsSelects.payingAsset" [currentValue]="payingAsset"
              (changedValue)="payingAsset = $event;
              updateMaskConfig();"></app-custom-option-select>   -->
            </div>
            <div class="input-container">
              <input type="text" inputmode="numeric" [disabled]="true" [(ngModel)]="assetValue" appNumberFormat
                name="paying-amount" (keyup.backspace)="updateSettlementAsset(true)" (keyup.space)="updateSettlementAsset(true)"
                (ngModelChange)="updateSettlementAsset();"
                placeholder="{{'0' + (isCommaDecimal ? ',' : '.') + '0000000'}}" class="asset-placeholder">
            </div>

            <div class="network-select" *ngIf="selectedAsset != 'BTC' && selectedAsset != 'ETH' && selectedAsset != 'POL'">
              <label> {{ 'Via' | translate }} </label>
            
              @for (enabledNetwork of enabledNetworks; track selectedAsset) {
                <div class="toggle-selection clickable" name="network" id="network" (click)="network = enabledNetwork; getUserWallets(true)"
                [ngClass]="{'selected-toggle': network === enabledNetwork}">
                <div class="toggle-icons">
                  <i class="ph ph-check"></i>
                </div>
                <h2> {{ getNetworkName(enabledNetwork) }} </h2>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
        @if (!sameAssets()) {
          <div class="rates-container">
            <h2 class="rate-content-title">
              {{ 'PAYMENT_REPORT.currentRate' | translate }}
              <i class="ph ph-info clickable" *ngIf="loading.length < 1" #tooltip="matTooltip" (click)="!isMobile || tooltip.toggle()"  matTooltip="{{ getCoinMarketTooltip() }}" [matTooltipPosition]="'above'"></i>
            </h2>
  
            @if (!flippedRates) {
              <label class="rates-asset-label"> {{payingAsset}} 1  </label> =  <label>{{selectedAsset}} {{ formatPrice(1 / payingAssetPrice) }}</label>
            } @else {
              <label class="rates-asset-label"> {{selectedAsset}} 1 </label> =  <label> {{payingAsset}} {{payingAssetPrice ? formatPrice(payingAssetPrice, true, true) : ''}} </label>
            }
            <a (click)="flippedRates = !flippedRates" class="flip-button clickable">FLIP</a>
          </div>
  
          <div class="input-container time-lapse-container" id="payment-deadline">
            <label class="time-lapse-title">
             {{ 'payment_flow.refreshingIn' | translate }}
            </label>
            <div class="time-lapse-bar" [ngStyle]="{'background': fillTimeLapseBar(refreshRatesTimerInSeconds, 15)}">
  
            </div>
            <label class="rate-cooldown"> {{ !sameAssets() ? formatTimer(refreshRatesTimerInSeconds.toString()) : '' }} </label>
          </div>

      }
      </div>
    <div class="modal-footer side-buttons-footer">
      <a class="cancel-button" (click)="closeModal()"> {{ 'payment_flow.Cancel' | translate }} </a>
      
      <div class="buttons-wrapper">
        <button class="secondary-button" (click)="backPreviousStep('your-information')">{{ 'back' | translate }}</button>
        <button class="proceed-button clickable" id="next-button" [ngClass]="{'disabled': testIfZero() }"
          (click)="!nullPrice && !(testIfZero()) ? nextStep('confirmation') : ''">
          {{ 'next' | translate }}
        </button>
      </div>
     
      <label class="secondary-text no-wallet-warning" *ngIf="walletError" [innerHTML]="'userTemporarilyUnableToReceivePayments' | translate: { assetName:this.getFormatedAssetForWarnings() }">
      </label>
      <label class="secondary-text" *ngIf="!walletError">
        {{ 'inTheNextStepYouWillHave' | translate: { minutes : totalTimeInMinutes } }}
      </label>
    </div>
  </div>

  <!-- Confirmation | confirmation -->
  <div class="modal-body confirmation-body" *ngIf="step == 'confirmation'">
    <div class="step-container">
      <img src="/assets/icons/help-book.png" class="help-book clickable" id="bookIcon" (click)="confirmationPaymentTour()">
      <div class="step-tittle flex-centered">
        <h2>
          {{ 'payment_flow.PaymentConfirmation' | translate }}
        </h2>

       
        <!-- <label class="timer-inform">
          <i class="ph ph-info"></i>
          <h3>
            {{ 'PAYMENT_REPORT.paymentMustBeCompletedWithin' | translate }} {{ minutes }} min
          </h3>
        </label> -->
      </div>

      <div class="request-info user-info mobile-i-owe" id="amount-received">
        <label class="quote-tittle" >
          <span class="italic">{{ 'iOwe' | translate: { receiver: '' } }}</span> {{ targetUser.publicName }} 
          <i class="ph ph-info i-owe-tooltip no-arrow clickable" #tooltip="matTooltip" (click)="!isMobile || tooltip.toggle()" *ngIf="loading.length < 1"  matTooltip="{{ 'iOweExplanation' | translate }}" [matTooltipPosition]="'below'"></i>  
          <br>
          <label class="i-owe-amount">
            <img class='icon asset-icon' src='/assets/icons/{{payingAsset}}.png' alt='{{payingAsset}}'> 
            <label class="italic">
              {{payingAsset}} {{formatPrice(savedpaymentValue , (payingAsset == 'BTC' || payingAsset == 'ETH'), true).toString()}}
            </label>
          
          </label>
         
        </label>
              
      </div>

        <div class="quote-info">
        <!-- <hr *ngIf="!isMobile" class="spacement"> -->

        <div class="quote-wrapper via-wrapper" id="amount-paid">
          <label class="quote-tittle italic">
            {{ 'soIAmSending' | translate }}
            <i class="ph ph-info no-arrow clickable" #tooltip="matTooltip" (click)="!isMobile || tooltip.toggle()" *ngIf="loading.length < 1"  matTooltip="{{ 'iAmSendingExplanations' | translate }}" [matTooltipPosition]="'below'"></i>

            <label class="via-label"> <span class="italic">via</span> {{ ( formatNetworkName( getSelectedAssetNetwork() ).toLowerCase() + 'Network') | translate }} </label>
          </label>
          <div class='amount-info'>
            <div class="asset-info flex-centered">
              <img class="icon asset-icon" src="/assets/icons/{{  selectedAsset }}.png" alt="{{ selectedAsset }}">
              <label>
                {{ selectedAsset }}
              </label>
            </div>
            <label class="amount"> {{formatPrice( currentQuote?.price , (selectedAsset === 'BTC' || selectedAsset ===
              'ETH') ? true : false)}}</label>
          </div>
        </div>
      </div>

      <div class="input-container time-lapse-container" id="payment-deadline">
        <label class="time-lapse-title">
         {{ 'openYourWalletAndSendPayment' | translate }}
        </label>
        <div class="time-lapse-bar" [ngStyle]="{'background': fillTimeLapseBar(refreshRatesTimerInSeconds, totalTimeInMinutes * 60)}">

        </div>
        <label class="rate-cooldown"> {{ !sameAssets() ? formatTimer(refreshRatesTimerInSeconds.toString()) : '' }} </label>
      </div>

      <div class="bottom-content">

        <div class="qr-code-wrapper" id="payment-address">
         
          <div class="nick-wallet-code">
            <div class="copy-code">
              <qrcode 
              class="qr-code"
              [qrdata]="walletAddress"
              [allowEmptyString]="true"
              [imageSrc]="isMobile ? './assets/expand-icon.png' : './assets/nicky-n.png'" 
              [imageWidth]="isMobile ? 32 : 32" 
              [imageHeight]="isMobile ? 32 : 39"
              [cssClass]="'center'"
              [colorDark]="'#000000ff'"
              [colorLight]="'#ffffffff'"
              [elementType]="'canvas'"
              [errorCorrectionLevel]="'M'" [margin]="0" [scale]="1" [width]="isMobile ? 80 : 100"
              [title]="'A custom title attribute'"
              (click)="isMobile ? qrModal = true : ''"></qrcode>
            </div>
          </div>
        </div>

          <div class="payment-info-container">
            <div class="rates-container">
              <h2 class="rate-content-title">
                {{ selectedAsset }} {{ 'address' | translate }}  <i class="ph ph-copy copy-button" (click)="copyWalletAddress()"></i>
              </h2>
              <label [title]="walletAddress" id="address" class="rates-asset-label wallet-address-label"> 
                {{ walletAddress }}
              </label>
            </div>
    
            @if (!sameAssets()) {

            <div class="rates-container">
              <h2 class="rate-content-title">
                {{ 'PAYMENT_REPORT.currentRate' | translate }}
                <i class="ph ph-info clickable" *ngIf="loading.length < 1" #tooltip="matTooltip" (click)="!isMobile || tooltip.toggle()"  matTooltip="{{ getCoinMarketTooltip() }}" [matTooltipPosition]="'above'"></i>
              </h2>
              <label class="flipped-rates">
                @if (!flippedRates) {
                  {{payingAsset}} 1   =  {{selectedAsset}} {{ formatPrice(1 / payingAssetPrice) }}
                } @else {
                  {{selectedAsset}} 1  =  {{payingAsset}} {{payingAssetPrice ? formatPrice(payingAssetPrice, true, true) : ''}} 
                }
                <a (click)="flippedRates = !flippedRates" class="flip-button clickable">FLIP</a>
              </label>
            </div>
          }

          </div>


      </div>

      <h1 *ngIf="isMobile" class="terms-inform" [innerHTML]="getWalletExchange(targetWallets?.cryptoAddress?.cryptoDataSource)"></h1>
    </div>


    <h3  *ngIf="!isMobile && !(selectedAsset === 'BTC') && availableWallets.length > 1" class="metamask-pay">
      {{ 'youHaveWalletExtensionsInstalledClickToPayWithOneOfThem' | translate }} 
      @for (availableWallet of availableWallets; track availableWallets ) {
        <img (click)="startPaymentWallet(availableWallet)" class="clickable"  alt="metamask"
        src="/assets/icons/{{availableWallet.info.name.replaceAll(' ', '').toLocaleLowerCase()}}-icon.png">
      }
    </h3>

    <h3  *ngIf="!isMobile && !(selectedAsset === 'BTC') && availableWallets.length === 1" class="metamask-pay">
      <!-- {{ 'weNoticedThatYouHaveTheMetamaskExtensionInstaled' | translate: {walletExtension: selectedWallet.info.name} }} -->
      <img src="/assets/icons/{{selectedWallet.info.name.toLocaleLowerCase().replaceAll(' ', '')}}-icon.png" alt="metamask">
      <a class="clickable" (click)="startPaymentWallet(selectedWallet)">{{ 'clickHereToPayWith' | translate: {walletExtension: selectedWallet.info.name} }}</a>
    </h3>

    <h1 *ngIf="!isMobile" class="terms-inform" [innerHTML]="getWalletExchange(targetWallets?.cryptoAddress?.cryptoDataSource)"></h1>

    <!-- Ask to syeda where to put this -->
    <!--  -->
    <div class="modal-footer side-buttons-footer">
      <label class="quote-warning" *ngIf="invalidQuote">{{ 'payment_flow.attentionQuoteIsOutdated' | translate }}</label>
      <a (click)="alreadyFinished = true; closeModal()">{{ 'cancel' | translate }}</a>
      <div class="buttons-wrapper" id="confirmation-step">
        <button class="secondary-button" (click)="backPreviousStep('payment')">{{ 'back' | translate }}</button>
        <button id="ihavesent-button" class="have-trasnfered italic" (click)=" transferred = true; completedSteps.confirmation = true;">
          {{ "iHaveSent" | translate }}
        </button>  
      </div>
      
      
      <!-- <label *ngIf="!isMobile && !(selectedAsset === 'BTC')">{{ 'payment_flow.or' | translate }}</label> -->
      <!-- <label class="secondary-text">
        {{ 'inTheNextStepYouWillBeAskedToProvideTheTransactionId' | translate }}
      </label> -->
      <!-- <button *ngIf="transferred" class="have-trasnfered" (click)=" loading.length == 0 ? nextStep('finish') : ''" >{{ 'PAYMENT_REPORT.finishTrnasaction' | translate }}</button> -->
    </div>
  </div>

  <!-- Identification | payment-info -->
  <form [formGroup]="payment" *ngIf="step == 'payment-info'">
    <div class="modal-body payment-info-body">
      <div class="step-container">
        <div class="step-tittle flex-centered">
          <h2>
           {{ (guestName || behalfName || user?.publicName)?.split(' ')[0] }}{{ 'whatsThisPaymentAbout' | translate }}
          </h2>
          <!-- <h3>
            {{ 'payment_flow.ProvideDescriptionAndReferenceForYourPayment' | translate }}
          </h3> -->
        </div>
        <div class="input-wrapper">
          <h3>{{ 'payment_flow.Description' | translate }}</h3>
          <div class="input-container">
            <textarea maxlength="200" type="text" class="fill-size-input bigger-input" formControlName="description" name="description"
              placeholder="{{ 'PAYMENT_REPORT.descriptionOptional' | translate }}"></textarea>
          </div>
        </div>
        <div class="input-wrapper">
          <h3>{{ 'payment_flow.Reference' | translate }}</h3>
          <div class="input-container">
            <input type="text" class="fill-size-input bigger-input"  [maxlength]="17" formControlName="reference" name="reference"
              placeholder="{{ 'PAYMENT_REPORT.descriptionOptional' | translate }}">
          </div>
        </div>
      </div>
      <div class="modal-footer side-buttons-footer centered-footer">
        <button id="next-button" (click)="nextStep('payment')">{{ 'next' | translate }}</button>
      </div>
    </div>
  </form>

  <!-- finish | finish -->
  <div class="modal-body step-success-body" *ngIf="(step == 'finish' && newPaymentId)">
    <div class="step-container step-overview">
      <div class="step-tittle success-tittle flex-centered">
        <img class="success-payment-img" src="/assets/icons/success-payment.png">
        <h2>
          {{ 'payment_flow.PaymentSuccessful' | translate }}!
        </h2>
      </div>
      <label class="payment-success-inform" [innerHTML]="getSuccessPhrase()">
       
      </label>
    </div>
    <div class="modal-footer side-buttons-footer centered-footer">
      <button *ngIf="!paymentRequestInfo?.successUrl" (click)="showConfirmationModal = false; closeModal();"> {{ isAuthenticated ? ('backToDashboard' | translate) : ('createAnAccount' | translate) }} </button>
      <button *ngIf="paymentRequestInfo?.successUrl" (click)="showConfirmationModal = false; closeModal();"> {{ 'proceed' | translate }} </button>
    </div>
  </div>

  <div class="modal-body step-success-body step-timeout" *ngIf="step === 'invalid-quote'">
    <div class="step-container step-overview">
      <img class="success-payment-img" src="/assets/icons/error.png">
      <label class="quote-inform">
        <h1>{{ 'timeOut' | translate }}</h1> <br>
        <h2>{{ 'didYouCompleteTheTransaction' | translate }}</h2>
      </label>
    </div>
    <div class="modal-footer side-buttons-footer centered-footer">
      <button (click)="nextStep('confirmation')" class="secondary-button">{{ 'noIDidNot' | translate }}</button>
      <button (click)="nextStep('confirmation'); transferred = true; completedSteps.confirmation = true">{{ 'yesICompletedTheTransaction' | translate }}</button>
    </div>

  </div>

  <div class="confirmation-modal-backdrop modal-backdrop" *ngIf="transferred">
    <div class="modal-body transaction-id-modal">
      <div class="modal-header">
        <h3 class="payment-tittle"> {{ 'PAYMENT_REPORT.transactionId' | translate }} </h3>
        <!-- <h3 class="close clickable" (click)="transferred = false;"> X </h3> -->
      </div>
      <div class="input-container time-lapse-container">
        <label class="time-lapse-title">
         {{ 'informTheTransactionId' | translate }}
        </label>
        <div class="time-lapse-bar" [ngStyle]="{'background': fillTimeLapseBar(refreshRatesTimerInSeconds, totalTimeInMinutes * 60)}">

        </div>
        <label class="rate-cooldown"> {{ !sameAssets() ? formatTimer(refreshRatesTimerInSeconds.toString()) : '' }} </label>
      </div>
      <!-- Removed warning -->
      <!-- <label class="timer-inform">
        <i class="ph ph-info"></i>
        <h3>
          {{ 'PAYMENT_REPORT.paymentMustBeCompletedWithin' | translate }} {{ totalTimeInMinutes }} min
        </h3>
      </label> -->
      <div>
      </div>
      <div class="input-wrapper">
        <label class="transaction-id-info" [innerHTML]="getInformingPhrase()">  
          <!-- {{ 'payment_flow.EnterOrPasteYourTransactionID' | translate }} -->
          
        </label>
        <div class="input-container">
          <input *ngIf="!isMobile" type="text"
            [ngClass]="{'invalid': !(payment.controls['transactionID']?.valid) && payment.controls['transactionID'].touched}"
            [(ngModel)]="transactionID" (ngModelChange)="payment.controls['transactionID'].setValue(transactionID)"
            class="fill-size-input transaction-id-input" id="txId" name="transaction-id"
            placeholder="{{ 'PAYMENT_REPORT.transactionId' | translate }}">

          <textarea *ngIf="isMobile" type="text"
            [ngClass]="{'invalid': !(payment.controls['transactionID']?.valid) && payment.controls['transactionID'].touched}"
            [(ngModel)]="transactionID" (ngModelChange)="payment.controls['transactionID'].setValue(transactionID)"
            class="fill-size-input transaction-id-input" id="txId" name="transaction-id"
            placeholder="{{ 'PAYMENT_REPORT.transactionId' | translate }}"></textarea>
        </div>
        <a *ngIf="clipboardPermissions?.state != 'denied' && !clipboardDenied" class="wallet-link clickable" (click)="pasteTransactionId()"   >
          {{ 'pasteFromClipboard' | translate }}
      </a>
      </div>
      <div class="modal-footer side-buttons-footer">
        <button class="cancel-button" (click)="transferred = false;"> {{ 'payment_flow.Cancel' | translate }} </button>
        <button id="submit-txid" class="have-trasnfered" [ngClass]="{'disabled': !this.transactionID }" (click)=" loading.length == 0 ? nextStep('finish') : ''">{{
          'submit' | translate }}</button>
      </div>
    </div>
  </div>

  <div class="confirmation-modal-backdrop modal-backdrop" *ngIf="qrModal">
    <div class="modal-body transaction-id-modal qr-code-modal">
      <div class="modal-header">
        <h3 class="payment-tittle"> {{ 'scanQrCode' | translate }} </h3>
        <!-- <h3 class="close clickable" (click)="transferred = false;"> X </h3> -->
      </div>
      <qrcode 
      class="qr-code"
      [qrdata]="walletAddress"
      [allowEmptyString]="true"
      [imageSrc]="'./assets/nicky-n.png'" 
      [imageHeight]="56"
      [imageWidth]="47" 
      [cssClass]="'center'"
      [colorDark]="'#000000ff'"
      [colorLight]="'#ffffffff'"
      [elementType]="'canvas'"
      [errorCorrectionLevel]="'M'" [margin]="1" [scale]="1" [width]="254"
      [title]="'A custom title attribute'"></qrcode>

      <label class="address-label">
        <h2> {{ 'payment_flow.Address' | translate }} </h2>
        {{ walletAddress }}
      </label>

      <div class="modal-footer side-buttons-footer">
        <button (click)="qrModal = false">{{'done' | translate }}</button>
      </div>
    </div>
  </div>

  <div class="confirmation-modal-backdrop modal-backdrop" *ngIf="moreDetailsModal">
    <div class="modal-body more-info-modal">
      <div class="modal-header">
        <i class="ph-bold ph-x close clickable" (click)="moreDetailsModal = false;"></i>
      </div>
        <label [innerHTML]="moreDetailsModalContent">  
          <!-- {{ 'payment_flow.EnterOrPasteYourTransactionID' | translate }} -->
          
        </label>
    </div>
  </div>

  <!-- <div class="confirmation-modal-backdrop modal-backdrop" *ngIf="newPaymentId">
    <div class="modal payment-id-modal" *ngIf="newPaymentId">
      <div class="modal-header">
        <h3 class="payment-tittle"> {{ 'DASHBOARD.paymentId' | translate }} </h3>
        <h3 class="close" (click)="alreadyFinished = true; closeModal()"> X </h3><br>
      </div>
      <div class="modal-body">
        <label>{{newPaymentId}}
          <img class="icon clickable" src="/assets/icons/copy.png" (click)="copyPaymentId()"
            [matTooltipPosition]="'above'" matTooltip="{{ 'PAYMENT_REPORT.copyThePaymentId' | translate }}">
        </label>
      </div>
    </div>
  </div> -->


  <div class="confirmation-modal-backdrop modal-backdrop" *ngIf="showConfirmationModal">
    <div class="modal confirmation-modal" *ngIf="showConfirmationModal">
      <div class="modal-header">
        <h3 class="payment-tittle"> {{ 'holdOn' | translate }} </h3>
        <h3 class="close" (click)="showConfirmationModal = false"> X </h3><br>
      </div>
      <div class="modal-body">
        <label> {{ 'areYouSureYouDontWantToReportThisPayment' | translate }} </label>
      </div>
      <div class="modal-footer side-buttons-footer">
        <button class="no-report" (click)="showConfirmationModal = false; alreadyFinished = true; closeModal()">{{
          'PAYMENT_REPORT.iDontT' | translate }}</button>
        <button (click)="showConfirmationModal = false;" [ngClass]="{'pt-continue': selectedLanguage == 'pt-br'}">{{
          'continue' | translate }}</button>
      </div>
    </div>
  </div>
  
  <app-nicky-footer *ngIf="isMobile"></app-nicky-footer>
  </div>