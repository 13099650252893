import { LyDialog } from '@alyle/ui/dialog';
import { ImgCropperConfig, ImgCropperEvent, LyImageCropper } from '@alyle/ui/image-cropper';
import { COUNTRIES_DB, COUNTRIES_DB_BR, COUNTRIES_DB_ES, Country } from '@angular-material-extensions/select-country';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CropperDialog } from 'src/app/components/cropper-dialog/cropper-dialog';
import { UTMDto } from 'src/app/dtos/utm.dto';
import { GeolocationService } from 'src/app/services/geo-location-service';
import { SigninService } from 'src/app/services/sign-in.service';
import { UserService } from 'src/app/services/user.service';
import { NICKY_URLS } from 'src/app/utils/urls.constants';
import { commonDomains } from 'src/environments/environment';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router,
    private signin: SigninService,
    private auth: AuthService,
    private toastService: ToastrService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private userService: UserService,
    private _dialog: LyDialog,
    private geolocationService:GeolocationService
  ) { }

  public showModal: boolean = false;
  public numbersLettersAndSpecial!: RegExp;
  public atLeast8Digits!: RegExp;
  public atLeast1SpecialCharacter!: RegExp;
  public atLeast1LowerCase!: RegExp;
  public atLeast1UpperCase!: RegExp;
  public atLeast1Number!: RegExp;
  public modalTitle!: string;
  public modalMessage!: string;
  private success!: boolean;
  public email: string = "";
  public token?: string;
  public loading: boolean = false;
  public finishRegister: boolean = false;
  public seeingPassword: boolean = false;
  public defaultBackgroundPosition: number = 40;
  public alreadyExistNick: string = "";
  public toggleBook: boolean = false;
  private utmSettings!: UTMDto;
  public currentStep: 'password' | 'personal-info' = 'password';
  public remainingSeconds: number = 5;
  public isDomainOwner!:boolean;
  public logo:any;
  public image:any;
  public base64image!:any;
  public imageFile:any;
  public maxWidth:number = 300;
  public maxHeight:number = 300;
  public cropped?: string;
  public country!:Country;
  public isMobile:boolean  = window.innerWidth <= 1100;
  public termsOfUseUrl = NICKY_URLS.TERMS_OF_USE;
  public cookiePolicyUrl = NICKY_URLS.COOKIE_POLICY;
  public privacyPolicyUrl = NICKY_URLS.PRIVACY_POLICY;

  public form: FormGroup = new FormGroup({
    user: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z]([a-zA-Z0-9._-]*[a-zA-Z0-9])?$/)]),
    domain: new FormControl(null, [Validators.required, Validators.pattern(/^[A-Za-z0-9][A-Za-z0-9-_#*.]*\.[A-Za-z0-9]+$/)]),
    nick: new FormControl(null, Validators.email),
    password: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#\$%\^&*]).{8,}$/)]),
    yourName: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9\.\-\sÃÇÁÕÂÔÓÉÍÚãçáõâôóéíú_]+[a-zA-Z0-9\s\.\-\sÃÇÁÕÂÔÓÉÍÚãçáõâôóéíú_]*$/)]),
    firstName: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9\.\-\sÃÇÁÕÂÔÓÉÍÚãçáõâôóéíú_]+[a-zA-Z0-9\s\.\-\sÃÇÁÕÂÔÓÉÍÚãçáõâôóéíú_]*$/)]),
    lastName: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9\.\-\sÃÇÁÕÂÔÓÉÍÚãçáõâôóéíú_]+[a-zA-Z0-9\s\.\-\sÃÇÁÕÂÔÓÉÍÚãçáõâôóéíú_]*$/)]),
    publicName: new FormControl(null, Validators.required),
    website: new FormControl(null, Validators.pattern(/^(https?:\/\/)?(www\.)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z0-9]{2,}(:[0-9]{1,5})?(\/.*)?$/)),
    country: new FormControl(null),
    websiteCertificate: new FormControl(),
    terms: new FormControl(null, Validators.required),
    recaptcha: new FormControl(null),
    utmSource: new FormControl(null),
    utmCampaign: new FormControl(null),
    utmMedium: new FormControl(null),
    isDomainOwner: new FormControl(null)
  });

  ngOnInit(): void {
    this.populateRegexp();
    this.form.controls['websiteCertificate'].setValue('https://');
    this.getUtmParams();
  }

  ngAfterViewInit(): void {
    this.addEventListenerToFirstAndLastName();
  }

  private addEventListenerToFirstAndLastName(){
    const formControls = this.form.controls;
    formControls['firstName'].valueChanges.subscribe({
      next: (value:any) => {
        this.setPublicNameValue(formControls);
      }
    })
    formControls['lastName'].valueChanges.subscribe({
      next: (value:any) => {
        this.setPublicNameValue(formControls);
      }
    })
  }

  private setPublicNameValue(formControl:any){
    const publicName = formControl['publicName'];
    
    if( publicName.touched ) {
      return
    } 

    const firstName = formControl['firstName'].value?.trim().split(' ')[0];
    const lastName = formControl['lastName'].value?.trim().split(' ').slice(-1);
    
    formControl['publicName'].setValue(`${firstName || ""} ${lastName || ""}`);
  }

  private getUtmParams() {
    this.route.queryParams.subscribe((params: Params) => {
      this.utmSettings = {
        utmCampaign: params['utm_campaign'],
        utmMedium: params['utm_medium'],
        utmSource: params['utm_source']
      };
      if(params['referrer']) {
        localStorage.setItem('referrer', params['referrer'])
      }
      localStorage.setItem("utmSettings", JSON.stringify(this.utmSettings));
    });
  }

  setAndTestEmail(paramEmail: string, controls: any) {
    const [user, domain] = paramEmail.split('@');
    controls.email.setValue(paramEmail);
    controls.user.setValue(user);
    controls.domain.setValue(domain);
    controls.email.disable();

    if (!controls.domain.valid || !controls.user.valid) {
      this.router.navigateByUrl('/home');
    }
  }

  private populateRegexp() {
    this.numbersLettersAndSpecial = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])[a-zA-Z\d!@#\$%\^&\*]{3,}$/;
    this.atLeast8Digits = /^.{8,}$/;
    this.atLeast1SpecialCharacter = /[!@#\$%\^&\*]+/;
    this.atLeast1LowerCase = /.*[a-z].*/;
    this.atLeast1UpperCase = /.*[A-Z].*/;
    this.atLeast1Number = /.*[0-9].*/;
  }

  public testPassword(): number {
    let strength = 0;
    const password = this.form.controls['password'].value;

    if (!password) return strength;

    if (this.match1LowerCase()) strength++;
    if (this.match1Number()) strength++;
    if (this.match1UpperCase()) strength++;
    if (this.matchSpecialCharacter()) strength++;
    if (this.matchAtLeast8Digits()) strength++;

    return strength;
  }


    openCropperDialog(event: Event) {
      this.cropped = null!;
      this._dialog.open<CropperDialog, Event>(CropperDialog, {
        data: event,
        width: 320,
        disableClose: true
      }).afterClosed.subscribe((result?: ImgCropperEvent) => {
        if (result) {
          const imageUrl = result.dataURL;
          this.logo = imageUrl;
          this.base64image = imageUrl;
          this.convertImageUrlToFile(imageUrl);
        }
      });
    }

    private async convertImageUrlToFile(imageUrl:string | undefined) {
      const response = await fetch(imageUrl || '');
      const blob = await response.blob();

      const file = new File([blob], "profilePicture.jpg", { type: "image/jpg" });
      this.imageFile = file;
    }


    @ViewChild(LyImageCropper, { static: true }) cropper!: LyImageCropper;
    myConfig: ImgCropperConfig = {
      width: 500, // Default `250`
      height: 500, // Default `200`
      type: 'image/png', // Or you can also use `image/jpeg`
      output: {
        width: 300,
        height: 300
      }
    };


  isValidEmail() {
    let email = this.form.controls['nick']
    let user = this.form.controls['user'];
    let domain = this.form.controls['domain'];
    let splitEmail = email.value?.split('@');
    if (splitEmail) {
      user.setValue(splitEmail[0] ?? '');
      domain.setValue(splitEmail[1] ?? '');
    }
    
    return user.valid && domain.valid;
  }

  public matchSpecialCharacter(): boolean {
    return this.atLeast1SpecialCharacter.test(this.form.controls['password'].value);
  }

  public match1Number(): boolean {
    return this.atLeast1Number.test(this.form.controls['password'].value);
  }

  match1LowerCase() {
    const password = this.getFormController('password').value;
    if(!password) {
      return false;
    }
    return this.atLeast1LowerCase.test(password);
  }

  public match1UpperCase(): boolean {
    return this.atLeast1UpperCase.test(this.form.controls['password'].value);
  }

  public matchAtLeast8Digits(): boolean {
    return this.atLeast8Digits.test(this.form.controls['password'].value);
  }

  public matchAll(): boolean {
    return this.matchSpecialCharacter() && this.match1Number() && this.match1LowerCase() && this.match1UpperCase() && this.matchAtLeast8Digits();
  }

  public hideModal(): void {
    this.showModal = false;
  }

  public goHome(): void {
    this.router.navigateByUrl('/home');
  }

  public nickAlreadyExist(): boolean {
    const newNick = `${this.form.controls['user'].value}@${this.form.controls['domain'].value}`;
    return this.alreadyExistNick === newNick;
  }

  public onCountrySelected(event: any): void {
    this.form.controls['country'].setValue(event?.alpha2Code?.toLowerCase());
  }

  public getFormController(controllerName: string) {
    return this.form.controls[controllerName];
  }

  public isControllerTouched(controllerName: string): boolean {
    return this.form.controls[controllerName].touched;
  }

  public isValidController(controllerName: string): boolean {
    if(controllerName === 'publicName') {
      return this.getFormController(controllerName).valid;
    }
    return this.getFormController(controllerName).valid && this.isControllerTouched(controllerName);
  }

  public nextStep(): void {
    if (!this.isValidController('password') || !this.isValidController('nick') || !this.form.controls['terms'].value) {
      return;
    }

    if ( this.testPassword() >= 5 && this.isValidEmail() ){
      if (this.currentStep === 'personal-info') {
        const fullName = `${this.form.controls['firstName'].value} ${this.form.controls['lastName'].value}`;
        this.form.controls['yourName'].setValue(fullName);

        if (this.form.controls['yourName'].valid && this.isValidController('publicName')) {
          this.submit();
        }
      } else {
        this.checkIfAlreadyExists();
      }
    }
  }

  private checkIfAlreadyExists(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.userService.userIsValidated(this.form.controls['nick'].value).subscribe({
      next: (response: any) => {
        if (response === true) {
          this.toastService.error(this.translate.instant('REGISTER.nickAlreadyExist'));
        } else {
          this.currentStep = 'personal-info';
          this.getLocation();
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  SSOAuth(socialConnection:string) {
    this.loading = true;
    this.auth.loginWithRedirect(
      {
        authorizationParams:
        {
          connection: socialConnection,
          redirect_uri: `${window.location.origin}/overview`,
          ui_locales: navigator.language || 'en'
        },
      }
    ).subscribe({
      next: (response: any) => {
      },
      error: (e: any) => {
        this.loading = false;
        console.log(e);
      }
    });
  }

  getLocation(): void{
    const language = localStorage.getItem('defaultLang');
    this.geolocationService.getUserLocation(language).subscribe({
      next: (response:any) => {
       const countries = this.getCountriesByLanguage(language);
       this.country = countries.filter(country => country.alpha3Code === response.country_code3)[0];
      },
      error: (e:any) => {

      }
    })
  }

  getCountriesByLanguage(language:string | null) {
    if (language === 'pt-br') {
      return COUNTRIES_DB_BR;
    } 

    if (language === 'es') {
      return COUNTRIES_DB_ES;
    }

    return COUNTRIES_DB;
  }

  private async submit() {
    this.loading = true
      if(this.validateForm())
        
        if (!this.itsAPublicDomain()) {
          this.isDomainOwner = this.form.controls['isDomainOwner'].value;
        } else {
          this.isDomainOwner = false;
        }

        await this.signin.WebAuth(this.form, this.isDomainOwner, this.imageFile).subscribe(
          (response: any) => {
            this.loading = false;
            if (response == 'user already') {
              this.toastService.error(this.translate.instant('REGISTER.nickAlreadyExist'));
              this.alreadyExistNick = this.form.controls['nick'].value;
              this.loading = false
            }
            if (response == 'user success') {
              this.toastService.success(this.translate.instant('REGISTER.registrationSuccessful'), '' , {
                timeOut: 3000
              });
              this.finishRegister = true;
              // setInterval( () => {
              //   this.remainingSeconds += -1;

              //   if(this.remainingSeconds <= 0) {
              //     
              //   }
              // }, 1000)
            }
        })
  }

  redirectToLoginPage() {
    this.auth.loginWithRedirect({
            authorizationParams: {
              registering: true,
              ui_locales: navigator.language || 'en'
            },
            appState: { target: '/overview' }
          });
  }

  private validateForm(): boolean {
    this.form.controls['nick'].setValue(`${this.form.controls['user'].value}@${this.form.controls['domain'].value}`);
    if (this.form.controls['nick'].valid) {
      if (this.form.valid) {
        return true;
      } else {
        this.toastService.error('Invalid form', '', { timeOut: 3000 });
        this.loading = false;
        return false;
      }
    } else {
      this.toastService.error('Invalid nick', '', { timeOut: 3000 });
      this.loading = false;
      return false;
    }
  }

  public modalPop(modalMessage: string, modalTitle: string): void {
    this.showModal = true;
    this.modalTitle = modalTitle;
    this.modalMessage = modalMessage;
  }

  itsAPublicDomain(){
    if(this.isValidEmail()){
      let domain = this.form.controls['domain'].value;
      return commonDomains.includes(domain);
    } else {
      return true;
    }
  }

}