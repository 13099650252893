import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { UserService } from 'src/app/services/user.service';
import { firstValueFrom } from 'rxjs';
import { Asset } from '../models/asset.model';
import { User } from '../models/user.mode';
import { Router } from '@angular/router';
import { PaymentService } from 'src/app/services/payment.service';
import { FilterService } from 'src/app/services/filter.service';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/services/event.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void => *', [
        animate('500ms ease-in')
      ]),
      transition('* => void', [
        animate('500ms ease-out')
      ])
    ])
  ]
})

export class UserGuideComponent implements OnInit {
  public isOpen: boolean = false;
  private userInfo!: any;
  private JWTToken!: string;
  private acceptedAssets:any = [];
  private userConnections: any;
  private finishedSteps:string[] = [];
  private paymentRequests:any;
  private subscription!: Subscription;

  public steps = [
    { stepInfo: 'setupPaymentConversions', finished: false, blocked: false, url: '/settings?conversionAssetsTab=true' },
    { stepInfo: 'addConnections', finished: false, blocked: false, url: '/settings?connectionsTab=true' },
    { stepInfo: 'addOrImportWallets', finished: false, blocked: false, url: '/settings?wallet=true'},
    { stepInfo: 'createFirstPaymentRequest', finished: false, blocked: true, url: '/overview?paymentRequest=true' }
  ];

  constructor(public auth: AuthService, private userService: UserService, private filterService:FilterService, private router:Router, private eventService: EventService) {}

  ngOnInit(): void {
    this.getUserInfo();
    this.subscription = this.eventService.requestComplete$.subscribe(() => {
      this.finishedSteps = [];
      this.isOpen = false;
      this.getUserInfo();
    });
  }

  async getUserInfo() {
    if (await firstValueFrom(this.auth.isAuthenticated$)) {
      try {
        this.JWTToken = await firstValueFrom(this.auth.getAccessTokenSilently());
        this.paymentRequests = await firstValueFrom(this.filterService.hasSomePaymentRequest(this.JWTToken));
        [this.userInfo, this.acceptedAssets, this.userConnections] = await Promise.all([
          firstValueFrom(this.userService.getUser(this.JWTToken)),
          (firstValueFrom(this.userService.getAcceptedAssets(this.JWTToken))) ,
          firstValueFrom(this.userService.getCryptoConnections(this.JWTToken)) ,
        ]);
        this.setFinishedSteps();
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    }
  }

  redirectTo(url:string | undefined) {
    if(!url)
      return;
    this.router.navigateByUrl(url);
    this.isOpen = false;
  }

  setFinishedSteps() {

    if (this.acceptedAssets.length > 0) {
      if(!this.finishedSteps.includes('setupPaymentConversions')) {
        this.finishedSteps.push('setupPaymentConversions');
      }
    }

    if (this.userConnections?.filter((connection: any) => connection.cryptoDataSource !== 'Manual')?.length > 0) {
      if(!this.finishedSteps.includes('addConnections')) {
        this.finishedSteps.push('addConnections');
      }
    }

    if (this.userConnections?.filter((connection: any) => connection.usedForAssets?.length > 0)?.length > 0) {
      if(!this.finishedSteps.includes('addOrImportWallets')) {
        this.finishedSteps.push('addOrImportWallets');
      }
    }

    if ( this.paymentRequests.total > 0 ) {
      this.finishedSteps.push('createFirstPaymentRequest');
      this.steps.find(step =>  {
        if (step.stepInfo === 'createFirstPaymentRequest') {
          step.finished = true;
          step.blocked = false;
        } 
      })
    }

    this.steps.forEach(async step => {
      if (this.finishedSteps.includes(step.stepInfo)) {
        step.finished = true;
      } else {
        step.finished = false;
      }
      if( this.finishedSteps.length > 2 ){
        if( !this.finishedSteps.includes('createFirstPaymentRequest') ) {
          this.finishedSteps.push('createFirstPaymentRequest');
        }
        if(step.stepInfo === 'createFirstPaymentRequest') {
          step.blocked = false;
          if(this.paymentRequests.total > 0) {
            step.finished = true;
          }
        }
      }
    });
  }

  getFinishedStepsPercentage(): number {
    const totalSteps = this.steps.length;
    const completedSteps = this.finishedSteps.length;
    const percentage = totalSteps > 0 ? (completedSteps / totalSteps) * 100 : 0;

    return percentage;
  }
  
  finishedConfigs(): boolean {
    const maxPercentage = 100;
    return this.getFinishedStepsPercentage() === maxPercentage;
  }

  getGradientBackground(){
    const percentage = this.getFinishedStepsPercentage();
    return `linear-gradient(90deg, #95A4FC ${percentage}%, #BCBCBC3B ${percentage}%)`;
  }

  finishedLoading(): boolean {
    return !!document.querySelector('app-dashboard') ||
           !!document.querySelector('app-profile') ||
           !!document.querySelector('app-favorite-nicks');
  }
}
