import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import FormatCurrency from '../utils/format-currency-utils';


@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private formatCurrency: FormatCurrency
  ) { }

  getAllPaymentReports(JWTToken: string, pageSize: any, pageIndex: any, emails: any[], filterForm?: FormGroup<any>, mustOrderByDescending?: boolean): Observable<any> {
    let receiverNicks: string | undefined = "";
    let dateStart = filterForm?.controls['dateStart'].value?.toString();
    let dateEnd = filterForm?.controls['dateEnd'].value?.toString();
    let amountMoreThan = filterForm?.controls['amountMoreThan'].value;
    let amountLessThan = filterForm?.controls['amountLessThan'].value;

    const blockchainAssets = filterForm?.controls['blockchainAssets'].value;
    const status = filterForm?.controls['status'].value;
    const assetFilters = {
      bitcoinFilter: filterForm?.controls['bitcoinFilter'].value,
      ethereumFilter: filterForm?.controls['ethereumFilter'].value,
      usdcFilter: filterForm?.controls['usdcFilter'].value,
      usdtFilter: filterForm?.controls['usdtFilter'].value,
    }
    const transactionId = filterForm?.controls['transactionId'].value;
    const orderBy = 'createdDate';
    const orderByDescending = mustOrderByDescending = ! null && mustOrderByDescending != undefined ? mustOrderByDescending : true;


    if (emails.length > 0) {
      emails.forEach((email: string, i: number) => {
        receiverNicks += email + ",";
      })
    } else {
      receiverNicks = undefined;
    }

    if (amountLessThan) {
      if (this.formatCurrency.commaDecimalCountries.includes(navigator.language)) {
        amountLessThan = amountLessThan.replace(/\./g, '')
      } else {
        amountLessThan = amountLessThan.replace(/\,/g, '')
      }
      amountLessThan = amountLessThan.replace(/,/g, ".")
    }

    if (amountMoreThan) {
      if (this.formatCurrency.commaDecimalCountries.includes(navigator.language)) {
        amountMoreThan = amountMoreThan.replace(/\./g, '')
      } else {
        amountMoreThan = amountMoreThan.replace(/\,/g, '')
      }
      amountMoreThan = amountMoreThan.replace(/,/g, ".")
    }


    if (dateStart) {
      dateStart = this.formatDate(dateStart);

    }

    if (dateEnd) {
      dateEnd = this.formatDate(dateEnd);
    }

    pageSize = pageSize;
    pageIndex = pageIndex;

    const httpOptions = {
      headers: new HttpHeaders({
        'accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`,
        'Content-Type': 'application/json'
      })
    };

    const body = {
      direction: "In",
      orderBy: orderBy,
      orderByDescending: orderByDescending,
      pageIndex: pageIndex > 0 ? pageIndex - 1 : 0,
      pageSize: pageSize || undefined,
      dateStart: dateStart || undefined,
      dateEnd: dateEnd || undefined,
      amountMoreThan: amountMoreThan || undefined,
      amountLessThan: amountLessThan || undefined,
      blockChainTransactionId: transactionId || undefined,
      email: receiverNicks ? receiverNicks : undefined,
      blockchainAssets: this.checkIfHasAssetFilter(assetFilters) ? this.getAssetFilters(assetFilters) : undefined,
      blockchainCheckStatus: status ? [status] : undefined,
    };

    return this.http.post(environment.api + 'PaymentReport/all', body, httpOptions);
  }

  private checkIfHasAssetFilter(assetFilters:any){
    if(assetFilters.ethereumFilter || assetFilters.bitcoinFilter || assetFilters.usdcFilter || assetFilters.usdtFilter){
      return true
    }
    return false;
  }

  private getAssetFilters(assetFilters:any){
    let assetFilterArray = [];

    if(assetFilters.ethereumFilter){
      assetFilterArray.push("ETH.ETH");
    }
    if(assetFilters.bitcoinFilter){
      assetFilterArray.push("BTC.BTC");
    }
    if(assetFilters.usdcFilter){
      assetFilterArray.push("ETH.USDC");
      assetFilterArray.push("MATIC.USDC");
    }
    if(assetFilters.usdtFilter){
      assetFilterArray.push("ETH.USDT");
      assetFilterArray.push("MATIC.USDt");
    }

    return assetFilterArray;
  }



  getAllPaymentRequests(JWTToken:string, filterForm:FormGroup<any>,mustOrderByDescending:boolean, emails:any, pageSize:any, pageIndex:any, statusFilter:string[]){
    let dateStart = filterForm?.controls['dateStart'].value?.toString();
    let dateEnd = filterForm?.controls['dateEnd'].value?.toString();
    let amountMoreThan = filterForm?.controls['amountMoreThan'].value;
    let amountLessThan = filterForm?.controls['amountLessThan'].value;

    let assetFilter = filterForm?.controls['assetFilter'].value;

    let shortId = filterForm?.controls['paymentId'].value

    let status = statusFilter.length > 0 ? statusFilter : undefined;

    let filteredEmails;

    if(emails[0]){
      filteredEmails = emails[0]
    } else {
      filteredEmails = filterForm.controls['receiverNick'].value
    }

    const orderByDescending = mustOrderByDescending =! null && mustOrderByDescending != undefined ? mustOrderByDescending : true;
    

    if (amountLessThan) {
      if (this.formatCurrency.commaDecimalCountries.includes(navigator.language)) {
        amountLessThan = amountLessThan.replace(/\./g, '')
      } else {
        amountLessThan = amountLessThan.replace(/\,/g, '')
      }
      amountLessThan = amountLessThan.replace(/,/g, ".")
    }

    if (amountMoreThan) {
      if (this.formatCurrency.commaDecimalCountries.includes(navigator.language)) {
        amountMoreThan = amountMoreThan.replace(/\./g, '')
      } else {
        amountMoreThan = amountMoreThan.replace(/\,/g, '')
      }
      amountMoreThan = amountMoreThan.replace(/,/g, ".")
    }

  
    if (dateStart) {
      dateStart = this.formatDate(dateStart);

    }

    if (dateEnd) {
      dateEnd = this.formatDate(dateEnd);
    }

    pageSize = pageSize;
    pageIndex = pageIndex;

    const httpOptions = {
      headers: new HttpHeaders({
        'accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`,
        'Content-Type': 'application/json'
      })
    };
    
    const body = {
      pageIndex: pageIndex > 0 ? pageIndex - 1 : 0,
      pageSize: pageSize ? pageSize : 10,
      dateStart: dateStart ? dateStart : undefined,
      dateEnd: dateEnd ? dateEnd : undefined,
      orderBy: "createdDate",
      orderByDescending: orderByDescending,
      amountMoreThan: amountMoreThan || undefined,
      amountLessThan: amountLessThan || undefined,
      blockchainAssets: assetFilter ? [
        assetFilter , '', '', ''
      ] : undefined,
      status: status,
      requesterUserEmail: filteredEmails != "" ? filteredEmails : undefined,
      shortId: shortId || undefined,
      blockchainAssetId: assetFilter || undefined
    };

    return this.http.post(environment.api + 'PaymentRequest/all', body, httpOptions);
  }

  hasSomePaymentRequest (JWTToken:string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'accept': 'text/plain',
        'Authorization': `Bearer ${JWTToken}`,
        'Content-Type': 'application/json'
      })
    };
    
    const body = {
      pageIndex: 0,
      pageSize: 5,
      orderBy: "createdDate",
    };

    return this.http.post(environment.api + 'PaymentRequest/all', body, httpOptions);
  
  }


  formatDate(date:string){
    let formatedDate
      formatedDate = new Date(date);
      try {
        formatedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
        return formatedDate;
      } catch (e) {
        console.log(e)
        this.toastr.error("Invalid date format, it must be yyyy/MM/dd", '', {
          timeOut: 5000
        });
        return false;
      }
  }
}