import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@auth0/auth0-angular';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { SigninService } from 'src/app/services/sign-in.service'
import { commonDomains, currentExchanges, currentFiats, devEnvironment, environment, testEnvironment } from 'src/environments/environment';
import { debounceTime, filter, firstValueFrom, Subject, timeout } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/app/services/payment.service';
import { Asset } from 'src/app/components/models/asset.model';
import { DomSanitizer } from '@angular/platform-browser';
import { WalletModel } from 'src/app/components/models/wallet.model';
import { bnbAddress, btcAddress, ethAddress, polyAddress, qrApi } from 'src/environments/environment';
import { CryptoConnectionDTO } from 'src/app/dtos/cryto-connection.dto';
import { getRandomValues } from 'crypto';
import { TranslateService } from '@ngx-translate/core';
import animatedBackground from 'src/app/utils/background-animation-utils';
import { toggleMenu } from 'src/app/app.component';
import { OffRampService } from 'src/app/services/off-ramp.service';
import { ConnectorModel } from 'src/app/components/models/connector.model';
import { NgxCurrencyInputMode } from 'ngx-currency';
import FormatCurrency from 'src/app/utils/format-currency-utils';
import { WalletsForConnector } from 'src/app/dtos/wallets-for-connector.dto';
import { OffRampSettings } from 'src/app/dtos/off-ramp.set.setting.dto';
import { savedOffRampSettings } from 'src/app/components/models/saved-off-ramp-seetings.model';
import { SelectionModel } from '@angular/cdk/collections';
import { optionSelectsModel } from 'src/app/components/models/option-select.model';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { withdrawFee } from 'src/app/dtos/withdrawl-fee.dto';
import { ProfileDto } from 'src/app/dtos/user-profile.dto';
import { User } from 'src/app/components/models/user.mode';
import { ImgCropperEvent, LyImageCropper, ImgCropperConfig, ImgCropperErrorEvent } from '@alyle/ui/image-cropper';
import { LySliderChange } from '@alyle/ui/slider';
import { CropperDialog } from 'src/app/components/cropper-dialog/cropper-dialog';
import { LyTheme2, THEME_VARIABLES } from '@alyle/ui';
import { LyDialog } from '@alyle/ui/dialog';
import { ManualOffRampDto } from 'src/app/dtos/manual-off-ramp.dto';
// import { Country } from "countryjs";
import { ShepherdService } from 'angular-shepherd';
//import { steps as defaultSteps, defaultStepOptions} from '../data';
import {COUNTRIES_DB, Country} from '@angular-material-extensions/select-country';
import StepOptionsButton from 'shepherd.js/src/types/step';
import { OffRampRequestModel } from 'src/app/dtos/off-ramp-request.dto';
import { Action } from 'rxjs/internal/scheduler/Action';
import { FiatWithdrawalConfigModel } from 'src/app/components/models/fiat-withdrawal-settings.model';
import { BlockchainAsset, CryptoConnectionAsset, CryptoConnectionInfo, CryptoDataSource } from 'src/app/components/models/crypto-connection-info.model';
import { features } from 'process';
import { WalletValidatorService } from 'src/app/services/crypto-detect-service';
import { EventService } from 'src/app/services/event.service';
import { translatedWebsiteLink } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements AfterViewInit {
  bookIconOpen: boolean | undefined;

  constructor(
    private router: Router,
    private userService: UserService,
    private auth: AuthService,
    private toastrService: ToastrService,
    private signInService: SigninService,
    private paymentService:PaymentService,
    private sanitize:DomSanitizer,
    public translate:TranslateService,
    private animatedBackground:animatedBackground,
    private changeDetector:ChangeDetectorRef,
    private offRampService:OffRampService,
    public formatCurrency:FormatCurrency,
    private theme: LyTheme2,
    private _dialog: LyDialog,
    private _cd: ChangeDetectorRef,
    private params: ActivatedRoute,
    private shepherdService: ShepherdService,
    private walletValidatorService:WalletValidatorService,
    private eventService: EventService
    ) { }

  //environments variables
  public currentSystemFiats:string[] = currentFiats;
  public availableExchanges = currentExchanges;

  // Server Response Variables Section
  public user!: User;
  public assets:Asset[] = [];
  public fiats:Asset[] = [];
  public allSystemAssets!:Asset[];
  public assetById: { [assetId: string]: Asset } = {}
  public assetsAndFiats!:Asset[];
  public userExchanges!: any;
  public userActiveWallets: string[] = [];
  public networkAssets:any[] = [];
  public metaMask:any;
  public metamaskAssets:any[] = [];
  public availableConnectors:ConnectorModel[] = [];
  public availableConnectorsForFiat!:ConnectorModel[] | undefined;
  public allUserConnections:any[] = [];
  public currentUserOffRampSettings:savedOffRampSettings[] = [];
  public offRampExchangeSettingsForUser: any[] = [];
  public showingUserConnections:any[] = [];
  public currentUserConnections:any[] = [];
  public JWTToken!: string;
  public userId!: string;
  public domain: any;
  public currentFee: number | undefined = 0;
  public maxTransferAmount:number | undefined = 0;
  private auth0User!:User;
  public autoOffRampPaymentReports!:boolean | undefined;
  public userAcceptedAssets:string[] = [];
  public FiatWithdrawalConfig:FiatWithdrawalConfigModel[] = [];
  public acceptedAssetsByExchange!:CryptoConnectionInfo[];
  public assetsOnExchange:{ [key: string]: CryptoConnectionAsset[] } = {};
  public offRampExchanges:string[] = [];
  private updateSetting$ = new Subject<Asset>();
  private debounceTimeInMs = 500;
  public confirmConnectionAdditionModal!:boolean;
  public askUserToConfigureRoutesModal!:boolean;
  public confirmExternalCryptoExchange!:string;

  // will be used while the system only supports USD
  public usdFiat:Asset[] = [];

  // user input variables
  public addressWallet!: any;
  public nameWallet!: any;
  public apiKey!: any;
  public apiSecret!: any;
  public privateWalletName!:string;
  public newWalletAddress:string = "";
  public exchangeId!:string;
  public exchangeName!:string;
  public convertToFiatPorcentage:number = 0;
  public balanceThreshold!:number;
  public minAmount!:number | undefined;
  public withdrawalAmount:number | string = "";
  public leaveMinimum:number | string = "";
  public country!:Country | undefined;
  public selectingCountry:boolean = false;
  public manualOffRampSettings:ManualOffRampDto = {
    amount: undefined,
    assetId: undefined,
    cryptoConnectionId: undefined
  };
  public maxAmount:number | undefined = undefined;
  public withdrawAll!:boolean;
  public notSupportManual!:boolean;
  
  @ViewChild('website') websiteInput!: ElementRef;
  @ViewChild('publicName') publicName!: ElementRef;
  @ViewChild('name') name!: ElementRef;
  @ViewChild('bio') bio!: ElementRef;
  @ViewChild('shortId') shortId!: ElementRef;
  @ViewChild('code') code!: ElementRef;
  @ViewChild('dnsName') dnsName!: ElementRef;
  @ViewChild('dnsType') dnsType!: ElementRef;
  @ViewChild('metaMaskLink')metaMaskLink!:ElementRef;
  @ViewChild('imageInput') imageInput!: ElementRef;
  public currentDeletingWallet!:WalletModel;
  public routingOptionName!:string | undefined;

  // options for the slider component
  options: Options = {
    floor: 0,
    ceil: 100,
    showTicks: true,
    ticksArray: [
      25 , 50, 75 ,100
    ],
    getLegend: (value: number): string => {
      return  value != 100 ? `<b class='steps-legend'>${value}%</b>` : '';
    },
    translate: (value: number, label: LabelType): string => {
          return `<b class='steps-legend'>${value}%</b>`;
    },
    // showTicksValues: true,
    draggableRange: true,
    step: 1
  };

  // Current option selects in the view
  currentOptionsSelects: {
    availableExchanges: optionSelectsModel[],
    connectionsAvailableExchanges: optionSelectsModel[],
    availableConnectorsForFiat: optionSelectsModel[],
    configurationExchange: optionSelectsModel[],
    anotherOptions: optionSelectsModel[],
    modalOptionsSelect:optionSelectsModel[],
    offRampExchangeSettingsForUser: optionSelectsModel[],
    assets:optionSelectsModel[],
    fiats:optionSelectsModel[],
    networkAssets:optionSelectsModel[],
    currentOffRampSettings: optionSelectsModel[],
    assetsAndFiats: optionSelectsModel[],
  } = {
    assetsAndFiats: [],
    availableExchanges: [],
    connectionsAvailableExchanges: [],
    anotherOptions: [],
    modalOptionsSelect: [],
    offRampExchangeSettingsForUser: [],
    assets: [],
    networkAssets:[],
    configurationExchange: [],
    fiats:[],
    availableConnectorsForFiat: [],
    currentOffRampSettings: []
  };


  //option select values variables
  public exchange: string | undefined = "Foxbit";
  public walletAsset:string = "ETH";
  public selectedNetwork:string = "BTC";
  public configurationExchange:any = "Foxbit"
  public connection:string = "";
  public selectedFiat:string = "USD.USD" ;
  public walletNetwork:string = "ETH";
  public selectedExchangeRule!:string | undefined;
  public currentBankWallet!:string;
  public automaticConversionFiat!:string | undefined;
  public withdrawDefaultFiat:string = "USD.USD"


  // view logics variables
  public numbersLettersAndSpecial!: RegExp;
  public atLeast8Digits!: RegExp;
  public atLeast1SpecialCharacter!: RegExp;
  public modalDeleteUser: boolean = false;
  public showModal: boolean = false;
  public domainIsLoaded = false;
  public userIsLoaded = false;
  public loading: boolean[] = [];
  public walletModal:boolean = false;
  public metaMaskWalletNames:any[] = [];
  public choosenMetaMaskAssets:string[] = [];
  public metaMaskAddress!:string;
  public metaMaskModal!:boolean;
  public choosenAssetName!:string;
  public defaultBackgroundPosition:number = 60;
  public customCurrencyMaskConfig:any;
  public isCommaDecimal = this.formatCurrency.isCommaDecimal(navigator.language);
  public selectedConnector!:WalletsForConnector;
  public addingRule!:boolean;
  public loadingConnections!:boolean;
  public currentOffRampSettings!:OffRampSettings;
  public selectedExchangeForSettings!:any;
  public connectorError: { errorMessage:string | undefined, errorCode:string | undefined } = {
    errorMessage : undefined,
    errorCode: undefined
  } ;
  public modalTittle!:string;
  public modalMessage!:string;
  public savedWallets:WalletModel[] = [];
  public currentWallet!:string;
  public offRampModal!:boolean;
  public automaticConversionSetModal:boolean = false;
  public choosenAsset!:string;
  public editBio!:boolean;
  public savingWallet!:boolean;
  public currentSelectedConnection:any;
  public isMobile!:boolean;
  public registerExchangeModal!:boolean;
  public registerSelecionModal!:boolean;
  public modalDeleteExchange!:boolean;
  public selectedLanguage!:string;
  private devEnvironment = devEnvironment;
  public testEnvironment = testEnvironment;
  public editingOffRampConnection!:boolean;
  public userCountry!:string;
  public enablingAsset:string | undefined = "USD";
  public helpModal!:boolean;
  public assetRule!:boolean;
  public selectedAsset!:Asset;
  public assetConfig: { minAmount?:number | string, maxAmount?:number | string,  leaveMinimum?:number | string} = {
    leaveMinimum: undefined,
    maxAmount: undefined,
    minAmount: undefined
  };
  public manualTourName!:string;
  private currentExchangeWallets:any = [];
  public currentAssetConfigs: { [key: string]: { cryptoDataSource: string, connection: string, name:string} } = {};
  public selectedAssetsForExchanges:string = "";
  public changedAssetsArray:any = [];
  public modalDeleteWallet!:boolean;
  private currentExchange:any;
  private currentStepId!:string |undefined;
  @ViewChildren('networkName') networkNameElements!: QueryList<ElementRef>;
  @ViewChildren('assetName') assetNameElements!: QueryList<ElementRef>;

  public currentSelectExchangeAssetOptions: { [key: string]: CryptoConnectionAsset[] } = {};
  public supportedNetworksOnExchange:string[] = [];
  public acceptedNetworksOnPrivateWallet:string[] = [];
  private systemSupportedNetworks = [ 'ETH', 'BTC', 'MATIC' ] 
  public privateWalletAddress!:string;
  public clipboardPermissions!:PermissionStatus;
  public clipboardDenied!:boolean;


  private tourStepLegend:any = undefined; 
  private nextButton:any = undefined;
  private backButton:any = undefined;
  private exitButton:any = undefined;
  private finishButton:any = undefined;
  
  public testModal!:boolean;

  private tourDefaults:any;
  private tourMobilePositionCheck:any;
  private tourPositionDefault:any;

  private defaultTourButtons: {
    haveBackButton:any[],
    haveFinishButton:any[],
    onlyNextButton:any[]
  } = {
    haveBackButton : [],
    haveFinishButton : [],
    onlyNextButton : []
  }

  sectionOffsets: { [key: string]: { offSet:number, height:number } } = {  };
  @ViewChild('generalDiv') generalDiv!: ElementRef;
  @ViewChild('domainDiv') domainDiv!: ElementRef;
  @ViewChild('tabsSelection') tabsSelection!: ElementRef;
  @ViewChild('offRampDiv') offRampDiv!: ElementRef;
  @ViewChild('routingDiv') routingDiv!: ElementRef;
  @ViewChild('connectionsDiv') connectionsDiv!: ElementRef;
  @ViewChild('securityDiv') securityDiv!: ElementRef;
  @ViewChild('automaticConversionDiv') automaticConversionDiv!: ElementRef;
  @ViewChild('privateWalletInput') privateWalletInput!: HTMLInputElement;


  //tabs logics variables
  public previousTab:string = 'General';
  public currentTab:string = 'General';
  public currentTabsXScroll:number = 0;
  public stopAutoScrollingFunction!:boolean;
  public tabs:string[] = [
    'General',
    'Connections',
    'Routing',
    'AutomaticConversion',
    'Off-Ramp',
    'Security'
  ]

  // 'Wallets',



  //Profile picture variables
  public logo:any;
  public image:any;
  public base64image!:any;
  public imageFile:any;
  public maxWidth:number = 300;
  public maxHeight:number = 300;
  public cropped?: string;

  public form: FormGroup = new FormGroup({
    password: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])[a-zA-Z\d!@#\$%\^&\*]{8,}$/)]),
    repeatPassword: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])[a-zA-Z\d!@#\$%\^&\*]{8,}$/)]),
  });

  async ngOnInit(): Promise<void> {

    this.checkWindowSize();
    this.setCurrentFiats();
    this.getSystemAssets();
    this.populateOptionSelects();
    this.populateTourLegendAsAButton();
    this.populateTourButton();
    this.populateTourDefaults();

    window.addEventListener("eip6963:announceProvider", (event: any) => {
      const walletProviderName = event.detail.info.name;
      console.log(walletProviderName)
      if (walletProviderName === "MetaMask") {     
        this.metaMask = event.detail;
      }
    });

    window.dispatchEvent(new Event("eip6963:requestProvider"));

    try {
    const permissionName = 'clipboard-read' as PermissionName;
    this.clipboardPermissions = await navigator?.permissions?.query({ name: permissionName });
    } catch (e) {
      this.clipboardDenied = true;
      console.log(e);
    }
  }

  ngAfterViewInit(): void {
    this.updateMaskConfig();
    this.getTokenWithCorrectAudience();
    this.populateRegexp();
    setTimeout(() => {
      if(this.isMobile){
        this.tabs.forEach((tab:any) => {
          let tabName = tab.charAt(0).toLowerCase() + tab.slice(1) + "Div";
          tabName = tabName.replace('-', '')
          try{
            if(this[tabName as keyof ProfileComponent]){
              const elementSizesObject = {
                offSet: this[tabName as keyof ProfileComponent]?.nativeElement.offsetTop || 0,
                height: this[tabName as keyof ProfileComponent]?.nativeElement.clientHeight || 0
              }
              this.sectionOffsets[tab] = elementSizesObject;
            }
          } catch (e) {
            console.log(e);
          };
        });
      }
    })

    if(!this.metaMask){
     this.addMetaMaskLink();
    }
    this.animatedBackground.animatedBackgroundTransition(this.defaultBackgroundPosition);

    this.params.queryParams.subscribe(params => {
      const { wallet, connectionTab, conversionAssetsTab, edit } = params;

      const tabMapping: { [key in 'wallet' | 'connectionsTab' | 'conversionAssetsTab']: string } = {
        wallet: 'Routing',
        connectionsTab: 'Connections',
        conversionAssetsTab: 'AutomaticConversion',
      };
      
      type TabKeys = keyof typeof tabMapping;
      
      (Object.keys(tabMapping) as TabKeys[]).forEach(key => {
        if (params[key]) {
          const tabName = tabMapping[key];
          if (this.isMobile) {
            this.changeCurrentTab(tabName, 'General');
          } else {
            this.currentTab = tabName;
          }
        }
      });      
      
    }
    )
  }

  async validatePrivateWallet() {
    this.acceptedNetworksOnPrivateWallet = [];
    const validNetworksForAsset = await this.walletValidatorService.validateAddress(this.privateWalletAddress);
    validNetworksForAsset.forEach((network:string) => {
      if (this.systemSupportedNetworks.includes(network)) {
        this.acceptedNetworksOnPrivateWallet.push(network);
      }
    })
  }

  populateTourLegendAsAButton() {
    this.tourStepLegend = {
      text: () => {
        const tourObject = this.shepherdService.tourObject;
        const stepsLength = tourObject.steps.length;
        const currentStep = this.shepherdService.tourObject.getCurrentStep();
        let stepIndex = -1; 
      
        if (currentStep) {
          stepIndex = tourObject.steps.findIndex(step => step.id === currentStep.id); 
        }

        return `${stepIndex + 1} ${this.translate.instant('of').toLowerCase()} ${stepsLength}`; 
      },
      classes: "sheperd-steps-legend"
    }
  }

  
  toggleBookIcon(open: boolean) {
    this.bookIconOpen = open;
  }

  setMaxWidth() {
    let maxWidth = 0;
    
    this.networkNameElements.forEach(el => {
      el.nativeElement.style.width = '';
      const width = el.nativeElement.offsetWidth;
      if (width > maxWidth) {
        maxWidth = width;
      }
    });
       
    this.networkNameElements.forEach(el => {
      el.nativeElement.style.width = `${maxWidth + 1}px`;
    });
  }

  setWalletNameMinWidth() {
    let maxWidth = 0;
    
    this.assetNameElements.forEach(el => {
      el.nativeElement.style.minWidth = '';

      const width = el.nativeElement.offsetWidth;
      if (width > maxWidth) {
        maxWidth = width;
      }
    });
   
    this.assetNameElements.forEach(el => {
      el.nativeElement.style.minWidth = `${maxWidth + (1)}px`;
    });
  }


  populateTourButton() {
    this.nextButton = {
      text: () => { return this.translate.instant("TOUR.next") },
      action: () => {
        return this.shepherdService.next();
      }
    };
    this.exitButton = {
      classes: 'exit-button',
      text: () => { return this.translate.instant("TOUR.exit") },
      action: () => {
                
        this.closeExchangeRegistrationModal();
        
        this.closeWalletModal();
        
        this._cd.detectChanges();
        this.cancelRuleAddition();

        setTimeout(() => {
          this.toggleBookIcon(false);
        }, 100); 

        return this.shepherdService.cancel();
      }
    }
    this.backButton = {
      classes: 'back-button secondary-button',
      text: () => { return this.translate.instant("TOUR.back") },
      action: () => {   
        this.toggleBookIcon(true);
        return this.shepherdService.back();
      }
    }
    this.finishButton = {
      classes: 'secondary-button',
      text: () => { return this.translate.instant("TOUR.finish") },
      action: () => {
        
        this.closeExchangeRegistrationModal();
        
        this.closeWalletModal();
        
        this._cd.detectChanges();
        this.cancelRuleAddition();

        setTimeout(() => {
          this.toggleBookIcon(false);
        }, 100); 

      return this.shepherdService.complete();
      }
    }
  }

  populateTourDefaults() {
    this.defaultTourButtons.haveBackButton = [
        this.tourStepLegend,
        this.exitButton,
        this.backButton,
        this.nextButton
    ]

     this.defaultTourButtons.haveFinishButton = [
        this.tourStepLegend,
        this.exitButton,
        this.backButton,
        this.finishButton
    ]

    this.defaultTourButtons.onlyNextButton = [
        this.tourStepLegend,
        this.exitButton,
        this.nextButton
    ]

    this.tourMobilePositionCheck = this.isMobile ? 'bottom' : 'right';

    this.tourPositionDefault = 'bottom';

    this.tourDefaults = {
      scrollTo: { behavior: 'smooth', block: 'center', inline: 'center' }
    }

    this.shepherdService.defaultStepOptions = {
      scrollTo: this.tourDefaults,
      cancelIcon: {
        enabled: false
      },
      canClickTarget: this.isMobile ? false : true,
      arrow: true,
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4
    }  
  }
  
  private setFocusOnInput() {
    
    const element = this.shepherdService.tourObject.getCurrentStep()?.getTarget();

    if(this.isMobile) {
      element?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      });
    }
 

    if( element?.id === this.currentStepId ) {
      return;
    }

    this.currentStepId = element?.id;
  
    if (!element) {
      return;
    }

    
    if (element.tagName.toLowerCase() === 'input' || element.tagName.toLowerCase() === 'textarea') {
      element.focus({
        preventScroll: this.isMobile ? false : true
      });
      return;
    }

    var inputElement = element.querySelector('input') || element.querySelector('textarea');
    
    if (inputElement) {
      inputElement.focus({
        preventScroll: this.isMobile ? false : true
      });
      return;
    }

  }

  //GENERAL TOUR

  startGeneralTour() {
    this.populateTourDefaults();
    const steps: StepOptionsButton.StepOptions[] = [
      {
        id: "introduction-account",
        attachTo: {
          element: '#introduction-account',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.onlyNextButton,

        beforeShowPromise: () => {
          this.toggleBookIcon(true);
          return Promise.resolve();
        },

        title: this.translate.instant("TOUR.welcomeToTheAccountSection"),
        text: this.translate.instant("TOUR.hereYouConfigureAllYourPersonalInformation")
      },
     /* {
        id: "user-photo",
        attachTo: {
          element: '#user-photo',
          on: this.tourMobilePositionCheck
        },


        buttons: this.defaultTourButtons.haveBackButton,
        title: this.translate.instant("TOUR.hereYouCanUploadYourPhoto"),
        text: this.translate.instant("TOUR.clickToUploadYourPhoto")
      },

      {
        id: "nick-input",
        attachTo: {
          element: '#nick-input',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,


        title: this.translate.instant("TOUR.thisIsYourNick"),
        text: this.translate.instant("TOUR.youCanDistributeItToYourFriends")
      },
      {
        id: "website",
        attachTo: {
          element: '#website',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,

        title: this.translate.instant("TOUR.hereYouCanPlaceYourWebsite"),
        text: this.translate.instant("TOUR.hereYouCanPlaceTheLinkToYourWebsite")
      },
      {
        id: "name",
        attachTo: {
          element: '#name',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,

        title: this.translate.instant("TOUR.hereYouSayYourName"),
        text: this.translate.instant("TOUR.hereYouEnterYourCompleteName"),

      },
      {
        id: "publicName",
        attachTo: {
          element: '#publicName',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,
        title: this.translate.instant("TOUR.hereYouSayYourPublicName"),
        text: this.translate.instant("TOUR.hereYouSayWhatYouWantToBeCalled")
      },

      {
        id: "bio",
        attachTo: {
          element: '#bio',
          on: this.tourMobilePositionCheck
        },

        // showOn() {
        //   return (window.innerWidth >  1100)
        // },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,
        title: this.translate.instant("TOUR.hereYouEnterYourBio"),
        text: this.translate.instant("TOUR.hereYouWriteALittleAboutYourself")
      },
*/
      //NEW! Matheus changes solicitations:  Do not explain each user information field, as they are already self-explanatory, just include 3 steps:
          // 1. Here you enter your personal data,
          // 2. What is UUID and what can it be used for,
          // 3. Save

      {
        id: "personal-data",
        attachTo: {
          element: '#personal-data',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.hereYouCanViewOrEnterYourPersonalData")
      },
      {
        id: "uniqueId",
        attachTo: {
          element: '#uniqueId',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,

        text: this.translate.instant("TOUR.thisIdIsYourUniqueIdentificationOnNicky"),
        ...this.tourDefaults
      },
/*
      {
        id: "countryTour",
        attachTo: {
          element: '#countryTour',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,
        title: this.translate.instant("TOUR.hereYouCanSelectYourCountry"),
        text: this.translate.instant("TOUR.clickToChooseYourCountry")
      },
*/
      {
        id: "saveGeneralButton",
        attachTo: {
          element: '#saveGeneralButton',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveFinishButton,

        highlightClass: this.tourDefaults.highlightClass,
        
        text: this.translate.instant("TOUR.yourPublicProfileWillBeCreatedBasedOnThem"),
        ...this.tourDefaults
      }

    ];

    this.startTour(steps);
  }

  getInformingPhrase() {
    const targetWebsitePath = '/support/creation-and-use-of-api-keys';
    const defaultLang = (localStorage.getItem('defaultLang') || 'pt-br') as string;
  
    let linkUrl;
    if (defaultLang === 'en') {
      linkUrl = `https://nicky.me${targetWebsitePath}`;
    } else {
      const translatedWebsiteLink = '/{{targetLanguage}}{{websitePath}}';
      linkUrl = `https://nicky-me.translate.goog${targetWebsitePath}?_x_tr_sl=en&_x_tr_tl=${defaultLang}&_x_tr_hl=${defaultLang}&_x_tr_pto=wapp`;
    }
  
    return linkUrl; 
  }

  //CONNECTION TOUR
  startConnTour() {

    if (this.currentTab != 'Connections') {
      this.currentTab = 'Connections';
    }

    const steps: StepOptionsButton.StepOptions[] = [
      {
        id: "intro-connections",
        attachTo: {
          element: '#intro-connections',
          on: this.tourMobilePositionCheck
        },

        beforeShowPromise: () => {
          this.toggleBookIcon(true);
          return Promise.resolve();
        },

        buttons: this.defaultTourButtons.onlyNextButton,

        title: this.translate.instant("TOUR.welcomeToTheConnectionsSection"),
        text: this.translate.instant("TOUR.hereYouConfigureAllYourConnections")
      },
      {
        id: "startConnTour",
        attachTo: {
          element: '#startConnTour',
          on: this.tourMobilePositionCheck
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          this.backButton,
          {
            text: this.translate.instant('TOUR.next'),
            action: () => {
              this.registerExchangeModal = true;
              setTimeout(() => {
                this.shepherdService.next();
              }, 100)
            }
          }
        ],
        
        text: this.translate.instant("TOUR.letsStart")
      },
      {
        id: "optionsConnTour",
        attachTo: {
          element: '#optionsConnTour',
          on: this.tourMobilePositionCheck
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          {
            classes: "back-button",
            text: () => { return this.translate.instant("TOUR.back") },
            action: () => {
              this.registerExchangeModal = false;
              setTimeout(() => {
                this.shepherdService.back();
              }, 10)
            }
          },
          {
            text: () => { return this.translate.instant("TOUR.next") },
            action: () => {
              document.getElementById('optionsConnTour')?.click();
              this.shepherdService.next();
            }
          }
        ],
        
        text: this.translate.instant("TOUR.youMayAddAsManyConnections")
      },
      {
        id: "apis-0",
        attachTo: {
          element: '#apiKeyTour',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.theApiKeyAndSecretWillAllow")
      },
      {
        id: "apis-1",
        attachTo: {
          element: '#apiKeyTour',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.youCanAlsoAllowNickyToHandleSending")
      },
      {
        id: "apis-2",
        attachTo: {
          element: '#apiSecretTour',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.checkTheExchangeDocumentation")
      },

        // Withdrawal Step
        {
          id: "withdrawal",
          attachTo: {
              element: '#withdrawalTour',
              on: this.tourMobilePositionCheck
          },

          buttons: this.defaultTourButtons.haveBackButton,

          text: this.translate.instant("TOUR.toSetupWithdrawals")
        },
        // API Key Tour Step
        {
          id: "api-key",
          attachTo: {
              element: '#apiKeyTour',
              on: this.tourMobilePositionCheck
          },

          buttons: this.defaultTourButtons.haveBackButton,

          text: this.translate.instant("TOUR.forUseAPI", { linkUrl: this.getInformingPhrase() })
        },
        {
            id: "name",
            attachTo: {
              element: '#nameTour',
              on: this.tourMobilePositionCheck
            },

            buttons: this.defaultTourButtons.haveBackButton,

            text: this.translate.instant("TOUR.theNameIsCompletelyOptional")
        },
        {
            id: "addButtonTour",
            attachTo: {
              element: '#addButtonTour',
              on: this.tourMobilePositionCheck
            },

            buttons: this.defaultTourButtons.haveFinishButton,

            text: this.translate.instant("TOUR.whenClickingOnAddConnection")
        }
      ];

      this.startTour(steps);
  }

  //DISABLED ROUTING TOUR
  startDisabledRoutingTour() {
   
  if (this.shepherdService.isActive) {
    this.shepherdService.complete();
  }

    const steps: StepOptionsButton.StepOptions[] = [
      
      {
        id: "startWalletTour",
        attachTo: {
          element: '#start-wallet',
          on: this.tourMobilePositionCheck
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          {
            text: this.translate.instant('TOUR.back'),
            classes: "back-button",
            action: () => {
                setTimeout(() => {
                  this.shepherdService.back();
                  this.startWalletTour();
                  this.shepherdService.complete();
                }, 100);
            return this.shepherdService.show('introduction-wallet')
          }
          },
          this.nextButton
        ],

        highlightClass: this.tourDefaults.highlightClass,

        text: this.translate.instant("TOUR.ifEnabledClickThisButtonToAddaNewWalletThenNicky")
      },
      {
        id: "wallet-select",
        attachTo: {
          element: '#wallet-select',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,
        highlightClass: this.tourDefaults.highlightClass,

        text: this.translate.instant("TOUR.nickyWillShowYouAListOfAssets")
      },
      {
        id: "options-select",
        attachTo: {
          element: '#options-select',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,
        text: this.translate.instant("TOUR.eachAssetIsListedWithItsWalletProvider")
      },
      {
        id: "disabled-routes",
        attachTo: {
          element: '#disabled-routes',
          on: this.tourPositionDefault
        },
        
        buttons: this.defaultTourButtons.haveFinishButton,

        highlightClass: this.tourDefaults.highlightClass,
        
        text: this.translate.instant("TOUR.nowYouKnowHowEditYouRoutes")
      }

    ];

    this.startTour(steps);
  }
    
  //ROUTING TOUR
  startWalletTour() {
      
  if (this.shepherdService.isActive) {
    this.shepherdService.complete();
  }
    const steps: StepOptionsButton.StepOptions[] = [
      {
        id: "introduction-wallet",
        attachTo: {
          element: '#introduction-wallet',
          on: this.tourMobilePositionCheck
        },
        
        beforeShowPromise: () => {
          this.toggleBookIcon(true);
          return Promise.resolve();
        },

        buttons: [          
          this.tourStepLegend,
          this.exitButton,
          {
            text: this.translate.instant('TOUR.next'),
            action: () => {
              const editRoutesButton = document.getElementById('start-wallet');
              if (editRoutesButton && !editRoutesButton.classList.contains('disabled')) {
              
                setTimeout(() => {
                  this.shepherdService.next();
                }, 10);

              } else {
                this.shepherdService.complete();
                this.closeWalletModal();
                this.startDisabledRoutingTour();
              }
            }
          }
      ],

        highlightClass: this.tourDefaults.highlightClass,
        title: this.translate.instant("TOUR.welcomeToTheRoutingSection"),
        text: this.translate.instant("TOUR.thisIsWhereYouConfigureTheRoutes")
      },
      {
        id: "what-is",
        attachTo: {
          element: '#what-is',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,

        title: this.translate.instant("TOUR.whatIsARoute"),
        text: this.translate.instant("TOUR.inNickyaRouteIsAConfiguration")
      },
      {
        id: "startWalletTour",
        attachTo: {
          element: '#start-wallet',
          on: this.tourMobilePositionCheck
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          this.backButton,
          {
            text: this.translate.instant('TOUR.next'),
            action: () => {
              this.openAssetWalletConfigurationModal();
                setTimeout(() => {
                  this.shepherdService.next();
                }, 100); 
              }         
          },
        ],

        highlightClass: this.tourDefaults.highlightClass,

        text: this.translate.instant("TOUR.clickThisButtonToAddaNewWallet")
      },
      {
        id: "wallet-select",
        attachTo: {
          element: '#wallet-select',
          on: this.tourMobilePositionCheck
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          {
            text: this.translate.instant('TOUR.back'),
            classes: "back-button",
            action: () => {
              setTimeout(() => {
                  this.shepherdService.back();
              }, 10);
              this.closeWalletModal();
            }
          },
          this.nextButton
        ],

        highlightClass: this.tourDefaults.highlightClass,

        text: this.translate.instant("TOUR.clickToAddARouting")
      },
      {
        id: "list-assets",
        attachTo: {
          element: '#wallet-select',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,
        text: this.translate.instant("TOUR.eachAssetIsListedWithItsWalletProvider")
      },
      {
        id: "cancelButtonTour",
        attachTo: {
          element: '#cancelButtonTour',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        highlightClass: this.tourDefaults.highlightClass,
        text: this.translate.instant("TOUR.ifYouDoNotWalletToDoThisNow")
      },
      {
        id: "saveButtonTour",
        attachTo: {
          element: '#saveButtonTour',
          on: this.tourMobilePositionCheck
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          this.backButton,
          this.finishButton
        ],
        highlightClass: this.tourDefaults.highlightClass,
        text: this.translate.instant("TOUR.readyYourWalletHasBeenAdded")
      }

    ];

    this.startTour(steps);
  }

  //PAYMENT CONVERSIONS TOUR
  paymentConversionsTour() {
    const steps: StepOptionsButton.StepOptions[] = [
      {
        id: "intro-paymentConversions",
        attachTo: {
          element: '#intro-paymentConversions',
          on: this.tourMobilePositionCheck
        },

        beforeShowPromise: () => {
          this.toggleBookIcon(true);
          return Promise.resolve();
        },

        buttons: this.defaultTourButtons.onlyNextButton,

        title: this.translate.instant("TOUR.welcomeToThePaymentConversionsSection"),
        text: this.translate.instant("TOUR.paymentConversionsReferToTheProcess")
      },  
      {
        id: "payment-conversions",
        attachTo: {
          element: '#payment-conversions',
          on: this.tourMobilePositionCheck
        },

        beforeShowPromise: () => {
          this.toggleBookIcon(true);
          return Promise.resolve();
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.inThisSectionYouEnable")
      },
      {
        id: "assets-select",
        attachTo: {
          element: '#assets-select',
          on: this.tourMobilePositionCheck
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          {
            text: this.translate.instant('TOUR.back'),
            classes: "back-button",
            action: () => {
              this.walletModal = false;
              setTimeout(() => {
                this.shepherdService.back();
              }, 10)
            }
          },

          this.nextButton

        ],
        highlightClass: this.tourDefaults.highlightClass,

        text: this.translate.instant("TOUR.youCanChooseItAsAPaymentAsset")
      },
      {
        id: "asset-toggle",
        attachTo: {
          element: '#asset-toggle',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveFinishButton,

        highlightClass: this.tourDefaults.highlightClass,
        
        text: this.translate.instant("TOUR.byClickingTheOnOffButton")
      },
    ];

    this.startTour(steps);
  }

  //SECURITY TOUR
  securityTour() {
    const steps: StepOptionsButton.StepOptions[] = [
      {
        id: "change-password",
        attachTo: {
          element: '#change-password',
          on: this.tourMobilePositionCheck
        },

        beforeShowPromise: () => {
          this.toggleBookIcon(true);
          return Promise.resolve();
        },

        buttons: this.defaultTourButtons.onlyNextButton,

        text: this.translate.instant("TOUR.ifYouWantToChangeYourNickypassword")
      },
      {
        id: "delete-account",
        attachTo: {
          element: '#delete-account',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveFinishButton,
        highlightClass: this.tourDefaults.highlightClass,
        
        text: this.translate.instant("TOUR.byClickingThisButtonYouPermanentlyDelete")
      },
    ];

    this.startTour(steps);
  }

  //BANK WITHDRAWAL TOUR
  bankWithdrawalTour() {
    const steps: StepOptionsButton.StepOptions[] = [
      {
        id: "start-bankTour",
        attachTo: {
          element: '#start-bankTour',
          on: this.tourMobilePositionCheck
        },

        beforeShowPromise: () => {
          this.toggleBookIcon(true);
          return Promise.resolve();
        },
    
        buttons: this.defaultTourButtons.onlyNextButton,

        title: this.translate.instant("TOUR.whatIsAGlobalWithdrawal"),
        text: this.translate.instant("TOUR.nickyCanTakeCareOfYourCripto")
      },
      {
        id: "conversion-rules",
        attachTo: {
          element: '#conversion-rules',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.hereYouShouldSetupTheCriteria")
      },
      {
        id: "check-fiat",
        attachTo: {
          element: '#check-fiat',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.thisIsTheTargetCurrency")
      },
      {
        id: "global-withdrawal",
        attachTo: {
          element: '#global-withdrawal',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.thisIsTheMinimumValueForNicky")
      },
      {
        id: "activate-settings",
        attachTo: {
          element: '#activate-settings',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.ifDisabled")
      },
      {
        id: "check-activate",
        attachTo: {
          element: '#check-activate',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.thisIsWhereYouSetupYourBankAccounts")
      },
      {
        id: "add-configuration",
        attachTo: {
          element: '#add-configuration',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveFinishButton,

        highlightClass: this.tourDefaults.highlightClass,
        
        text: this.translate.instant("TOUR.justClickTheButtonToAddANewWithdrawal")
      },
    ];

    this.startTour(steps);
  }

  //ADD BANK WITHDRAWAL CONFIGURATION TOUR
  addConfigurationTour() {
    const steps: StepOptionsButton.StepOptions[] = [
      {
        id: "start-addConfigurationTour",
        attachTo: {
          element: '#start-addConfigurationTour',
          on: this.tourMobilePositionCheck
        },

        beforeShowPromise: () => {
          this.toggleBookIcon(true);
          return Promise.resolve();
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          {
            text: this.translate.instant('TOUR.next'),
            action: () => {

              setTimeout(() => {
                const firstOption = this.currentOptionsSelects.offRampExchangeSettingsForUser[1].value;
                if (firstOption) {
                  this.selectedFiat = firstOption;
                  this.startAddingRule();
                  this._cd.detectChanges();
                }

                this.shepherdService.next();
              }, 500);
            }
          }
        ],

        title: this.translate.instant("TOUR.hereYouConfigureTheWithdrawalRule"),
        text: this.translate.instant("TOUR.eachRuleDefinesARoute"),
      },
      {
        id: "selectedFiat",
        attachTo: {
          element: '#selectedFiat',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.theWithdrawalWillBeCarriedOut")

      },
      {
        id: "current-bank",
        attachTo: {
          element: '#current-bank',
          on: this.tourMobilePositionCheck
        },

        buttons: this.defaultTourButtons.haveBackButton,

        text: this.translate.instant("TOUR.chooseWhichAccountShallBeUsedForThisRule")

      },
      {
        id: "save-configuration",
        attachTo: {
          element: '#save-configuration',
          on: this.tourMobilePositionCheck
        },

        buttons: [
          this.tourStepLegend,
          this.exitButton,
          this.backButton,
          {
            text: this.translate.instant('TOUR.finish'),
            action: () => {
              setTimeout(() => {
                this._cd.detectChanges();
                this.cancelRuleAddition();
                this.toggleBookIcon(false);
                this.shepherdService.complete();
              }, 500);
            }
          }
        ],

        highlightClass: this.tourDefaults.highlightClass,
        
        text: this.translate.instant("TOUR.clickHereToFinishCreatingYourRule")
      },
    ];

    this.startTour(steps);
  }

  private startTour(steps: StepOptionsButton.StepOptions[]) {
    steps.forEach(step => {
      if (!step.when) {
       step.when = {
        show: () => {
          const currentStepText = step.text;
          const sheperdObject = this.shepherdService.tourObject; 
          const stepIndex = this.shepherdService.tourObject.steps.findIndex(currentStep => currentStep.id === step.id);
          
          if(this.isMobile) {
            const element = this.shepherdService.tourObject.steps[stepIndex].getElement();
            if (element) element.focus = () => { };
            element?.scrollTo();
          }
          
          if (stepIndex === steps.length - 1) {
            this.shepherdService.tourObject.steps[stepIndex].updateStepOptions({
              text: currentStepText + "<img src='/assets/white-nicky-logo.png' class='tour-logo'>"
            });
          }
          setTimeout(() => {
            this.setFocusOnInput();
          }, 500)
        }
       }
      } 
    });
    this.shepherdService.addSteps(steps)

    if( this.isSocialUser() ) {
      this.shepherdService.tourObject.removeStep('change-password');

      // this.shepherdService.tourObject.getById('change-password')?.destroy();
    }

    this.shepherdService.start();
  }

  getAwareText() {
    return this.translate.instant('ifYouAreAwareOfThis');
  }

  getCountry(){
  }

  updateLanguage(language:string){
    this.selectedLanguage = language;
  }

  toggleMenu(){
    toggleMenu()
  }

  itsDevEnvironment(){
    return this.devEnvironment;
  }

  populateOptionSelects() {
    const showAllRules = {
      name: 'PROFILE.showAllRules',
      value: undefined
    };

    const generateOptions = (exchange:any) => ({
      name: exchange,
      value: exchange
    });

    const exchangesOptions = this.availableExchanges.map(generateOptions);

    this.currentOptionsSelects.availableExchanges = [...exchangesOptions];
    this.currentOptionsSelects.connectionsAvailableExchanges = [...exchangesOptions];
    this.currentOptionsSelects.modalOptionsSelect = [...exchangesOptions];

    this.currentOptionsSelects.availableExchanges.unshift(showAllRules);
    this.currentOptionsSelects.connectionsAvailableExchanges.unshift({
      name: 'PROFILE.showAllConnections',
      value: ''
    });
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.isMobile && !this.stopAutoScrollingFunction) {
      const scrollPosition = window.scrollY;
      const marginBottom = document.documentElement.clientHeight * 0.04;
	      for (let i = this.tabs.length - 1; i >= 0; i--) {
        const section = this.tabs[i];
        let tabName = section.charAt(0).toLowerCase() + section.slice(1) + "Div";
        tabName = tabName.replace('-', '')
        try {
          const currentNativeElement = this[tabName as keyof ProfileComponent]?.nativeElement;
          if (this.isElementInViewport(currentNativeElement)) {
            this.currentTab = section
            break;
          }
        } catch (error) {
          console.log(error)
        } 

        if (this.reachedVerticalMaxScroll()) {
          this.currentTab = this.tabs[this.tabs.length - 1];
          break;
        }
      };
      this.horizontalTabsScroll();
    }
  }

  formatWalletProviderName(asset: Asset | BlockchainAsset) {
    
    var currentAssetRouteConfig = this.currentAssetConfigs[asset.id]; 
    
    if(this.selectedAssetsForExchanges.includes(asset.id)) {
      if(!this.currentExchange?.cryptoDataSource || this.currentExchange?.cryptoDataSource === "Manual" || this.configurationExchange === "Manual") {
        return this.privateWalletName;
      } 
      return this.currentExchange?.cryptoDataSource;
    }

    if(currentAssetRouteConfig?.cryptoDataSource === "Manual") {
      return currentAssetRouteConfig.name
    }
    
    return currentAssetRouteConfig?.cryptoDataSource || '';
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize()
  }

  scrollToSection(section: ElementRef, currentSection:string) {
    // const yScrollAmount = (section.nativeElement.getBoundingClientRect().top + window.scrollY);
    const waitingTime = Math.abs(this.tabs.indexOf(this.currentTab) - this.tabs.indexOf(currentSection)) * 500;

    // let percentAmount = 0.905;
    // if(this.currentTab == 'General'){
    //   percentAmount = 0;
    // }
    // if(this.currentTab == 'Security'){
    //   percentAmount = 1.5;
    // }

    this.horizontalTabsScroll();
    this.stopAutoScrollingFunction = true;

    section.nativeElement.scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'start'
  });

    // window.scroll({
    //   top: yScrollAmount * percentAmount,
    //   behavior: 'smooth'
    // });
    setTimeout(() => {
      this.stopAutoScrollingFunction = false;
    }, waitingTime)
  }

  horizontalTabsScroll(){
    const previousTabIndex = this.tabs.indexOf(this.previousTab);
    const currentTabIndex = this.tabs.indexOf(this.currentTab);
    const isNexTab = previousTabIndex < currentTabIndex;

    if(previousTabIndex == currentTabIndex) {
      return
    }
  

    const xScrollAmount = ( currentTabIndex - previousTabIndex) * 100;
    this.currentTabsXScroll += xScrollAmount;

    if(this.tabs.indexOf('AutomaticConversion') < currentTabIndex){
       this.currentTabsXScroll * 1.5
    }

    if(this.selectedLanguage === "pt-br" && this.currentTab === 'Connections'){
      this.currentTabsXScroll *= 0.8;
    }

    this.tabsSelection.nativeElement.scrollTo({
      left: this.currentTabsXScroll,
      right: undefined,
      behavior: 'smooth'
    });

    this.previousTab = this.currentTab;
  }

  getTabName(tab:string){
    if(tab === "AutomaticConversion"){
      return this.translate.instant(`paymentReportConversion`);
    }

    return this.translate.instant('PROFILE.' + tab);
  }

  isElementInViewport(currentNativeElement: HTMLElement): boolean {
    const rect = currentNativeElement.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    const isCompletelyVisible = (rect.top >= 0) && (rect.bottom <= windowHeight);

    const isAtMiddleOfScreen = rect.top <= (windowHeight / 2) && rect.bottom >= (windowHeight / 2);

    return isCompletelyVisible || isAtMiddleOfScreen;
  }


  reachedVerticalMaxScroll(): boolean {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;
    return scrollTop + clientHeight >= scrollHeight;
  }

  calculateActualLength(i:number){
    return 0.04;
  }


  updateMaskConfig (){
    this.customCurrencyMaskConfig = {
      align: "center",
      allowNegative: false,
      allowZero: false,
      decimal: this.isCommaDecimal ? ',' : '.',
      precision: 2,
      prefix: "",
      suffix: "",
      thousands: this.isCommaDecimal ? '.' : ',',
      nullable: false,
      min: null,
      max: null,
      inputMode: NgxCurrencyInputMode.Financial
    }
  }

  checkWindowSize() {
    if (window.innerWidth > 1100){
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  getAvailableConnectors() {
    this.loading.push(true);
    this.offRampService.getAvailableConnectors(this.JWTToken).subscribe({
      next: (response:any) => {
        this.availableConnectors = [];
        // While Coinbase is disabled in prod/test environment 
        response.forEach((connector:ConnectorModel) => {
          this.availableConnectors.push(connector);
        });
        this.setUserOffRampOptions();
        this._cd.detectChanges();
        this.loading.pop();
      },
      error: (e:any) => {
        console.log(e);
        this.loading.pop();
      }
    })
  }

  setCurrentFiats(){
    this.currentSystemFiats.forEach((fiat) => {
      this.currentOptionsSelects.fiats.push({
        name: fiat.split(".")[0],
        value: fiat
    })})
  }

  setUserOffRampOptions(){
    this.availableConnectors?.forEach((connector:ConnectorModel) => {

      let connectorSavingOptions = {
        name: connector.name,
        exchange: connector.cryptoDataSource,
        id: connector.id
      }

        this.offRampExchangeSettingsForUser.push({
          connectorSavingOptions,
          fiat: this.withdrawDefaultFiat
        })

        
      this.setExchangeSettingsOptions();

      this.selectedFiat = "undefined";
    
  })
  }

  setWithdrawAll(){
    this.withdrawAll = !this.withdrawAll;
    if(this.withdrawAll){
      this.manualOffRampSettings.amount = undefined;
    }
  }

  getManualOffRampFee(){
    this.getOffRampFees(this.manualOffRampSettings.cryptoConnectionId, this.manualOffRampSettings.assetId);
  }

  getOffRampFees(connectionId:string | undefined, assetId:any){
    const feesInfo:withdrawFee =  {
      amount: 1000,
      cryptoConnectionId: connectionId,
      assetId: assetId
    }
    this.offRampService.getOffRampFees(this.JWTToken, feesInfo).subscribe({
      next:(response:any) => {
        this.currentFee = response.fee || 0;
        this.maxTransferAmount = response?.limit || 0;
        this.notSupportManual = false;
      },
      error: (e:any) => {
        this.currentFee = undefined;
        this.notSupportManual = true;
        console.log(e)
      }
    }
    )
  }

  setExchangeSettingsOptions(){
    this.currentOptionsSelects.offRampExchangeSettingsForUser = [];
    
    this.currentOptionsSelects.offRampExchangeSettingsForUser.push({
      name: `Select an exchange`,
      value: "undefined",
    });

    this.offRampExchangeSettingsForUser.forEach(exchangeSetting => {
      this.currentOptionsSelects.offRampExchangeSettingsForUser.push({
        name: `${ exchangeSetting.connectorSavingOptions.exchange} - ${(exchangeSetting.connectorSavingOptions?.name || "" )}`,
        value: this.getConnectionIdAndFiat(exchangeSetting),
      })
    });
  }

  getConnectionIdAndFiat(exchangeSettingForUser:any){
    if(!exchangeSettingForUser){
      return "undefined";
    }
    return exchangeSettingForUser.connectorSavingOptions.id + ' ' + this.withdrawDefaultFiat
  }

  noCurrentUserOffRampSettings(){
    if(this.currentUserOffRampSettings && this.currentUserOffRampSettings.length < 1){
      return true;
    }
    return false;

  }

  getOffRampAllSettings() {
    this.loading.push(true);
    this.offRampService.getOffRampAllSettings(this.JWTToken).subscribe({
      next: (response:any) => {
        this.currentUserOffRampSettings = [];
        response.forEach((setting:savedOffRampSettings) => {
          if(setting.isEnabled)
            this.currentUserOffRampSettings.push(setting)
        });
        this.currentUserOffRampSettings.forEach((offRampSetting:savedOffRampSettings) =>
        {
            this.currentOptionsSelects.currentOffRampSettings.push({
              name: offRampSetting.cryptoConnection.name || undefined,
              value: offRampSetting.cryptoConnection.id
            })
        }
        )
        this.manualOffRampSettings.cryptoConnectionId = this.currentOptionsSelects.currentOffRampSettings[0]?.value || "";
        this.loading.pop();
      },
      error: (e:any) => {
        console.log(e);
        this.loading.pop();
      }
    })
  }

  startAddingRule() {
    this.assetRule = false;
    this.addingRule = true;
    this.editingOffRampConnection = false;
    this.minAmount = 0;
    // this.maxAmount = undefined;
    const currentConfig: WalletsForConnector = {
      cryptoConnectionId: this.selectedFiat.split(' ')[0],
      assetId: this.selectedFiat.split(' ')[1],
    }
    this.getWalletsForConnector(currentConfig)
  };

  cancelRuleAddition(){
    this.addingRule = false;
    this.editingOffRampConnection = false;
    this.minAmount = 0;
    // this.maxAmount = undefined;
  }

  getExchangeName(exchangeId:string){
    this.selectedExchangeForSettings = this.availableConnectors?.filter((connector) =>  connector.id == this.selectedExchangeRule)[0];
    return this.selectedExchangeForSettings.name || this.selectedExchangeForSettings.cryptoDataSource;
  }

  getWalletsForConnector(connection:WalletsForConnector) {
    this.loadingConnections = true;
    this.offRampService.getWalletsForConnector(this.JWTToken, connection).subscribe({
      next: (response:any) => {
        this.availableConnectorsForFiat = [];
        this.currentOptionsSelects.availableConnectorsForFiat = [];
        this.availableConnectorsForFiat = response;
        response.forEach((bankWallet:any) => {
          this.currentOptionsSelects.availableConnectorsForFiat.push({
            name: `${bankWallet.walletId} ${bankWallet.method}` ,
            value: bankWallet.walletId
          })
        });
        this.getOffRampFees(connection.cryptoConnectionId, connection.assetId);
        this.currentBankWallet = (response[0]?.walletId || '') ;
        this.loadingConnections = false;
      },
      error: (e:any) => {
        this.availableConnectorsForFiat = undefined
        this.currentOptionsSelects.availableConnectorsForFiat = [];
        if(e?.error?.ErrorCode == "NotEnoughPermissionsException"){
          this.connectorError.errorMessage = this.translate.instant("PROFILE.NotEnoughPermissionsException");
          this.connectorError.errorCode = e?.error?.ErrorCode;
        } else {
          this.connectorError.errorMessage = this.translate.instant("PROFILE.thisConnectionDoesntSupport");
          this.connectorError.errorCode = undefined;
        }
        this.loadingConnections = false;
      }
    })
  }

  setOffRampSettings(){
    this.currentOffRampSettings = {
      cryptoConnectionId: this.selectedFiat.split(' ')[0],
      blockchainAssetId: this.selectedFiat.split(' ')[1],
      walletId: this.currentBankWallet,
      isEnabled: true,
      minAmount: this.minAmount,
      // maxAmount: this.maxAmount
    }

    // if(this.minAmount && this.maxAmount && this.minAmount > this.maxAmount){
    //   this.toastrService.error(this.translate.instant('maxAmountShouldBeGreaterThanTheTreshold'));
    //   return;
    // }
    this.loading.push(true);

    this.offRampService.setOffRampingSettings(this.JWTToken, this.currentOffRampSettings).subscribe({
      next: (response:any) => {
        this.getOffRampAllSettings();
        this.toastrService.success(this.translate.instant('PROFILE.successFullyAddedANewOffRampSetting'));
        this.addingRule = false;
        this.minAmount = 0;
        // this.maxAmount = undefined;
        this.loading.pop();
        this.automaticConversionSetModal = false;
      },
      error: (e:any) => {
        this.loading.pop();
      }
    })
  }

  getAcceptedAssets(){
    this.userService.getAcceptedAssets(this.JWTToken).subscribe({
      next: (assets:any) => {
       this.setActiveAssets(assets);
      },
      error: (e:any) => {
        this.loading.pop();
      }
    })
  }


  toggleAssetStauts(asset: Asset): void {
    if(this.loading.length > 1){
      return;
    }
    if(asset.active === true){
      if(this.userAcceptedAssets.length < 2){
        this.toastrService.error(this.translate.instant('thisOperationCannotBeCarriedOut'));
        return;
      }
      const index = this.userAcceptedAssets.indexOf(asset.id);
      if (index > -1) {
        this.userAcceptedAssets.splice(index, 1);
      }
      this.setAcceptedAssets(this.userAcceptedAssets);
      asset.active = false;
    } else {
      this.userAcceptedAssets.push(asset.id)
      this.setAcceptedAssets(this.userAcceptedAssets);
      asset.active = true;
    }
    // this.updateLocalStorage(asset, toggle);
  }

  setAcceptedAssets(assets:string[]){
    this.loading.push(true);
    this.userService.setAcceptedAssets(this.JWTToken, assets).subscribe({
      next: (assets:any) => {
        this.loading.pop();
      },
      error: (e:any) => {
        this.loading.pop();
      }
    })
  }

  setActiveAssets(assets:Asset[]){
    this.userAcceptedAssets = assets
    .filter((asset) => !asset.id.includes("MATIC") || asset.assetTicker == 'POL')
    .map((asset) => asset.id);
    this.assetsAndFiats?.forEach((asset:Asset) => {
      if(this.userAcceptedAssets.includes(asset.id)){
        asset.active = true;
      }
    })
  }


  testLocalStorageMock(){
    if(localStorage.getItem('assets')?.trim() != ""){
      return false
    }
    return true;
  }

  confirmAutoSettingsForAutomaticConversion(){
    this.automaticConversionFiat = this.selectedFiat.split(' ')[1],
    this.convertToFiatPorcentage = 100;
    this.saveAutomaticConversionInfo(true);
  }

  setPaymentReportOffRampSetting(fiat:Asset){
    const userHasSomeActiveRule = this.usdFiat?.some(fiat => fiat.activeRule === true);
    const settings:ProfileDto = {
      autoOffRampPaymentReports: userHasSomeActiveRule,
      defaultFiatCurrencyId: "USD.USD"
    }
    this.loading.push(true);
    this.userService.editGeneralUserInfo(this.JWTToken, settings).subscribe({
      next: (response:any) => {
        this.user = response;
        this.loading.pop();
      },
      error: (e:any) => {
      }
    })
  }

  scrollToAssetRule(){
      setTimeout(() => {
        const element = document.getElementsByClassName('conversion-rules-table');
        if (element && this.isMobile) {
          element[0].scrollIntoView({  
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          });
        }
      }, 200)
    
  }

  updateSetting(fiat: Asset) {
    this.updateSetting$.next(fiat);
  }

  editOffRampSetting(currentOffRampSettings:savedOffRampSettings){
    this.selectedFiat =  currentOffRampSettings.cryptoConnection.id + ' ' + currentOffRampSettings.blockchainAssetId;
    this.minAmount = currentOffRampSettings.minAmount;
    // this.maxAmount = currentOffRampSettings.maxAmount;
    this.editingOffRampConnection = true;
    this.addingRule = true;
    const currentConfig:WalletsForConnector = {
      cryptoConnectionId: this.selectedFiat.split(' ')[0] ,
      assetId: this.selectedFiat.split(' ')[1],
    }
    this.getWalletsForConnector(currentConfig);
  }

  disableSetting(currentOffRampSettings:savedOffRampSettings){
    const disableSetting:OffRampSettings = {
      cryptoConnectionId: currentOffRampSettings.cryptoConnection.id,
      blockchainAssetId: currentOffRampSettings.blockchainAssetId,
      walletId: currentOffRampSettings.walletId,
      isEnabled: false,
      treshold: currentOffRampSettings.treshold ,
    }
    this.loading.push(true);
    this.offRampService.setOffRampingSettings(this.JWTToken, disableSetting).subscribe({
      next: (response:any) => {
        this.getOffRampAllSettings();
        // this.addingRule = false;
        this.loading.pop();
      },
      error: (e:any) => {
        this.loading.pop();
      }
    })
  }

  filteredOffRampRules(){
    if(this.selectedExchangeRule == undefined){
      return this.currentUserOffRampSettings
    }
    return this.currentUserOffRampSettings.filter((offRampSetting:savedOffRampSettings) =>
      offRampSetting.cryptoConnection.cryptoDataSource == this.selectedExchangeRule
    )
  }

  canSaveOffRamp(){
    if( this.selectedFiat && this.connectorSupportsCurrentFiat()){
      return true;
    } else {
      return false;
    }
  }

  startAddingAssetRule(){
    // const currentConfig = this.FiatWithdrawalConfig.filter((withdrawalConfig:FiatWithdrawalConfigModel) => withdrawalConfig.blockchainAssetId === fiat.id)[0];
    this.addingRule = false;
    this.assetRule = true; 
    this.scrollToAssetRule();
  }


  getAllFiatWithdrawalConfig(){
    this.offRampService.getFiatWithdrawalConfig(this.JWTToken).subscribe({
      next: (response:any) => {
        this.FiatWithdrawalConfig = response;
        this.fillActiveWithdrawalConfigs();
      },
      error: (e:any) => {
        console.log(e)
      }
    })
  }

  fillActiveWithdrawalConfigs(){
    let currentFiats:string[] = [];
    this.FiatWithdrawalConfig.forEach( (withdrawalConfig:FiatWithdrawalConfigModel) => {
      this.fiats.forEach((fiat:Asset) => {
        if((withdrawalConfig.blockchainAssetId == fiat.id) && !currentFiats.includes(fiat.id)){
          fiat.minAmount = withdrawalConfig.minAmount;
          fiat.activeRule = withdrawalConfig.isEnabled;
          currentFiats.push(fiat.id);
        }
      })
    })
  }

  saveFiatWithdrawalConfig(fiat:Asset){
    if(this.loading.length > 0) {
      return;
    }
    
    if(!fiat.minAmount){
      fiat.minAmount = 0;
    }    

    const fiatWithdrawalConfig:FiatWithdrawalConfigModel = {
      isEnabled: fiat.activeRule || false,
      minAmount: fiat.minAmount || 0,
      blockchainAssetId: fiat.id,
    };
    
    this.setFiatWithdrawalConfigStatus(fiatWithdrawalConfig, fiat);
    
  }

  removeFiatWithdrawalConfig(fiat:Asset){
    const fiatWithdrawalConfig:FiatWithdrawalConfigModel = {
      isEnabled: false,
      minAmount: 0,
      blockchainAssetId: fiat.id,
    };
    this.setFiatWithdrawalConfigStatus(fiatWithdrawalConfig, fiat);
  }

  setFiatWithdrawalConfigStatus( fiatWithdrawalConfig:FiatWithdrawalConfigModel, fiat:Asset ){
    this.loading.push(true);
    this.offRampService.setFiatWithdrawalConfig(this.JWTToken, fiatWithdrawalConfig).subscribe({
      next: (response:any) => {
        // this.getAllFiatWithdrawalConfig();
        this.setPaymentReportOffRampSetting(fiat)
        this.loading.pop()
      },
      error: (e:any) => {
        console.log(e);
      }
    })
  }

  connectorSupportsCurrentFiat(){
    if(this.availableConnectorsForFiat)
      return this.availableConnectorsForFiat?.length > 0;
    return false;
  }

  haveAnActiveFiatRule(fiat:Asset | BlockchainAsset){    
    return fiat.activeRule ? 'var(--green)' : '#C9C9C9'
  }

  public getSystemAssets(){
    this.paymentService.getAllAssets().subscribe({
      next: (assets:any) => {
        assets.forEach((asset:Asset, i:number) => {
          if(asset.assetName === 'Ethereum') {
            assets[i].assetName = 'Ether';
          }
        });

        console.log(assets);
        this.metamaskAssets = assets.filter( (asset:Asset) => !asset.isFiat && !(asset.id === "MATIC.MATIC") && !(asset.id === "BTC.BTC"));
        this.assets = assets.filter( (asset:Asset) => !asset.isFiat && (!asset.id?.includes("MATIC") || asset.assetTicker == 'POL'));
        this.fiats = assets.filter( (asset:Asset) => asset.isFiat);
        this.usdFiat = this.fiats.filter( (asset:Asset) => asset.id === "USD.USD" )
        this.allSystemAssets = assets;
        this.currentOptionsSelects.assets = [];
        this.assetsAndFiats = assets.filter( (asset:Asset) => !asset.id?.includes("MATIC") || asset.assetTicker == 'POL');
        this.allSystemAssets.forEach(asset => {
          this.assetById[asset.id] = asset;
        })
        this.assets.forEach(asset => {
          this.currentOptionsSelects.assets.push({
            name: asset.assetTicker,
            value: asset.assetTicker
          })
        })
        this.assetsAndFiats.forEach(asset => {
          this.currentOptionsSelects.assetsAndFiats.push({
            name: asset.assetTicker,
            value: asset.assetTicker
          })
        })

        this.changeNetworkAssets();
      },
      error: (e:any) => {
        console.log(e)
        this.toastrService.error(this.translate.instant('unknownError'))
      }
    })
  }

  changeCurrentTab(tab: string, currentTab:string) {
    this.previousTab = this.currentTab;
    this.currentTab = tab;
    
    setTimeout(() => {
      this.setWalletNameMinWidth();
    }, 200)

    if (this.isMobile) {
      switch (tab) {
        case 'Routing':
          this.scrollToSection(this.routingDiv, currentTab);
          break;
        case 'Connections':
          this.scrollToSection(this.connectionsDiv, currentTab);
          break;
        case 'Security':
          this.scrollToSection(this.securityDiv, currentTab);
          break
        case 'Off-Ramp':
          this.scrollToSection(this.offRampDiv, currentTab);
          break
        case 'Domain':
          this.scrollToSection(this.domainDiv, currentTab);
          break
        case'AutomaticConversion':
          this.scrollToSection(this.automaticConversionDiv, currentTab);
          break
        default:
          this.scrollToSection(this.generalDiv, currentTab)
          break
      }
    }
    this.changeDetector.detectChanges();
    if (tab == "General") {
      setTimeout(() => {
        this.fillViewElements()
      }, 200)
    }
  }

  tourMobileTabSelection(tabName:any){
      const nextIndex = this.tabs.indexOf(tabName);
      const nextTab = this.tabs[nextIndex];

      this.changeCurrentTab(tabName, nextTab)
  }

  checkIfConnectionAlreadyExists(assetId: string) {
    return this.savedWallets?.some(wallet => wallet.asset.trim() === assetId.trim());
}

  savePrivateWallet() {
    this.savedWallets.forEach((wallet: any) => {
        if (wallet.asset === this.formatAsset()) {
            this.removeAssetWalletConnection(wallet, false);
        }
    });
    this.saveWalletChanges();
  }

  // Metamask old logics
  // saveMetaMaskWallets(){
  //   this.savingWallet = true;
  //   this.choosenMetaMaskAssets.forEach((asset:string, i) => {
  //     this.savedWallets.forEach((wallet:any) => {
  //       if(wallet.asset === asset) {
  //         this.removeAssetWalletConnection(wallet, false)
  //       }
  //     })
  //     this.configurationExchange = "private";
  //     this.newWalletAddress = this.metaMaskAddress;
  //     this.saveWalletChanges(asset);
  //   })
  //   this.savingWallet = false;
  //   this.choosenMetaMaskAssets = [];
  //   this.metaMaskWalletNames = [];
  //   this.metaMaskModal = false;
  //   this.toastrService.success(this.translate.instant('PROFILE.successfullySavedYourChanges'));
  //   this.getAllUserConnections();
  //   this.getCryptoConnections();
  // }

  addMetaMaskLink(){
    let elements;
    setTimeout(() => {
      elements = document.querySelectorAll('.not-installed');
      elements.forEach(function (element:any) {
        element.href = 'https://metamask.io/download/';
      });
    }, 500);

  }

  // openWalletModal(){
  //   this.changeWalletConnectionExchange();
  //   this.walletModal = true;
  //   this.addMetaMaskLink();
  // }

  requestMetaMask(){
    if(this.metaMask){
      this.metaMask.provider.request({method: 'eth_requestAccounts'}).then(
        (response:any) => {
          // this.metaMaskModal = true;
          this.metaMaskAddress = response[0];
          this.privateWalletAddress = response[0];
          this.validatePrivateWallet();
        },
        (e:any) => {
          if(e.message?.includes('Already processing')){
            this.toastrService.error(this.translate.instant('youHaveToAcceptnickyAccessRequestInMetamask'));
            return;
          }
          console.log(e)
        }
      )
    } else {
      window.open('https://metamask.io/download/', '_blank');
    }
  }

  chooseAsMetaMaskAsset(assetId:string){
    if(this.choosenMetaMaskAssets.length < 1){
      this.metaMaskWalletNames = [];
    }
    if(!this.choosenMetaMaskAssets.includes(assetId)){
      this.choosenMetaMaskAssets.push(assetId);
    } else {
      const index = this.choosenMetaMaskAssets.indexOf(assetId);
      if (index !== -1) {
        this.choosenMetaMaskAssets.splice(index, 1);
      }
    }
  }

  getDeletingWalletName(){
    let name:string | undefined = 'Private';
    this.savedWallets.forEach(wallet => {
      if(wallet.asset.trim() === this.choosenAssetName.trim()){
        name = wallet.walletName;
      }
    })
    return name;
  }

  async pasteWalletAddress(){
    if( this.clipboardPermissions.state === 'denied' ) {
      this.privateWalletInput?.focus();
      this.toastrService.error(this.translate.instant("permissionToReadFromYourClipboardHasBeenDenied"));
      return;
    }
    this.tryToReadClipboard()
  }

  async tryToReadClipboard(onlyTesting?:boolean) {
    try {
      const address = await navigator.clipboard.readText();
      if (!onlyTesting) {
        this.acceptedNetworksOnPrivateWallet = [];
        const validNetworksForAsset = await this.walletValidatorService.validateAddress(address);
        validNetworksForAsset.forEach((network:string) => {
          if (this.systemSupportedNetworks.includes(network)) {
            this.acceptedNetworksOnPrivateWallet.push(network);
          }
        })
        if( address.trim() === "" || this.acceptedNetworksOnPrivateWallet.length < 1) {
          if (address.trim() === "") {
            this.toastrService.error(this.translate.instant('weCouldNotDetectAValidAddressFromTheClipboardContent'));
          } else {
            this.toastrService.error(`${this.translate.instant('weCouldNotDetectAValidAddressFrom')} ${address}`);
          }
          this.privateWalletAddress = "";
          return;
        }
        this.privateWalletAddress = address.trim();
      }
    } catch(e:any) {
      console.log(e);
      this.clipboardDenied = true;
      // this.privateWalletInput?.removeAttribute('readonly');
      // this.privateWalletInput?.removeAttribute('click');
      // this.privateWalletInput?.focus();
    }
  }

  clearWalletAddress(){
    this.privateWalletAddress = "";
    this.acceptedNetworksOnPrivateWallet = [];
    this.validatePrivateWallet();
  }

  openInformativeModal(){
    this.modalMessage = this.translate.instant('PROFILE.yourCompanyLogoMust')
    this.modalTittle = this.translate.instant('PROFILE.uploadYourLogo')
    this.showModal = true;
  }

  closeWalletModal(){
    this.walletModal = false;
    // this.configurationExchange = this.currentOptionsSelects.configurationExchange[0];
    this.privateWalletAddress = '';
    this.privateWalletName = '';
    this.routingOptionName = '';
  }

  closeExchangeRegistrationModal(){
    this.registerExchangeModal = false;
    this.apiKey = '';
    this.apiSecret = '';
    this.exchangeName = '';
  }

  async setApiKeyFromClipboard() {
    this.apiKey = await this.getClipboardValue();
  }

  async setApiSecretFromClipboard() {
    this.apiSecret = await this.getClipboardValue();
  }

  async getClipboardValue() {
    return await navigator.clipboard.readText()
  }

  
  inputMetaMaskAddress(){
    this.metaMask.request({method: 'eth_requestAccounts'}).then(
      (response:any) => {
        this.newWalletAddress = response[0];
      },)
  }



  associateConnection( assetId:string, exchangeId:string, notLastInteration?:boolean ){
    this.userService.associateConection(this.JWTToken, assetId, exchangeId).subscribe({
      next: async (response:any) => {
        if ( notLastInteration ) {
          return;
        }
        this.closeWalletModal();
        // this.toastrService.success(this.translate.instant('PROFILE.routesSuccessfullyUpdated'));
        this.loading.pop();
        this.getAllUserConnections();
        this.getCryptoConnections();
        this._cd.detectChanges();
        this.eventService.notifyRequestComplete();
      },
      error: (e:any) => {
        console.log(e);
        this.showErrorMessage(assetId);
      }
    })
  }

  async removeInvalidWalletRoute(selectedAsset:any) {
    this.loading.push(true);
      this.userService.getCryptoConnections(this.JWTToken).subscribe({
        next: async (response:any) => {
         const assetConnections = await firstValueFrom (this.userService.allUserConnections(this.JWTToken)) as any;
         let removingAssets: any = [];
         let currentWallet:any = undefined;
         let i = 0;

         for (const asset of selectedAsset) {
           try {
             currentWallet = await firstValueFrom(this.paymentService.getUserWallets(this.user.email, asset));
           } catch (e) {
             // console.log('aqui');
           }
         
           if (!currentWallet?.cryptoAddress) {
             const filterArray = response;
             const existentWallet = filterArray.filter((wallet: any) => wallet.blockchainAsset?.id === asset && wallet.cryptoDataSource === "Manual");
             const existentExchange = filterArray.filter((wallet: any) => wallet.usedForAssets?.includes(asset) && wallet.cryptoDataSource != "Manual");
         
             if (existentExchange.length > 0 || existentWallet.length > 0) {
               for (const element of existentExchange) {
                 const currentAssetConnection = assetConnections.filter((assetconnection: any) => assetconnection.blockchainAssetId === asset && assetconnection.cryptoConnectionId === element.id)[0];
                 if (currentAssetConnection) {
                   removingAssets.push(asset);
                   await this.deleteUserAssetConnection(currentAssetConnection.id, true);
                 }
               }
         
               for (const element of existentWallet) {
                 await this.removeCryptoConnection(element.id, true);
                 const currentAssetConnection = assetConnections.filter((assetconnection: any) => assetconnection.blockchainAssetId === asset && assetconnection.cryptoConnectionId === element.id)[0];
                 if (currentAssetConnection) {
                   await this.deleteUserAssetConnection(currentAssetConnection.id, true);
                 }
               }
             }
           }
         
           if (i === (selectedAsset.size - 1)) {
             await this.getAllUserConnections();
             await this.getCryptoConnections();
         
             const sortedAssets = this.sortAssets(removingAssets);
         
             const formattedAssets = this.formatAssetsForDisplay(sortedAssets);
         
             const assetsString = formattedAssets.join(', ');
         
             if (removingAssets.length > 0) {
               this.showErrorMessage(assetsString);
             } else {
               this.toastrService.success(this.translate.instant('PROFILE.routesSuccessfullyUpdated'));
             }
           }
           i++;
         }         
  
        },
        error: (e:any) => {
          console.log(e);
        }
      })
      this.loading.pop();
  }

  sortAssets(assets: string[]): string[] {
    return assets.sort((a: string, b: string) => {
      const result = this.formatAssetName(a).localeCompare(this.formatAssetName(b));
      if (result === 0) {
        return a.split('.')[0].localeCompare(b.split('.')[0]);
      }
      return result;
    });
  }
  
  formatAssetsForDisplay(assets: string[]): string[] {
    return assets.map((asset: string) => {
      const networkName = asset.includes('ETH') || asset.includes('MATIC') ? "(" + this.getNetworkName(asset.split(".")[0]) + ")" : '';
      return `${this.formatAssetName(asset)} ${networkName}`.trim();
    });
  }
  
  showErrorMessage(assetsString: string): void {
    this.toastrService.error(
      this.translate.instant('unableToRetrieveValidAddressesFor', { assetsString: assetsString }), 
      '', 
      {
        tapToDismiss: true,
        disableTimeOut: true,
        enableHtml: true
      }
    );
  }

  removeSelectedAssetsFromWallets(selectedAssets: Set<string>) {
      this.savedWallets.forEach((wallet) => {
          if (selectedAssets.has(wallet.asset.split('.')[1])) {
              this.removeAssetWalletConnection(wallet, true);
          }
      });
  }

  finishWalletConfigs(selectedAsset?:any) {
      const interval = setInterval(() => {
          if (this.loading.length === 0) {
              clearInterval(interval);
              this.closeWalletModal();
              this.loading.push(true);
              this.resetWalletConfiguration();
              // this.toastrService.success(this.translate.instant('PROFILE.routesSuccessfullyUpdated'));
              this._cd.detectChanges();
              if(selectedAsset) {
                this.selectedAssetsForExchanges = "";
                this.removeInvalidWalletRoute(selectedAsset);
              } else {
                this.getCryptoConnections();
                this.getAllUserConnections();
              }
          }
      }, 500);
  }

  resetWalletConfiguration() {
      this.currentExchangeWallets = [];
      this.selectedAssetsForExchanges = "";
  }

  trackByNetwork(index: number, network: any): any {
    return network.id; 
  }

  trackByAsset(index: number, asset: any): any {
      return asset.blockchainAsset.id; 
  }

  trackByAssetOnExchange(index: number, assetOnExchange: any): any {
      return assetOnExchange.blockchainAsset.id; 
  }

  associateExchangeToAsset(savingAsset: string = this.formatAsset(), cryptoConnectionId: string = this.configurationExchange, counter:number, notLastInteration?: boolean, ): Promise<void> {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const filterArray = this.allUserConnections;
          const existentWallet = filterArray.filter((wallet: any) => wallet.blockchainAsset?.id === savingAsset && wallet.cryptoDataSource === "Manual");
          const existentExchange = filterArray.filter((wallet: any) => wallet.usedForAssets?.includes(savingAsset) && wallet.cryptoDataSource !== "Manual");

          if (existentExchange.length > 0 || existentWallet.length > 0) {
              existentExchange.forEach(element => {
                  this.deleteUserAssetConnection(element.id, false);
              });
              existentWallet.forEach(element => {
                  this.removeCryptoConnection(element.id, notLastInteration);
                  const currentAssetConnection = this.allUserConnections.filter((assetconnection:any) => assetconnection.blockchainAssetId === savingAsset && assetconnection.cryptoConnectionId === element.id)[0];
                  if (currentAssetConnection ) {
                     this.deleteUserAssetConnection(currentAssetConnection.id, true);
                  }
              });
              this.associateConnection(savingAsset, cryptoConnectionId, notLastInteration);
              resolve();
              return;
          }

          this.associateConnection(savingAsset, cryptoConnectionId, notLastInteration);
          resolve();
        }, 500 * counter)
      
      });
  }

  invalidWalletName(throwError?:boolean) {
    if (this.routingOptionName) {
      return false;
    }
    
    if (!this.privateWalletName) {
      if(throwError) {
        this.toastrService.error(this.translate.instant('youNeedToInformAWalletName'));
      }
      return true;
    }
  
    const walletNameExists = this.savedWallets?.some(wallet =>
      wallet.walletName?.toLowerCase()?.trim() === this.privateWalletName.toLowerCase()?.trim()
    );
  
    // const walletNameInUseByExchange = this.acceptedAssetsByExchange?.some(exchange =>
    //   exchange.cryptoDataSource.toLowerCase()?.trim() === this.privateWalletName.toLowerCase()?.trim()
    // );
    this.hasExchangeName(this.privateWalletName || "")
  
    if (walletNameExists) {
      if(throwError) {
        this.toastrService.error(this.translate.instant('thisNameIsAlreadyBeingUsedTryAnotherOne'));
      }
      return true;
    }

    return false;
  }

saveWalletChanges(savingAsset?: Set<string>) {

    let walletName: string | undefined = this.configurationExchange === 'Manual' ? this.privateWalletName : undefined;
    const address = this.privateWalletAddress.replace(" ", "");
    let counter = 1;
    savingAsset?.forEach((asset) => {
        const CryptoConnectionDTO: CryptoConnectionDTO = {
            cryptoDataSource: "Manual",
            blockchainAssetId: asset,
            address: address,
            name: walletName,
        };

        if(!this.acceptedNetworksOnPrivateWallet.includes(asset.split(".")[0])) {
          return;
        }

        this.userService.addCryptoConnection(this.JWTToken, CryptoConnectionDTO).subscribe({
            next: async (response: any) => {
                const cryptoConnectionId = response.id;
                await this.associateExchangeToAsset(asset, cryptoConnectionId, counter).then(() => {
                  console.log(counter);
                  counter++;
                });
           
            },
            error: (e: any) => {
                console.log(e);
                this.loading.pop();
                if (e.error.detail?.includes("Address invalid")) {
                    this.toastrService.error(this.translate.instant('invalidWalletAddress'));
                    return;
                }
                this.toastrService.error(this.translate.instant('unknownError'));
            }
        });
    });

    this.closeWalletModal();
}


  saveWalletsConfiguration() {
    this.changedAssetsArray = [];
    this.testModal = false;
    // this.loading.push(true);
    const selectedAssets = new Set(this.selectedAssetsForExchanges.trim().split(' '));
    const currentExchangeId = this.configurationExchange;
    const currentExchange = this.userExchanges.find((exchange: any) => exchange.id === currentExchangeId);
    const exchangeCryptoDataSource = currentExchange?.cryptoDataSource;
    this.loading.push(true);

    // debugger
    if(this.configurationExchange === "Manual"){
      if(this.invalidWalletName(true) || this.acceptedNetworksOnPrivateWallet.length < 1) {
        if(this.acceptedNetworksOnPrivateWallet.length < 1) {
          this.toastrService.error(this.translate.instant('invalidWalletAddress'));
        }
        this.loading.pop();
        return;
      }

      this.removeUnselectedAssetsFromWallets(selectedAssets, currentExchangeId);
      this.selectedAssetsForExchanges = "";
      this.saveWalletChanges(selectedAssets);
      this.loading.push(true);
      return;
    }

    if (!exchangeCryptoDataSource) {
        this.loading.pop();
        return;
    }

    this.removeUnselectedAssetsFromWallets(selectedAssets, currentExchangeId);

    const exchangeData = this.acceptedAssetsByExchange.find(exchange => exchange.cryptoDataSource === exchangeCryptoDataSource);
    if (!exchangeData || !exchangeData.supportedAssets) {
        this.loading.pop();
        return;
    }
    let counter = 0;
    const promises = exchangeData.supportedAssets.map(async (asset, index) => {
        const assetTicker = asset.blockchainAsset?.id;
        const assetId = asset.blockchainAsset?.id;
        const alreadyExists = this.savedWallets?.some(wallet => ((wallet.asset === assetId) && (wallet.cryptoDataSource === exchangeData.cryptoDataSource)) );
        
        if (assetId && assetTicker && selectedAssets.has(assetId) && !alreadyExists) {
            counter++
            this.loading.push(true);
            return await this.associateExchangeToAsset(assetId, currentExchangeId, counter, true).then(() => this.loading.pop());
        }

        return Promise.resolve();
    });

    Promise.all(promises).then(() => {
        this.finishWalletConfigs(selectedAssets);
    }).finally(() => {
        this.loading.pop();
        this.eventService.notifyRequestComplete();
    });
  }

  buildChangesArray(){
    this.loading.push(true);
    const supportedNetworks = this.getSupportedNetworks();
    const selectedAssetsByNetwork:any = {};
    let changedAssetsAmount:number = 0;

    for (let network of supportedNetworks) {
      selectedAssetsByNetwork[network] = [];

      const supportedAssets = this.getSupportedAssetsOnNetwork(network);
      for (let asset of supportedAssets) {
        if (this.selectedAssetsForExchanges.includes(asset.blockchainAsset.id)) {
          if((this.configurationExchange === "Manual" ? this.currentExchange?.walletName : this.currentExchange?.cryptoDataSource) != this.getCurrentAssetConfig(asset.blockchainAsset)) {
            if (this.getCurrentAssetConfig(asset.blockchainAsset)) {
              selectedAssetsByNetwork[network].push({
                formattedName: this.formatAssetName(asset.blockchainAsset.id),
                currentConfig: this.getCurrentAssetConfig(asset.blockchainAsset),
                network: network
              });

              changedAssetsAmount++;
            }
          }
        }
      }
    }
    if(this.configurationExchange === "Manual" && this.invalidWalletName(true)) {
        this.loading.pop();
        return;
    }
    
    if( changedAssetsAmount > 0) {
      this.loading.pop();
      this.changedAssetsArray = selectedAssetsByNetwork;
      this.testModal = true;
      return;
    }

    if ( this.selectedAssetsForExchanges.length > 0 ) {
      this.loading.pop();
      this.saveWalletsConfiguration();
        }
  }

  removeUnselectedAssetsFromWallets(selectedAssets: Set<string>, currentExchangeId: string) {
      if (this.configurationExchange === "Manual") {
        this.savedWallets.forEach((wallet) => {
          const sameWalletAddress = wallet.address === this.privateWalletAddress;
          const sameWalletName = wallet.walletName === this.privateWalletName;
          const assetId = wallet.asset;
          if (!selectedAssets.has(assetId) && sameWalletName && sameWalletAddress) {
              this.removeAssetWalletConnection(wallet, true);
          }
          if (selectedAssets.has(assetId) && wallet.walletName != this.privateWalletName && wallet.address === this.privateWalletAddress) {
            this.removeAssetWalletConnection(wallet, true);
          }
      });
      return;
      }
      this.savedWallets.forEach((wallet) => {
          const assetId = wallet.asset;
          if (!selectedAssets.has(assetId) && wallet.id === currentExchangeId) {
              this.removeAssetWalletConnection(wallet, true);
          }
          if (selectedAssets.has(assetId) && wallet.id != currentExchangeId) {
            this.removeAssetWalletConnection(wallet, true);
          }
      });
  }

  removeCryptoConnection(walletId: string, silenceCheck?:boolean ) {
    this.userService.removeCryptoConnection(this.JWTToken, walletId).subscribe({
      next: (response: any) => {
        if(!silenceCheck){
          // this.toastrService.success(this.translate.instant('PROFILE.exchangeSucessFullyRemoved'), '', {
          //   timeOut: 3000
          // })
          this.exchange = 'Foxbit';
          this.userActiveWallets = [];
          this.userExchanges = [];
          this.apiKey = ''
          this.apiSecret = ''
          this.getCryptoConnections();
          this.getAvailableConnectors();
          this.addingRule = false;
          this.minAmount = 0;
          // this.maxAmount = undefined;
          this.editingOffRampConnection = false;
          this.eventService.notifyRequestComplete();
        }
      },
      error: (e: any) => {
        this.toastrService.error(this.translate.instant('unknownError'), '', {
          timeOut: 3000
        })
        console.log(e)
      },
    })
  }

  removePrivateCryptoConnection(walletId: string, silenceCheck?:boolean ) {
    this.userService.removeCryptoConnection(this.JWTToken, walletId).subscribe({
      next: (response: any) => {
        if(!silenceCheck){
          // this.toastrService.success(this.translate.instant('PROFILE.exchangeSucessFullyRemoved'), '', {
          //   timeOut: 3000
          // })
          this.exchange = 'Foxbit';
          this.userActiveWallets = [];
          this.userExchanges = [];
          this.getCryptoConnections();
          this.getAvailableConnectors();
          this.eventService.notifyRequestComplete();
        }
      },
      error: (e: any) => {
        this.toastrService.error(this.translate.instant('unknownError'), '', {
          timeOut: 3000
        })
        console.log(e)
      },
    })
  }


  deleteUserAssetConnection(connectionId:string, deleting?:boolean){
    this.userService.deleteUserAssetConnection(this.JWTToken, connectionId).subscribe({
      next:(response:any) => {
        if(deleting){
          this.getAllUserConnections();
          this.getCryptoConnections();
          this.eventService.notifyRequestComplete();
        }
      },
      error: (e:any) => {
        console.log(e)
      }
    })
  }

  removeAssetWalletConnection(wallet:WalletModel, silenceCheck?:boolean){
      let walletId = wallet.id;
      let deleting = true;
      if(silenceCheck === true){
        deleting = false;
      }
      const existentWallet = this.currentUserConnections.filter((connectionWallet:any) => connectionWallet.blockchainAssetId == wallet.asset);
      walletId = existentWallet[0]?.id;
      this.deleteUserAssetConnection(walletId, deleting)
      if(wallet.defaultWallet == "Manual")
        this.removeCryptoConnection(wallet.id, deleting);
  }

  formatAsset(){
    if(this.walletAsset != 'USDC' && this.walletAsset != 'USDT'){
      return this.walletAsset + "." + this.walletAsset
    } else {
      return this.walletNetwork + "." + this.walletAsset
    }
  }

  getWalletName(wallet:WalletModel){
    return wallet.walletName ? wallet.walletName : 'Private Wallet'
  }

  formatAssetName(asset:string){
    if(asset.includes('BTC')){
      return 'Bitcoin'
    }
    if(asset == "ETH.ETH"){
      return 'Ether'
    }
    if(asset.includes("USDC")){
      return "USD Coin"
    }
    if(asset.includes("USDT")){
       return "Tether"
     }
    if(asset.includes('POL')) {
      return 'Polygon'
    }
     return ''
  }


  getNetworkName(networkId:string){
    switch (networkId){
      case 'ETH':
        return this.translate.instant('ethereumNetwork')
      // case 'MATIC':
      //   return this.translate.instant('ethereumNetwork')'Polygon'
      case 'BTC':
        return this.translate.instant('bitcoinNetwork')
      default:
          return this.translate.instant('polygonNetwork')
    }

  }

  changeNetworkAssets(){
    this.networkAssets = [];
    this.currentOptionsSelects.networkAssets = [];
    if(this.walletAsset == "USDC" || this.walletAsset == "USDT"){
      this.networkAssets.push("ETH")
      this.networkAssets.push('MATIC')
    }
    if(this.networkAssets.length < 1 ){
      this.networkAssets.push('None')
    }
    this.networkAssets.forEach(asset => {
      this.currentOptionsSelects.networkAssets.push({
        name: asset == 'MATIC' ? 'Polygon' : asset,
        value: asset
      })
    })
  }


  getAddress(){
    let asset = this.formatAsset();
    if (asset.startsWith('BTC.')) {
      return btcAddress + "address/" + this.newWalletAddress;
    }
    if (asset.startsWith('ETH.')) {
      return ethAddress + "address/" + this.newWalletAddress;
    }
    if (asset.startsWith('MATIC.')) {
      return polyAddress + "address/" + this.newWalletAddress;
    }
    if (asset.startsWith('BEP.')) {
      return bnbAddress + "address/" + this.newWalletAddress;
    }
    return '';
  }

  getCryptoConnections() {
    this.loading.push(true);
    this.userService.getCryptoConnections(this.JWTToken).subscribe({
      next: (walletsConections: any) => {
        this.loading.pop();
        this.userActiveWallets = [];
        this.savedWallets = [];
        walletsConections.forEach((wallet: any) => {
          let i = 0;
          if(wallet.usedForAssets.length > 0){
            wallet.usedForAssets.forEach(() => {
              this.filterAssociatedWallets(wallet, i)
            i++;
            })
          }
          if(wallet.cryptoDataSource != "Manual"){
              this.userActiveWallets.push(wallet.cryptoDataSource)
          }
        });
        this.userExchanges = walletsConections.filter( (wallet:any) => wallet.cryptoDataSource != "Manual");

        this.allUserConnections = walletsConections

        this.configurationExchange = this.userExchanges[0]?.id ? this.userExchanges[0].id : 'Manual';

        if(this.userExchanges.length == 0){
          this.configurationExchange = "Manual"
        }

        this.populateConfigurationExchangesOptionSelect();
       

        this.getEachConnectionStatus();
        this.selectedUserConnections();
        
        setTimeout(() => {
          this.setWalletNameMinWidth();
        }, 1000);

        this.loading.pop();

      },
      error: (e: any) => {
        this.handleError(e);
        this.loading.pop();
      },
    })
  }

  populateConfigurationExchangesOptionSelect() {
    this.currentOptionsSelects.configurationExchange = [];
  
        
    this.userExchanges.forEach((configuration:any) => {
      this.currentOptionsSelects.configurationExchange.push(
        {
           name: configuration.cryptoDataSource,
           value: configuration.id
        })
    });
    
    let currentManualWallets: (string | undefined)[] = [];

    this.savedWallets.forEach((wallet) => {
      if(wallet.cryptoDataSource === "Manual") {
        // !this.currentOptionsSelects.configurationExchange?.some((option) => option.name === wallet.walletName)
        if(!currentManualWallets.includes(wallet.walletName)) {
          this.currentOptionsSelects.configurationExchange.push(
            {
                name: this.hasExchangeName(wallet.walletName || ""),
                value: `${wallet.address}`
            })
            currentManualWallets.push(wallet.walletName);
        }
      }
    })

    this.currentOptionsSelects.configurationExchange.push(
      {
          name: this.translate.instant('privateWallet'),
          value: 'Manual'
      })
      
  }

  hasExchangeName(walletName:string){
    const walletNameInUseByExchange = this.acceptedAssetsByExchange?.some(exchange =>
         exchange.cryptoDataSource.toLowerCase()?.trim() === walletName.toLowerCase()?.trim()
    );
    
    if(walletNameInUseByExchange) {
      return `${walletName} (${this.translate.instant('privateWallet')})`
    } 

    return walletName;
  }

  setNetworksAndAssetsForExchange(exchange: any) {
    if (!exchange || exchange.length === 0) return;

    const supportedNetworks:string[] = [];
    const exchangeSupportedAssets = exchange[0].supportedAssets;
    this.supportedNetworksOnExchange = [];
    this.currentSelectExchangeAssetOptions = {};
  
    exchange[0].supportedAssets.forEach((asset: any) => {
      const assetIdParts = asset.blockchainAsset.id?.split('.');
      if( this.systemSupportedNetworks.includes(assetIdParts[0]) ) {
        if (assetIdParts && assetIdParts[1] !== 'MATIC' && asset.isEnabled && !supportedNetworks.includes(assetIdParts[0])) {
          supportedNetworks.push(assetIdParts[0]);
        }
      }
    });
    
    exchangeSupportedAssets.forEach((asset:any) => {
      const assetIdParts = asset.blockchainAsset.id?.split('.');
      if(supportedNetworks.includes(assetIdParts[0]) && assetIdParts[1] != "MATIC"){
        if (!this.currentSelectExchangeAssetOptions[assetIdParts[0]]) {
          this.currentSelectExchangeAssetOptions[assetIdParts[0]] = [];
        }
        this.currentSelectExchangeAssetOptions[assetIdParts[0]].push(asset);
        this.supportedNetworksOnExchange = supportedNetworks;
      }
    });
  }

  getSupportedAssetsOnNetwork(network:string){
    return this.currentSelectExchangeAssetOptions[network];
  }

  getSupportedNetworks() {
    this.setMaxWidth();
    if(this.configurationExchange === "Manual") {
      return this.acceptedNetworksOnPrivateWallet;
    } else {
      return this.supportedNetworksOnExchange;
    }
  }

  openAssetWalletConfigurationModal() {

    this.currentSelectExchangeAssetOptions = {};
  
    this.currentExchange = this.userExchanges.find((exchange: any) => exchange.id === this.configurationExchange);

    if (!this.currentExchange)  {
      this.configurationExchange = "Manual";
    };
  
    const exchangeCryptoDataSource = this.currentExchange?.cryptoDataSource || "Manual";

    this.setCurrentAssetByExchange(exchangeCryptoDataSource);
    
    this.populateConfigurationExchangesOptionSelect();

    this.walletModal = true;
    this._cd.detectChanges();
  }
  
  changeWalletConnectionExchange( exchangeOptionValue?:string ) {

    this.configurationExchange = exchangeOptionValue;
        // debugger

    if (this.configurationExchange === 'Manual') {
      this.tryToReadClipboard(true);
    }

    let exchangeCryptoDataSource: any;

    if (this.configurationExchange !== 'Manual') {
      this.currentExchange = this.userExchanges.find((exchange: any) => exchange.id === this.configurationExchange);
      const optionIsAExchangeConnection = this.acceptedAssetsByExchange?.some(exchange => exchange.cryptoDataSource === this.currentExchange?.cryptoDataSource); 
  
      if (!optionIsAExchangeConnection) {
        this.setupPrivateWallet();
        exchangeCryptoDataSource = 'Manual';
        this.configurationExchange = "Manual";
      } else {
        this.resetRoutingOptionName();
        exchangeCryptoDataSource = this.currentExchange?.cryptoDataSource;
      }
    } else {
      this.resetPrivateWallet();
      exchangeCryptoDataSource = this.configurationExchange;
    }
  
    this.setCurrentAssetByExchange(exchangeCryptoDataSource);
  }
  
  setupPrivateWallet() {
    this.currentExchange = this.savedWallets.find((wallet: any) => wallet.address === this.configurationExchange && wallet.walletName === this.routingOptionName);
    this.privateWalletAddress = this.configurationExchange;
    this.privateWalletName = this.routingOptionName || this.configurationExchange;
    this.validatePrivateWallet();
  }
  
  resetRoutingOptionName() {
    this.routingOptionName = undefined;
  }
  
  resetPrivateWallet() {
    this.acceptedNetworksOnPrivateWallet = [];
    this.privateWalletAddress = "";
    this.privateWalletName = "";
    this.routingOptionName = undefined;
  }

  getCurrentAssetConfig(asset:Asset | BlockchainAsset):string{
    const currentConfig = this.currentAssetConfigs[asset.id];
    if (currentConfig?.cryptoDataSource === "Manual") {
      return currentConfig.name;
    } 

    return currentConfig?.cryptoDataSource;
  }
  
  setCurrentAssetByExchange(exchangeCryptoDataSource: string) {
    this.selectedAssetsForExchanges = "";
    this.currentAssetConfigs = {};
    this.allUserConnections.forEach((connection) => {
      connection.usedForAssets.forEach((asset: string) => {
        const assetId = asset;
  
        this.currentAssetConfigs[asset] = {
          cryptoDataSource: connection.cryptoDataSource,
          connection: connection.id,
          name: connection.name || connection.cryptoDataSource
        };
        
        if (this.shouldIncludeAsset(assetId, exchangeCryptoDataSource, connection)) {
          if (this.configurationExchange === "Manual" && this.routingOptionName != connection.name) {
            return;
          };
          this.selectedAssetsForExchanges += ` ${assetId}`;
        }
      });
    });
  
    const currentAssetsByExchange = this.acceptedAssetsByExchange.filter(
      (exchange) => exchange.cryptoDataSource === exchangeCryptoDataSource
    );
  
    console.log(currentAssetsByExchange);
    this.setNetworksAndAssetsForExchange(currentAssetsByExchange);
  }
  
  shouldIncludeAsset(assetId: string, exchangeCryptoDataSource: string, connection: any): boolean {

    if (exchangeCryptoDataSource === 'Manual' && this.routingOptionName === connection?.name && connection?.cryptoDataSource === "Manual") {
      return !this.selectedAssetsForExchanges.includes(assetId);
    }
    
    if(exchangeCryptoDataSource === "Manual" && !this.routingOptionName) {
      return false;
    }

    return !this.selectedAssetsForExchanges.includes(assetId) && exchangeCryptoDataSource === connection.cryptoDataSource;
  }
  
  
  assignAssetToExchange(asset: Asset | BlockchainAsset) {
    const assetId = asset.id;
    const currentExchangeSource = this.currentExchange?.cryptoDataSource;
    const currentExchangeName = this.currentExchange?.name;
    const isManual = currentExchangeSource === 'Manual';
    const assetName = this.formatAssetName(assetId);

    if (this.selectedAssetsForExchanges.includes(assetId)) {
      this.selectedAssetsForExchanges = this.selectedAssetsForExchanges.replaceAll(`${assetId}`, '');
      // const currentAssetConfig = this.currentAssetConfigs[assetId];
      //toastr logics that was removed
      // if (currentAssetConfig) {
      //   if (
      //     (currentAssetConfig.cryptoDataSource === 'Manual' && currentAssetConfig.name === currentExchangeName) ||
      //     currentAssetConfig.cryptoDataSource === currentExchangeSource
      //   ) {
      //     this.toastrService.success(this.translate.instant('theWalletRoutesForAssetNameWillBeRemoved', {assetName: assetName}));
      //     return;
      //   } else {
      //     this.toastrService.success(this.translate.instant('theRouteUsedForAssetNameWillBeChangedToExchangeName', { 
      //       assetName: assetName, 
      //       exchangeName: isManual ? currentAssetConfig.name : currentAssetConfig.cryptoDataSource
      //     }));
      //     return;
      //   }
      // }
  
      // this.toastrService.success(this.translate.instant('theWalletRoutesForAssetNameWillBeRemoved', {assetName: assetName}));
    } else {
      this.selectedAssetsForExchanges += ` ${assetId}`;
      // this.toastrService.success(this.translate.instant('theRouteUsedForAssetNameWillBeChangedToExchangeName', { 
      //   assetName: assetName, 
      //   exchangeName: isManual ? currentExchangeName : currentExchangeSource
      // }));
    }
  }
  
    
    // if( selectedAssets.size < 1) {
    //   this.finishWalletConfigs();
    // }
  
  checkIfItsDisablingAsset(assetId:string){

    const notInSelectedAssets = !this.selectedAssetsForExchanges.includes(assetId);

    if(this.currentExchange?.cryptoDataSource === "Manual") {
      if (this.currentAssetConfigs[assetId]?.name === this.routingOptionName && notInSelectedAssets) {
        return true;
      } else {
        return false;
      }
    }

    if ( this.currentAssetConfigs[assetId]?.cryptoDataSource === this.currentExchange?.cryptoDataSource && notInSelectedAssets ) {
      if(this.configurationExchange != 'Manual')
        return true
      }

    return false
  }

  hasSomeWallet(asset: Asset | BlockchainAsset){

    if(this.currentAssetConfigs[asset.id]) {

      return this.currentAssetConfigs;
    }
    
    return false;
  }

  assetIsConnectedToTheSelectedProvider(asset: Asset | BlockchainAsset){
    if(this.configurationExchange === 'Manual') {
      if( this.currentAssetConfigs[asset.id]?.name === this.privateWalletName ) {
        return true
      }
    }
    if(this.currentAssetConfigs[asset.id]?.connection === this.configurationExchange) {
      return true;
    }

    return false;
  }

  isSelectedWalletForNewSettings(asset:Asset | BlockchainAsset){
    return this.selectedAssetsForExchanges.includes(asset.id);
  }

    openCropperDialog(event: Event) {
      this.cropped = null!;
      this._dialog.open<CropperDialog, Event>(CropperDialog, {
        data: event,
        width: 320,
        disableClose: true
      }).afterClosed.subscribe((result?: ImgCropperEvent) => {
        if (result) {
          const imageUrl = result.dataURL;
          this.logo = imageUrl;
          this.base64image = imageUrl;
          this.convertImageUrlToFile(imageUrl);
          this._cd.markForCheck();
        }
      });
    }

    private async convertImageUrlToFile(imageUrl:string | undefined) {
      const response = await fetch(imageUrl || '');
      const blob = await response.blob();

      const file = new File([blob], "profilePicture.jpg", { type: "image/jpg" });
      this.imageFile = file;
    }


    @ViewChild(LyImageCropper, { static: true }) cropper!: LyImageCropper;
    myConfig: ImgCropperConfig = {
      width: 500, // Default `250`
      height: 500, // Default `200`
      type: 'image/png', // Or you can also use `image/jpeg`
      output: {
        width: 300,
        height: 300
      }
    };


  updateProfilePicture(){
    this.userService.updateProfilePicture(this.JWTToken, this.imageFile).subscribe({
      next: (response:any) => {
        // this.toastrService.success('Your logo has been updated successfully','',{
        //   timeOut: 3000
        // })
        this.imageFile = undefined
      },
      error: (e:any) => {
        // this.toastrService.success('Your logo has been updated successfully','',{
        //   timeOut: 3000
        // })
        this.imageFile = undefined
      }
    })
  }


  getUserProfilePicture(){
    this.userService.getProfilePicture(this.JWTToken).subscribe({
      next: (response:any) => {
        this.base64image = this.sanitize.bypassSecurityTrustUrl(URL.createObjectURL(response));
      },
      error: (e:any) =>{
      }
    })
  }

    //  cropper component logics //

  selectedUserConnections(){
    if(this.connection == ''){
      this.showingUserConnections = this.allUserConnections.filter( (wallet:any) => wallet.cryptoDataSource != "Manual" );
      return;
    }
    this.showingUserConnections = this.allUserConnections.filter( (wallet:any) => wallet.cryptoDataSource == this.connection);

  }

  async getEachConnectionStatus(){

    let index:number = 0;
    this.loading.push(true);
    this.userExchanges.forEach( (exchange:any) => {
        this.userService.checkExchangeConnection(this.JWTToken, exchange.id).subscribe({
          next: (response:any) => {
            if(response.isValid == true){
              exchange.isValid = true;
            } else {
              exchange.isValid = false;
            }
            this.loading.pop();
          },
          error: (e:any) => {
            this.loading.pop();
          }
        })
    });
  }

  filterAssociatedWallets(wallet:any,i:number){
    const walletName = wallet.name ? wallet.name : wallet.cryptoDataSource
    const formatedWallet = {
      walletName: walletName == 'Manual' ? 'Private' : walletName,
      cryptoDataSource: wallet.cryptoDataSource,
      asset: wallet.usedForAssets[i],
      assetName: this.formatAssetName(wallet.usedForAssets[i]),
      defaultWallet: wallet.cryptoDataSource,
      address: wallet.address,
      id: wallet.id,
     }
     this.savedWallets.push(formatedWallet);
     this.savedWallets.forEach((wallet) => {
      this.getWalletAddress(wallet);
     })
     this.savedWallets.sort((a, b) => {
       const result = a.assetName.localeCompare(b.assetName);
        if (result === 0) {

         return a.asset.split('.')[0].localeCompare(b.asset.split('.')[0]);
       }
       return result;
    });
  }

  addCryptoConnection(confirmation?:boolean) {

    if (!this.apiKey?.trim() || !this.apiSecret?.trim()) {
      return;
    }    

    if (!confirmation) {
      this.confirmConnectionAdditionModal = true;
      return;
    } 

    if(this.confirmExternalCryptoExchange.trim() != this.translate.instant('agree').toUpperCase()) {
      return;
    }


    if(this.loading.length > 0) {
      return;
    }

    this.loading.push(true);

    let connectionDto:CryptoConnectionDTO = {
      cryptoDataSource: this.exchange || '',
      apiKey: this.apiKey,
      apiSecret: this.apiSecret,
      name: this.exchangeName
    }
    this.userService.addCryptoConnection(this.JWTToken, connectionDto).subscribe({
      next: (response: any) => {
        this.toastrService.success(this.translate.instant('PROFILE.exchangeSuccessfullyAdded'), '', {
          timeOut: 3000
        })
        this.closeExchangeRegistrationModal();
        this.getCryptoConnections();
        this.getAllUserConnections();
        this.getAvailableConnectors();
        this.eventService.notifyRequestComplete();
        this.loading.pop();
        this.editingOffRampConnection = false;
        this.addingRule = false;
        this.minAmount = 0;
        this.confirmConnectionAdditionModal = false;
        this.askUserToConfigureRoutesModal = true;
        // this.maxAmount = undefined;
      },
      error: (e: any) => {
        this.loading.pop();
        this.confirmConnectionAdditionModal = false;
        if(e.error.detail?.includes("Unable to connect using supplied ApiKey")){
        this.toastrService.error(this.translate.instant('PROFILE.unableToConnectUsingSuppliedApiKey'),'',{
          timeOut: 3000
        })
          return;
        } else {
          this.toastrService.error(e.error.title);
          return;
        }

        this.toastrService.error(this.translate.instant('unknownError'), '', {
          timeOut: 3000
        })
        console.log(e)
      },
    })
  }

  goToRoutesTab(){
    this.askUserToConfigureRoutesModal = false;
    this.changeCurrentTab('Routing', this.currentTab);
    this.openAssetWalletConfigurationModal();
  }

   updateLocalStorage(asset: Asset, toggle: boolean): void {
    const savedAssets = localStorage.getItem('assets') || '';
    localStorage.removeItem('assets');
    const assetId = `${asset.id}`;
    let updatedAssets = toggle ? `${savedAssets} ${assetId} ` : savedAssets.replace(`${assetId} `, '');
    localStorage.setItem('assets', updatedAssets);
    localStorage.setItem('mockEmail', this.user?.email);
  }


   activeAsset(asset: Asset): void {

    const toggle = !asset.active;
    // this.toggleAssetStauts(asset, toggle);
    this._cd.detectChanges();
  }

   addOrRemoveAsset(): void {
    const savedAssets = localStorage.getItem('assets') || '';
    const asset = this.assetsAndFiats.find(asset => this.enablingAsset === asset?.id);

    if (asset) {
      const toggle = !savedAssets.includes(`${asset.id}`);
      // this.toggleAssetStauts(asset, toggle);
      this._cd.detectChanges();
    }
  }

  getAddOrRemoveText(): string {
    const savedAssets = localStorage.getItem('assets') || '';
    const asset = this.assetsAndFiats.find(asset => this.enablingAsset === asset?.id);

    return (asset && savedAssets.includes(`${asset.id}`)) ? 'REMOVE' : 'ADD';
  }


  getAllUserConnections(){

    this.userService.allUserConnections(this.JWTToken).subscribe({
      next: (connections:any) => {
        this.currentUserConnections = connections;
      },
      error: (e:any) => {
        console.log(e)
        this.toastrService.error(this.translate.instant('unknownError'))
      }
    })
  }

  getUserWallets(){
    this.loading.push(true);
    this.paymentService.getUserWallets(this.user?.email, this.choosenAsset).subscribe({
      next: (response:any) => {
        let cryptoAddress = response.cryptoAddress?.address
        window.open(this.getLink(cryptoAddress), '_blank');
        this.loading.pop();
      },
      error: (Response:any) => {
        this.toastrService.error(this.translate.instant('unknownError'))
        this.loading.pop();
      }
    })
  }

  getLink(cryptoAddress:string){
    if (this.choosenAsset.startsWith('BTC.')) {
      return btcAddress + "address/" + cryptoAddress;
    }
    if (this.choosenAsset.startsWith('ETH.')) {
      return ethAddress + "address/" + cryptoAddress;
    }
    if (this.choosenAsset.startsWith('MATIC.')) {
      return polyAddress + "address/" + cryptoAddress;
    }
    if (this.choosenAsset.startsWith('BEP.')) {
      return bnbAddress + "address/" + cryptoAddress;
    }
    return '';
  }

  userHasConnection(exchange: string) {
    return this.userActiveWallets.includes(exchange.trim())
  }

  public async changePassword() {

    this.signInService.resetPassword(this.user?.email).subscribe({
      next: (response: any) => {
      },
      error: (e: any) => {
        if (e.status == '200') {
          this.toastrService.success(`${this.translate.instant('weHaveSentPasswordChangeInstructionTo')} ${this.user?.email}, ${this.translate.instant('pleaseCheckYourMailbox')}`, '', {
            timeOut: 3000
          })
        } else {
          this.toastrService.error(e.statusText, '', {
            timeOut: 3000
          })
        }
      },
    })
  }

  public getTokenWithCorrectAudience() {
    this.auth.getAccessTokenSilently({
      authorizationParams: {
        audience: environment.auth0Audience
      },
    }).subscribe({
      next: (response: any) => {
        this.JWTToken = response;
        this.getUserProfilePicture();
        this.getCurrentUser();
        this.getUserDomain();
        this.getAcceptedAssets();
      },
      error: (e: any) => this.handleError(e),
    })
  }

  isSocialUser(){
    if(this.auth0User){
      return this.auth0User?.googleAuth;
    }
    return false;
  }

  getAcceptedAssetByExchangeArray() {
    return this.acceptedAssetsByExchange[this.configurationExchange]?.supportedAssets || [];
  }  

  private getAcceptableAssetsByExchange(){
    this.userService.getAcceptableAssetsByExchange().subscribe({
      next: (response:any) => {
        this.acceptedAssetsByExchange = response;
        console.log(this.acceptedAssetsByExchange);
        this.acceptedAssetsByExchange.forEach(exchange => {
          exchange.features?.forEach(feature => {
            if(feature.name === 'IProvideOfframp' && feature.isEnabled) {
              this.offRampExchanges.push(exchange.cryptoDataSource);
            }
          })
          if (!this.assetsOnExchange[exchange.cryptoDataSource]) {
            this.assetsOnExchange[exchange.cryptoDataSource] = [];
          }
          this.assetsOnExchange[exchange.cryptoDataSource] = (exchange.supportedAssets || [])  ;
        });
      },
      error: (e:any) => {
        console.log(e)
      }
    })
  }

  getAssetsOnExchange(asset:Asset | BlockchainAsset, active:boolean) {
    let exchangeName = this.currentAssetConfigs[asset.id].cryptoDataSource;
    if (active) {
      exchangeName = this.currentExchange?.cryptoDataSource;
    }
    const acceptedNetworks = this.assetsOnExchange[exchangeName]?.filter(assetOnExchange => assetOnExchange.blockchainAsset.id === asset.id);
    return acceptedNetworks || [];
  }

  private getCurrentUser() {
    this.loading.push(true);
    this.auth.user$.subscribe((user: any) => {
      this.userId = user.sub;
      this.auth0User = user;
      // this.googleAuth = user.
    });
    this.userService.getUser(this.JWTToken).subscribe({
      next: (response: any) => {
        if(!response.defaultFiatCurrencyId){
          this.currentOptionsSelects.fiats.unshift({
            name: 'selectAFiat',
            value: undefined
          })
        }
        this.user = response;
        this.autoOffRampPaymentReports = this.user.autoOffRampPaymentReports;
        const country = COUNTRIES_DB.filter(country => country.alpha2Code === this.user.country?.toUpperCase())
        if(country.length === 1){
          this.country = country[0];
        };
        this.manualOffRampSettings.assetId = this.getDefaultCurrentId();
        this.automaticConversionFiat = this.getDefaultCurrentId();
        this.changeDetector.detectChanges();
        this.getUserCryptoConnectionsInfo();
      },
      error: (e: any) => this.handleError(e),
      complete: () => {
        this.userIsLoaded = true,
        this.loading.pop();
        this.domainIsLoaded && this.userIsLoaded ? this.fillViewElements() : ''
      }
    })
  }

  private getUserCryptoConnectionsInfo(){
    this.getCryptoConnections();
    this.getAllUserConnections();
    this.getOffRampAllSettings();
    this.getAvailableConnectors();
    this.getAllFiatWithdrawalConfig();
    this.getAcceptableAssetsByExchange();
  }

  getDefaultCurrentId(){
    return this.user.defaultFiatCurrencyId || undefined
  }

  private async getUserDomain(buttonCheck?: boolean) {
    buttonCheck ? this.loading.push(true)   : '';
    this.userService.getDomain(this.JWTToken).subscribe({
      next: (response: any) => {
        this.domain = response[0];
        if (buttonCheck) {
          this.domain.isValid == true
            ? this.toastrService.success(this.translate.instant('PROFILE.validatedDomain'), '', {
              timeOut: 3000
            })
            :
            this.toastrService.warning(this.translate.instant('PROFILE.yourDnsStatusStillTheSame'), '', {
              timeOut: 3000
            })
        }
        if(!this.itsAPublicDomain()) {
          this.tabs = [
            'General',
            'Connections',
            'Routing',
            'Domain',
            'AutomaticConversion',
            'Off-Ramp',
            'Security'
          ]
        }
        buttonCheck ? this.loading.pop() : '';
      },
      error: (e: any) => {
        this.toastrService.error(this.translate.instant('PROFILE.nickyWasUnableToValidateYourDomain'), '', {
          timeOut: 5000
        })
        console.log(e)
        buttonCheck ? this.loading.pop() : '';
      },
      complete: () => {
        this.domainIsLoaded = true,
          this.domainIsLoaded && this.userIsLoaded ? this.fillViewElements() : ''
        buttonCheck ? this.loading.pop() : '';
      }
    })
  }

  
  itsAPublicDomain(){
    if( this.domain ){
      return commonDomains.includes(this.domain.name);
    }
    return true;
  }

  public fillViewElements() {
    this.websiteInput.nativeElement.value = this.user?.websiteUrl;
    this.publicName.nativeElement.value = this.user?.publicName;
    this.name.nativeElement.value = this.user?.name;
    this.bio.nativeElement.value = this.user?.bio;
  }

  public saveGeneralUserInfo() {
    this.loading.push(true);
    if(this.imageFile){
      this.updateProfilePicture();
    }
    if (this.publicName.nativeElement.value == null || this.publicName.nativeElement.value.trim() == '') {
      this.toastrService.error(this.translate.instant('PROFILE.publicNameMustBeFilled'), '', {
        timeOut: 3000
      })
      this.loading.pop();
      return;
    }

    const userProfile:ProfileDto = {
      publicName : this.publicName.nativeElement.value.trim(),
      name: this.name.nativeElement.value.trim(),
      websiteUrl: this.websiteInput.nativeElement.value.trim(),
      bio: this.bio.nativeElement.value.trim(),
      country: this.country?.alpha2Code
    }

    if (!(userProfile.websiteUrl?.includes('https://') || userProfile.websiteUrl?.includes('http://'))) {
      userProfile.websiteUrl = 'https://' + userProfile.websiteUrl
    }

    this.updateUserInfo(userProfile);
  }

  public saveAutomaticConversionInfo(autoSetting?:boolean) {
    if(this.loading.length > 1){
      return;
    }
    if(this.automaticConversionFiat === undefined){
      this.toastrService.error('You must choose a default fiat to save the automatic conversion settings.','',{
        timeOut: 10000
      })
      return;
    }
    this.loading.push(true)
    const automaticOffRampConfigs:ProfileDto = {
        defaultFiatCurrencyId: this.automaticConversionFiat,
        // automaticConvertToFiatPercentage: 1
        // Number.parseInt(this.convertToFiatPorcentage.toString()) / 100
    };

    this.updateUserInfo(automaticOffRampConfigs, autoSetting);
  }



  updateUserInfo(updatingInfo:ProfileDto, autoSetting?:boolean){
    this.userService.editGeneralUserInfo(this.JWTToken, updatingInfo).subscribe({
      next: (response: any) => {
        this.user = response;
        this.automaticConversionFiat = this.user.defaultFiatCurrencyId;
        if(!autoSetting){
          this.toastrService.success(this.translate.instant('PROFILE.userInfoUpdatedSuccessfully'), '', {
            timeOut: 3000
          });
          this.automaticConversionSetModal = false;
        } else {
          this.setOffRampSettings();
        }

        this.loading.pop()
      },
      error: (e: any) => this.handleError(e),
    })
  }

  enabledOffRampButton(){
    if((!(this.manualOffRampSettings.amount === undefined || this.manualOffRampSettings.amount === 0) || this.withdrawAll) && !this.notSupportManual){
      return true;
    } else {
      return false;
    }

  }

  startOffRamp(){
    if(this.loading.length > 1){
      return;
    }

    if(!this.enabledOffRampButton()){
      return;
    }

    this.loading.push(true);

    if(!this.withdrawAll){
      this.settedValueOffRamp();
      return;
    } else {
      this.maxValueOffRamp();
    }

  }

  settedValueOffRamp(){
    this.offRampService.offRampStart(this.JWTToken, this.manualOffRampSettings).subscribe({
      next: (response:any) => {
        this.loading.pop();
        const offRampRequestResponse:OffRampRequestModel = response;
        this.manualOffRampResponseHandler(offRampRequestResponse);
      },
      error: (e:any) => {
        if(e.error?.Message?.includes('Off ramp settings not found')){
          this.toastrService.error(this.translate.instant('offRampsSettingsNotFoundForTheTargetFiat'));
        }
        if(e.error?.ErrorCode === "NotEnoughBalanceException"){
          this.toastrService.error(this.translate.instant('notEnoughBalances'));
        }
        console.log(e)
        this.loading.pop();
      }
    })
  }

  maxValueOffRamp(){
    this.offRampService.maxOffRampStart(this.JWTToken, this.manualOffRampSettings).subscribe({
      next: (response:any) => {
        this.loading.pop();
        const offRampRequestResponse: OffRampRequestModel = response;
        this.manualOffRampResponseHandler(offRampRequestResponse)
      },
      error: (e:any) => {
        if(e.error?.Message?.includes('Off ramp settings not found')){
          this.toastrService.error(this.translate.instant('offRampsSettingsNotFoundForTheTargetFiat'));
        }
        if(e.error?.ErrorCode === "NotEnoughBalanceException"){
          this.toastrService.error(this.translate.instant('notEnoughBalances'));
        }
        console.log(e)
        this.loading.pop();
      }
    })
  }

  manualOffRampResponseHandler(offRampRequestResponse: OffRampRequestModel) {
    if (offRampRequestResponse.success == false) {
      if (offRampRequestResponse.error) {
        if (offRampRequestResponse?.error.includes('Minimum withdraw')) {
          this.toastrService.error(`${this.translate.instant('minimumWithdrawValueOf')} ${this.manualOffRampSettings.assetId?.split('.')[0]} ${this.formatCurrency.convertToLocal("100")}`)
        } else {
          this.toastrService.error(offRampRequestResponse.error);
        }
      } else {
        this.toastrService.error(this.translate.instant('unknownError'))
      }
      return;
    }
    this.toastrService.success(
      `${this.translate.instant('offRampRequestCompletedSuccessfully')} ${this.translate.instant('inTheValueOf')} ${offRampRequestResponse.blockchainAssetId.split('.')[0] + ' ' + this.formatCurrency.convertToLocal(offRampRequestResponse.amount.toString())}`,
      '',
      {
        timeOut: 20000
      });
    this.offRampModal = false;
    return;
  }



  public onCopyPlaceholder(field:HTMLInputElement) {
    const copy = field.placeholder;
    navigator.clipboard.writeText(copy);
    this.toastrService.success(this.translate.instant('sucessfullyCopiedToClipboard'), '', {
      timeOut: 3000
    })
  }

  public onCopyWalletAddress(wallet:WalletModel){
    if(wallet.cryptoDataSource == "Manual"){
      navigator.clipboard.writeText(wallet.address);
      this.toastrService.success(this.translate.instant('sucessfullyCopiedToClipboard'), '', {
        timeOut: 3000
      })
    } else {
      this.getWalletForAsset(wallet, true);
    }

  }

  public getWalletForAsset(wallet:WalletModel, copy?:boolean){
    if(!copy){
      this.loading.push(true)
    }
    this.paymentService.getUserWallets(this.user?.email, wallet.asset).subscribe({
      next: (response: any) => {
        if(copy){
          navigator.clipboard.writeText(response.cryptoAddress.address);
          this.toastrService.success(this.translate.instant('sucessfullyCopiedToClipboard'), '', {
            timeOut: 3000
          })
          return
        }
        window.open(this.getLink(response.cryptoAddress.address), '_blank');
        this.loading.pop()
      },
      error: (e:any) => {
        this.exchangeError(e);
        this.loading.pop()
      }
    })
  }

  public getWallet(wallet:WalletModel, copy?:boolean){
    if(this.currentExchangeWallets.includes(wallet.id + wallet.asset)){
      return;
    } 
    this.currentExchangeWallets.push(wallet.id + wallet.asset);
    this.paymentService.getUserWallets(this.user?.email, wallet.asset).subscribe({
      next: (response: any) => {       
        wallet.address = response.cryptoAddress?.address;
      },
      error: (e:any) => {
        this.exchangeError(e);
        this.loading.pop();
      }
    })
  }

  exchangeError (serverError:any) {
    console.log(serverError)
    if (serverError.error.Message?.includes('Kraken') || serverError.error.ErrorCode?.includes('KrakenError')) {
       this.toastrService.error(
        `${this.translate.instant('krakenMessage')} "${serverError.error.Message}" ${this.translate.instant('pleaseAccess')}`,
        this.translate.instant('krakenError'),
        {
          enableHtml: true,
          timeOut: 10000
        })
    } else {
      this.toastrService.error(`${serverError.error?.Message.includes('Exchange') ? 'Exchange Error - ' : undefined} ${serverError.error?.Message.replace('System.Exception').split('\r')[0]}`,'',{
        timeOut: 10000
      })
    }
  }

  openInExplorer(wallet:WalletModel){
    this.choosenAsset = wallet.asset;
    if(wallet.cryptoDataSource == "Manual"){
      window.open(this.getLink(wallet.address), '_blank');
      return
    } else {
      // this.getWalletForAsset(wallet);
      return
    }
  }

  checkAddressInExplorer(address:string){
    if(address){
      if(this.acceptedNetworksOnPrivateWallet.length > 0) {
        window.open(this.getPrivateWalletLink(address), '_blank');
      }
      return
    }
  }

  getPrivateWalletLink(cryptoAddress:string){
    if (this.acceptedNetworksOnPrivateWallet.includes('BTC')) {
      return btcAddress + "address/" + cryptoAddress;
    }
    if (this.acceptedNetworksOnPrivateWallet.includes('ETH')) {
      return ethAddress + "address/" + cryptoAddress;
    }
    if (this.acceptedNetworksOnPrivateWallet.includes('MATIC')) {
      return polyAddress + "address/" + cryptoAddress;
    }
    if (this.acceptedNetworksOnPrivateWallet.includes('BEP')) {
      return bnbAddress + "address/" + cryptoAddress;
    }
    return '';
  }

  getWalletAddress(wallet:WalletModel) {
      this.getWallet(wallet);
  }

  public onCopyShortId() {
    const shortId = this.shortId.nativeElement.placeholder;
    navigator.clipboard.writeText(shortId);
    this.toastrService.success(this.translate.instant('PROFILE.yourUuidHasBeenCopiedToTheClipboard'), '', {
      timeOut: 3000
    })
  }

  public checkExchangeConnection(exchangeId:string, index:number){
    this.loading.push(true)
    this.userService.checkExchangeConnection(this.JWTToken, exchangeId).subscribe({
      next: (response:any) => {
        if(response.isValid == true){
          this.toastrService.success(this.translate.instant('PROFILE.thisExchangeConnectionIsValid'))
        } else {
          this.toastrService.error(this.translate.instant('PROFILE.thisExchangeConnectionIsntValidAnymore'))
          this.userExchanges[index].isValid = false;
        }
        this.loading.pop()
      },
      error: (e:any) => {
        this.toastrService.error(this.translate.instant('unknownError'))
        this.loading.pop();
      }
    })
  }

  public checkDNS() {
    this.userService.checkDNS(this.JWTToken, this.domain.name).subscribe({
      next: (response: any) => {
        this.getUserDomain(true)
      },
      error: (e: any) => this.handleError(e),
    })
  }

  copyDNS() {
    navigator.clipboard.writeText( this.domain?.validationKey || '' );
    this.toastrService.success(this.translate.instant('sucessfullyCopiedToClipboard'));
  }


  onCountrySelected(event:any){
    this.country = event;
    this.selectingCountry = false;
  }

  // getBackgroundFlag(){
  //   if(!this.country){
  //     return `background-image: url("/assets/icons/no-country-icon.png")`
  //   }
  //   return `background-image: url("/assets/svg-country-flags/svg/${this.country.alpha2Code}.svg")`
  // }


  populateRegexp() {
    this.numbersLettersAndSpecial = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])[a-zA-Z\d!@#\$%\^&\*]{3,}$/;
    this.atLeast8Digits = /^.{8,}$/;
    this.atLeast1SpecialCharacter = /[!@#\$%\^&\*]+/;
  }

  closeModal(event: any) {
    this.showModal = false;
    this.modalDeleteUser = false;
    this.modalDeleteExchange = false;
  }

  deleteUser(confirm?: any) {
    if (!confirm) {
      this.modalDeleteUser = true;
    } else {
      this.loading.push(true)
      this.userService.deleteUser(this.JWTToken).subscribe({
        next: (response: any) => {
          this.toastrService.success(this.translate.instant('PROFILE.userSuccessfullyDeleted'), '', {
            timeOut: 5000
          });
          this.auth.logout({
            logoutParams: { returnTo: environment.websiteUrl }
          })
          this.loading.pop();
        },
        error: (e: any) => {console.log(e); this.handleError(e)},
        complete: () => this.loading.pop()
      })
    }
  }

  public handleError(error: any) {
    console.log(error)
    switch (error.status.toString()) {
      case '500': 
      if(error?.error?.Message?.includes('DELETE statement conflicted with the REFERENCE constraint')){
        this.toastrService.error(this.translate.instant('inOrderToDeleteYourAccountPlease'), '', {
          timeOut: 10000
        });
        break;
      }
      this.toastrService.error(this.translate.instant('unknownError'), '', {
        timeOut: 3000
      });
        break;
      case '400':
        if (error.error.errors.q == "The q field is required.") {
          this.toastrService.error("The field must have an value before submit", '', {
            timeOut: 3000
          })
        } else {
          this.toastrService.error(error.error.errors, '', {
            timeOut: 3000
          })
        }
        break;
      case '404':
        this.toastrService.error(error.statusText, '', {
          timeOut: 3000
        })
        break;
      default:
        this.toastrService.error(this.translate.instant('unknownError'), '', {
          timeOut: 3000
        })
    }
    this.loading.pop();
  }
}
